import {Grid, List, ListItem, ListItemText} from '@mui/material'
import SummaryChart from './SummaryChart'

import {styled} from '@mui/system';

const StyledGrid = styled(Grid) (({theme}) => ({
    width:'100vh', 
            overflow:'auto',
            '&::-webkit-scrollbar': {
                width: "8px",
                height: "8px",
            },
            //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.3)",
                'border-radius': "10px",
                'background': 'rgba(0,0,0,0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
                'border-radius': '10px',
                'background': 'linear-gradient(left, #999, #888)',
                '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.8)',
            },
            // '&::-webkit-scrollbar-thumb':{
            //     'background': 'linear-gradient(left, #888, #777)'
            // },
}));

const StyledListItemText = styled(ListItemText) (({theme}) => ({
    fontSize:'14px', //https://stackoverflow.com/questions/51801916/material-ui-how-to-change-fontsize-in-lists
}));

const Summary = (props) => {
	const {workspaceId,mainLayoutId,priceParams, width, height} = props
    
    
	return (
		<StyledGrid item container direction="row" spacing={2}>
                    
            <Grid item style={{minWidth:300}} display='inline'>
                <List>
                    <ListItem divider={true} style={{color:'white'}} >
                        <StyledListItemText primary="Open" />
                        <StyledListItemText primary={priceParams['OpenPrice']} primaryTypographyProps={{align:'right'}}/>
                    </ListItem> 
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="Volume" />
                        <StyledListItemText primary={priceParams['volume']} primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="Day's Range" />
                        <StyledListItemText primary={`${priceParams['LowestPrice']} - ${priceParams['HighestPrice']}`} primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="52 Week Range" />
                        <StyledListItemText primary={`${priceParams['min52W']} - ${priceParams['max52W']}`} primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="Beta"  />
                        <StyledListItemText primary={priceParams['Beta']}  primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="PE"  />
                        <StyledListItemText primary={priceParams['PE']}  primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="Market Cap" />
                        <StyledListItemText primary={priceParams['MarketCap']} primaryTypographyProps={{align:'right'}}/>
                    </ListItem>
                    <ListItem divider={true} style={{color:'white'}}>
                        <StyledListItemText primary="Outstanding Shares"  />
                        <StyledListItemText primary={priceParams['OutstandingShares']}  primaryTypographyProps={{align:'right'}}/>
                    </ListItem>  
                </List> 
            </Grid>
            <Grid item style={{minWidth:600}}>
                <SummaryChart
                    workspaceId={workspaceId} 
                    mainLayoutId={mainLayoutId}
                    width={width}
                    height={height} 
                />
                {/* <span style={{color:'white'}}>here</span> */}
            </Grid>
		</StyledGrid>
	)
}

export default Summary