import {useState, useRef, memo, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
// import isDeepEqual from 'fast-deep-equal'
import MarketDataService from "../../../services/MarketService";
import IndicatorCalculation from "./analysis/indicatorCalculation"

import {parseHistoricalData} from './processRawData'
import InitializeChart from './DrawChart1'
import AnalysisModal from './AnalysisModal'
import axios from 'axios'
import { refreshStyle, analysisStyle } from '../../UniversalComponents/cssStyle/iconStyle';

import RefreshIcon from '@mui/icons-material/Refresh';
import {Tooltip, IconButton, Button} from '@mui/material';
import * as mainChartActions from '../../../js/actions/mainChartActions';
const Charting = (props) => {
    // console.log('chart is rendered') 
    const d3Container = useRef(null)
    const workspaceId = props.workspaceId
    const {id, layoutProps} = props.layout
    // const id = props.layout.id.toString()
    const chartId = `layout${id.toString()}`
    const cols = props.mainBreakpoint.currentCols || 12
    // console.log(reduxStore.mainChart, workspaceId, id)
    const chartParams = useSelector(state => state.mainChart[workspaceId][id])
    const {tickerParams,svgParams,indicators,analysis} = chartParams
    const dispatch = useDispatch()
    const initTimeSeriesData = {
        historicalPrice:[],
        news:'',
    }
    const [timeSeriesData,setTimeSeriesData] = useState(initTimeSeriesData)
    const [modalShow, setModalShow] = useState(false)
    

    const tickerList = tickerParams.filter(item => item.ticker).map(item => item.ticker ) 
    
    const fetchHistoricalPrice = () => {
            
            // MarketDataService.getHistoricalPrice(ticker)
            //                 .then(response => {
                                
            //                     const processedhistoricalPrice = parseHistoricalData(response.data)
                                
            //                     setTimeSeriesData(prevState => ({...prevState, historicalPrice: processedhistoricalPrice}))
            //                     const tickerValue = props.layout? [props.layout.id, ticker] : ticker
            //                     actions.then(a => dispatch(a.changeTicker(tickerValue)));
            //                 })
            //                 .catch(e => console.log(e))
            // if (analysis.news.enableNews) {
            //     const f = MarketDataService['getNews'] 
            //     f(tickerParams[0].ticker)
            //     .then(response => {
            //         setTimeSeriesData(prevState => ({...prevState, news:response.data})) 
            //         // dispatch(getTickerNews(response.data)) 
                    
            //     })
            //     .catch(e => console.log(e))
            // }
            
            // console.log(tickerList)
            MarketDataService.getHistoricalPrice1(tickerList)
                            .then(axios.spread((...responses) => {
                                let data = responses.map(response => parseHistoricalData(response.data))
                                // console.log(data)
                                setTimeSeriesData(prevState => ({...prevState, historicalPrice: data}))
                            }))
                            .catch(e => console.log(e))
                                
                                                 
        
    }
    // to get indicator data by calculating based on timeseries data of symbol
    const indicatorTimeSeriesData = () => {
        let accumulatedIndicatorHeight = 0
        for (const indicator of indicators) {
            if (indicator !== 'volume') {
                let selectedTicker = analysis[indicator]['ticker']
                // let indicatorPosition = analysis[indicator]['position']
                let indexOfTicker = tickerList.includes(selectedTicker)? tickerList.indexOf(selectedTicker) : 0
                // if (indicatorPosition === 'sub') {
                    accumulatedIndicatorHeight += analysis[indicator]['indicatorHeight']
                    // let f = IndicatorCalculation[indicator]
                    // timeSeriesData[indicator] = f(timeSeriesData.historicalPrice[indexOfTicker],analysis[indicator])
                    // https://stackoverflow.com/questions/1168807/how-can-i-add-a-key-value-pair-to-a-javascript-object
                // }
                 
                timeSeriesData[indicator] = IndicatorCalculation.indicators(timeSeriesData.historicalPrice[indexOfTicker],indicator,analysis[indicator])
            }
        }
        
        const adjustSVGHeight = svgParams.height - accumulatedIndicatorHeight//* 0.75 * indicators.length
        // console.log(adjustSVGHeight)
        dispatch(mainChartActions.adjustSVGParamsMainChart([workspaceId,id,{
            adjustedHeight:adjustSVGHeight
        }]))
        setTimeSeriesData({...timeSeriesData})
    }
    //add the Event Listener to auto resize the svg's width and height
    //useDeepCompareEffect hook to ensure ONLY selected chart's svg is updated
    
    useDeepCompareEffect(() => {
        if(tickerParams.length> 0){
            // console.log(`update demension-${chartId}`)
            // console.log(layoutProps)
            updateDimensions()
        }
        // window.addEventListener('resize', updateDimensions)
        //return () => window.removeEventListener("resize",updateDimensions)
        // return () => setValue(null)
    },[layoutProps,tickerParams])

    const updateDimensions = () => {
        const buttonHeightSize = 0
        let accumulatedIndicatorHeight = 0
        for (const indicator of indicators) {
            // let indicatorPosition = analysis[indicator]['position']
            // if (indicatorPosition === 'sub') {
                accumulatedIndicatorHeight += analysis[indicator]['indicatorHeight']
            // }
        }    
        dispatch(mainChartActions.adjustSVGParamsMainChart([workspaceId,id,{
            height: ((layoutProps.h - 1) * 30 ) - (svgParams.margin.top + svgParams.margin.bottom) - buttonHeightSize,//* 0.8429 * 1.,
            width : (window.innerWidth * layoutProps.w / cols) - (svgParams.margin.left + svgParams.margin.right), //* 0.8429,
            adjustedHeight: (((layoutProps.h - 1) * 30 )- (svgParams.margin.top + svgParams.margin.bottom) ) - buttonHeightSize - accumulatedIndicatorHeight,
        }]))
        // because to use object a dependency may cause infinite loop in useEffect
        // , we must stringify the chartParams.
        // https://dmitripavlutin.com/react-useeffect-infinite-loop/
        // setValue(JSON.stringify(newDimension))
        
    }


    useDeepCompareEffect(() => {
        if (tickerParams.length > 0) {
            fetchHistoricalPrice()
        }
    },[tickerParams])
    // loading indicator data
    useDeepCompareEffect(() => {
        // console.log(chartParams.indicators.length)
        if (timeSeriesData.historicalPrice && timeSeriesData.historicalPrice.length && indicators.length >= 1){
            
            indicatorTimeSeriesData()
            // setValue('indicator')
        }
    },[timeSeriesData.historicalPrice,analysis,indicators])
    
    useEffect(() => {
        
        // console.log(svgParams)
        if (timeSeriesData.historicalPrice.length === tickerParams.length && tickerParams.length > 0){
            //algorithm to get the index of the longest Object in a Array.
            const indexOfLongestArray = timeSeriesData.historicalPrice
                                                    .reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0);
            // console.log(indexOfLongestArray)
            // determine there is a YAxis on the left of chart.
            // let isYAxisLeft = chartParams.tickerParams.map(item =>  Object.values(item).includes('L1')).every(x => x === false)
            // let numberOfLeftAxis = 0
            // let numberOfRightAxis = 0
            // for (let i = 0; i< chartParams.tickerParams.length; i++){
            //     let side = [...chartParams.tickerParams[i]['axis']][0]
            //     console.log(side)
            //     side === 'L'? numberOfLeftAxis++ : numberOfRightAxis++
            // }
                                                
            InitializeChart(d3Container.current,
                            {timeSeriesData, 
                            chartParams:chartParams, 
                            chartId:chartId,
                            indexOfLongestArray:indexOfLongestArray,
                            },
                            
                            )
        }
    },[timeSeriesData,tickerParams,svgParams])
    
    
    const handleRefresh = () => {
        fetchHistoricalPrice()
    }
    // const updateTimeSeriesData = (indicatorName) => {
    //     const {[indicatorName]:value, ...newState} = timeSeriesData
    //     // console.log(newState)
    //     setTimeSeriesData(newState)
    // } 
    
    return (
        
        <div className="flex-container">
           {tickerParams.length > 0 ?
                <div>
                    <div className="flex-item">
                            <div>
                                <Button
                                    // variant="outlined"
                                    color="secondary"
                                    style={analysisStyle}
                                    onClick={() => setModalShow(true)}
                                >
                                    Analysis
                                </Button>
                                <Tooltip title="Refresh">
                                    <IconButton 
                                        aria-label="refresh" 
                                        style={refreshStyle}
                                        onClick={handleRefresh}
                                    >
                                        <RefreshIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            
                    </div>
                    
                    <div className="flex-item">
                        <div ref={d3Container} > { /*onMouseDown={e => e.stopPropagation()}> /*onWheel={handleWheel}>*/}
                            <svg id={chartId} />
                        </div>
                    </div>
                    {modalShow &&
                    <div onMouseDown={e => e.stopPropagation()}> 
                    {/* IMPORTANT capture the grid item's click and cancel the drag event  https://stackoverflow.com/questions/38543470/capturing-grid-item-click-event-in-react-grid-layout */}
                        <AnalysisModal
                            workspaceId={workspaceId}
                            mainChartActions ={mainChartActions}
                            // chartId={chartId}
                            timeSeriesData = {timeSeriesData}
                            layout={props.layout}
                            chartParams={chartParams}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                    }
                </div>
            :null}
            
        </div>

    )
}
export default Charting