const CREATE_SIMPLE_CHART = 'CREATE_SIMPLE_CHART'
// const INITIATE_SIMPLE_CHART = 'INITIATE_SIMPLE_CHART'
const REMOVE_SIMPLE_CHART = 'REMOVE_SIMPLE_CHART'

const UPDATE_TICKER_AND_SIGNAL_NAME = 'UPDATE_TICKER_AND_SIGNAL_NAME'

const ADJUST_SVG_PARAMS = 'ADJUST_SVG_PARAMS'
const CHANGE_TICKER = 'CHANGE_TICKER'

export const createSimpleChart = payload => {
    return {
        type: CREATE_SIMPLE_CHART,
        payload
    }
}

// export const initiateSimpleChart = payload => {
//     return {
//         type: INITIATE_SIMPLE_CHART,
//         payload
//     }
// }

export const removeSimpleChart = payload => {
    return {
        type: REMOVE_SIMPLE_CHART,
        payload
    }
}


export const updateTickerAndSignalName = payload => {
    return {
        type: UPDATE_TICKER_AND_SIGNAL_NAME,
        payload
    }
}

export const adjustSVGParams = payload => {
    return {
        type: ADJUST_SVG_PARAMS,
        payload
    }
}

export const changeTicker = payload => {
    return {
        type: CHANGE_TICKER,
        payload
    }
}
