import { Responsive as ResponsiveReactGridLayout} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {useSelector,useDispatch} from 'react-redux'
import _ from "lodash";
import WatchList from './WatcherComponent/WatchList/WatchList'
import SignalType from './WatcherComponent/SignalType'
import { useEffect, useRef, memo } from "react";
import { useDeepCompareEffect } from "react-use";
import isDeepEqual from 'fast-deep-equal'
import MarketDataService from "../../../services/MarketService";
import {updateSubLayoutSize , addSignaledTicker, refreshWatcherGrid, updateWatcherLayoutsChange} from '../../../js/actions/watcherActions'
import SimpleCharting from "./WatcherComponent/SimpleChart/SimpleChart";
// import Header from "./WatcherComponent/Header";
import Header from "../../UniversalComponents/Header";
import { refreshStyle } from "../../UniversalComponents/cssStyle/iconStyle";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Tooltip, IconButton } from "@mui/material";

const signalComponents = {
  watchList: WatchList,
  chart: SimpleCharting,
  signalType: SignalType,
  header:Header,
}

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const Watcher = (props) => {
    // console.log('watcher rendered')
    const dispatch = useDispatch()
    const inputRef = useRef()
    const {id,layoutProps, title, component, isFullscreen} = props.layout
    const workspaceId = props.workspaceId
    const {currentBreakpoint, currentCols} = props.mainBreakpoint
    const mainLayoutProps = layoutProps
    const storedWatcherItems = useSelector(state => state.watcher[workspaceId])
    const tickerWatchList = useSelector(state => state.tickerInWatchList[workspaceId][id])
    const signalWatchList = useSelector(state => state.signalInWatchList[workspaceId][id])
    
    
    
    let subLayouts = storedWatcherItems[id]
    // if (storedWatcherItems[id]){
        // subLayouts = storedWatcherItems[id]
    let subLayoutProps = subLayouts.map(item=> ({...item.layoutProps}))
    // } else {
    //     // subLayouts = initSubLayouts
    //     subLayoutProps = initSubLayouts.map((item) => item.layoutProps)
    //     dispatch(addNewWatcherLayout([id,initSubLayouts]))
    //     dispatch(initiateTickerWatchList([id,[]]))
    //     dispatch(initiateSignalWatchList([id,['seq5DayRise']]))
    //     dispatch(initiateSimpleChart([id]))
    // }
    
    const generateDOM = () => {
        //if a mainLayout is initially created, the subLayout will be the initSubLayouts,
        //otherwise, it will select the storedSignalLayout
        // let subLayouts = null
        // if (Object.keys(storedWatcherItems[mainLayoutId]).includes(mainLayoutId.toString())) {
        //     subLayouts = storedWatcherItem
        // } else {
        //     subLayouts = initSubLayouts
            
        // }
        return _.map(subLayouts, (l,idx) => {
            let watcherComponentName = l.watcherComponentName
            // if(component) {
            const SpecificLayout = signalComponents[l.watcherComponentName]
            return (
            
                <div key={l.layoutProps.i} data-grid={l.layoutProps}>
                    {watcherComponentName==='header' ?
                        <>
                        <Header
                            workspaceId={workspaceId} 
                            title={title}
                            inputRef={inputRef}
                            componentId={id}
                            isFullscreen={isFullscreen}
                            componentName={component}
                            
                        />
                        <Tooltip title="Refresh">
                            <IconButton 
                                aria-label="refresh" 
                                style={refreshStyle}
                                onClick={handleRefresh}
                            >
                                <RefreshIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        </>
                    : <SpecificLayout workspaceId={workspaceId} layout={l} mainLayoutId={id} currentCols={currentCols}/>
                    }
                </div>
            )
            
        }).reverse()// to render the grid as LIFO method. This is to display autocomplete panel in WatchList layout on top of others
    }

    const handleRefresh = () => {
        let signalFormData = {
            tickers:tickerWatchList,
            signals:signalWatchList
        } 
        // console.log(signalFormData) 
        MarketDataService.getSignal(signalFormData).then(
            res => {
                    let returnData = res.data
                    dispatch(addSignaledTicker([workspaceId,id,returnData]))

                    
            })
            .catch(error => {alert(error)})
    }
    
    useDeepCompareEffect(() => {
        // console.log(tickerWatchList)
        if(tickerWatchList.length > 0){
            let signalFormData = {
                tickers:tickerWatchList,
                signals:signalWatchList
            } 
            // console.log(signalFormData) 
            MarketDataService.getSignal(signalFormData).then(
                res => {
                        let returnData = res.data
                        dispatch(addSignaledTicker([workspaceId,id,returnData]))

                        
                })
                .catch(error => {alert(error)})
            
        }
    },[tickerWatchList,signalWatchList])

    useEffect(() => {
        // console.log('store watcher called')
        if(signalWatchList.length>0){
            let gridLayoutItem = {
                id:'',
                layoutProps:{
                    x:0,
                    y:'',
                    w:2,
                    h:4,
                    maxW:2,
                    i:''
                },
                watcherComponentName:'signalType',
                signalName:'',
                tickers:[]
            }
            // alert('True')
            let gridLayouts = []
            for (let i = 0; i < signalWatchList.length;i++) {
                let item = {
                    ...gridLayoutItem,
                    id:id,
                    layoutProps:{
                        ...gridLayoutItem.layoutProps,
                        y:5+4*i,
                        i:`${id+1}.${4+i}`
                    },
                    signalName:signalWatchList[i]
                }
                gridLayouts.push(item)
            }
            // console.log(gridLayouts)
            dispatch(refreshWatcherGrid([workspaceId,id,gridLayouts]))
        }
    },[signalWatchList])

    useEffect(() => {
        // if(subLayoutProps['w'] !== mainLayoutProps['w']){
            
            dispatch(updateSubLayoutSize([workspaceId,id,mainLayoutProps]))
        // }
    },[mainLayoutProps])

    // const calculateWH = (widthPx, heightPx, colWidth, rowHeight, margin) => {
    //     let w = Math.ceil((widthPx - margin[0]) / (colWidth + margin[0]));
    //     let h = Math.ceil((heightPx - margin[1]) / (rowHeight + margin[1]));
    //     return [w, h];
    // };
    
    // const calculatePxWH = (w,h,colWidth,rowHeight,margin) => {
    //     let widthPx = Math.ceil(w * (colWidth + margin[0]) + margin[0])
    //     let heightPx = Math.ceil(h * (rowHeight + margin[1]) + margin[1])
    //     return [widthPx,heightPx]
    // }
    

    // const onLayoutChange = (layout,layouts) => {
    //     console.log(layout)
    //     dispatch(updateWatcherLayoutsChange([id,layout]))
    // }
    // useEffect(() => {
    //     console.log(calculateWH(window.innerWidth,window.innerHeight,12,30,[0,0]))
    //     console.log(calculatePxWH(1,4,12,30,[0,0]))
    // })
    return (
        <>
            <ResponsiveReactGridLayout
                width={props.width} //KEY IMPORTANT 
                // onLayoutChange={(layout,layouts)=>onLayoutChange(layout,layouts)}
                {...props} style={{background:'black'}}
                layouts={{[currentBreakpoint]:subLayoutProps}}
                // measureBeforeMount = {true}
            >

                {subLayouts && generateDOM()}
            </ResponsiveReactGridLayout>
        </>
    );
}
// https://github.com/react-grid-layout/react-grid-layout/issues/1095 -> Changing x, y, w, h or static in state does not trigger layout update

Watcher.defaultProps = {
//   isDraggable: true,
//   isResizable: true,
//   preventCollision:true,
//   autoSize:true,
//   isBounded:true,
//   items: 5,
  rowHeight: 30,
//   verticalCompact:true,
//   width: 1920, //KEY IMPORTANT
  // onLayoutChange: function (layouts) {return layouts},
  breakpoints:{lg: 1500, md: 996, sm: 768, xs: 480, xxs: 0},
  cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
  margin:[0,0]
  
};
export default Watcher