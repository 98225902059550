import Editable from "../UniversalComponents/Editable"
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import MinimizeIcon from '@mui/icons-material/Minimize';

import { minimizeStyle, fullscreenStyle, removeStyle } from "./cssStyle/iconStyle";
import { updateComponentTitle,updateLayoutChangeFullScreen,removeLayout, updateLayoutChangeHidden } from "../../js/actions/dashboardActions";
import { removeWatcherLayout, removeSignalWatchList, removeTickerWatchList} from '../../js/actions/watcherActions'
import { removeSimpleChart } from "../../js/actions/simpleChartActions";
import { removeNewsLayout } from "../../js/actions/newsAction";
import { removeOverview } from "../../js/actions/overviewActions";
import Autocomplete from "./Autocomplete";
import MarketDataService from "../../services/MarketService";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { removePriceBoard } from "../../js/actions/priceBoardActions";
import { removeMainChart } from "../../js/actions/mainChartActions";

const reduxAction = {
  overview : 'overviewAction',
  chart:'mainChartActions',
  priceboard:'priceBoardActions',
  news: 'newsActions'
}

const Header = (props) => {
	const {workspaceId, title, inputRef, componentId,isFullscreen,componentName } = props
	const dispatch = useDispatch()
	const [symbolList, setSymbolList] = useState()
	const [userInput, setUserInput] = useState(title)
	const handleFullScreenEvent = (id,isFullscreen) => {
		const onFullScreen  = typeof isFullscreen === 'boolean' ? true : false
		// const hidden = typeof isHidden === 'boolean'? layouts : false
		dispatch(updateLayoutChangeFullScreen([workspaceId,id,onFullScreen]))
	}
	// const handleHiddenEvent = (id) => {
	// dispatch(updateLayoutChangeHidden([id, true]))
	// }
	const onRemoveLayout = (i,componentName) => {
		// console.log(layouts)
		// setReduxStore([
		let mainLayoutId = parseInt(i)
		//   ...reduxStore.filter(item => item.id !== (parseInt(i)-1))
		// ])
		dispatch(removeLayout([workspaceId,i]))
		if (componentName ==='watcher') {
			dispatch(removeWatcherLayout([workspaceId,mainLayoutId]))
			dispatch(removeSignalWatchList([workspaceId,mainLayoutId]))
			dispatch(removeTickerWatchList([workspaceId,mainLayoutId]))
			dispatch(removeSimpleChart([workspaceId,mainLayoutId]))
		} else if (componentName ==='news') {
			dispatch(removeNewsLayout([workspaceId,mainLayoutId]))
		} else if (componentName ==='overview') {
			dispatch(removeOverview([workspaceId,mainLayoutId]))
		} else if (componentName === 'priceboard') {
			dispatch(removePriceBoard([workspaceId,mainLayoutId]))
		} else if (componentName === 'chart') {
			dispatch(removeMainChart([workspaceId,mainLayoutId]))
		}
	}
	const handleChange = (e) => {
		setUserInput(e.target.value)
	}
	const handleKeyDown = (e) => {
		if(e.key === 'Enter' || e.key === 'Escape'){
			dispatch(updateComponentTitle([workspaceId,componentId,userInput]))
		}
	}
	useEffect(()=>{
		symbolListInfo()
	},[])
	const symbolListInfo = () => {
		try {
			if (componentName ==='news') {
				Promise.all([
					MarketDataService.getSymbolList(),
					MarketDataService.getNewsSources()
				]).then((values) => {
					
					setSymbolList(values[0]['data'].concat(values[1]['data']))
				})
			} else {
				MarketDataService.getSymbolList()
				.then(response => {
					setSymbolList(response.data)
				})
			}
		
		} catch(error) {
		// console.log(error)
		}
	}
	return (
		<div
			style={{
				display: 'flex',
				//     justifyContent: 'Right',
				//     alignItems: 'Right',
				height: '30px',
				backgroundColor:'#43464B'
			}} 
		>
			<div onMouseDown={e => e.stopPropagation()}>
				{/* autocomplete is only rendered for Overview, Chart and PriceBoard */}
				{Object.keys(reduxAction).includes(componentName) ?
					<Autocomplete
						workspaceId={workspaceId}
						layoutId = {componentId}
						suggestions={symbolList}
						reduxActionName={reduxAction[componentName]}
						filterKeys={['symbol','long_name']}
						placeholderName={'Enter Symbol or Full Name'}
						isNestedList={false}
						positionOfSuggestion={30}
					/>
					:null
				}
			</div>
			<div onMouseDown={e => e.stopPropagation()}>
				<Tooltip title="Edit your title">
					<div>
					<Editable
						text={title}
						placeholder={`Untitled${componentId}`}
						childRef={inputRef}
						type='input'
						// style={{position:'absolute',right:'300px'}}
					>
						<input
							ref={inputRef} 
							type='text'
							name='title'
							placeholder='Edit title'
							value={userInput}
							style={{background:'none',color:'orange'}}
							onChange={handleChange}
							onKeyDown={handleKeyDown}
						/>
					</Editable>
					</div>
				</Tooltip>
				{/* a button to trigger fullscreen event */}
			</div>
			<div onMouseDown={e => e.stopPropagation()}>
				<Tooltip title="Minimize">
					
					<IconButton 
						aria-label="minimize" 
						style={minimizeStyle}
						onClick={() => dispatch(updateLayoutChangeHidden([workspaceId,componentId,true]))}//,isHidden)}
					>
						<MinimizeIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/>
					</IconButton>
					
				</Tooltip>
				{/* a button to trigger fullscreen event */}
				<Tooltip title="Full screen">
					
					<IconButton 
						aria-label="fullscreen" 
						style={fullscreenStyle}
						onClick={() => handleFullScreenEvent(componentId,isFullscreen)}//,isHidden)}
					>
						{!isFullscreen ? <FullscreenSharpIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/> 
								: <FullscreenExitSharpIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/>}
					</IconButton>
					
				</Tooltip>
				{/* a button to trigger delete event */}
				<Tooltip title="Delete">
					
					<IconButton 
						aria-label="delete" 
						style={removeStyle}
						onClick={() => onRemoveLayout(componentId,componentName)}
					>
						<DeleteIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/>
					</IconButton>
					
				</Tooltip>
			</div >	
		</div>
	)
}

export default Header