const initState = {
    items:[{
        id:0,
        layoutProps:{
            i:"0",
            x:0,
            y:0,
            w:4,
            h:16
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:null,
        title:'Untitled-0',
        isHidden:false,
        isFullscreen:false,
    },
],
    newCounter:0,
    workspaceLabel:null
}
const firstState={
    items:[{
        id:0,
        layoutProps:{
            i:"0",
            x:0,
            y:0,
            w:4,
            h:14
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:"news",
        title:'Global News',
        isHidden:false,
        isFullscreen:false,
    },
    {
        id:1,
        layoutProps:{
            i:"1",
            x:4,
            y:0,
            w:4,
            h:14
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:"priceboard",
        title:'Vietnam stocks',
        isHidden:false,
        isFullscreen:false,
    },
    {
        id:2,
        layoutProps:{
            i:"2",
            x:8,
            y:0,
            w:4,
            h:14
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:"priceboard",
        title:'Global stocks',
        isHidden:false,
        isFullscreen:false,
    },
    {
        id:3,
        layoutProps:{
            i:"3",
            x:6,
            y:14,
            w:6,
            h:17
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:"marketmap",
        title:'VNIndex Market Map',
        isHidden:false,
        isFullscreen:false,
    },
    {
        id:4,
        layoutProps:{
            i:"4",
            x:0,
            y:14,
            w:6,
            h:17
        },
        buttons: {
            name:["Overview","Price Board","Chart",'Watcher','News','Market Map','Market Research','Macroeconomic','Quotes'],
            value:["overview","priceboard",'chart', 'watcher','news','marketmap','marketresearch','macroeconomic','quotes']
        },
        component:"chart",
        title:'S&P 500',
        isHidden:false,
        isFullscreen:false,
    }
],
    newCounter:4,
    workspaceLabel:'Workspace-0'
}
export const dashboardReducer = (state={0:firstState} ,action) => {
    switch(action.type){
        case 'CREATE_NEW_WORKSPACE':{
            let [workspaceId, workspaceLabel] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...initState,
                    workspaceLabel:workspaceLabel
                }
            }
        }
        case 'DELETE_WORKSPACE':{
            let workspaceId = action.payload
            let {[workspaceId]:value, ...newState} = state
            return newState
        }

        case 'MODIFY_WORKSPACE_LABEL':{
            let [workspaceId, label] = action.payload
            // console.log(action.payload)
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    workspaceLabel: label
                }
            }
        }
        case 'ADD_NEW_LAYOUT':{
            let [workspaceId,newLayout,counter] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    items:[
                        ...state[workspaceId].items,
                        newLayout
                    ],
                    newCounter:counter + 1
                }

            }
        }    
        case 'REMOVE_LAYOUT': {
            let [workspaceId, mainLayoutId]= action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    items: [
                        
                        ...state[workspaceId].items.filter((item,index)=> item.id !== mainLayoutId)
                    ]
                }    
            }
        }
        case 'ADD_COMPONENT_INTO_LAYOUT':{
            const [workspaceId, mainLayoutId, boardType] = action.payload
            
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    items:[
                            ...state[workspaceId].items.map((item) => {
                                if(item.id === mainLayoutId){
                                    return {
                                            ...item,
                                            component : boardType
                                    }
                                }
                                return item
                            })
                        ]}
                }
        }
        case 'UPDATE_COMPONENT_TITLE':{
            const [workspaceId, mainLayoutId, componentTitle] = action.payload
            // console.log(action.payload)
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    items:[
                            ...state[workspaceId].items.map((item) => {
                                if(item.id === mainLayoutId){
                                    return {
                                        ...item,
                                        // layoutProps:{
                                        //     ...item.layoutProps,
                                        //     i:boardType
                                        // },
                                        
                                        title : componentTitle
                                        
                                    }
                                }
                                return item
                            })
                        ]}
                }
        }
        case 'UPDATE_LAYOUT_CHANGE': {
            // console.log('callback call')
            let [workspaceId,layouts] = action.payload
            let mainState = {
                ...state,
            }
            // let layouts = action.payload
            layouts.map((l) => {
                let index = mainState[workspaceId].items.findIndex(i => i['layoutProps']['i'] === l['i'])
                let item = mainState[workspaceId].items[index]
                item['layoutProps']=l
                mainState[workspaceId].items[index] = item
            })
            return mainState
            // let newLayoutProps = action.payload
            // console.log(newLayoutProps)
            // let mainState = {...state}
            // let index = mainState.items.findIndex(item => item['layoutProps']['i'] === newLayoutProps['i'])
            // let item = mainState.items[index]
            // item['layoutProps'] = newLayoutProps
            // mainState.items[index]=item
            // // push the item to the last position -> https://stackoverflow.com/questions/24909371/move-item-in-array-to-last-position
            // let itemsLength = mainState.items.length
            // mainState.items.splice(itemsLength - 1,0,mainState.items.splice(index,1)[0])
            // return mainState
        }
        case 'UPDATE_LAYOUT_CHANGE_ON_DRAG_RESIZE_EVENT':{
            let [workspaceId,newLayoutProps] = action.payload
            // console.log(newLayoutProps)
            let mainState = {...state}
            let index = mainState[workspaceId].items.findIndex(item => item['layoutProps']['i'] === newLayoutProps['i'])
            let item = mainState[workspaceId].items[index]
            item['layoutProps'] = newLayoutProps
            mainState[workspaceId].items[index]=item
            // push the item to the last position -> https://stackoverflow.com/questions/24909371/move-item-in-array-to-last-position
            let itemsLength = mainState[workspaceId].items.length
            mainState[workspaceId].items.splice(itemsLength - 1,0,mainState[workspaceId].items.splice(index,1)[0])
            return mainState
        }
        case 'CHANGE_LAYOUT_HEIGHT':{
            let [workspaceId,mainLayoutId,height] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    items: [...state[workspaceId].items.map((item, index) => {
                                if(item.id === mainLayoutId) {
                                    return {
                                        ...item,
                                        layoutProps: {
                                            ...item.layoutProps,
                                            h: height
                                        }
                                    }
                                }
                                return item
                            })]
                        }
                }
        }
        case 'UPDATE_LAYOUT_CHANGE_FULLSCREEN': {
            let [workspaceId,mainLayoutId,onFullscreen] = action.payload
            // console.log(...state.items)
            
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    items:[...state[workspaceId].items.map((item,index) => {
                        let oldState = {...item}
                        // console.log(oldState)
                        if(item['id'] === mainLayoutId) {
                            if(onFullscreen === false){
                                // console.log(item['isFullscreen']) 
                                return {
                                    ...item,
                                    layoutProps: oldState['isFullscreen'],
                                    
                                    isFullscreen: false
                                }
                            } 
                            else {
                                // console.log(onFullscreen)
                                return {
                                    ...item,
                                    layoutProps: {
                                        ...item.layoutProps,
                                        x:0,
                                        y:0,
                                        w:12,
                                        h:30
                                    },
                                    isFullscreen: oldState['layoutProps']
                                    
                                }
                            }
                        }
                        return item 
                    })]
                }
            } 
        }

        case 'UPDATE_LAYOUT_CHANGE_HIDDEN': {
            let [workspaceId,mainLayoutId,isHidden] = action.payload
            let mainState = {...state}
            let index = mainState[workspaceId].items.findIndex(item => item.id === mainLayoutId)
            let item = mainState[workspaceId].items[index]
            item['isHidden'] = isHidden
            mainState[workspaceId].items[index]=item
            // push the item to the last position -> https://stackoverflow.com/questions/24909371/move-item-in-array-to-last-position
            let itemsLength = mainState[workspaceId].items.length
            mainState[workspaceId].items.splice(itemsLength - 1,0,mainState[workspaceId].items.splice(index,1)[0])
            return mainState
            
        }
        // case 'ADD_CHART_PARAMS':{
        //     let [id,chart] = action.payload
        //     return {
        //         ...state,
        //         items: [...state.items.map((item, index) => {
        //                     if(item.id === id) {
        //                         return {
        //                             ...item,
        //                                 ...item,
        //                                 chartParams:chart
        //                         }
        //                     }
        //                     return item
        //                 })]
        //             }
        // }
        
        default:
            return state
    }
}