import {useEffect, useState,useRef, useMemo,} from 'react'
import { useDeepCompareEffect } from 'react-use'; 
// import TextField from '@mui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
// import isDeepEqual from 'fast-deep-equal'
// import { usePrevious } from 'react-use';
import MarketDataService from "../../../services/MarketService"
// import ChartModal from './ChartModal'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ShowChartSharpIcon from '@mui/icons-material/ShowChartSharp';
import Table from '../../UniversalComponents/Table'
import axios from 'axios'

import {decode} from 'html-entities'
import { addNewLayout } from '../../../js/actions/dashboardActions';
import { removeTickerOutOfPriceBoard } from '../../../js/actions/priceBoardActions';
import { addTickerIntoMainChartFromHeader } from '../../../js/actions/mainChartActions';
// import _ from "lodash";
// import { webSocketService } from '../../../workers/dedicatedWorker';
// import WorkerBuilder from '../../../workers/worker-builder';
// const instance = new WorkerBuilder(webSocketService)
const PriceBoard =(props) => {
    // console.log('priceBoard is rendered')
    // console.log(props.layout.layoutProps)
    const {id} = props.layout
    const workspaceId = props.workspaceId
    const cols = props.mainBreakpoint.currentCols || 12
    // const reduxStore = useSelector(state => state)
    const tickerList = useSelector(state => state.tickersForPriceBoard[workspaceId][id])
    const counter = useSelector(state => state.dashboard[workspaceId].newCounter)
    const dispatch = useDispatch()
    const [priceBoardParams, setPriceBoardParams] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false) 
    // const url = "wss://ws-feed.pro.coinbase.com"
    // const [restartWorker, setRestartWorker] = useState(false)
    
    // const url= "https://api.pro.coinbase.com"

    // const [lastPrice, setLastPrice] = useState([])
    // const prevPrice = usePrevious(lastPrice)
    // const [chartModalShow, setChartModalShow] = useState(false)
    // const [stockModalShow, setStockModalShow] = useState(false)
    // const [ticker,setTicker] = useState('')
    
    
    const removeStyle = {
    //   position: "absolute",
    //   right: "2px",
    //   top: 0,
      cursor: "pointer",
      color:"white",
    };
    // const webSocketService = (url, tickers) => {
    //     let ws = new WebSocket(url);
    //     let msg = {
    //         type: "subscribe",
    //         product_ids: tickers,
    //         channels: ["ticker"]
    //     };
        
    //     let jsonMsg = JSON.stringify(msg);
        
    //     ws.onopen = () => {ws.send(jsonMsg)};
    //     ws.onmessage = (e) => {
    //         let data = JSON.parse(e.data);
    //         let last = parseInt(data.price)
    //         let open = parseInt(data.open_24h)
    //         let net_change = last - open
    //         let pct_change = (last/open - 1)* 100
    //         console.log(priceBoardParams)
    //         setPriceBoardParams([{
    //                     ticker:data.product_id,
    //                     last: last,
    //                     net_change: net_change,
    //                     pct_change: pct_change,
    //                     volume:data.volume_24h
    //         }])
    //         // setPriceBoardParams(prevState => [...prevState.map((item) => {
    //         //     if(item.ticker === data.product_id){
    //         //         return {
    //         //             ...item,
    //         //             ticker:data.product_id,
    //         //             last: last,
    //         //             net_change: net_change,
    //         //             pct_change: pct_change,
    //         //         }
    //         //     }
    //         //     return item
    //         // })])
    //         console.log(data)
    //     }
    //     return () => ws.onclose = () => {
    //         console.log('websocket disconnected')
    //         ws = null
    //         // automatically try to reconnect on connection loss
    //         webSocketService(url,tickers)
    //     }
    // }
    
    useEffect(() => {
        refreshTickerInfo()
    },[tickerList])
    useDeepCompareEffect(() => {
        if (tickerList) {
            const interval = setInterval(()=>{
                refreshTickerInfo()
            },60000);
            refreshTickerInfo()
            return () => clearInterval(interval);
        } else {
            setPriceBoardParams([])
        }
        
    },[tickerList])

    // useEffect(() => {
    //     // let pairs = [];
    //     // webSocketService(url,['BTC-USD'])
    //     // const worker = new Worker("../../../../public/test.dedicatedWorker.js")
    //     instance.onmessage = (message) => {
    //         setPriceBoardParams([message.data])
    //     }
    //     return () => {
    //         instance.terminate()
    //         setRestartWorker(true)
    //     }

        
    // }, [restartWorker]);

    
    const refreshTickerInfo = () => {
        try {
            // console.log(tickerList) 
            MarketDataService.getLatestPriceInfo(tickerList)
                            .then(axios.spread((...responses) => {
                                
                                // console.log(responses.map(response=> response.data))
                                setPriceBoardParams(responses.map(response=> response.data))
                                setLoading(false) // MUST be input here
                            }))
                            .catch(e => setError(true))
                             
            
        } catch (error) {
            console.log(error)
        }
     
    }
    //This is to store previous lastPrice
    const ref = useRef()// it is important to put it outside of the function
    const usePrevious = (value) => {
        // const ref = useRef() //DON'T put it here
        useEffect(() => {
            ref.current = value;  
        })
        return ref.current
    } 

    const addLayout = (ticker) => {
        
        // console.log(layoutProps)
        const newLayoutId = counter + 1
        const newLayout = 
            {
            
                id:newLayoutId,
                layoutProps:{
                    x:5,//layoutProps.w + layoutProps.x,
                    y:4, //layoutProps.y,
                    w:4,
                    h:16,
                    i:newLayoutId.toString()
                },
                
                buttons: {
                    name:["Overview","Price Board","Chart","Watcher","News"],
                    value:["overview","priceboard",'chart','watcher','news']
                },
                component:'chart',
                title:`Untitled-${newLayoutId}`,
                
                
                isHidden:false,
                isFullscreen:false,
            }
        dispatch(addNewLayout([workspaceId,newLayout,counter]))
        dispatch(addTickerIntoMainChartFromHeader([workspaceId,counter+1,ticker]))
    };
    //https://stackoverflow.com/questions/76114402/how-to-access-the-row-data-from-a-single-cell-in-material-react-table
    const columns = useMemo(
        () => [
            {   id:'long_name',
                header:'Long Name',
                accessorFn: (row) => row,
                accessorKey:'long_name',
                cell:({cell}) => {
                    // console.log(cell.getValue())
                    const net_change = cell.getValue()["net_change"]
                    // console.log(props)
                    const style = {
                        color: net_change > 0 ? "green" : net_change < 0 ? "red" : "inherit",
                    };

                    return (
                        <div>
                            <span 
                                style={style}
                                // onClick={(e)=> openStockModal(e.target.value)}
                            >
                                {cell.getValue()["long_name"]}
                            </span>
                            {/* <br/>
                            <span style={{color:'white',fontSize:'10px'}}>{props.row.original.long_name}</span> */}
                        </div>
                    )
                }
            },
            {   id:'ticker',
                header:'Ticker',
                accessorFn: (row) => row,
                accessorKey:'ticker',
                cell:({cell}) => {
                    // console.log(cell)
                    const net_change = cell.getValue()["net_change"]
                    
                    const style = {
                        color: net_change > 0 ? "green" : net_change < 0 ? "red" : "inherit",
                    };

                    return (
                        <div>
                            <span 
                                style={style}
                                // onClick={(e)=> openStockModal(e.target.value)}
                            >
                                {cell.getValue()["ticker"].toUpperCase()}
                            </span>
                            {/* <br/>
                            <span style={{color:'white',fontSize:'10px'}}>{props.row.original.long_name}</span> */}
                        </div>
                    )
                }
            },
            {   id:'last',
                header:'Last Price',
                accessorFn: (row) => row,
                accessorKey:'last',
                //Any cell/header templates that use hooks can just use a classical function declaration: https://github.com/TanStack/table/discussions/4205#discussioncomment-3206311
                cell: function Cell ({cell}) {
                    const prevPrice = usePrevious(cell.getValue()["last"])
                    const changeUp = cell.getValue()["last"]> prevPrice
                    // console.log(changeUp,{current:props.row.values.last, prev: prevPrice})
                    // const changeDown = props.row.values.last < prevPrice

                    const net_change = cell.getValue()["net_change"]
                    
                    const priceStyle = {
                        // backgroundColor:changeUp ? "green" : changeDown ? "red" : "inherit",
                        color: net_change > 0 ? "green" : net_change < 0 ? "red" : "inherit",
                        paddingLeft: "8px",
                    };
                    // setLastPrice(props.row.values.last)
                    // console.log(cell.getValue())
                    return (
                        <div>
                            {changeUp 
                                ? <span style={{color:'green'}}>{decode('&#8679')}</span>
                                : <span style={{color:'red'}}>{decode('&#8681')}</span> 

                            }
                        
                        <span style={priceStyle}>{cell.getValue()["last"].toFixed(2)}</span>
                        </div>
                        
                    )

                    
                }
            },
            {   id:'net_change',
                header:'Net Change',
                accessorKey:'net_change',
                cell:({cell}) => {
                    const net_change =cell.getValue()
                    
                    const style = {
                        color: net_change > 0 ? "green" : net_change < 0 ? "red" : "inherit",
                    };

                    return (<span style={style}>{net_change.toFixed(2)}</span>)
                }
            },
            {   id:'pct_change',
                header:'%Change',
                accessorKey:'pct_change',
                cell:({cell}) => {
                    // console.log(props.getValue());
                    
                    const pct_change = cell.getValue()
                    
                    const style = {
                        color: pct_change > 0 ? "green" : pct_change < 0 ? "red" : "inherit",
                    };

                    return (<span style={style}>{pct_change.toFixed(2)}</span>)

                    
                }
                
            },
            // {
            //     Header:'Volume',
            //     accessor:'volume',
            // },
            {   id:'actions',
                header:'Action',
                accessorFn: (row) => row,
                accessorKey:'actions',
                cell: ({cell}) => {
                    // const rowIdx = props.row.id;
                    // console.log(cell)
                    const tickerInRow = cell.getValue()["ticker"]
                    return (
                        <div> 
                            <div onMouseDown={(event) => event.stopPropagation()}>
                                <IconButton 
                                    aria-label="delete" 
                                    style={removeStyle} 
                                    onClick={() => handleDeleteRow(tickerInRow)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                {/* <button  onClick={()=>handleDeleteRow(tickerInRow)} value={rowIdx}>Delete</button> */}
                                {/* <Button variant='primary' onClick={handleChartRow} value={tickerInRow}>
                                    Chart
                                </Button> */}
                                <IconButton 
                                    aria-label="delete" 
                                    style={removeStyle} 
                                    onClick={() => addLayout(tickerInRow)}
                                    // value={tickerInRow}
                                >
                                    <ShowChartSharpIcon fontSize="small" />
                                </IconButton>

                                
                            </div>
                                        
                        </div>
                    );
                }, 
            },
        ],
        [counter]
    )


    
    const handleDeleteRow = (ticker) => {
        
        dispatch(removeTickerOutOfPriceBoard([workspaceId,id,ticker]))
        
    }
    
    return (
        <>
            {isLoading && <span style={{color:'white'}}>Loading...</span>}
            {isError && <span style={{color:'white'}}>We have data error. Try to refresh later</span>}
            {priceBoardParams.length !== 0 && !isLoading && !isError && <Table columns={columns} data={priceBoardParams} layout={props.layout} cols={cols} />}
        </>
            
                
            
        
    )
}

// const compareProps = (preProps,nextProps) => {
//     console.log(preProps.layout.layoutProps,nextProps.layout.layoutProps)
//     // return isDeepEqual(preProps.layout)
// }
export default PriceBoard
