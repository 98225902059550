const CREATE_NEWS_BOARD = 'CREATE_NEWS_BOARD'
const ADD_TICKER_INTO_NEWS_LIST = 'ADD_TICKER_INTO_NEWS_LIST'
const REMOVE_TICKER_FROM_NEWS_LIST = 'REMOVE_TICKER_FROM_NEWS_LIST'
const REMOVE_NEWS_LAYOUT = 'REMOVE_NEWS_LAYOUT'

export const createNewsBoard = payload => {
    return {
        type: CREATE_NEWS_BOARD,
        payload
    }
}


export const addTickerIntoNewsList = payload => {
	return {
		type:ADD_TICKER_INTO_NEWS_LIST,
		payload
	}
}

export const removeTickerFromNewsList = payload => {
	return {
		type:REMOVE_TICKER_FROM_NEWS_LIST,
		payload
	}
}

export const removeNewsLayout = payload => {
	return {
		type:REMOVE_NEWS_LAYOUT,
		payload
	}
}
