const CREATE_MACROECONOMIC = 'CREATE_MACROECONOMIC';
const ADD_NEW_ECONOMIC_TEMPLATE = 'ADD_NEW_ECONOMIC_TEMPLATE';
const REMOVE_MACROECONOMIC = 'REMOVE_MACROECONOMIC';
const ADD_NEW_ECONOMIC_SUBLAYOUT = 'ADD_NEW_ECONOMIC_SUBLAYOUT';
const DELETE_ECONOMIC_SUBLAYOUT = 'DELETE_ECONOMIC_SUBLAYOUT';
const UPDATE_ECONOMIC_SUBLAYOUT_PROPS = 'UPDATE_ECONOMIC_SUBLAYOUT_PROPS';
const UPDATE_ECONOMIC_SUBLAYOUT_PERIOD_RANGE = 'UPDATE_ECONOMIC_SUBLAYOUT_PERIOD_RANGE';
const ADD_NEW_INDICATOR_IN_MACROECONOMIC = 'ADD_NEW_INDICATOR_IN_MACROECONOMIC';
const REMOVE_INDICATOR_IN_MACROECONOMIC = 'REMOVE_INDICATOR_IN_MACROECONOMIC';
const UPDATE_SVG_PARAMS_ECONOMIC_CHART ='UPDATE_SVG_PARAMS_ECONOMIC_CHART';
const UPDATE_TICKER_PARAMS_ECONOMIC_CHART ='UPDATE_TICKER_PARAMS_ECONOMIC_CHART';
const TOGGLE_LAYOUT_TYPE = 'TOGGLE_LAYOUT_TYPE';


export const createMacroeconomic = payload => {
    return {
        type: CREATE_MACROECONOMIC,
        payload
    }
};

export const addNewEconomicTemplate = payload => {
    return {
        type: ADD_NEW_ECONOMIC_TEMPLATE,
        payload
    }
}

export const removeMacroeconomic = payload => {
    return {
        type: REMOVE_MACROECONOMIC,
        payload
    }
};

export const addNewEconomicSubLayout = payload => {
    return {
        type: ADD_NEW_ECONOMIC_SUBLAYOUT,
        payload
    }
};

export const deleteEconomicSubLayout = payload => {
    return {
        type: DELETE_ECONOMIC_SUBLAYOUT,
        payload
    }
}

export const addNewIndicatorInMacroeconomic = payload => {
    return {
        type: ADD_NEW_INDICATOR_IN_MACROECONOMIC,
        payload
    }
};
export const removeIndicatorInMacroeconomic = payload => {
    return {
        type: REMOVE_INDICATOR_IN_MACROECONOMIC,
        payload
    }
};

export const updateSvgParamsEconomicChart = payload => {
    return {
        type: UPDATE_SVG_PARAMS_ECONOMIC_CHART,
        payload
    }
};
export const updateTickerParamsEconomicChart = payload => {
    return {
        type: UPDATE_TICKER_PARAMS_ECONOMIC_CHART,
        payload
    }
};

export const updateEconomicSubLayoutProps = payload => {
    return {
        type: UPDATE_ECONOMIC_SUBLAYOUT_PROPS,
        payload
    }
};
export const updateEconomicSubLayoutPeriodRange = payload => {
    return {
        type: UPDATE_ECONOMIC_SUBLAYOUT_PERIOD_RANGE,
        payload
    }
};

export const toggleLayoutType = payload => {
    return {
        type: TOGGLE_LAYOUT_TYPE,
        payload
    }
}


