const SET_ACTIVE_WORKSPACE = 'SET_ACTIVE_WORKSPACE'
const UPDATE_WORKSPACE_COUNT = 'UPDATE_WORKSPACE_COUNT'
const HIDE_WORKSPACE = 'HIDE_WORKSPACE'

export const setActiveWorkspace = payload => {
	return {
		type: SET_ACTIVE_WORKSPACE,
		payload
	}
}

export const updateWorkspaceCount = payload => {
	return {
		type: UPDATE_WORKSPACE_COUNT,
		payload
	}
}
export const hideWorkspace = payload => {
	return {
		type: HIDE_WORKSPACE,
		payload
	}
}