const CREATE_MAIN_CHART = 'CREATE_MAIN_CHART'
const GET_CHART_TYPE = 'GET_CHART_TYPE'
const ENABLE_VOLUME = 'ENABLE_VOLUME'
const ENABLE_NEWS = 'ENABLE_NEWS'
const UPDATE_INDICATOR_PARAMS = 'UPDATE_INDICATOR_PARAMS'
const ADD_INDICATOR = 'ADD_INDICATOR'
const REMOVE_INDICATOR = 'REMOVE_INDICATOR'
const ADJUST_SVG_PARAMS_MAIN_CHART = 'ADJUST_SVG_PARAMS_MAIN_CHART'
const CHANGE_TICKER = 'CHANGE_TICKER'
const ADD_TICKER_INTO_MAIN_CHART_FROM_HEADER = 'ADD_TICKER_INTO_MAIN_CHART_FROM_HEADER' 
const ADD_NEW_TICKER_PARAMS = 'ADD_NEW_TICKER_PARAMS'
const CHANGE_TICKER_PARAMS = 'CHANGE_TICKER_PARAMS'
const UPDATE_TICKER_PARAMS = 'UPDATE_TICKER_PARAMS'
const REMOVE_TICKER_PARAMS = 'REMOVE_TICKER_PARAMS'
const REMOVE_MAIN_CHART = 'REMOVE_MAIN_CHART'

export const createMainChart = payload => {
    return {
        type:CREATE_MAIN_CHART,
        payload
    }
}

export const addTickerIntoMainChartFromHeader = payload => {
    return {
        type:ADD_TICKER_INTO_MAIN_CHART_FROM_HEADER,
        payload
    }
}

export const removeMainChart = payload => {
    return {
        type:REMOVE_MAIN_CHART,
        payload
    }
}

export const getChartType = payload => {
    return {
        type: GET_CHART_TYPE,
        payload
    }
}
export const adjustSVGParamsMainChart = payload => {
    
    return {
        type: ADJUST_SVG_PARAMS_MAIN_CHART,
        payload
    }
}
export const enableNews = payload => {
    return {
        type: ENABLE_NEWS
	,
        payload
    }
}

export const enableVolume = payload => {
    return {
        type: ENABLE_VOLUME
	,
        payload
    }
}
export const updateIndicatorParams = payload => {
    return {
        type: UPDATE_INDICATOR_PARAMS,
        payload
    }
}

export const addIndicator = payload => {
    return {
        type: ADD_INDICATOR
	,
        payload
    }
}
export const removeIndicator = payload => {
    return {
        type: REMOVE_INDICATOR
	,
        payload
    }
}
export const changeTicker = payload => {
    return {
        type: CHANGE_TICKER
	,
        payload
    }
}

export const addNewTickerParams = payload => {
    return {
        type: ADD_NEW_TICKER_PARAMS
	,
        payload
    }
}
export const updateTickerParams = payload => {
    return {
        type: UPDATE_TICKER_PARAMS
	,
        payload
    }
}
export const changeTickerParams = payload => {
    return {
        type: CHANGE_TICKER_PARAMS
	,
        payload
    }
}
export const removeTickerParams = payload => {
    return {
        type: REMOVE_TICKER_PARAMS
	,
        payload
    }
}
