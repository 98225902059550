import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
// import ListGroup from 'react-bootstrap/ListGroup'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Tab from 'react-bootstrap/Tab'

import {Grid, Chip, Box, TextareaAutosize, 
        Tabs, Tab, Typography, 
        List, ListItem, ListItemText } from "@mui/material"
import  StyledEngineProvider  from '@mui/material/StyledEngineProvider';
import {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import {styled} from '@mui/system';
import Autocomplete from '../../../../UniversalComponents/Autocomplete'
import TickerTable from './TickerTable'
import {updateSignalList} from '../../../../../js/actions/watcherActions'
import { changeLayoutHeight } from '../../../../../js/actions/dashboardActions'


const StyledGrid = styled(Grid) (({theme}) => ({
  flexGrow: 1,
    backgroundColor: '#263238',
    display: 'flex',
    height: 400,
}));


const StyledTabs = styled(Tabs) (({theme}) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const StyledListItemText = styled(ListItemText) (({theme}) => ({
  "&:hover":{
    backgroundColor:'darkorange',
    color:'white'
    
  },
  cursor:'pointer'
}));

const StyledBox = styled(Box) (({theme}) => ({
  border:"1px solid grey",
    color:'white',
    minHeight:'90px',
    overflowY:'auto',
            '&::-webkit-scrollbar': {
                width: "8px",
                height: "8px",
            },
            //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.3)",
                'border-radius': "10px",
                'background': 'rgba(0,0,0,0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
                'border-radius': '10px',
                'background': 'linear-gradient(left, #999, #888)',
                '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.8)',
            },
            
}));

// manage Ticker 
const TickerManager = (props) => {
  const { workspaceId, layoutId, objectList, reduxActionName, filterKeys } = props
  // console.log(props)
  return (
  <>  
    <Autocomplete
                workspaceId={workspaceId}
                layoutId = {layoutId}
                suggestions={objectList}
                reduxActionName={reduxActionName}
                filterKeys={filterKeys}
                placeholderName={'Add Ticker...'}
                isNestedList={false}
    />
    <TickerTable
            layoutId = {layoutId}
            workspaceId = {workspaceId}

    />
  </>
  )
}

// https://codesandbox.io/s/kxsqg?file=/demo.js

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const SignalManager = (props) => {
  const {workspaceId, layoutId, objectList } = props
  // https://stackoverflow.com/questions/40774697/how-to-group-an-array-of-objects-by-key
  // console.log(props) 
  const reduxStore = useSelector(state => state)
  const dispatch = useDispatch()

  const signalWatchList = reduxStore.signalInWatchList[workspaceId][layoutId]
  // console.log(signalWatchList[layoutId],layoutId)
  const watcherItems = reduxStore.watcher[workspaceId][layoutId]
  // const heightOfMainLayout = layoutId !== '' ? reduxStore.dashboard['items'].filter(item => item.id === layoutId)[0]['layoutProps']['h']
  //                                             :null
  
  
  const [description, setDescription] = useState('')
  const [signals,updateSignals] = useState(signalWatchList)
  // console.log(signals)
  let groupIndicatorsName = []
   
  if (objectList) {
    var groupIndicatorsInfo = objectList.reduce((r, a) => {
        r[a.group_name]=r[a.group_name] || []
        r[a.group_name].push(a)
        return r
    },Object.create(null))
    groupIndicatorsName = Object.keys(groupIndicatorsInfo)
  }
  const handleDelete = (signal) => {
      console.log(signal)
      updateSignals(signals.filter(s => s !== signal ))
      // dispatch(removeSignalFromWatchList([layoutId,signal]))
      // if (totalHeightOfSignalItem - 4 < heightOfMainLayout) {
      //   dispatch(changeLayoutHeight([layoutId,totalHeightOfSignalItem-4]))
      // }
  }
  
  const handleClick = (signal) => {
    if(signals.includes(signal)) return alert('the signal is already existed')
    updateSignals(prevState => [...prevState, signal])
      // dispatch(addSignalIntoWatchList([layoutId,signal]))
      // // console.log(totalHeightOfSignalItem)
      // if (totalHeightOfSignalItem + 4 > heightOfMainLayout) {
      //   dispatch(changeLayoutHeight([layoutId,totalHeightOfSignalItem+4]))
      // }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(updateSignalList([workspaceId,layoutId,signals]))
    
    const signalComponents = watcherItems.filter(item => item['watcherComponentName'] === 'signalType')
    // console.log(signalComponents.filter(signalComponent => signalComponent['signalName'] === 'seq5DayRise'))
    let totalHeightOfSignalItems = 0
    for (let signal of signals) {
      const isExisted = signalComponents.some(signalComponent => signalComponent['signalName'] === signal)
      totalHeightOfSignalItems += isExisted ? signalComponents.filter(signalComponent => signalComponent['signalName'] === signal)[0]['layoutProps']['h']
                                             : 4
      // console.log(totalHeightOfSignalItems)
    dispatch(changeLayoutHeight([workspaceId,layoutId,totalHeightOfSignalItems+4]))
    //   if (signal === signalComponents) {

    //   }
    //   totalHeightOfSignalItems +=  signalComponents.filter(item => item['signalName'] === signal)['layoutProps']['h']
    }
  }
  const handleMouse = (description) => {
    // console.log(description)
      setDescription(description)
  }
  // const classes = useStyles()
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <StyledEngineProvider injectFirst>
      <Grid container justify="flex-start" direction="column" spacing={2} >
        <StyledGrid item>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            
          >
            {groupIndicatorsName.map((indicatorGroup,index)=> (
              <Tab key={index} label={indicatorGroup} {...a11yProps(index)} style={{color:'white'}} /> 
            ))}
            
          </StyledTabs>
          {groupIndicatorsName.map((indicatorGroup,index) => (
            <TabPanel value={value} index={index} key={index} >
              <List>
                {groupIndicatorsInfo[indicatorGroup].map((indicatorInfo, index) => (
                <ListItem key={indicatorInfo.code}>
                    <StyledListItemText 
                      primary={indicatorInfo.code} 
                      onClick={e => handleClick(indicatorInfo.code)}
                      onMouseEnter={e => handleMouse(indicatorInfo.description)}
                      
                    />
                </ListItem>
                ))}
              </List>
            </TabPanel>
          ))}
        </StyledGrid>
        <Grid item>
          <TextareaAutosize
            // maxRows={2} 
            value={description} 
            style={{backgroundColor:'black', color:'white', width:'100%'}}
            placeholder="Indicator definition" 
          />
        </Grid>
        <Grid item>
          {/* https://codesandbox.io/s/93bfb?file=/demo.js -> Chip demo*/}
          
          <StyledBox >
              
                <Grid container spacing={1}>
                  {signals
                    // .fill(null)
                    .map((t,i) => {return (
                      <Grid item key={t}>
                          <Chip label={t} onDelete={()=>handleDelete(t)} style={{color:'white',background:'grey'}}/>
                      </Grid>
                      )})
                  }
                </Grid>
          </StyledBox>
          <form onSubmit={handleSubmit}>
              <Button name='signal-update' type="submit">Update</Button>
          </form>  
        </Grid>
      </Grid>
    </StyledEngineProvider>
  )
  
}

const SignalTickerManager = (props) => {
  const {title } = props.data
  console.log('signalTickerManager is rendered')
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='signal-modal'
      // style={{maxWidth:'720px'}}
      
    >
      <Modal.Header style={{backgroundColor:'#282c34', color:'orange'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'#282c34', color:'orange'}}>
        
        {title==='Ticker Manager'? TickerManager(props.data) : SignalManager(props.data)}
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:'#282c34', color:'orange'}}>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SignalTickerManager