import {useEffect, useState, useMemo,} from 'react'
import {useDeepCompareEffect} from 'react-use'
// import isDeepEqual from 'fast-deep-equal'
// import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from 'react-redux';
import MarketDataService from "../../../services/MarketService"

import IconButton from '@mui/material/IconButton';
import Table from '../../UniversalComponents/Table';

// import TagsInput from '../../UniversalComponents/TagInput';
import { refreshStyle } from '../../UniversalComponents/cssStyle/iconStyle';

import RefreshIcon from '@mui/icons-material/Refresh';
import { removeTickerFromNewsList } from "../../../js/actions/newsAction";
import { Tooltip, Chip, Box } from '@mui/material';
import {styled} from '@mui/system';
// import Tags from '../../UniversalComponents/MuiAutocomplete';

const StyledChip = styled(Chip) (({theme}) => ({
    margin: theme.spacing(0.5, 0.25),
      color:'white',
      background:'grey',
      fontSize:12,
      border:'none'
  }));

const News =(props) => {
    // const tickerList = useSelector(state=>state.dashboard.tickerList)
    // console.log('news is rendered')
    const {id,layoutProps} = props.layout
    const workspaceId = props.workspaceId
    const cols = props.mainBreakpoint?.currentCols || 12
    const dispatch = useDispatch()
    const tickerList = useSelector(state=> state['tickersForNews'][workspaceId][id] )
    // console.log(tickerList);
    // const tickerList = reduxStore.tickersForNews[workspaceId][id]
    const [newsData, setNewsData] = useState([])
    const [loading, setLoading] = useState(true) 
    
    // const [symbolAndNewsSources, setSymbolAndNewsSources] = useState([])

    useDeepCompareEffect(() => {
        if (tickerList.length !== 0){
            const interval = setInterval(()=>{
                refreshNews()
                // setLoading(false) => cannot input this here 
            },300000);
            refreshNews()
            
            return () => clearInterval(interval);
        }
    },[tickerList])

    const refreshNews = () => {
        if (tickerList.length !== 0) {
            let formData = {
                tickers:tickerList,
                
            } 
        // console.log(signalFormData) 
            MarketDataService.getNews2(formData).then(
                res => {
                        // console.log(res.data)
                        setNewsData(res.data)
                        setLoading(false) // MUST be input here
                })
                .catch(error => {alert(error.name)})             
        } else{
            setNewsData([])
        }
       
    }
    // useEffect(() => {
    //     Promise.all([
    //         MarketDataService.getSymbolList(),
    //         MarketDataService.getNewsSources()
    //     ]).then((values) => {
            
    //         setSymbolAndNewsSources(values[0]['data'].concat(values[1]['data']))
    //     })
    // },[])
    
    const columns = useMemo(
        () => [
            {   
                header:'Date',
                accessorKey:'published',
                cell:(props) => {
                    // console.log(props.rows)
                    return (
                        <div>
                            <span style={{color:'orange'}}>{props.row.original.published.replace('T',' ')}</span>
                        </div>
                    )
                },
                padding:0,
                width:100
            },
            // {
            //     Header:'Ticker',
            //     accessor:'ticker',
            //     Cell:(props) => {
                    
            //         return (
            //             <span>{props.row.values.ticker.toUpperCase()}</span>
            //         )
            //     },
            //     marginLeft:0,
            //     minWidth:60,
            //     width:15,
            //     maxWidth:60
            // },
            {   
                header:'Title',
                accessorKey:'title',
                cell:(props) => {
                    const title = props.row.original.title
                    const url = props.row.original.link
                    const classification = props.row.original.classification
                    // console.log(url)
                    return <a onMouseDown={(event) => event.stopPropagation()} href={url} rel="noopener noreferrer" target="_blank" style={classification === 'Highlight' ?{color:'red'} : {color:'orange'}}>{title}</a>
                },
                
                width:"70%"
            },
            {   
                header:'Source',
                accessorKey:'symbol',
                cell:(props) => {
                    
                    return (
                        <span style={{color:'orange'}}>{props.row.original.symbol.toUpperCase()}</span>
                    )
                },
                marginLeft:0,
                width:15,
                
            }, 
            
        ],
        []
    )

    


    // const handleSelectedTags = (tickers) => {
    //     // console.log(tickers)
    //     dispatch(addTickerIntoNewsList([workspaceId,id,tickers]))
    // }
    const handleRefresh = () => {
        refreshNews()
    }
    const handleDelete = (ticker) => {
        // console.log(ticker);
        dispatch(removeTickerFromNewsList([workspaceId,id,ticker]))
        
    }
    

    return (
        <div className="container" onMouseDown={(event) => event.stopPropagation()}>
            <div>
                <Tooltip title="Refresh">
                    <IconButton 
                        aria-label="refresh" 
                        style={refreshStyle}
                        onClick={handleRefresh}
                    >
                        <RefreshIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, fontColor:"white" }}>
                {tickerList.map((item, index) => (
                    <StyledChip
                        key={index}
                        label={item}
                        tabIndex={-1}
                        onDelete={()=>handleDelete(item)}
                        // sx={{ marginTop: 1 }}
                    />
                ))}
            </Box>
            {/* <TagsInput
                // workspaceId = {workspaceId}
                layoutProps = {layoutProps}
                suggestions={symbolAndNewsSources}
                reduxActionName={'dashBoardAction'}
                filterKeys={['symbol','long_name']}
                placeholder={'Enter Symbol or Full Name'}
                id="tags"
                name="tags"
                selectedTags = {handleSelectedTags}
                selectedItem={tickerList}
                fullWidth
                variant="outlined"
                
            /> */}
            
            {/* https://www.pluralsight.com/guides/load-remote-chart-data-for-d3.js-in-a-react-app */}
            <div className="row">
                {loading && <span style={{color:'white'}}>Loading...</span>}
                {newsData.length !== 0 && !loading && <Table columns={columns} data={newsData} layout={props.layout} cols={cols} />}
                {newsData.length === 0 && !loading && <span style={{color:'white'}}>There is no news for these above tickers over past 2 days</span>}
                {/* <div>
                    <ShowModal
                        ticker = {ticker}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>  */}

            </div>
        </div>
    )
}

export default News