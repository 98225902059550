import React,{useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import {styled} from '@mui/system';
import Container from '@mui/material/Container';
// import { StyledEngineProvider } from '@mui/material/styles';
import {connect} from 'react-redux'
import * as actions from '../../js/actions/authActionTypes'
import {useNavigate, useLocation} from 'react-router-dom'

const StyledPaper = styled('div') (({theme}) => ({
  marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor:'dark',
    color:'white'
}));
const StyledAvatar = styled(Avatar) (({theme}) => ({
  margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary,
}));

const StyledForm = styled('form') (({theme}) => ({
  width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    background:"dark",
}));
const StyledTextField = styled(TextField) (({theme}) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "green"
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "purple"  
  },
}));
const StyledButton = styled(Button) (({theme}) => ({
  margin: theme.spacing(3, 0, 2),
}));



function Login(props) {
  // const classes = useStyles();
  const [username, setUserName] = useState(null)
  const [password, setPassword] = useState(null)
  
  const handleFormFieldChange = (event) => {
    switch(event.target.id) {
      case 'username': setUserName(event.target.value); break;
      case 'password': setPassword(event.target.value); break;
      default: return null
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    props.onAuth(username,password)
  }
  //let history = useHistory //https://stackoverflow.com/questions/72474436/how-to-migrate-usehistory-replacex-y-with-usenavigate-in-react-router-v6
  let navigate = useNavigate()
  let location = useLocation()
  let { from } = location.state || { from : {pathname: "/"}}

  //check whether is a new release for the app.
  useEffect(() => {
    const lastLoginDate = new Date(localStorage.getItem('lastLoginDate'))
    // console.log(lastLoginDate === new Date('Thu Jan 01 1970 08:00:00 GMT+0800 (Indochina Time)'))
    const releasedAppDate = new Date('Sat Nov 28 2021 23:00:00 GMT+0800 (Indochina Time)')
    // console.log(releasedAppDate)
    if (lastLoginDate <= releasedAppDate) {
      console.log(lastLoginDate)
      localStorage.removeItem('dashboardInfo')
      localStorage.setItem('releasedAppDate','Sat Nov 28 2021 23:00:00 GMT+0800 (Indochina Time)')
    }
    localStorage.setItem('lastLoginDate',new Date())
  },[])
  useEffect(() => {
    if (props.isAuthenticated) navigate(from, { replace: true }) //{history.replace(from)}
  })
  return (
    
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper>
        <StyledAvatar>
          <LockOutlinedIcon />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <StyledForm noValidate onSubmit={handleSubmit}>
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
            // color='secondary'
            
            InputLabelProps={{style:{color:'white'}}}
            InputProps={{style:{color:'white',borderColor:'white'}}}
            onChange={handleFormFieldChange}
          />
          <StyledTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            
            InputLabelProps={{style:{color:'white'}}}
            InputProps={{style:{color:'white'}}}
            onChange={handleFormFieldChange}
          />
          
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            
          >
            Sign In
          </StyledButton>
          
        </StyledForm>
      </StyledPaper>
      
    </Container>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}
export default connect(null, mapDispatchToProps)(Login)