// import * as d3 from 'd3';
import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import MarketDataService from "../../../services/MarketService";
import Table from '../../UniversalComponents/Table';
import {updateItemInResearchList} from "../../../js/actions/marketResearchActions"

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { refreshStyle } from '../../UniversalComponents/cssStyle/iconStyle';
import { Tooltip } from '@mui/material';
import {MultiSelect} from 'react-multi-select-component';

const MarketResearch = (props) => {
    const {id, layoutProps} = props.layout;
    const workspaceId = props.workspaceId;
    const cols = props.mainBreakpoint.currentCols || 12;
    const height = (layoutProps.h -1) * 30 - 60
    const width = window.innerWidth * layoutProps.w / cols - 100

    const [dataset, setDataset] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()
    const marketResearchState = useSelector(state => state['marketResearch'][workspaceId][id])
    const sources = marketResearchState['sources'];
    const types = marketResearchState['types'];
    
    const selectedSourceOptions = sourceOptions.filter((el) => {
        return sources.some(s => s === el.value);
    });
    const selectedTypeOptions = typeOptions.filter((el) => {
        return types.some(s => s === el.value);
    })
    // console.log(selectedSourceOptions)

    useEffect(() => {
        let formData = {
            sources: sources,
            types: types,
        }
        MarketDataService.getMarketResearch(formData)
                            .then(response => {
                                // console.log(response.data)
                                setDataset(response.data)
                                setLoading(false)
                            })
                            .catch(error => {alert(error.name)})
    },[sources, types])

    const refreshResearchLayout = () => {
        if (sources.length != 0 && types.length != 0) {
            let formData = {
                sources: sources,
                types: types,
            }
            MarketDataService.getMarketResearch(formData)
                                .then(response => {
                                    console.log(response.data)
                                    setDataset(response.data)
                                    setLoading(false)
                                })
                                .catch(error => {alert(error.name)})
        }
    }

    const handleRefresh = () => {
        setDataset([]);
        setLoading(true);
        refreshResearchLayout()
    }

    const selectSourceOptions = (remainingItems) => {
        const remainingItemValues = remainingItems.map(item => item['value']);
        dispatch(updateItemInResearchList([workspaceId, id, 'sources',remainingItemValues]))
    }
    const selectTypeOptions = (remainingItems) => {
        const remainingItemValues = remainingItems.map(item => item['value']);
        dispatch(updateItemInResearchList([workspaceId, id, 'types',remainingItemValues]))
    }

    const columns = useMemo(
        () => [
            {
                Header:'Date',
                accessor:'created_at',
                Cell:(props) => {
                    // console.log(props.value)
                    return (
                        <div>
                            <span style={{color:'orange'}}>{props.value}</span>
                        </div>
                    )
                },
                padding:0,
                width:100
            },
            {
                Header:'Title',
                accessor:'title',
                Cell:(props) => {
                    const title = props.row.values.title;
                    const url = props.row.original.research_url
                    return <a href={url} rel="noopener noreferrer" target="_blank">{title}</a>
                },
                width:"70%" //make the column's width = 80% of total table's width
            },
            {
                Header:'Source',
                accessor:'research_source',
                Cell:(props) => {
                    
                    return (
                        <span style={{color:'orange'}}>{props.value.toUpperCase()}</span>
                    )
                },
                marginLeft:0,
                width:15,
                
            },
        ],[]
    )
    return (
        <div className = "container">
            <div>
                <Tooltip title="Refresh">
                    <IconButton
                        aria-label="refresh"
                        style={refreshStyle}
                        onClick={handleRefresh}
                    >
                        <RefreshIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </div>
            {/* <div> style={{display:"flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}> */}
            <div>
                <div style={{display:"inline-block", margin:"0",padding:"0",  width:width/2, height:40,position: "relative" }}>    
                    <MultiSelect
                        options ={sourceOptions}
                        value = {selectedSourceOptions}
                        onChange={selectSourceOptions}
                        // hasSelectAll={selectedSourceOptions.length == sourceOptions.length}
                        labelledBy={"sources"}
                        overrideStrings = {{
                            "allItemsAreSelected": "All research sources are selected.",
                            "clearSearch": "Clear Search",
                            "clearSelected": "Clear Selected",
                            "noOptions": "No options",
                            "search": "Search",
                            "selectAll": "Select All",
                            "selectAllFiltered": "Select All (Filtered)",
                            "selectSomeItems": "Select sources...",
                            "create": "Create",
                        }}
                        valueRenderer={(_, _options)=> "Select source..."}
                        // isCreatable={true}
                    />
                </div>
                <div style={{display:"inline-block",margin:"0",padding:"0", width:width/2, height:40 , position: "relative"}}>
                    <MultiSelect
                        options ={typeOptions}
                        value = {selectedTypeOptions}
                        onChange={selectTypeOptions}
                        // hasSelectAll={selectedSourceOptions.length == sourceOptions.length}
                        labelledBy={"types"}
                        overrideStrings = {{
                            "allItemsAreSelected": "All research types are selected.",
                            "clearSearch": "Clear Search",
                            "clearSelected": "Clear Selected",
                            "noOptions": "No options",
                            "search": "Search",
                            "selectAll": "Select All",
                            "selectAllFiltered": "Select All (Filtered)",
                            "selectSomeItems": "Select types...",
                            "create": "Create",
                        }}
                        valueRenderer={(_, _options)=> "Select type..."}
                    />
                </div>
            </div>
            <div className="row">
                {loading && <span style={{color:'white'}}>Loading...</span>}
                {dataset.length !== 0 && !loading && <Table columns={columns} data={dataset} layout={props.layout} cols={cols}/>}
                {dataset.length === 0 && !loading && <span style={{color:'white'}}>There is no research</span>}
            </div>
        </div>
    )
}

export default MarketResearch

const sourceOptions = [
    {value: "VNDIRECT", label: "VNDIRECT" },
    {value: "MBS", label: "MB Securities" },
    {value:'SSI',label:'Saigon Securities'},
    {value:'HSC',label:'Ho Chi Minh Securities'},
    {value:'VDS',label:'Viet Dragon Securities'},
    {value:'MASVN', label:'Mirae Asset Securities'},
    {value:'VCBS', label:"VCB Securities"},
    {value:'Finngroup',label:'Finn Group'},
    {value:'Blackrock',label:'Blackrock Investment Institute'},
    {value:'GS',label:'GoldmanSachs'},
    {value:'Citigroup',label:'Citigroup Insights'},
    {value:'MorganStanley',label:'Morgan Stanley Insights'},
    {value:'WellFargo',label:'Well Fargo Research'},
    {value:'BIDV', label:'BIDV Research'}
];

const typeOptions = [
    {value: 'daily_commentary', label:'Daily Commentary'},
    {value: 'weekly_commentary',label:'Weekly Commentary'},
    {value: 'macro_report',     label:'Macro report'},
    {value: 'strategy_report',  label:'Strategy report'},
    {value: 'industry_report',  label:'Industry report'},
    {value: 'company_report',   label:'Company report'},
    {value: 'bond_report',      label:'Bond report'},
    {value: 'fx_report',        label:'FX report'},
    {value: 'ir_report',        label:'Interest Rate report'},
    {value: 'event_report',     label:'Event report'},
    {value: 'thematic_report',  label:'Thematic Report'},
    
]