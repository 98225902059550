// import * as d3 from 'd3'
import {select, selectAll,
        min, max,
        scaleBand,scaleLinear, scaleOrdinal,
        axisLeft, axisBottom,
        rgb, format,
        line
        } from 'd3'
export const barChart = (data,chartId,reportType,performanceInCurrentYearData) => {
    // data[0]['PBT'] = -8000
	// const {svgParams} = chartParams
    // https://bl.ocks.org/bricedev/0d95074b6d83a77dc3ad
    // https://bl.ocks.org/Rdilorenzo73/d3ef804fca7ed0ddaf67a0fb74f76682
    // we should combine two tutorials above when our data sometimes has negative values
    selectAll(`#${chartId} > g`).remove()
    const margin = {top: 30, right: 50, bottom: 30, left:50 }
    const height = 400 - (margin.top + margin.bottom)
    const width = 600 - (margin.left + margin.right)
    // console.log(data, performanceInCurrentYearData)
	const svg = select(`#${chartId}`)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.style('border','1px solid black')
			.attr('transform', `translate(${margin.left}, ${margin.top})`)
    const dataKeys = Object.keys(data[0])
    const numberOfReportingNames = (dataKeys.length - 1)/2
    // console.log(numberOfReportingNames)
    const reportingNames = Object.keys(data[0]).slice(1,numberOfReportingNames + 1)
    // console.log(reportingNames)
    const xScaleTime = scaleBand()
                        .rangeRound([0,width])
                        .paddingInner(0.1)
                        .domain(data.map(d => d.Date))
    const xScaleReportingName = scaleBand()
                                    .padding(0.05)
                                    .domain(reportingNames).rangeRound([0, xScaleTime.bandwidth()])
    //if there is a negative value, yMin will be equal to that negative value, otherwise it will be zero
    const yMin = Math.min(min(data, d=> min(reportingNames, name => d[name])), 0) 
    
    const yMax = max(data, d=> max(reportingNames, name => d[name]))
    const yScaleReportingName = scaleLinear()
                                    .rangeRound([height, 0])
                                    .domain([yMin, yMax * 1.2])
    
    const colorScale = scaleOrdinal()
                            .range(["#2196f3", "#009688", "#ff9800", "#6b486b",'#cddc39','#f06292','#7b1fa2'])
    //add xAxis
    svg.append('g')
        .attr('class',`xAxisTime-${chartId}`)
        .attr('transform',`translate(0, ${height})`)
        .call(axisBottom(xScaleTime))
        // .selectAll(".tick text")
        // .call(wrap, xScaleTime.bandwidth())
    //add yAxis
    svg.append('g')
        .attr('class',`yAxisReportingName-${chartId}`)
        .call(axisLeft(yScaleReportingName).ticks(null,'s'))
        .append('text')
        .attr("x",2)
        .attr("y", yScaleReportingName(yScaleReportingName.ticks().pop()) + 0.5)
        .attr("dy", "0.32em")
        .attr("fill","white")
        .attr('font-weight','bold')
        .attr('text-anchor','start')
        .text("VND")
    //add bar
    var selection = svg.append('g')
        .attr('class',`mainBar-${chartId}`)
        .selectAll('g')
        .data(data)
        
        .enter().append('g')
        
        .attr('transform', function (d) { return `translate(${xScaleTime(d.Date)}, 0)`})
    selection
        .selectAll(`rect`)
        .data(function(d){return reportingNames.map(name => {return {key: name, value:d[name]} })})
        .enter().append('rect')
        .attr('class',`bar-${chartId}`)
        .attr('x', function(d) {return xScaleReportingName(d.key)})
        //if the value is negative, put the top left corner of the rect bar on the zero line
        // https://bl.ocks.org/Rdilorenzo73/d3ef804fca7ed0ddaf67a0fb74f76682
        .attr('y', d => ( d.value<0? yScaleReportingName(0) : yScaleReportingName(d.value)))
        .attr('width', xScaleReportingName.bandwidth())
        .attr('height', d => yMin < 0 ? Math.abs(yScaleReportingName(d.value) - yScaleReportingName(0))
                                        : height - yScaleReportingName(d.value))
        .attr('fill', function(d) {return colorScale(d.key)})
        .style('opacity','1')
        .on("mouseover", function(event,selectedData,) {
            // highlight bar
            // console.log(d3.selectAll(`.bar-${chartId}`))
            const selectedElement = selectAll(`.bar-${chartId}`).filter((d,i)=> d.key === selectedData.key)
            const unselectedElements = selectAll(`.bar-${chartId}`).filter((d,i)=> d.key !== selectedData.key)
            const currentColor = selectedElement.style('fill')
            selectedElement.style('fill',rgb(currentColor).brighter(1.5)).raise()
            unselectedElements.style('opacity','0.1')
            //highlight legend
            selectAll(`.legend-${chartId}`)
                        .filter(function(d) {return d !== selectedData.key})
                        .style('opacity','0.1')
            selectAll(`.legend-${chartId} > rect`)
                        .filter(function(d) {return d === selectedData.key}).raise()
                        .style('fill',rgb(currentColor).brighter(1.5))
                        
            selectAll(`.legend-${chartId} > text`)
                        .filter(function(d) {return d === selectedData.key})
                        .style('fill',rgb(currentColor).brighter(1.5))
                        .raise()
            //visible text
            selectAll(`.label-${chartId}`)
                        .filter((d,i)=> d.key === selectedData.key)
                        .style('visibility','visible')
                        .raise()
            // d3.select(this.parentNode).raise()
            
        })
        .on("mouseout", function(d) {
            //adjust the opacity to 1 and bring all bars to back
            selectAll(`.bar-${chartId}`).style('fill',d => colorScale(d.key)).style('opacity','1').lower()
            selectAll(`.legend-${chartId}`)
                        .style('opacity','1')
            selectAll(`.legend-${chartId} > rect`)
                        .style('fill',d => colorScale(d))
            selectAll(`.legend-${chartId} > text`)
                        .style('fill',d => colorScale(d))
            //hidden text
            selectAll(`.label-${chartId}`).style('visibility','hidden')
           

        });
    
    // draw bar for company performance in current year
    // console.log((data[data.length-1]['Date']).replace(/\D/g,''))
    var currentFinancialYear =  isNaN(data[data.length-1]['Date']) ? parseInt((data[data.length-1]['Date']).replace(/\D/g,''))
                                                                : data[data.length-1]['Date']
    if (reportType==='IncomeStatement'
        &&performanceInCurrentYearData
        && currentFinancialYear === new Date().getFullYear()){ //check if the company has financial plan in current Year
            var currentYearData = data[data.length-1]
            var sortedReportingNames = reportingNames.filter(name => 
            
                currentYearData[name] !== 0
            )
            // console.log(performanceInCurrentYearData)
            var tooltip = select('body').append('div')
                                .attr('class',`tooltip-${chartId}`)
                                .style('position','absolute')
                                .style('z-index','10')
                                .style('visibility','hidden')
                                .style('padding','10px')
                                .style('background','rgba(0,0,0,0.6)')
                                .style('border-radius','4px')
                                .style('color','#fff')
                                
            select(selection.nodes()[data.length-1]).append('g')
                .selectAll('rect')
                .data(sortedReportingNames.map(name => {return {key:name, value: performanceInCurrentYearData[name]}}))
                .enter().append('rect')
                .attr('class',`subBar-${chartId}`)
                .attr('x',d=> xScaleReportingName(d.key))
                .attr('y',d => d.value < 0 ? yScaleReportingName(0) : yScaleReportingName(d.value))
                .attr('width', xScaleReportingName.bandwidth())
                .attr('height', d=> yMin < 0 ? Math.abs(yScaleReportingName(d.value) - yScaleReportingName(0)) 
                                            :height - yScaleReportingName(d.value))
                .attr('fill', 'red')
                // https://observablehq.com/@bsaienko/animated-bar-chart-with-tooltip 
                .on('mouseover',function(event,selectedData) {
                    var runrate = (selectedData.value / currentYearData[selectedData.key])*100
                    tooltip
                        .html(
                            `<div>${selectedData.key} : ${selectedData.value.toFixed(2)}</div><br /><div>YTD runrate is ${runrate.toFixed(2)} %</div>`
                        )
                        .style('visibility','visible')
                    select(this).transition().style('fill','#FF5C00')
                })
                .on('mousemove',function(event,d) {
                    tooltip
                        .style('top',event.y - 10 + 'px')
                        .style('left',event.x + 10 + 'px')
                })
                .on('mouseout', function() {
                    tooltip.html(``).style('visibility','hidden')
                    select(this).transition().style('fill','red')
                })
        
    }
    selection.selectAll('text')
                    .data(function(d) {return reportingNames.map(name => {return {key: name, value:d[name]}})})
                    .enter().append('text')
                    .attr('class',`label-${chartId}`)
                    .attr("x", d =>  xScaleReportingName(d.key))
                    .attr("y", d => yScaleReportingName(d.value))
                    .attr("dy", "-0.5em")
                    .attr("text-anchor", "middle")
                    .attr('fill','white')
                    .text(d => format(",.2f")(d.value))
                    .style('visibility','hidden')

    //add legend
    var legend = svg.selectAll(`.legend-${chartId}`)
                    .data(reportingNames)
                    .enter().append('g')
                    .attr('class',`legend-${chartId}`)
                    .attr('transform',function(d,i) {return `translate(0,${i*20})`})
                    // .style('opacity',"0")
    legend.append('rect')
            .attr('x', width - 18)
            .attr('width',18)
            .attr('height', 18)
            .style('fill', d => colorScale(d))
    legend.append('text')
            .attr('x',width - 24)
            .attr('y',9)
            .attr('dy', '.35em')
            .style('text-anchor','end')
            .style('fill', d=> colorScale(d))
            .text(d => d)
    
    
    // THIS function IS used if the data has negative value
   //https://bl.ocks.org/mbostock/7555321 - wrap long labels
    // function wrap(text, width) {
    //     text.each(function() {
    //         var text = d3.select(this),
    //             words = text.text().split(/\s+/).reverse(),
    //             word,
    //             line = [],
    //             lineNumber = 0,
    //             lineHeight = 1.1, // ems
    //             y = text.attr("y"),
    //             dy = parseFloat(text.attr("dy")),
    //             tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
    //         while (word = words.pop()) {
    //         line.push(word);
    //         tspan.text(line.join(" "));
    //         if (tspan.node().getComputedTextLength() > width) {
    //             line.pop();
    //             tspan.text(line.join(" "));
    //             line = [word];
    //             tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
    //         }
    //         }
    //     });
    // }  
    

}
export const lineChart = (data,chartId) => {
    // https://codepen.io/zakariachowdhury/pen/JEmjwq
    selectAll(`#${chartId} > g`).remove()

    const margin = {top: 30, right: 50, bottom: 30, left:50 }
    const height = 400 - (margin.top + margin.bottom)
    const width = 600 - (margin.left + margin.right)

    const lineOpacity = "1";
    const lineOpacityHover = "1.5";
    const otherLinesOpacityHover = "0.1";
    const lineStroke = "1.5px";
    const lineStrokeHover = "2.5px";
    const circleOpacity = '1';
    const circleOpacityOnLineHover = "0.25"
    const legendOpacity = '1'
    const legendOpacityHover = '1.5'
    const otherLegendsOpacityHover = '0.1'
    // const circleRadius = 3;
    // const circleRadiusHover = 6;

    //we need to filter the null value
    data = data.filter(d => Object.values(d).indexOf(null) === -1)
    // console.log(data) 
	const svg = select(`#${chartId}`)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.style('border','1px solid black')
			.attr('transform', `translate(${margin.left}, ${margin.top})`)
    const dataKeys = Object.keys(data[0])
    const numberOfReportingNames = (dataKeys.length - 1)/2

    const reportingNames = Object.keys(data[0]).slice(numberOfReportingNames+1,numberOfReportingNames*2 + 1)
    // console.log(reportingNames)
    const revisedData = reportingNames.map(name => {
        return {
            name: name,
            values: data.map(d => {
                return {
                    date: d.Date,
                    growth: +d[name] !== -1 ? +d[name] * 100 : NaN
                }
            })
        }
    })
    // console.log(revisedData)
    const xScaleTime = scaleBand()
                        .range([0,width])
                        .paddingOuter(0)
                        .domain(data.map(d => d.Date))
    const yMin = min(revisedData, d=> min(d.values, v => v.growth))
    const yMax = max(revisedData, d=> max(d.values, v => v.growth))
    // console.log(yMin,yMax)
    const yScaleReportingName = scaleLinear()
                                    .rangeRound([height, 0])
                                    .domain([yMin, yMax])
    
    const colorScale = scaleOrdinal()
                            .range(["#2196f3", "#009688", "#ff9800", "#6b486b",'#cddc39','#f06292'])
    //add xAxis
    svg.append('g')
        .attr('class',`xAxisTime-${chartId}`)
        .attr('transform',`translate(0, ${height})`)
        .call(axisBottom(xScaleTime))
    //add yAxis
    svg.append('g')
        .attr('class',`yAxisReportingName-${chartId}`)
        
        .call(axisLeft(yScaleReportingName).ticks(null,'s'))
        .append('text')
        .attr("x",2)
        .attr("y", yScaleReportingName(yScaleReportingName.ticks().pop()) + 0.5)
        .attr("dy", "0.32em")
        .attr("fill","white")
        .attr('font-weight','bold')
        .attr('text-anchor','start')
        .text("%")
    //add line and mouse event to show text
    const drawLine = line()
                    .defined(d => d.growth)
                    .x(d => xScaleTime(d.date))
                    .y(d => yScaleReportingName(d.growth))

    const linePaths = svg.append('g')
                        .attr('class',`linePaths-${chartId}`)
                        .attr('transform', `translate(${xScaleTime(xScaleTime.domain()[1])/2}, 0)`)
    linePaths.selectAll('.line-group')
            .data(revisedData)
            .enter().append('g')
            .attr('class','line-group')
            .append('path')
            .attr('class',`line-${chartId}`)
            .attr('fill','none')
            .attr('d',(d,i) => drawLine(d.values))
            .attr('stroke',(d,i)=> colorScale(d.name))
            .attr('stroke-width', '2')
            .style('opacity', lineOpacity)
            .on("mouseover", function(e,selectedData) {
                selectAll(`.line-${chartId}`)
                                .style('opacity', otherLinesOpacityHover);
                selectAll(`.circle-${chartId}`)
                                .style('opacity', circleOpacityOnLineHover);
                selectAll(`.legend-${chartId}`)
                                .style('opacity', otherLegendsOpacityHover)
                select(this)
                    .style('opacity', lineOpacityHover)
                    .style("stroke-width", lineStrokeHover)
                    .style("cursor", "pointer")
                selectAll(`.legend-${chartId}`).filter(function(d) {return d === selectedData.name}).style('opacity', legendOpacityHover)
                selectAll(`.linePaths-${chartId}`)
                    .selectAll(`.text-group-${chartId}`)
                    .data(selectedData.values.filter(v => !isNaN(v.growth))) //get all values on the lines and filter out the NaN values
                    .enter().append('g')
                    .append('text')
                    .attr('class',`text-group-${chartId}`)
                    .attr("x", d => xScaleTime(d.date))
                    .attr("y", d => yScaleReportingName(d.growth))
                    .attr("dy", "-0.5em")
                    .attr("text-anchor", "middle")
                    .attr('fill','white')
                    .text(d => format(",.2f")(d.growth))
            })
            .on("mouseout", function(d) {
                selectAll(`.line-${chartId}`)
                                .style('opacity', lineOpacity);
                selectAll(`.circle-${chartId}`)
                                .style('opacity', circleOpacity);
                selectAll(`.legend-${chartId}`)
                                .style('opacity', legendOpacity)
                select(this)
                    .style("stroke-width", lineStroke)
                    .style("cursor", "none");

                selectAll(`.linePaths-${chartId}`)
                    .selectAll(`.text-group-${chartId}`).remove()
            });
    //add circle
    linePaths.selectAll('circle-group')
            .data(revisedData)
            .enter().append('g')
            .style('fill', (d,i) => colorScale(d.name))
            .selectAll('circle')
            .data(d => d.values.filter(v => !isNaN(v.growth))).enter()
            .append('g')
            .attr('class','circle')
            .append('circle')
            .attr('cx', d => xScaleTime(d.date))
            .attr('cy', d => yScaleReportingName(d.growth))
            .attr('r',3)
            

    
    //add legend
    var legend = svg.selectAll(`.legend-${chartId}`)
                    .data(reportingNames)
                    .enter().append('g')
                    .attr('class',`legend-${chartId}`)
                    .attr('transform',function(d,i) {return `translate(0,${i*20})`})
                    // .style('opacity',"0")
    legend.append('rect')
            .attr('x', width - 18)
            .attr('width',18)
            .attr('height', 18)
            .style('fill', d => colorScale(d))
    legend.append('text')
            .attr('x',width - 24)
            .attr('y',9)
            .attr('dy', '.35em')
            .style('text-anchor','end')
            .style('fill', d=> colorScale(d))
            .text(d => d)


}
