import {useEffect, useState, useMemo} from 'react'
import { useDispatch } from 'react-redux';
import Table from '../../UniversalComponents/Table'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { removeSVGItems } from './removeSVGItems';

const TickerTable = (props) => {
    const {tickerParams,indicators} = props.chartParams
    const layoutId = props.layoutId
    const mainChartActions = props.mainChartActions
    const suggestions = props.suggestions
    const workspaceId = props.workspaceId
    const dispatch = useDispatch()
    
    // console.log(suggestions)
    
    //Creating an Editable and Dynamic React Table with TanStack : https://muhimasri.com/blogs/react-editable-table/

    const EditableCell = ({
        // value:initialValue,
        // row:{index},
        // column: {id},
        // data: tableData,
        getValue,
        row,
        column,
        table
        
    }) => {
        const initialValue = getValue();
        const [userInput, setUserInput] = useState(initialValue)
        const [filteredSuggestions, setFilteredSuggestions] = useState([])
        const [activeSuggestion,setActiveSuggestion] = useState(0)
        const [showSuggestions, setShowSuggestions] = useState(false)
        const fullName = 'long_name'
        const shortName= 'symbol'

        const onChange = e => {
            // console.log(table)
            const currentUserInput = e.target.value.trim()
            // console.log(suggestions) 
             
            let currentFilteredSuggestions = suggestions.filter(
                suggestion =>
                    suggestion[fullName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
            );
            // if useInput does not match long_name, then it will continue to try match the symbols 
            if (!currentFilteredSuggestions.length){
                currentFilteredSuggestions=suggestions.filter(
                    suggestion =>
                        suggestion[shortName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
                )
            }
            setFilteredSuggestions(currentFilteredSuggestions)
            setShowSuggestions(true)
            setUserInput(currentUserInput.toUpperCase())
        }

        // We'll only update the external data when the input is blurred
        const handleKeyDown = (e) => {
            if(e.key==='Enter') {
                //check duplicated Value
                
                const checkDuplicated = table.options.data.map(d=> d['ticker']).indexOf(userInput)
                if (checkDuplicated !== -1) {
                    setUserInput("")
                    alert('this ticker is existed')
                    return
                }

                //check whether the ticker in the symbolLists database
                const isExisted = suggestions.some(item=> item.symbol.toUpperCase() === userInput.toUpperCase())
                
                if (!isExisted) {
                    setUserInput("")
                    alert("This ticker is not in database")
                    return
                }
                onTickerParamsChange(row.index, column.id, userInput)
            }
        }
        const handleClick = (ticker) => {
          
            setFilteredSuggestions([])
            setShowSuggestions(false)
            const checkDuplicated = table.options.data.map(d=> d['ticker']).indexOf(ticker)
                if (checkDuplicated !== -1) {
                    setUserInput("")
                    alert('this ticker is existed')
                    return
                }
            onTickerParamsChange(row.index, column.id, ticker)
            
            // dispatch(reduxAction[reduxActionName]([layoutId,ticker]))
        }
        
        const boldQuery = (str, query, col, rowStyle) => {
            const n = str.toUpperCase();
            const q = query.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return col === 1 ? <span style={{paddingRight:"35px"}}>{str}</span>:str // bail early
            }
            const l = q.length;
            return (<td style={rowStyle}>{str.substr(0, x)}<span style={{ fontWeight: 'bold',textDecorationLine: 'underline'}}>{str.substr(x, l)}</span>{str.substr(x + l)}</td>)
        }
        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                // https://stackoverflow.com/questions/7421775/css-i-want-a-div-to-be-on-top-of-everything 
                <table className="suggestions" style={{position:'absolute',zIndex:10, backgroundColor:'grey'}}>
                    <tbody>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className;
                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                        className = "suggestion-active";
                        }
                        return (
                        <tr style={{color:'white',cursor:'pointer'}}className={className} key={suggestion[fullName]} onClick={()=>handleClick(suggestion[shortName].toUpperCase())} >
                            {boldQuery(suggestion[shortName],userInput,1,{paddingRight:"35px"})}{boldQuery(suggestion[fullName],userInput,2,{})}
                        </tr>
                        //should not create space -> https://stackoverflow.com/questions/39914455/react-validatedomnesting-text-cannot-appear-as-a-child-of-tr 
                        );
                    })}
                    </tbody>
                </table>
                );
            } else {
                suggestionsListComponent = (
                <div className="no-suggestions">
                    <em style={{color:'white'}}>No suggestions, you're on your own!</em>
                </div>
                );
            }
        }
        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setUserInput(initialValue)
        }, [initialValue])

        return (
            <div>
                <input value={userInput} onChange={onChange} onKeyDown={handleKeyDown} />
                {suggestionsListComponent}
            </div>
        )
        
    }
    
    const onTickerParamsChange = (rowIndex,columnId,value,chartType) => {
        
        let tickerParamsIndex = rowIndex
        const chartId = `layout${layoutId.toString()}`
        // d3.select(`#${chartId} > g >.yAxis-${tickerParamsIndex}`).remove()
        // d3.select(`#${chartId} > g >.xAxis`).remove()
        // d3.select(`#${chartId} > g >.text-${tickerParamsIndex}`).remove()
        removeSVGItems(chartId, tickerParamsIndex, chartType)
        // console.log(rowIndex, columnId)
        dispatch(mainChartActions.changeTickerParams([workspaceId,layoutId,rowIndex,columnId,value]))
        for (let indicatorName of indicators) {
            // console.log(indicatorName, value)
            dispatch(mainChartActions.updateIndicatorParams([workspaceId,layoutId,indicatorName,{ticker:value}]))
        }

    }
    
    const handleDeleteRow = (tickerParamsIndex,ticker,chartType) => {
        const chartId = `layout${layoutId.toString()}`
        // d3.select(`#${chartId} > g >.yAxis-${tickerParamsIndex}`).remove()
        // d3.select(`#${chartId} > g >.xAxis`).remove()
        // d3.select(`#${chartId} > g >.text-${tickerParamsIndex}`).remove()
        // d3.select(`#${chartId}`).selectAll(`.${chartType}-${chartId}-${tickerParamsIndex}`).remove()
        // console.log(tickerParamsIndex)
        removeSVGItems(chartId, tickerParamsIndex, chartType)
        dispatch(mainChartActions.removeTickerParams([workspaceId,layoutId,ticker]))
    }
    const removeStyle = {
    //   position: "absolute",
    //   right: "2px",
    //   top: 0,
      cursor: "pointer",
      color:"white",
    };
    const columns = useMemo(
      () => [
        {   
            id:'panel',
            header:'Panel',
            accessorKey:'panel',
            cell:({cell}) => {
                
                return (
                    <span>{cell.getValue()}</span>
                )
            }
        },
        {   id:"ticker",
            header:'Ticker',
            accessorKey:'ticker',
            cell: EditableCell
        },
        {   id:"color",
            header:'Color',
            accessorFn: (row) => row,
            accessorKey:'color',
            cell:({cell}) => {
                // console.log(cell)
                const chartType = cell.getValue()["chartType"]
                // console.log(props)
                return (
                    <div>
                        <input  
                                type='color' 
                                value={cell.getValue()["color"]}
                                onChange = {(e) => onTickerParamsChange(cell.row.index,'color',e.target.value,chartType)} 
                        />
                        
                    </div>
                )
            }
        },
        {   id:'chartType',
            header:'Chart Type',
            accessorKey:'chartType',
            cell:({cell}) => {
                // console.log(cell)
                return (
                    <div>
                        <select
                            value={cell.getValue()} 
                            onChange = {(e)=>{onTickerParamsChange(cell.row.index,'chartType',e.target.value,e.target.value)}}
                            name="chartType"
                        >
                            
                            <option value="toggleLine">Line</option>
                            <option value="toggleCandleStick">CandleSticks</option>
                            {/* <option value="line">Line</option> */}
                        </select>
                    </div>
                )
            }
        },
        {   id:"axis",
            header:'Axis (*)',
            accessorFn: (row) => row,
            accessorKey:'axis',
            cell:({cell}) => {
                // console.log(cell)
                const chartType = cell.getValue()["chartType"]
                // add a function to automatic append new axis
                // let initialYAxisArray=['R1','L1']
                // let R = [1]
                // let L = [2] 
                // const selectedYAxisArray = props.data.map(p => p['axis'])
                // // let newYAxisArray = initialYAxisArray.filter(axis => !selectedYAxisArray.includes(axis))

                // const [yAxisName,position] = [...selectedYAxisArray[selectedYAxisArray.length - 1]]
                // const newPosition = parseInt(position) + 1
                // const newYAxisName = `${yAxisName}${newPosition}`
                // // console.log(parseInt(position) +1)
                // initialYAxisArray.push(newYAxisName)
                return (
                    <div>
                        {/* <FormControl fullWidth> */}
                            <select
                                value={cell.getValue()["axis"]} 
                                onChange = {(e)=>{onTickerParamsChange(cell.row.index,'axis',e.target.value,chartType)}}
                                // inputProps={{
                                name="axis"
                                // }}
                            >
                                <option value="R1">R1</option>
                                <option value="L1">L1</option>
                                <option value="R2">R2</option>
                                <option value="L2">L2</option>
                                <option value="R3">R3</option>
                                <option value="L3">L3</option>
                                {/* {initialYAxisArray.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))} */}
                            </select>
                        {/* </FormControl> */}
                    </div>
                )
            }
        },
        {   id:'actions',
            header:'Action',
            accessorFn: (row) => row,
            accessorKey:'actions',
            cell: ({cell}) => {
                // console.log(cell)
                const ticker = cell.getValue()["ticker"]
                const chartType = cell.getValue()["chartType"]
                return (
                    <div> 
                        <div>
                            <IconButton 
                                aria-label="delete" 
                                style={removeStyle} 
                                onClick={() => handleDeleteRow(cell.row.index,ticker,chartType)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>

                            
                        </div>
                                    
                    </div>
                );
            }, 
        },
            
      ],
      [suggestions]
    )
    // console.log(tickerParams)
    return (
        <div>
            <Table columns={columns} data={tickerParams} layout={null} cols={12}/>
            
            {/* <button onClick={handleClick}>Update</button> */}
            <br />
            <span style={{color:'white'}}>{'(*): Please select the Axis following order. For example: R1 -> R2'}</span>
        </div>
    )
}

export default TickerTable;