import { combineReducers } from 'redux'

import {dashboardReducer} from './dashboardReducer'
import {watcherReducer, tickerWatchList, signalWatchList} from './watcherReducer'
import {simpleChartReducer} from './simpleChartReducer'
import newsReducer from './newsReducer'
import { overviewReducer } from './overviewReducer'
import authMainReducer from './authReducer'
import priceBoardReducer from './priceBoardReducer'
import { mainChartReducer } from './mainChartReducer'
import {workspaceReducer} from './workspaceReducer'
import { marketMapReducer } from './marketMapReducer'
import { marketResearchReducer} from './marketResearchReducer';
import { macroeconomicReducer} from './macroeconomicReducer';

const rootReducer = combineReducers({
    workspace: workspaceReducer,
    dashboard: dashboardReducer,
    mainChart:mainChartReducer,
    watcher: watcherReducer,
    tickerInWatchList: tickerWatchList,
    signalInWatchList: signalWatchList,
    simpleChart: simpleChartReducer,
    tickersForNews: newsReducer,
    tickersForPriceBoard:priceBoardReducer,
    overview: overviewReducer,
    marketmap: marketMapReducer,
    marketResearch: marketResearchReducer,
    macroeconomic: macroeconomicReducer,
    auth: authMainReducer
})

export default rootReducer