const CREATE_MARKET_MAP = 'CREATE_MARKET_MAP'
const REMOVE_MARKET_MAP = 'REMOVE_MARKET_MAP'

export const createMarketMap = payload => {
    return {
        type: CREATE_MARKET_MAP,
        payload
    }
}

export const removeMarketMap = payload => {
    return {
        type: REMOVE_MARKET_MAP,
        payload
    }
}