import {useState, useEffect} from 'react'

import Autocomplete from '../../../../UniversalComponents/Autocomplete'
import MarketDataService from '../../../../../services/MarketService'
import SignalTickerManager from './SignalTickerManager';

import MenuIcon from '@mui/icons-material/Menu';


const WatchList = (props) => {
    // console.log(props)
    const {workspaceId,mainLayoutId} = props
    const [symbolList, setSymbolList] = useState([]) 
    const [signalList, setSignalList] = useState([]) 
    const [modalShow, setModalShow] = useState(false)
    const [modalData, setModalData] = useState({
        title:'',
        workspaceId:'',
        layoutId:'',
        objectList:'',
        reduxActionName:'',
        filterKeys:null
    })


    useEffect(() => {
        Promise.all([
            MarketDataService.getSymbolList(),
            MarketDataService.getSignalList()
        ]).then((responses) => {
            setSymbolList(responses[0].data)
            setSignalList(responses[1].data)
        }).catch(error => {alert(error)})
    },[])
    

    const {w,h} = props.layout.layoutProps
    const calculatePxWH = (w,h,colWidth,rowHeight,margin) => {
        let widthPx = Math.ceil(window.innerWidth * (w + margin[0]) + margin[0])/colWidth
        let heightPx = Math.ceil((h - 1) * (rowHeight + margin[1]) + margin[1])
        return [widthPx,heightPx]
    }
    const widthPx = calculatePxWH(w,h,12,30,[0,0])[0]

    const handleClick = (data) => {
        
        setModalData(data)
        setModalShow(true)
    }

    return (
        <div>
            <Autocomplete
                workspaceId={workspaceId}
                layoutId = {mainLayoutId}
                suggestions={symbolList}
                reduxActionName={'tickerInWatchListAction'}
                filterKeys={['symbol','long_name']}
                placeholderName = {'Add Ticker...'}
                isNestedList={false}
                width = {widthPx - 40}
            />
            <MenuIcon 
                    style={{color:'grey',cursor:'pointer'}} 
                    onClick={()=> handleClick({
                        title:'Ticker Manager',
                        workspaceId:workspaceId,
                        layoutId:mainLayoutId,
                        objectList:symbolList,
                        reduxActionName:'tickerInWatchListAction',
                        filterKeys:['symbol','long_name']
                        
                    })} 
            />
            <br/>
            <Autocomplete
                workspaceId={workspaceId}
                layoutId = {mainLayoutId}
                suggestions={signalList}
                reduxActionName={'signalInWatchListAction'}
                filterKeys={['code','name']}
                placeholderName= {'Add Signal...'}
                isNestedList={false}
                width = {widthPx - 40}   
            />
            <MenuIcon 
                    style={{color:'grey', cursor:'pointer'}} 
                    onClick={()=> handleClick({
                        title:'Signal Manager',
                        workspaceId:workspaceId,
                        layoutId:mainLayoutId,
                        objectList:signalList,
                        reduxActionName:'signalInWatchListAction',
                        filterKeys:['code','name']
                        
                    })} 
            />
            {/* the modal should be rendered only if user clicks the MenuIcon */}
            {modalShow && 
                <div onMouseDown={(e) => e.stopPropagation()}>
                    <SignalTickerManager

                        data={modalData}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />  
                </div>
            }
        </div>
    )
}
export default WatchList