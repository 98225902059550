import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import Table from '../../../UniversalComponents/Table'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {updateTickerParamsEconomicChart,removeIndicatorInMacroeconomic} from "../../../../js/actions/macroeconomicActions";

// import { removeSVGItems } from './removeSVGItems';

const IndicatorConfigTable = (props) => {
    // console.log(props)
    const data = props.data
    // const mainLayoutId = props.malayoutId
    
    const suggestions = props.suggestions
    const {workspaceId, mainLayoutId,subLayoutIdx} = props
    const dispatch = useDispatch()
    
    // console.log(suggestions)
    
    //https://codesandbox.io/embed/github/tannerlinsley/react-table/tree/master/examples/editable-data?codemirror=1 
    const EditableCell = ({
        value:initialValue,
        row:{index,original},
        column: {id},
        data: tableData,
        
    }) => {
        const [userInput, setUserInput] = useState(initialValue)
        const [filteredSuggestions, setFilteredSuggestions] = useState([])
        const [activeSuggestion,setActiveSuggestion] = useState(0)
        const [showSuggestions, setShowSuggestions] = useState(false)
        const fullName = 'long_name'
        const shortName= 'symbol'

        const onChange = e => {
            const currentUserInput = e.target.value.trim()
            // console.log(suggestions) 
             
            let currentFilteredSuggestions = suggestions.filter(
                suggestion =>
                    suggestion[fullName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
            );
            // if useInput does not match long_name, then it will continue to try match the symbols 
            if (!currentFilteredSuggestions.length){
                currentFilteredSuggestions=suggestions.filter(
                    suggestion =>
                        suggestion[shortName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
                )
            }
            setFilteredSuggestions(currentFilteredSuggestions)
            setShowSuggestions(true)
            setUserInput(currentUserInput.toUpperCase())
        }

        // We'll only update the external data when the input is blurred
        const handleKeyDown = (e) => {
            if(e.key==='Enter') {
                //check duplicated Value
                
                const checkDuplicated = tableData.map(d=> d['indicator']).indexOf(userInput)
                if (checkDuplicated !== -1) {
                    setUserInput("")
                    alert('this ticker is existed')
                    return
                }

                //check whether the ticker in the symbolLists database
                const isExisted = suggestions.some(item=> item.symbol.toUpperCase() === userInput.toUpperCase())
                
                if (!isExisted) {
                    setUserInput("")
                    alert("This ticker is not in database")
                    return
                }
                onIndicatorParamsChange(index, id, userInput)
            }
        }
        const handleClick = (ticker) => {
          
            setFilteredSuggestions([])
            setShowSuggestions(false)
            const checkDuplicated = tableData.map(d=> d['ticker']).indexOf(ticker)
                if (checkDuplicated !== -1) {
                    setUserInput("")
                    alert('this ticker is existed')
                    return
                }
            onIndicatorParamsChange(index, id, ticker)
            
            // dispatch(reduxAction[reduxActionName]([layoutId,ticker]))
        }
        
        const boldQuery = (str, query, col, rowStyle) => {
            const n = str.toUpperCase();
            const q = query.toUpperCase();
            const x = n.indexOf(q);
            if (!q || x === -1) {
                return col === 1 ? <span style={{paddingRight:"35px"}}>{str}</span>:str // bail early
            }
            const l = q.length;
            return (<td style={rowStyle}>{str.substr(0, x)}<span style={{ fontWeight: 'bold',textDecorationLine: 'underline'}}>{str.substr(x, l)}</span>{str.substr(x + l)}</td>)
        }
        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                // https://stackoverflow.com/questions/7421775/css-i-want-a-div-to-be-on-top-of-everything 
                <table className="suggestions" style={{position:'absolute',zIndex:10, backgroundColor:'grey'}}>
                    <tbody>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className;
                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                        className = "suggestion-active";
                        }
                        return (
                        <tr style={{color:'white',cursor:'pointer'}}className={className} key={suggestion[fullName]} onClick={()=>handleClick(suggestion[shortName].toUpperCase())} >
                            {boldQuery(suggestion[shortName],userInput,1,{paddingRight:"35px"})}{boldQuery(suggestion[fullName],userInput,2,{})}
                        </tr>
                        //should not create space -> https://stackoverflow.com/questions/39914455/react-validatedomnesting-text-cannot-appear-as-a-child-of-tr 
                        );
                    })}
                    </tbody>
                </table>
                );
            } else {
                suggestionsListComponent = (
                <div className="no-suggestions">
                    <em style={{color:'white'}}>No suggestions, you're on your own!</em>
                </div>
                );
            }
        }
        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
            setUserInput(initialValue)
        }, [initialValue])

        return (
            <div>
                <input value={userInput} onChange={onChange} onKeyDown={handleKeyDown} />
                {suggestionsListComponent}
            </div>
        )
        
    }
    
    const onIndicatorParamsChange = (props,value) => {
        const rowIndex = props.row.index;
        const columnId = props.column.id;
        const chartType = props.row.values.chartType;
        const oldValue = props.value;
        var statusBarChart = props.data.some(function(el) {
            return (el.chartType === 'toggleBar' || el.chartType == 'toggleStackedBar');
        });
        let isMassUpdate = false; // for axis and chartType
        let updatedTickerParams = {}
        if (
            (columnId === 'axis' && (chartType === 'toggleBar' || chartType === 'toggleStackedBar'))
            || (columnId === 'chartType' && value !== 'toggleLine' && (chartType === 'toggleBar' || chartType === 'toggleStackedBar'))
            
        ){
            /*
            - user changes axis OR changes (chartType AND selected value is not toggleLine)
            - AND (current chartType is toggleBar OR toggleStackedBar)
            */
            console.log('mass')
            isMassUpdate = true;
            updatedTickerParams = {
                [columnId]:value
            }
        } else if(columnId ==='chartType' && oldValue ==='toggleLine' && statusBarChart && (value ==='toggleBar' || value ==='toggleStackedBar')) {
            isMassUpdate = true;
            let axis = '';
            
            for (let i=0; i < props.data.length; i++) {
                
                if (props.data[i].chartType==='toggleBar' || props.data[i].chartType=='toggleStackedBar') {
                    axis = props.data[i].axis;
                    break;
                }
                
            }
            
            updatedTickerParams = {
                chartType: value,
                axis: axis
            }
        } else {
            updatedTickerParams = {
                [columnId]:value
            }
        }
        // let tickerParamsIndex = rowIndex
        
        // console.log(rowIndex,columnId,value, updatedSVGParams)
        dispatch(updateTickerParamsEconomicChart([workspaceId,mainLayoutId, subLayoutIdx, rowIndex,updatedTickerParams, isMassUpdate, columnId]))
        

    }
    
    const handleDeleteRow = (rowIndex) => {
        dispatch(removeIndicatorInMacroeconomic([workspaceId,mainLayoutId,subLayoutIdx, rowIndex]));
    }
    const removeStyle = {
    //   position: "absolute",
    //   right: "2px",
    //   top: 0,
      cursor: "pointer",
      color:"white",
    };
    const columns = React.useMemo(
      () => [
        
        {
            Header:'Indicator',
            accessor:'label',
            Cell: EditableCell
        },
        {
            Header:'Color',
            accessor:'color',
            Cell:(props) => {
                
                return (
                    <div>
                        <input  
                                type='color' 
                                value={props.value}
                                onChange = {(e) => onIndicatorParamsChange(props,e.target.value)} 
                        />
                        
                    </div>
                )
            }
        },
        {
            Header:'Chart Type',
            accessor:'chartType',
            Cell:(props) => {
                var statusBarChart = props.data.some(function(el) {
                    return (el.chartType === 'toggleBar');
                });
                var statusStackedBarChart = props.data.some(function(el) {
                    return (el.chartType === 'toggleStackedBar');
                });
                return (
                    <div>
                        <select
                            value={props.value} 
                            onChange = {(e)=>{onIndicatorParamsChange(props,e.target.value)}}
                            name="chartType"
                        >
                            
                            <option value="toggleLine">Line</option>
                            <option value="toggleBar" disabled={(statusStackedBarChart&&props.value === 'toggleLine') ? true : false}>Bar</option>
                            <option value="toggleStackedBar" disabled={(statusBarChart &&props.value === 'toggleLine') ? true : false}>Stacked Bar</option>
                            {/* <option value="line">Line</option> */}
                        </select>
                    </div>
                )
            }
        },
        {
            Header:'Axis (*)',
            accessor:'axis',
            Cell:(props) => {
                // const chartType = props.row.values.chartType
                // add a function to automatic append new axis
                let initialYAxisArray=['R1','L1']//'R2','L2','R3','L3']
                // let R = [1]
                // let L = [2] 
                // const selectedYAxisArray = props.data.map(p => p['axis'])
                // // let newYAxisArray = initialYAxisArray.filter(axis => !selectedYAxisArray.includes(axis))

                // const [yAxisName,position] = [...selectedYAxisArray[selectedYAxisArray.length - 1]]
                // const newPosition = parseInt(position) + 1
                // const newYAxisName = `${yAxisName}${newPosition}`
                // // console.log(parseInt(position) +1)
                // initialYAxisArray.push(newYAxisName)
                
                return (
                    <div>
                        {/* <FormControl fullWidth> */}
                            <select
                                value={props.value} 
                                onChange = {(e)=>{onIndicatorParamsChange(props,e.target.value)}}
                                // inputProps={{
                                name="axis"
                                // }}
                            >
                                {/* <option value="R1">R1</option>
                                <option value="L1">L1</option>
                                <option value="R2">R2</option>
                                <option value="L2">L2</option>
                                <option value="R3">R3</option>
                                <option value="L3">L3</option> */}
                                {initialYAxisArray.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                        {/* </FormControl> */}
                    </div>
                )
            }
        },
        {
            Header:'Action',
            accessor:'actions',
            Cell: (props) => {
                const ticker = props.row.values.label
                return (
                    <div> 
                        <div>
                            <IconButton 
                                aria-label="delete" 
                                style={removeStyle} 
                                onClick={() => handleDeleteRow(ticker)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>

                            
                        </div>
                                    
                    </div>
                );
            }, 
        },
            
      ],
      [suggestions]
    )
    return (
        <div>
            <Table columns={columns} data={data} layout={null} />
            
            {/* <button onClick={handleClick}>Update</button> */}
            <br />
            <span style={{color:'white'}}>{'(*): Please select the Axis following order. For example: R1 -> R2'}</span>
            <br />
            <span style={{color:'white'}}>{'- Stack Bar chart cannot be used conincidently with group bar chart and should only be used in some circumtances'}</span>

        </div>
    )
}

export default IndicatorConfigTable;