const CREATE_OVERVIEW = 'CREATE_OVERVIEW'
const ADD_TICKER_INTO_OVERVIEW_FROM_HEADER = 'ADD_TICKER_INTO_OVERVIEW_FROM_HEADER'
const REMOVE_OVERVIEW = 'REMOVE_OVERVIEW'
const UPDATE_TICKER = 'UPDATE_TICKER'
const ADJUST_SVG_PARAMS_OVERVIEW = 'ADJUST_SVG_PARAMS_OVERVIEW'

export const createOverview = payload => {
    return {
        type: CREATE_OVERVIEW,
        payload
    }
}

export const addTickerIntoOverviewFromHeader = payload => {
    return {
        type: ADD_TICKER_INTO_OVERVIEW_FROM_HEADER,
        payload
    }
}

export const removeOverview = payload => {
    return {
        type: REMOVE_OVERVIEW,
        payload
    }
}

export const updateTicker = payload => {
    return {
        type: UPDATE_TICKER,
        payload
    }
}

export const adjustSVGParamsOverview = payload => {
    return {
        type: ADJUST_SVG_PARAMS_OVERVIEW,
        payload
    }
}