const initState = {0:{0:['GLOBALNEWS']}}

const newsReducer = (state=initState, action) => {
  
  switch (action.type ) {
    
    case 'CREATE_NEWS_BOARD':{
      let [workspaceId,mainLayoutId] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[]
        }
      }
    }
    case 'REMOVE_NEWS_LAYOUT': {
      let [workspaceId,mainLayoutId] = action.payload
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
    }
    // case 'ADD_TICKER_INTO_NEWS_LIST': {
    //   let [workspaceId, mainLayoutId,tickers] = action.payload
      
    //   return {
    //     ...state,
    //     [workspaceId]:{
    //       ...state[workspaceId],
    //       [mainLayoutId]: tickers
    //     }
    //   }
    // }
    case 'ADD_TICKER_INTO_NEWS_LIST': {
      let [workspaceId, mainLayoutId,ticker] = action.payload
      
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId],
            ticker
          ]
        }
      }
    }
    // case 'REMOVE_TICKER_FROM_NEWS_LIST': {
    //   let[workspaceId, mainLayoutId, indexOfTicker] = action.payload
    //   return {
    //     ...state,
    //     [workspaceId]:{
    //       ...state[workspaceId],
    //       [mainLayoutId]:[
    //         ...state[workspaceId][mainLayoutId].filter((_,index) => index !== indexOfTicker)
    //       ]
    //     }
    //   }
    // }
    case 'REMOVE_TICKER_FROM_NEWS_LIST': {
      let[workspaceId, mainLayoutId, removedTicker] = action.payload
      console.log(removedTicker)
      console.log(state[workspaceId][mainLayoutId].filter((ticker) => ticker.toLowerCase() !== removedTicker.toLowerCase()))
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].filter((ticker) => ticker.toLowerCase() !== removedTicker.toLowerCase())
          ]
        }
      }
    }
    default:
      return state
  }
  
}

export default newsReducer