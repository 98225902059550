// import * as d3 from 'd3'
import {select,
        min, max,
        line,
        scaleLinear, 
        axisRight, axisLeft} from 'd3'
const bollingerband = (tickerParamsIndex,
                        chartId,timeSeriesData,
                        chartParams,xScale,yScale,
                        yAxisLeftWidth,yAxisRightWidth,
                        indicators) => {
    
    select(`#bollinger-group-${chartId}-${tickerParamsIndex}`).remove() 
    const svg = select(`#${chartId} > g `).append('g')
                .attr('id',`bollinger-group-${chartId}-${tickerParamsIndex}`)
    // svg.append('g').attr('id',`bollinger-group-${chartId}-${tickerParamsIndex}`)
    const {bollingerMidColor, bollingerUpperColor,bollingerLowerColor} = chartParams.analysis.bollingerband
    timeSeriesData.bollingerband.then(result =>  {
                // console.log(result)
                if (result.timeSeriesBBand !== undefined) {
                    
                    
                    const midLine = line()
                                    .x(d => xScale(new Date(d['Date'])))
                                    .y(d => yScale(d['bbmid']));
                    const midLineSelect = //select(`#${chartId} > g `)
                                            svg.selectAll(`.bollinger-${chartId}-${tickerParamsIndex} midline`)
                                            .data([result.timeSeriesBBand])
                                            
                    
                    midLineSelect.join(
                        enter => enter
                                            .append('path')
                                            .style('fill', 'none')
                                            .attr('class', `bollinger-${chartId}-${tickerParamsIndex} midline`)
                                            .attr('cursor','pointer')
                                            .attr('pointer-events',"visible")
                                            .attr('clip-path', `url(#clip-${chartId})`)
                                            .attr('stroke', bollingerMidColor)
                                            .attr('stroke-width', '1.5')
                                            .attr('d', midLine),
                        
                            
                        // update =>
                        // update
                        //     .transition()
                        //     .duration(150)
                        //     .attr('stroke', bollingerMidColor)
                        //     .attr('d', midLine)
                    );
                    const upperLine = line()
                                    .x(d => xScale(new Date(d['Date'])))
                                    .y(d => yScale(d['bbup']));
                    const upperLineSelect = //select(`#${chartId} > g `)
                                            svg.selectAll(`.bollinger-${chartId}-${tickerParamsIndex} upperline`)
                                            .data([result.timeSeriesBBand])
                                            
                    
                    upperLineSelect.join(
                        enter => enter
                                            .append('path')
                                            .style('fill', 'none')
                                            .attr('class', `bollinger-${chartId}-${tickerParamsIndex} upperline`)
                                            .attr('cursor','pointer')
                                            .attr('pointer-events',"visible")
                                            .attr('clip-path', `url(#clip-${chartId})`)
                                            .attr('stroke', bollingerUpperColor)
                                            .attr('stroke-width', '1.5')
                                            .attr('d', upperLine),
                        
                            
                        // update =>
                        // update
                        //     .transition()
                        //     .duration(150)
                        //     .attr('stroke', bollingerUpperColor)
                        //     .attr('d', upperLine)
                    );
                    const lowerLine = line()
                                    .x(d => xScale(new Date(d['Date'])))
                                    .y(d => yScale(d['bblow']));
                    const lowerLineSelect = //select(`#${chartId} > g `)
                                            svg.selectAll(`.bollinger-${chartId}-${tickerParamsIndex} lowerline`)
                                            .data([result.timeSeriesBBand])
                                            
                    
                    lowerLineSelect.join(
                        enter => enter
                                            .append('path')
                                            .style('fill', 'none')
                                            .attr('class', `bollinger-${chartId}-${tickerParamsIndex} lowerline`)
                                            .attr('cursor','pointer')
                                            .attr('pointer-events',"visible")
                                            .attr('clip-path', `url(#clip-${chartId})`)
                                            .attr('stroke', bollingerLowerColor)
                                            .attr('stroke-width', '1.5')
                                            .attr('d', lowerLine),
                        
                            
                        // update =>
                        // update
                        //     .transition()
                        //     .duration(150)
                        //     .attr('stroke', 'red')
                        //     .attr('d', lowerLine)
                    );                                                               
                    
                    
            //ANNOTATION
                    // svg.append('text')
                    //     .attr('class',`macd-${chartId}-${tickerParamsIndex} annotation`)
                    //     .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator + 10})`)//${adjustedHeight})`)
                    //     // .attr("dy", ".15em")
                    //     .attr("text-anchor", "start")
                    //     .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                    //     .style("font-size", "10px") 
                    //     .text(`MACD Line-${tickerParams[tickerParamsIndex].ticker},
                    //                     Last: ${result.timeSeriesMACD.slice(-1)[0]['macd'].toFixed(2)}`);
                }
            })
    }                    
const macd = (tickerParamsIndex,chartId,timeSeriesData,
                chartParams,xScale,yScale,
                yAxisLeftWidth,yAxisRightWidth,
                indicators) => {
    select(`#macd-group-${chartId}-${tickerParamsIndex}`).remove() 
    
    const {analysis, svgParams, tickerParams} = chartParams
    const {macdColor, signalColor, histogramColor,indicatorHeight} = chartParams.analysis.macd
    let accumulatedIndicatorHeightOfThisIndicator = 0
    // console.log(indicators)
    for (let i = 0; i < indicators.length; i++ ) {
        accumulatedIndicatorHeightOfThisIndicator += analysis[indicators[i]]['indicatorHeight']
        if(indicators[i] === 'macd') break
    }
    // console.log(accumulatedIndicatorHeightOfThisIndicator)
    const width = svgParams.width
    const height = svgParams.height
    // const adjustedHeight = svgParams.adjustedHeight
    const svg = select(`#${chartId} > g`).append('g').attr('id',`macd-group-${chartId}-${tickerParamsIndex}`)
    svg.append('clipPath')
                        .attr('id',`macd-series-${chartId}`)
                        // .attr('clip-path','url(#clip)')
                        .append('rect')
                        .attr('width',width-yAxisLeftWidth-yAxisRightWidth)
                        .attr('height',indicatorHeight)//height-adjustedHeight)
                        .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator})`) //adjustedHeight})`)
    // const margin = chartParams.svgParams.margin
    
    const yRightMACDAxis = svg
                            .append('g')
                            .attr('class',`macd-${chartId}-${tickerParamsIndex} yRightAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${width - yAxisRightWidth},0)`)
                                                            // ${indicatorHeight - accumulatedIndicatorHeightOfThisIndicator})`)
                              
    const yLeftMACDAxis = svg
                            .append('g')
                            .attr('class',`macd-${chartId}-${tickerParamsIndex} yLeftAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${yAxisLeftWidth},${0})`)
                                        
    timeSeriesData.macd.then(result =>  {
                // console.log(xScale.range())
                if (result.timeSeriesMACD !== undefined) {
                    const yMACDMin = min(result.timeSeriesMACD,d => d['macd'])
                    const yMACDMax = max(result.timeSeriesMACD,d => d['macd'])
                    // console.log(yPEMin,yPEMax)
                    
                    const yMACDScale = scaleLinear()
                                        .domain([yMACDMin * 0.9,yMACDMax * 1.1])
                                        .range([height- (accumulatedIndicatorHeightOfThisIndicator -indicatorHeight),height-accumulatedIndicatorHeightOfThisIndicator])//adjustedHeight])
                    
                    yRightMACDAxis.call(axisRight(yMACDScale));
                    yRightMACDAxis.selectAll('text')
                                    .style('color',`white`)
                    
                    yLeftMACDAxis.call(axisLeft(yMACDScale));
                    yLeftMACDAxis.selectAll('text')
                                    .style('color',`white`)
                    const lineMACD = line()
                                .x(d => xScale(new Date(d['Date'])))
                                .y(d => yMACDScale(d['macd'])) 
                    const lineMACDSelect = svg
                    // .select('g')
                    .selectAll(`.macd-${chartId}-${tickerParamsIndex} macd-line`)
                    .data([result.timeSeriesMACD])

                    // d3.select(`#${chartId}`).selectAll(`.pe-line-${chartId}-${tickerParamsIndex}`)
                    lineMACDSelect.join(
                            enter => enter.append('path')
                                        .style('fill','none')
                                        .attr('class', `macd-${chartId}-${tickerParamsIndex} macd-line`)
                                        .attr('cursor', 'pointer')
                                        //.attr('pointer-events','visible')
                                        .attr('clip-path', `url(#macd-series-${chartId})`)
                                        .attr('stroke',macdColor)
                                        .attr('stroke-width', '1.5')
                                        .attr('d', lineMACD),
                            update => update.transition()
                                            .duration(750)
                                            .attr('d', lineMACD)
                    )
                    const lineMACDSignal = line()
                                .x(d => xScale(new Date(d['Date'])))
                                .y(d => yMACDScale(d['macdsignal'])) 
                    const lineMACDSignalSelect = svg
                    // .select('g')
                    .selectAll(`.macd-${chartId}-${tickerParamsIndex} macd-signal`)
                    .data([result.timeSeriesMACD])

                    // d3.select(`#${chartId}`).selectAll(`.pe-line-${chartId}-${tickerParamsIndex}`)
                    lineMACDSignalSelect.join(
                            enter => enter.append('path')
                                        .style('fill','none')
                                        .attr('class', `macd-${chartId}-${tickerParamsIndex} macd-signal`)
                                        .attr('cursor', 'pointer')
                                        //.attr('pointer-events','visible')
                                        .attr('clip-path', `url(#macd-series-${chartId})`)
                                        .attr('stroke',signalColor)
                                        .attr('stroke-width', '1.5')
                                        .attr('d', lineMACDSignal),
                            update => update.transition()
                                            .duration(750)
                                            .attr('d', lineMACDSignal)
                    )
                    
                    svg.selectAll(".bar")
                        .data(result.timeSeriesMACD)
                        .enter().append("rect")
                        .attr("class",  `macd-${chartId}-${tickerParamsIndex} macd-histogram`)
                        .attr("y", function(d) { return yMACDScale(Math.max(0, d.macdhist)); })
                        .attr("x", function(d, i) { return xScale(new Date(d['Date'])) })
                        .attr('clip-path', `url(#macd-series-${chartId})`)
                        .attr("height", function(d) { return Math.abs(yMACDScale(d.macdhist) - yMACDScale(0)); })
                        .attr("width", 1)
                        .attr("fill", function(d){ return d.macdhist < 0 ? "#d7191c": "#1a9641"; });
                    
            //ANNOTATION
                    svg.append('text')
                        .attr('class',`macd-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator + 10})`)//${adjustedHeight})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`MACD Line-${tickerParams[tickerParamsIndex].ticker},
                                        Last: ${result.timeSeriesMACD.slice(-1)[0]['macd'].toFixed(2)}`);
                    
            
            // create ceiling- line
                    svg
                        
                        .append('line')
                        .attr('class',`macd-${chartId}-${tickerParamsIndex} macd-ceiling`)
                        .attr('stroke','grey')
                        // .attr('stroke-dasharray','1')
                        .attr('x1', yAxisLeftWidth)
                        .attr('y1', yMACDScale(yMACDMax * 1.1))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yMACDScale(yMACDMax * 1.1));  
                    
            
                        
        }
                    
    })
    
}

const movingaverage = (tickerParamsIndex,
                        chartId,timeSeriesData,
                        chartParams,xScale,yScale,
                        yAxisLeftWidth,yAxisRightWidth,
                        indicators) => {
    
    select(`#movingaverage-group-${chartId}-${tickerParamsIndex}`).remove() 
    const svg = select(`#${chartId} > g `).append('g')
                .attr('id',`movingaverage-group-${chartId}-${tickerParamsIndex}`)
    // svg.append('g').attr('id',`bollinger-group-${chartId}-${tickerParamsIndex}`)
    const movingaverageParams = chartParams.analysis.movingaverage.multipleState
    // console.log(timeSeriesData)
    timeSeriesData.movingaverage.then(result =>  {
                // console.log(result)
                if (result.timeSeriesMA !== undefined) {
                    
                    const keys = Object.keys(result.timeSeriesMA[0])
                    keys.shift()
                    // console.log(keys)
                    for (let i=0; i < keys.length; i++){

                    
                        const maLine = line()
                                        .x(d => xScale(new Date(d['Date'])))
                                        .y(d => yScale(d[keys[i]]));
                        const maLineSelect = //select(`#${chartId} > g `)
                                                svg.selectAll(`.movingaverage-${chartId}-${tickerParamsIndex}-${i}`)
                                                .data([result.timeSeriesMA])
                                                
                        
                        maLineSelect.join(
                            enter => enter
                                                .append('path')
                                                .style('fill', 'none')
                                                .attr('class', `movingaverage-${chartId}-${tickerParamsIndex}-${i}`)
                                                .attr('cursor','pointer')
                                                .attr('pointer-events',"visible")
                                                .attr('clip-path', `url(#clip-${chartId})`)
                                                .attr('stroke', movingaverageParams[i]['color'])
                                                .attr('stroke-width', '1.5')
                                                .attr('d', maLine),
                            
                                
                            // update =>
                            // update
                            //     .transition()
                            //     .duration(150)
                            //     .attr('stroke', bollingerMidColor)
                            //     .attr('d', midLine)
                        );
                    
                        // update =>
                        // update
                        //     .transition()
                        //     .duration(150)
                        //     .attr('stroke', 'red')
                        //     .attr('d', lowerLine)
                                                                                   
                    }
                    
            //ANNOTATION
                    // svg.append('text')
                    //     .attr('class',`macd-${chartId}-${tickerParamsIndex} annotation`)
                    //     .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator + 10})`)//${adjustedHeight})`)
                    //     // .attr("dy", ".15em")
                    //     .attr("text-anchor", "start")
                    //     .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                    //     .style("font-size", "10px") 
                    //     .text(`MACD Line-${tickerParams[tickerParamsIndex].ticker},
                    //                     Last: ${result.timeSeriesMACD.slice(-1)[0]['macd'].toFixed(2)}`);
                }
            })
}
const rsi = (tickerParamsIndex,chartId,timeSeriesData,
                chartParams, xScale, yScale,
                yAxisLeftWidth,yAxisRightWidth,
                indicators) => {
      //if(value) {
        //  if (this.zoom) {
        //       d3.select('svg')
        //         .select('.rsi-line')
        //         .transition()
        //         .duration(750)
        //         .call(this.zoom.transform,d3.zoomIdentity.scale(1));
        //  }
        
        select(`#rsi-group-${chartId}-${tickerParamsIndex}`).remove() 
        const svg = select(`#${chartId} > g`).append('g').attr('id',`rsi-group-${chartId}-${tickerParamsIndex}`)
        const {analysis, svgParams, tickerParams} = chartParams
        const {upperRange, lowerRange, color, isDivergenceConvergence,indicatorHeight} = analysis.rsi
        let accumulatedIndicatorHeightOfThisIndicator = 0
        for (let i = 0; i < indicators.length; i++ ) {
            accumulatedIndicatorHeightOfThisIndicator += analysis[indicators[i]]['indicatorHeight']
            if(indicators[i] === 'rsi') break
        }
        const width = svgParams.width
        const height = svgParams.height
        // const adjustedHeight = svgParams.adjustedHeight
        svg.append('clipPath')
                        .attr('id',`rsi-series-${chartId}`)
                        // .attr('clip-path','url(#clip)')
                        .append('rect')
                        .attr('width',width-yAxisLeftWidth-yAxisRightWidth)
                        .attr('height', indicatorHeight)//height-adjustedHeight)
                        .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator})`) //adjustedHeight})`)
        // console.log(height,adjustedHeight) 
        // const margin = chartParams.svgParams.margin 
        const yRsiScale = scaleLinear()
                            .domain([0,100])
                            .range([height- (accumulatedIndicatorHeightOfThisIndicator - indicatorHeight),height-accumulatedIndicatorHeightOfThisIndicator])//adjustedHeight])
                            
        const yRightRsiAxis = svg
                            .append('g')
                            .attr('class',`rsi-${chartId}-${tickerParamsIndex} yRightAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${width - yAxisRightWidth},
                                                            ${0})`)
                            .call(axisRight(yRsiScale).tickValues([0,50,100]));
        yRightRsiAxis.selectAll('text')
                                    .style('color',`white`)
        const yLeftRsiAxis = svg
                            .append('g')
                            .attr('class',`rsi-${chartId}-${tickerParamsIndex} yLeftAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${yAxisLeftWidth},${0})`)
                            .call(axisLeft(yRsiScale).tickValues([0,50,100]));
        yLeftRsiAxis.selectAll('text')
                                    .style('color',`white`)
        timeSeriesData.rsi.then(result => {
            if (result !== undefined) {
                // timeSeriesData = result.timeSeriesRSI.filter(r => r.value !== null)
                // console.log(result.timeSeriesRSI)
                const lineRsi = line()
                            .x(d => xScale(new Date(d['Date'])))
                            .y(d => yRsiScale(d['value']))
                            // .defined()
                    
                const lineRsiSelect = svg
                                        // .select('g')
                                        .selectAll(`.rsi-${chartId}-${tickerParamsIndex} rsi-line`)
                                        .data([result.timeSeriesRSI])
                lineRsiSelect.join(
                    enter => enter.append('path')
                                .style('fill','none')
                                .attr('class', `rsi-${chartId}-${tickerParamsIndex} rsi-line`)
                                .attr('cursor', 'pointer')
                                //.attr('pointer-events','visible')
                                .attr('clip-path', `url(#rsi-series-${chartId})`)
                                .attr('stroke',`${color}`)
                                .attr('stroke-width', '1.5')
                                .attr('d', lineRsi),
                    update => update.transition()
                                    .duration(750)
                                    .attr('d', lineRsi)
                )
                    // console.log(timeSeriesData.rsi.timeSeriesRSI.slice(-1)[0])
                svg.append('text')
                .attr('class',`rsi-${chartId}-${tickerParamsIndex} annotation`)
                .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator + 10})`)//${adjustedHeight + 10})`)
                // .attr("dy", ".15em")
                .attr("text-anchor", "start")
                .style("fill", `${chartParams.tickerParams[tickerParamsIndex]['color']}`)
                .style("font-size", "10px") 
                .text(`RSI-${chartParams.tickerParams[tickerParamsIndex].ticker},
                                Last: ${result.timeSeriesRSI.slice(-1)[0]['value'].toFixed(2)}`);
                // create ceiling- line
                svg
                    
                    .append('line')
                    .attr('class',`rsi-${chartId}-${tickerParamsIndex} ceiling-line`)
                    .attr('stroke','grey')
                    // .attr('stroke-dasharray','1')
                    .attr('x1', yAxisLeftWidth)
                    .attr('y1', yRsiScale(100))
                    .attr('x2', xScale.range()[1])
                    .attr('y2', yRsiScale(100));  
                // create upper- line
                
                svg
                    
                    .append('line')
                    .attr('class',`rsi-${chartId}-${tickerParamsIndex} upper-line`)
                    .attr('stroke','orange')
                    .attr('stroke-dasharray','1')
                    .attr('x1', yAxisLeftWidth)
                    .attr('y1', yRsiScale(upperRange))
                    .attr('x2', xScale.range()[1])
                    .attr('y2', yRsiScale(upperRange));
                // create mid - line
                svg
                
                .append('line')
                .attr('class',`rsi-${chartId}-${tickerParamsIndex} mid-line`)
                .attr('stroke','orange')
                .attr('stroke-dasharray','1')
                .attr('x1', yAxisLeftWidth)
                .attr('y1', yRsiScale(50))
                .attr('x2', xScale.range()[1])
                .attr('y2', yRsiScale(50));
                // create lower-line
                svg
                
                .append('line')
                .attr('class',`rsi-${chartId}-${tickerParamsIndex} lower-line`)
                .attr('stroke','orange')
                .attr('stroke-dasharray','1')
                .attr('x1',yAxisLeftWidth)
                .attr('y1', yRsiScale(lowerRange))
                .attr('x2', xScale.range()[1])
                .attr('y2', yRsiScale(lowerRange));

                
                // console.log(result.checkDivergence) 
                if (isDivergenceConvergence && (result.checkConvergence[0] || result.checkDivergence[0])) {
                    //this.vergenceData = this.checkConvergence(this.rsiData,this.currentData,30);
                    //console.log(this.vergenceData)
                    // if (this.vergenceData !== undefined) {
                    // console.log(result.checkDivergence[0])
                    let data
                    result.checkConvergence[0] === true ? data = result.checkConvergence 
                                            : data = result.checkDivergence
                    let [condition,firstIndicatorValue,secondIndicatorValue,firstPrice,secondPrice,firstIndex,secondIndex] = data
                    // console.log(timeSeriesData.historicalPrice[tickerParamsIndex]) 
                    svg
                        
                        .append('line')
                        .attr('class',`rsi-${chartId}-${tickerParamsIndex} inst-vergence-line`)
                        .attr('stroke','red')
                        // .attr('stroke-dasharray','5')
                        .attr('stroke-width',3)
                        .attr('y1', yScale(firstPrice))
                        .attr('x1', xScale(timeSeriesData.historicalPrice[tickerParamsIndex][firstIndex]['Date']))
                        .attr('x2', xScale(timeSeriesData.historicalPrice[tickerParamsIndex][secondIndex]['Date']))
                        .attr('y2', yScale(secondPrice));

                    svg
                        .append('line')
                        .attr('class',`rsi-${chartId}-${tickerParamsIndex} indicator-vergence-line`)
                        .attr('stroke','red')
                        // .attr('stroke-dasharray','5')
                        .attr('stroke-width',3)
                        .attr('x1', xScale(timeSeriesData.historicalPrice[tickerParamsIndex][firstIndex]['Date']))
                        .attr('y1', yRsiScale(firstIndicatorValue))
                        .attr('x2', xScale(timeSeriesData.historicalPrice[tickerParamsIndex][secondIndex]['Date']))
                        .attr('y2', yRsiScale(secondIndicatorValue));
                }
            }
        })
      // } else {
      //    d3.selectAll('.rsi-line').select('.yRsiAxis').remove();
      // }
}
// http://plnkr.co/edit/Mf3Q0CK6tdxxQlYUDKLX?p=preview&preview
// http://using-d3js.com/05_10_symbols.html
// https://bl.ocks.org/d3noob/5259f38ebcf5659f39ef18d726d4ec21
// https://programmer.help/blogs/drawing-a-series-of-multi-symbol-scatter-plots-of-d3-two-dimensional-graphs.html
// https://stackoverflow.com/questions/43174396/how-to-draw-the-triangle-symbol -> rotate triangle
const signal = (tickerParamsIndex, chartId,data,
                 chartParams,xScale,yScale,
                 yAxisLeftWidth,yAxisRightWidth,
                 indicators) => {
    select(`#${chartId} > g`)
            // .selectAll(`.symbol-${chartId}-${tickerParamsIndex}`)
            .selectAll('circle')
            .remove();
    // if (chartParameters.chartType === 'line'){
    

    // var arc = d3.symbol().type(d3.symbolTriangle);
    const revisedData = data['historicalPrice'][0].filter(item => item.check !== null)
    // console.log(revisedData)
    select(`#${chartId} > g`).selectAll(`dot`)
    
        .data(revisedData)
        // .enter()
        // .append('path')
        // .attr('class','point')
        // .attr('d', arc)
        // .attr('fill', 'yellow')
        // .attr('transform', function(d) {
        //     console.log(d)
        //     return `translate(${xScale(d.Date)},${yScale(25000)})`;
        // });
        .enter().append("circle")
        .attr('class', `symbol-${chartId}-${tickerParamsIndex}`)                               
        .attr("r", 5.5)
        .style("fill", "#fff8ee")    
        .style("opacity", .8)      // set the element opacity
        .style("stroke", "#f93")    // set the line colour
        .style("stroke-width", 3.5) 
        .attr("cx", function(d) { return xScale(d.Date); })       
        .attr("cy", function(d) { return yScale(d.check); })     

        // const symbolPath = d3.symbol()
        //             .type(function(d) {
        //                 console.log(d)
        //                 if (d['check'] === 'up') { 
        //                     return d3.symbolTriangle 
        //                 } else if(d['check'] === 'down') {
        //                     return d3.symbolTriangle
        //                 }
        //             })
        //             // .style(d => {
        //             //     if (d['check'] === 'up') { 
        //             //         return 'green' 
        //             //     } else if(d['check'] === 'down') {
        //             //         return 'red'
        //             //     }
        //             // })
        //             // .size(30)
        //             // .attr('transform',d => `translate(${xScale(d['Date'])},${yScale(d['Close'])})`)
                
        // const lineSelect = d3.select(`#${chartId}`)
        //                         .selectAll(`.symbol-${chartId}-${tickerParamsIndex}`)
        //                         .data(data['historicalPrice']);
        
        // lineSelect.join(
        //     enter => enter
        //                         .append('path')
        //                         .style('fill', 'none')
        //                         .attr('class', `symbol-${chartId}-${tickerParamsIndex}`)
        //                         .attr('cursor','pointer')
        //                         .attr('pointer-events',"visible")
        //                         .attr('clip-path', 'url(#clip)')
        //                         .attr('d', symbolPath),
            
                
        //     update =>
        //     update
        //         .transition()
        //         .duration(750)
        //         // .attr('stroke', chartParameters.tickerParams[tickerParamsIndex].color)
        //         .attr('d', symbolPath)
        // );
}

const pe = (tickerParamsIndex,chartId,timeSeriesData,
            chartParams,xScale,yScale,
            yAxisLeftWidth,yAxisRightWidth,
            indicators) => {

    select(`#pe-group-${chartId}-${tickerParamsIndex}`).remove() 
    
    const {analysis, svgParams, tickerParams} = chartParams
    const {std1stLevel, std2ndLevel, std1stColor, std2ndColor,meanColor,peColor,indicatorHeight} = chartParams.analysis.pe
    let accumulatedIndicatorHeightOfThisIndicator = 0
    for (let i = 0; i < indicators.length; i++ ) {
        accumulatedIndicatorHeightOfThisIndicator += analysis[indicators[i]]['indicatorHeight']
        if(indicators[i] === 'pe') break
    }
    const width = svgParams.width
    const height = svgParams.height
    // const adjustedHeight = svgParams.adjustedHeight
    const svg = select(`#${chartId} > g`).append('g').attr('id',`pe-group-${chartId}-${tickerParamsIndex}`)
    svg.append('clipPath')
                        .attr('id',`pe-series-${chartId}`)
                        // .attr('clip-path','url(#clip)')
                        .append('rect')
                        .attr('width',width-yAxisLeftWidth-yAxisRightWidth)
                        .attr('height',indicatorHeight)//height-adjustedHeight)
                        .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator})`) //adjustedHeight})`)
    // const margin = chartParams.svgParams.margin
    
    const yRightPEAxis = svg
                            .append('g')
                            .attr('class',`pe-${chartId}-${tickerParamsIndex} yRightAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${width - yAxisRightWidth},
                                                            ${0})`)
                                        
    const yLeftPEAxis = svg
                            .append('g')
                            .attr('class',`pe-${chartId}-${tickerParamsIndex} yLeftAxis`) //tickerParamsId = 0
                            .attr('transform',`translate(${yAxisLeftWidth},${0})`)
                                        
    timeSeriesData.pe.then(result =>  {
                // console.log(result.timeSeriesPE.slice(-1)[0])
                if (result?.timeSeriesPE !== undefined) {
                    const yPEMin = Math.min(min(result.timeSeriesPE,d => d['pe']),result.timeSeriesPE[0][`-${std2ndLevel} Std Dev`])
                    const yPEMax = Math.max(max(result.timeSeriesPE,d => d['pe']),result.timeSeriesPE[0][`+${std2ndLevel} Std Dev`])
                    // console.log(yPEMin,yPEMax)
                    
                    const yPEScale = scaleLinear()
                                        .domain([yPEMin * 0.9,yPEMax * 1.1])
                                        .range([height- (accumulatedIndicatorHeightOfThisIndicator -indicatorHeight),height-accumulatedIndicatorHeightOfThisIndicator])//adjustedHeight])
                    // const yRightPEAxis = svg
                    //                     .append('g')
                    //                     .attr('class',`pe-${chartId}-${tickerParamsIndex} yRightAxis`) //tickerParamsId = 0
                    //                     .attr('transform',`translate(${width - yAxisRightWidth},
                    //                                                     ${0})`)
                    yRightPEAxis.call(axisRight(yPEScale));
                    yRightPEAxis.selectAll('text')
                                    .style('color',`white`)
                    // const yLeftPEAxis = svg
                    //                     .append('g')
                    //                     .attr('class',`pe-${chartId}-${tickerParamsIndex} yLeftAxis`) //tickerParamsId = 0
                    //                     .attr('transform',`translate(${yAxisLeftWidth},${0})`)
                    yLeftPEAxis.call(axisLeft(yPEScale))
                    yLeftPEAxis.selectAll('text')
                                    .style('color',`white`)
                    const linePE = line()
                                .x(d => xScale(new Date(d['Date'])))
                                .y(d => yPEScale(d['pe'])) 
                    const linePESelect = svg
                    // .select('g')
                    .selectAll(`.pe-${chartId}-${tickerParamsIndex} pe-line`)
                    .data([result.timeSeriesPE])

                    // d3.select(`#${chartId}`).selectAll(`.pe-line-${chartId}-${tickerParamsIndex}`)
                    linePESelect.join(
                            enter => enter.append('path')
                                        .style('fill','none')
                                        .attr('class', `pe-${chartId}-${tickerParamsIndex} pe-line`)
                                        .attr('cursor', 'pointer')
                                        //.attr('pointer-events','visible')
                                        .attr('clip-path', `url(#pe-series-${chartId})`)
                                        .attr('stroke',peColor)
                                        .attr('stroke-width', '1.5')
                                        .attr('d', linePE),
                            update => update.transition()
                                            .duration(750)
                                            .attr('d', linePE)
                    )
            //ANNOTATION
                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${height-accumulatedIndicatorHeightOfThisIndicator + 10})`)//${adjustedHeight})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`PE-${tickerParams[tickerParamsIndex].ticker},
                                        Last: ${result.timeSeriesPE.slice(-1)[0]['pe'].toFixed(2)}`);
                    
                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${yPEScale(result.timeSeriesPE[0][`+${std2ndLevel} Std Dev`])})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`+${std2ndLevel} Std Dev`);

                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${yPEScale(result.timeSeriesPE[0][`+${std1stLevel} Std Dev`])})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`+${std1stLevel} Std Dev`);

                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${yPEScale(result.timeSeriesPE[0][`-${std2ndLevel} Std Dev`])})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`-${std2ndLevel} Std Dev`);

                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${yPEScale(result.timeSeriesPE[0][`-${std1stLevel} Std Dev`])})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text(`-${std1stLevel} Std Dev`);

                    svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${yAxisLeftWidth},${yPEScale(result.timeSeriesPE[0]['mean'])})`)
                        // .attr("dy", ".15em")
                        .attr("text-anchor", "start")
                        .style("fill", `${tickerParams[tickerParamsIndex]['color']}`)
                        .style("font-size", "10px") 
                        .text('mean');

            // create ceiling- line
                    svg
                        
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-ceiling`)
                        .attr('stroke','grey')
                        // .attr('stroke-dasharray','1')
                        .attr('x1', yAxisLeftWidth)
                        .attr('y1', yPEScale(yPEMax * 1.1))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(yPEMax * 1.1));  
                    
                    // create upper- line
                    svg
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-upper-std2ndLevel`)
                        .attr('stroke',std2ndColor)
                        .attr('stroke-dasharray','1')
                        .attr('x1', xScale.range()[0])
                        .attr('y1', yPEScale(result.timeSeriesPE[0][`+${std2ndLevel} Std Dev`]))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(result.timeSeriesPE[0][`+${std2ndLevel} Std Dev`]));
                    
                    svg
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-upper-std1stLevel`)
                        .attr('stroke',std1stColor)
                        .attr('stroke-dasharray','1')
                        .attr('x1', xScale.range()[0])
                        .attr('y1', yPEScale(result.timeSeriesPE[0][`+${std1stLevel} Std Dev`]))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(result.timeSeriesPE[0][`+${std1stLevel} Std Dev`]));
                        
                    svg
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-lower-std2ndLevel`)
                        .attr('stroke',std2ndColor)
                        .attr('stroke-dasharray','1')
                        .attr('x1', xScale.range()[0])
                        .attr('y1', yPEScale(result.timeSeriesPE[0][`-${std2ndLevel} Std Dev`]))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(result.timeSeriesPE[0][`-${std2ndLevel} Std Dev`]));
                    
                    svg
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-lower-std1stLevel`)
                        .attr('stroke',std1stColor)
                        .attr('stroke-dasharray','1')
                        .attr('x1', xScale.range()[0])
                        .attr('y1', yPEScale(result.timeSeriesPE[0][`-${std1stLevel} Std Dev`]))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(result.timeSeriesPE[0][`-${std1stLevel} Std Dev`]));

                    svg
                        .append('line')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} pe-mean`)
                        .attr('stroke',meanColor)
                        .attr('stroke-dasharray','1')
                        .attr('x1', xScale.range()[0])
                        .attr('y1', yPEScale(result.timeSeriesPE[0]['mean']))
                        .attr('x2', xScale.range()[1])
                        .attr('y2', yPEScale(result.timeSeriesPE[0]['mean']));
                    if (analysis['pe']['doSimulation']) {
                        //this.vergenceData = this.checkConvergence(this.rsiData,this.currentData,30);
                        //console.log(this.vergenceData)
                        // if (this.vergenceData !== undefined) {
                        // console.log(result.checkDivergence[0])
                        const today = new Date()
                        const futureDate = new Date(new Date().getTime()+(30*24*60*60*1000))
                        const simulationParams = analysis['pe']['simulationParams']
                        const currentPrice = simulationParams[0]['currentValue']
                        const expectedPrice = simulationParams[0]['expectedValue']
                        const currentEPS = simulationParams[1]['currentValue']
                        const expectedEPS  = simulationParams[1]['expectedValue']
                        // console.log(expectedPrice * 1000/expectedEPS) 
                        svg
                            
                            .append('line')
                            .attr('class',`rsi-${chartId}-${tickerParamsIndex} expectedPrice-line`)
                            .attr('stroke','red')
                            .attr('clip-path', `url(#pe-series-${chartId})`)
                            .attr('stroke-width',3)
                            .attr('y1', yScale(currentPrice))
                            .attr('x1', xScale(today))
                            .attr('x2', xScale(futureDate))
                            .attr('y2', yScale(expectedPrice))
                            .attr('stroke',tickerParams[tickerParamsIndex].color)
                            .attr('stroke-dasharray', '1.5');

                        svg
                            .append('line')
                            .attr('class',`rsi-${chartId}-${tickerParamsIndex} expectedPE-line`)
                            .attr('stroke','red')
                            .attr('clip-path', `url(#pe-series-${chartId})`)
                            .attr('stroke-width',3)
                            .attr('x1', xScale(today))
                            .attr('y1', yPEScale(currentPrice * 1000/currentEPS))
                            .attr('x2', xScale(futureDate))
                            .attr('y2', yPEScale(expectedPrice * 1000/ expectedEPS))
                            .attr('stroke',peColor)
                            .attr('stroke-dasharray', '1.5');
                    }
        } else {
                                    
            // alert('The data is not available at the moment. Please try after a few minutes')
            svg.append('text')
                        .attr('class',`pe-${chartId}-${tickerParamsIndex} annotation`)
                        .attr('transform',`translate(${30},${height - accumulatedIndicatorHeightOfThisIndicator + 50})`)
                        // .attr("dy", ".15em")
                        // .attr("text-anchor", "start")
                        .style("fill", `white`)
                        .style("font-size", "15px") 
                        .text(`The PE is not available for this ticker. Please remove or change ticker.`);
        }    
                    
    })
    
}

const volume = (tickerParamsIndex,chartId,timeSeriesData,
            chartParams,xScale,yScale,
            yAxisLeftWidth,yAxisRightWidth,
            indicators) => {
    select(`#volume-group-${chartId}-${tickerParamsIndex}`).remove()
    const svg = select(`#${chartId} > g`).append('g').attr('id',`volume-group-${chartId}-${tickerParamsIndex}`) 
    
    // svg.append('g')
    //     .attr('id',`volume-series-${chartId}`)
    //     .attr('clip-path',`url(#clip-${chartId})`)
    const {svgParams, analysis} = chartParams
    const width = svgParams.width
    // const height = chartParams.svgParams.height
    const adjustedHeight = svgParams.adjustedHeight
    // const margin = chartParams.svgParams.margin
    svg.append('clipPath')
                        .attr('id',`volume-series-${chartId}`)
                        // .attr('clip-path','url(#clip)')
                        .append('rect')
                        .attr('width',width-yAxisLeftWidth-yAxisRightWidth)
                        .attr('height',adjustedHeight*3/4)//height-adjustedHeight)
                        .attr('transform',`translate(${yAxisLeftWidth},${adjustedHeight-adjustedHeight*3/4})`)
    const data = timeSeriesData.historicalPrice[tickerParamsIndex]
    const yMinVolume = min(data, d => d['Volume'])
    const yMaxVolume = max(data, d=> d['Volume'])
    var yVolumeScale = scaleLinear().domain([yMinVolume,yMaxVolume])
                        .range([adjustedHeight,adjustedHeight*3/4])
    const bars = //select(`#volume-series-${chartId}`)
                    svg
                    .selectAll(`.volume-${chartId}-${tickerParamsIndex}`)
                    .data(data);
    // console.log(xScale(data[0]['Date']))
    bars.join(
        enter => enter.append('rect')
                    .attr('class', `volume-${chartId}-${tickerParamsIndex}`)
                    .attr('x', d => xScale(d['Date']))
                    .attr('y', d => yVolumeScale(d['Volume']))
                    .attr('fill', (d, i) => {
                        if (i === 0) {
                            return analysis['volume']['risingColor'];
                        } else {
                            //green bar if price is rising during that period, and red when price is falling
                            return data[i - 1].Close > d.Close  ? analysis['volume']['risingColor'] 
                                                                : analysis['volume']['fallingColor'];
                        }
                    })
                    .attr('clip-path', `url(#volume-series-${chartId})`)
                    .attr('width', 1)
                    .attr('height', d => adjustedHeight - yVolumeScale(d['Volume'])),
        // update => update.transition()
        //                 .duration(750)
        //                 .attr('x', d => xScale(d['Date']))
        //                 .attr('y', d => yVolumeScale(d['Volume']))
        //                 .attr('fill', (d,i) => {
        //                     if (i === 0) {
        //                         return '#03a678';
        //                     } else {
        //                         // green bar if price is rising during that period, and red when price is falling
        //                         return data[i - 1].Close > d.Close ? '#c0392b' : '#03a678';
        //                     }
        //                 })
        //                 .attr('width', 1)
        //                 .attr('height', d => adjustedHeight - yVolumeScale(d['Volume']))
    )
}
export default {
    // news,
    bollingerband,
    macd,
    movingaverage,
    rsi,
    signal,
    pe,
    volume
    
}