
import {Grid, Chip, Box, Button, Tooltip } from "@mui/material"
import AddBoxIcon from '@mui/icons-material/AddBox';
import {IconButton} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// import Signal from '../Signal';
import {useDispatch, useSelector} from 'react-redux'
import { updateLayoutChangeHidden } from "../../js/actions/dashboardActions"
import { hideWorkspace } from "../../js/actions/workspaceAction";

import { styled } from "@mui/material/styles";
import Autocomplete from "../UniversalComponents/Autocomplete";
import MarketDataService from "../../services/MarketService";
import { useEffect,useState } from "react";
import { expandLessStyle,expandMoreStyle } from "../UniversalComponents/cssStyle/iconStyle";


const StyledBox = styled(Box) (({theme}) => ({
    background:"black", 
      border:"none",
    //   width:window.innerWidth * layoutProps.w / 12 * 0.8,
    //   height:50,
      padding: 0
      // padding:"0.3rem",
}));
const StyledChip = styled(Chip) (({theme}) => ({
    margin: theme.spacing(0.5, 0.25),
      color:'white',
      background:'grey',
      fontSize:12,
      border:'none'
}));


const ToolBoxItem = (workspaceId,hiddenLayouts) => {
    const dispatch = useDispatch()
    // console.log(hiddenLayouts)
    if (hiddenLayouts !== undefined) {
        return (hiddenLayouts
            // .fill(null)
            .map((l,i) => (
            <Grid item key={i}>
                <StyledChip label={l.title} onClick={() => dispatch(updateLayoutChangeHidden([workspaceId,l.id, false]))} />
            </Grid>
            )))
    }
}
const ToolBox = (props) => {
//     const {w,h} = props.layout.layoutProps
//     const calculatePxWH = (w,h,colWidth,rowHeight,margin) => {
//         let widthPx = Math.ceil(window.innerWidth * (w + margin[0]) + margin[0])/colWidth
//         let heightPx = Math.ceil((h - 1) * (rowHeight + margin[1]) + margin[1])
//         return [widthPx,heightPx]
//     }
//     const [widthPx, heightPx] = calculatePxWH(w,h,12,30,[0,0])
    const reduxStore = useSelector(state => state)
    const {isHideWorkspace} = reduxStore.workspace
    const dispatch = useDispatch()
    const [symbolList, setSymbolList] = useState()
    // const classes = useStyles()
    useEffect(()=>{
        symbolListInfo()
    },[])
    const symbolListInfo = () => {
        try {
            MarketDataService.getSymbolList()
                            .then(response => {
                                setSymbolList(response.data)
                            })
        } catch(error) {
            console.log(error)
        }
    }
    const toggleNavBar = () => {
        dispatch(hideWorkspace(!isHideWorkspace))
    }
    return (
        <div>
            {/* <button style={{backgroundColor:'#101010',width:widthPx - 2,border:'none', boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'}}>
                <span style={{color:'white'}}>{props.layout.signalName}</span>
                <span style={{color:'white',position:'absolute',right:'5px'}}>{props.layout.tickers !== undefined ? props.layout.tickers.length : 0}</span>
            </button> */}
            <StyledBox  
                    // {...scrollbarStyle}
                //     maxWidth={widthPx} 
                //     height={heightPx}
                // className={classes.root}
            >
                
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={1}>
                            {/* autocomplete */}
                            <Grid item>
                                <Autocomplete
                                    workspaceId={props.workspaceId}
                                    layoutId = {null}
                                    suggestions={symbolList}
                                    reduxActionName={null}
                                    filterKeys={['symbol','long_name']}
                                    placeholderName={'Enter Symbol or Full Name'}
                                    isNestedList={true}
                                    positionOfSuggestion={100}
                                />
                            </Grid>
                            {ToolBoxItem(props.workspaceId,props.hiddenLayouts)}
                            <Grid item style={{ marginLeft: "auto" }}>
                                <Tooltip title="Add new layout">
                                    <IconButton 
                                        aria-label="minimize"
                                        // color="primary"
                                        style={{color:'lightBlue',padding:0}}
                                        onClick={props.addLayout}//,isHidden)}
                                    >
                                        <AddBoxIcon style={{fontSize:35}}/>
                                    </IconButton>
                                </Tooltip>
                                {!isHideWorkspace?
                                <Tooltip title="Collapse">
                                    {/* <div> */}
                                    <IconButton 
                                    aria-label="Hide" 
                                    style={expandLessStyle}
                                    onClick={toggleNavBar}//,isHidden)}
                                    >
                                    {/* {!navBarVisible ? <ExpandLessIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/>  */}
                                        <ExpandLessIcon fontSize="small" style={{marginTop:0}}/>
                                    </IconButton>
                                    {/* </div> */}
                                </Tooltip>
                                :
                                <Tooltip title="Expand">
                                    <IconButton 
                                        aria-label="Expand" 
                                        style={expandMoreStyle}
                                        onClick={toggleNavBar}//,isHidden)}
                                    >
                                        <ExpandMoreIcon fontSize="small" style={{marginBottom:-10, marginTop:-5}}/> 
                                        
                                    </IconButton>
                                </Tooltip> 
                                }
                                {/* <Button variant="contained" color="primary" onClick={props.addLayout}>
                                Add
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledBox>
        </div>
    )
}
export default ToolBox
