import React, {useState, useEffect,useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InitializeChart from '../../Chart/DrawChart1'
import { adjustSVGParamsOverview} from '../../../../js/actions/overviewActions'
import MarketDataService from '../../../../services/MarketService'
import { parseHistoricalData } from '../../Chart/processRawData'
import { useDeepCompareEffect } from 'react-use'
const SummaryChart = (props) => {
    const {workspaceId,mainLayoutId,width,height} = props 
    const d3Container = useRef(null)
    const reduxStore = useSelector(state => state)
    // let mainLayoutProps = reduxStore.dashboard.items.filter(item => item.id === mainLayoutId)[0]['layoutProps']
    // console.log(mainLayoutProps)
    const chartParams = reduxStore.overview[workspaceId][mainLayoutId]
    
    
    const ticker = chartParams.tickerParams[0]['ticker']
    
    // console.log(timeSeriesData)
    // if Dashboard is used, chartParams will come from dashboard, 
    // otherwise, it will be sourced from mainChartParams
    // const chartParams = reduxStore.mainChartParams
    
    const dispatch = useDispatch()
    
    //add the Event Listener to auto resize the svg's width and height
    const initTimeSeriesData = {
        historicalPrice:[],
        
    }
    const [timeSeriesData,setTimeSeriesData] = useState(initTimeSeriesData)
    const [loading, setLoading] = useState(true)
    
    const updateDimensions = () => {
        if (ticker) {
            const margin = {top: 30, right: 50, bottom: 10, left:50 }
            // const i = chartParams.indicators.length >0 ? (0.75 * chartParams.indicators.length) : 1
            const newDimension = {
                height: 400 - 20 - (margin.top + margin.bottom),//* 0.8429 * 1.,
                width : 600 - (margin.left + margin.right), //* 0.8429,
                adjustedHeight: 400 - 20 - (margin.top + margin.bottom) ,
                }
            // because to use object as a dependency may cause infinite loop in useEffect
            // , we must stringify the chartParams.
            // https://dmitripavlutin.com/react-useeffect-infinite-loop/
            dispatch(adjustSVGParamsOverview([workspaceId,mainLayoutId,newDimension]))
        } 
    }
    useEffect(() => {
        
        updateDimensions();
        // window.addEventListener('resize', updateDimensions)
        //return () => window.removeEventListener("resize",updateDimensions)
        // return () => setValue(null)
    },[width])
    useEffect(() => {
        if (ticker) {
            // console.log(signalFormData)
            
            MarketDataService.getHistoricalPrice(ticker).then(
                response => {
                        // console.log(res.data.map(item => )
                        // console.log(res.data)
                        // dispatch(updateTimeSeriesData([
                        //         mainLayoutId,
                        //         res.data.map(item => {return {...item,'Date':new Date(item['Date'] * 1000)}})
                        // ]))
                        
                        const revisedData = parseHistoricalData(response.data)
                        
                        setTimeSeriesData(prevState => ({...prevState, historicalPrice: [revisedData]}))
                        setLoading(false)
                })
                .catch(error => {alert(error)})
        // return () => dispatch(updateTimeSeriesData([mainLayoutId,null]))
    }},[ticker])
    // useEffect(() => {
    //     return () => dispatch(clearTimeSeriesData([mainLayoutId]))
    // },[])

    useDeepCompareEffect(() => {
        // console.log(timeSeriesData, loading)
        if(timeSeriesData.historicalPrice.length > 0) {
            
            InitializeChart(d3Container.current,{timeSeriesData:timeSeriesData,
                                                chartParams,
                                                chartId:`simpleChart-${mainLayoutId}`,
                                                // tickerParamsIndex:0,
                                                indexOfLongestArray:0,
                                                // isYAxisLeft:true,
                                                // numberOfRightAxis:1,
                                                // numberOfLeftAxis:0
                                            },)
            
            
        }
        
    },[timeSeriesData,chartParams])
    
    return (
        <div className="flex-container">
            
            <div className="flex-item">
                <div ref={d3Container}>
                    {loading &&  <span style={{color:'white'}}>Loading...</span>}
                    <svg id={`simpleChart-${mainLayoutId}`} />
                </div>
            </div>
            
            
        </div>

    )
}
export default SummaryChart