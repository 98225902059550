const initState = {0:{1:['TCB','HPG'],2:['^DJI','^N225']}}
const priceBoardReducer = (state=initState, action) => {
  
  switch (action.type ) {
    case 'CREATE_PRICE_BOARD':{
      let [workspaceId,mainLayoutId] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[]
        }
      }
    }
    
    case 'REMOVE_PRICE_BOARD': {
			let [workspaceId,mainLayoutId] = action.payload
      // console.log(action.payload)
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
		}
    case 'ADD_TICKER_INTO_PRICE_BOARD': {
      let [workspaceId,mainLayoutId,ticker] = action.payload
      // console.log(action.payload)
      // if (state[workspaceId] !== undefined) {
      //   const tickerList = state[workspaceId][mainLayoutId] !== undefined 
      //                                         ? [...state[workspaceId][mainLayoutId],ticker]
      //                                         : [ticker]
      
      //   return {
      //     ...state,
      //     [workspaceId]:{
      //       ...state[workspaceId],
      //       [mainLayoutId]: tickerList
      //     }
      //   }
      // } else {
        return {
          ...state,
          [workspaceId]:{
            ...state[workspaceId],
            [mainLayoutId]:[
              ...state[workspaceId][mainLayoutId],
              ticker
            ]
          }
        }
      // }
    }
    case 'REMOVE_TICKER_OUT_OF_PRICE_BOARD': {
      let[workspaceId,mainLayoutId, removedTicker] = action.payload
      return {
        ...state,
        [workspaceId]: {
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].filter((ticker) => ticker.toLowerCase() !== removedTicker.toLowerCase())
          ]
        }
      }
    }
    default:
      return state
  }
  
}

export default priceBoardReducer