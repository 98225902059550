import {select,selectAll,
        axisBottom,axisRight,axisLeft,
        scaleTime,
        line,
        // min,max,
        timeDay,} from 'd3'
import crossHair from './generateCrossHair';
import RenderIndicators from './analysis/renderIndicators'
// https://github.com/muratkemaldar/using-react-hooks-with-d3/blob/16-zoomable-line-chart/src/ZoomableLineChart.js
// https://stackoverflow.com/questions/66808447/how-can-i-zoom-into-this-graph-using-d3v6
const zoomed = 
  (zoomEvent,chartId,
    chartParams,
    numberOfSymbol,xScale,
    yScaleGroup,
    sizeOfYAxisGroup,yAxisLeftWidth,yAxisRightWidth,
    xMin,xMax,xAxisPadding,width,
    timeSeriesData,
  ) => {
	// console.log(zoomEvent.transform)
	
    // const tickWidth = 5;
    //update the xAxis
    
    const xAxis = axisBottom(xScale)
    const updatedXScale = zoomEvent.transform.rescaleX(xScale);
    const xRescale = scaleTime()
                      .domain([xMin, timeDay.offset(xMax,xAxisPadding)])
                      .range([0, width]);
    xScale.domain(zoomEvent.transform.rescaleX(xRescale).domain());
    select(`#${chartId} > g >.xAxis`).call(xAxis.scale(updatedXScale)).selectAll('text').style('color',`white`) ;
    if (zoomEvent.sourceEvent || zoomEvent.transform.k !== 1) {
      // console.log(numberOfSymbol)
      let updatedYScaleGroup = []
      for (let i=0; i< numberOfSymbol ; i++) {
        //UPDATE THE YAXIS
        const yAxis = sizeOfYAxisGroup[i] === 'R' ? axisRight(yScaleGroup[i]) : axisLeft(yScaleGroup[i])
        // create new scale ojects based on zoom/pan event
        const updatedYScale = zoomEvent.transform.rescaleY(yScaleGroup[i]);
        updatedYScaleGroup.push(updatedYScale)
        const lastPrice = timeSeriesData.historicalPrice[i][timeSeriesData.historicalPrice[i].length - 1]['Close']
        select(`#${chartId} > g >.yAxis.y${i}`).call(yAxis.scale(updatedYScale));
        select(`#${chartId} > g >.yAxis.y${i}`).selectAll("text")
                .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
                .style("color", "white"); 
        select(`#${chartId} > g >.yAxis.y${i} > .yAxisBox`)
                    .attr('transform',sizeOfYAxisGroup[i] === 'R' ? `translate(${0},${updatedYScale(lastPrice)-10})`
                                                                  :`translate(${0},${updatedYScale(lastPrice)})rotate(180)`)
        select(`#${chartId} > g >.yAxis.y${i} > .yAxisLabel`)
                    .attr('transform',`translate(${0},${updatedYScale(lastPrice)})`)
        
        // UPDATE THE MAINCHART
        if (select(`.toggleLine-${chartId}-${i}`).nodes().length > 0) {
          const updateClosePriceChartPlot = line()
                        .x(d => updatedXScale(d['Date']))
                        .y(d => updatedYScale(d['Close']));
          select(`.toggleLine-${chartId}-${i}`).attr('d',updateClosePriceChartPlot)
        } else if (selectAll(`.toggleCandleStick-${chartId}-${i}`).nodes().length > 0) {
          const candlesticksLine =line()
                          .x(d => d['x'])
                          .y(d => d['y'])
          const bodyWidth = 5;
          selectAll(`.toggleCandleStick-${chartId}-${i} .high-low`)
                              .attr('d', d => {
                                  return candlesticksLine([
                                      {x: updatedXScale(d['Date']), y:updatedYScale(d['High'])},
                                      {x: updatedXScale(d['Date']), y:updatedYScale(d['Low'])}
                                  ])
                              })
          selectAll(`.toggleCandleStick-${chartId}-${i} rect`)
                            .attr('x', d=>updatedXScale(d.Date) - bodyWidth/2)
                            .attr('y', d => {
                                return d['Close'] > d['Open'] ? updatedYScale(d['Close']): updatedYScale(d['Open'])
                            })
                            .attr('width',bodyWidth)
                            .attr('height',d=> {
                                return d['Close'] > d['Open'] ? updatedYScale(d['Open']) - updatedYScale(d['Close']) 
                                                              : updatedYScale(d['Close']) - updatedYScale(d['Open'])
                            })
        }
        //UPDATE INDICATOR
        if (chartParams.indicators.length >= 1){
          const indicatorsInSubChart = Object.keys(chartParams.analysis).filter(item => chartParams.analysis[item].position === 'sub')
          const indicatorsInMainChart = Object.keys(chartParams.analysis).filter(item => chartParams.analysis[item].position === 'main')
          if (indicatorsInSubChart.length > 0) {
            for (const indicator of indicatorsInSubChart){
                
                // console.log(index)
                const f = RenderIndicators[indicator]
                if(chartId.split('-')[0] === 'simpleChart'){    
                    // console.log(timeSeriesData)
                    f(0,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[0],indicatorsInSubChart)
                } 
                // else if(index !== -1){
                //     f(index,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[index],yAxisLeftWidth,yAxisRightWidth)
                // } 
                else {
                    let ticker = chartParams.analysis[indicator]['ticker']
                    let index = chartParams.tickerParams.findIndex(tickerParam => tickerParam['ticker'] === ticker)
                    index !== -1 // this happened when we change the instrument
                            ? f(index,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[index],yAxisLeftWidth,yAxisRightWidth,indicatorsInSubChart)
                            : f(0,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[0],yAxisLeftWidth,yAxisRightWidth,indicatorsInSubChart)
                }
            }
          }
          if (indicatorsInMainChart.length > 0) {
            for (const indicator of indicatorsInMainChart){
                
                // console.log(index)
                const f = RenderIndicators[indicator]
                if(chartId.split('-')[0] === 'simpleChart'){    
                    // console.log(timeSeriesData)
                    f(0,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[0],indicatorsInMainChart)
                } 
                // else if(index !== -1){
                //     f(index,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[index],yAxisLeftWidth,yAxisRightWidth)
                // } 
                else {
                    let ticker = chartParams.analysis[indicator]['ticker']
                    let index = chartParams.tickerParams.findIndex(tickerParam => tickerParam['ticker'] === ticker)
                    index !== -1 // this happened when we change the instrument
                            ? f(index,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[index],yAxisLeftWidth,yAxisRightWidth,indicatorsInMainChart)
                            : f(0,chartId,timeSeriesData,chartParams,updatedXScale,updatedYScaleGroup[0],yAxisLeftWidth,yAxisRightWidth,indicatorsInMainChart)
                }
            }
          }
        }
      }
    }
    crossHair(chartId,timeSeriesData,zoomEvent,chartParams,updatedXScale,yAxisLeftWidth,yAxisRightWidth)
}

 function zoomed1 (d, i, nodes) {
    // const xAxis = d3.axisBottom(this.xScale);
    // const yAxis = d3.axisRight(this.yScale);
    // const ohlcLine = d3
    //   .line()
    //   .x(d => d['x'])
    //   .y(d => d['y']);
    // 
    // // only fire the zoomed function when an actual event is triggered, rather than on every update
    // if (d3.event.sourceEvent || d3.event.transform.k !== 1) {
    //   // create new scale ojects based on zoom/pan event
    //   const updatedXScale = d3.event.transform.rescaleX(this.xScale);
    //   const updatedYScale = d3.event.transform.rescaleY(this.yScale);
    //   // update axes
    //   const xMin = d3.min(this.currentData, d => d['Date']);
    //   const xMax = d3.max(this.currentData, d => d['Date']);
    //   const xAxis_padding = d3.timeDay.count(xMin,xMax) * 0.05;
    //   const xRescale = d3
    //     .scaleTime()
    //     .domain([xMin, d3.timeDay.offset(xMax,xAxis_padding)])
    //     .range([0, this.width]);
    //   this.xScale.domain(d3.event.transform.rescaleX(xRescale).domain());
    //   this.yAxis.call(yAxis.scale(updatedYScale));
    //   this.xAxis.call(xAxis.scale(updatedXScale));
    //   // update close price and moving average lines based on zoom/pan
    //   const updateClosePriceChartPlot = d3
    //     .line()
    //     .x(d => updatedXScale(d['Date']))
    //     .y(d => updatedYScale(d['Close']));
    //   const updateMovingAverageLinePlot = d3
    //     .line()
    //     .x(d => updatedXScale(d['Date']))
    //     .y(d => updatedYScale(d['average']))
    //     .curve(d3.curveBasis);
    //   for (var i = 0;i<this.ma_period_array.length;i++){
    //         d3.select(`.moving-average-line-${this.ma_period_array[i]['period']}`).attr('d', updateMovingAverageLinePlot);
    //   }
      
      
    //   d3.select('.price-chart').attr('d', updateClosePriceChartPlot);

      
      // update volume series based on zoom/pan
      // d3.selectAll('.vol').attr('x', d => updatedXScale(d['Date']));
      // update news based on zoon/pan
      // d3.selectAll('.news-group').attr('transform',(d, i) => `translate(${updatedXScale(d['Date'])}, ${this.height - 80})`);
      // update drawLine -> this will cause error of crosshair
      /*
      const draw_line = d3.selectAll('.draw-line')
      console.log(this.xScale.invert(draw_line.attr('x1')))
      d3.selectAll('.draw-line').attr('x1',updatedXScale(this.xScale.invert(draw_line.attr('x1'))))
                                .attr('y1',updatedYScale(this.yScale.invert(draw_line.attr('y1'))))
                                .attr('x2',updatedXScale(this.xScale.invert(draw_line.attr('x2'))))
                                .attr('y2',updatedYScale(this.yScale.invert(draw_line.attr('y2'))))
      */
      // update ohlc series based on zoom/pan
      // d3.selectAll('.ohlc .high-low').attr('d', d => {
      //   return ohlcLine([
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['High']) },
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['Low']) }
      //   ]);
      // });
      // d3.selectAll('.open-tick').attr('d', d => {
      //   return ohlcLine([
      //     {
      //       x: updatedXScale(d['Date']) - tickWidth,
      //       y: updatedYScale(d['Open'])
      //     },
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['Open']) }
      //   ]);
      // });
      // d3.selectAll('.close-tick').attr('d', d => {
      //   return ohlcLine([
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['Close']) },
      //     {
      //       x: updatedXScale(d['Date']) + tickWidth,
      //       y: updatedYScale(d['Close'])
      //     }
      //   ]);
      // });

      // update candlesticks series based on zoom/pan
      // d3.selectAll('.candlestick .high-low').attr('d', d => {
      //   return candlesticksLine([
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['High']) },
      //     { x: updatedXScale(d['Date']), y: updatedYScale(d['Low']) }
      //   ]);
      // });
      // d3.selectAll('.candlestick rect')
      //   .attr('x', d => updatedXScale(d['Date']) - bodyWidth / 2)
      //   .attr('y', d => {
      //     return d['Close'] > d['Open']
      //       ? updatedYScale(d['Close'])
      //       : updatedYScale(d['Open']);
      //   })
      //   .attr('height', d => {
      //     return d['Close'] > d['Open']
      //       ? updatedYScale(d['Open']) - updatedYScale(d['Close'])
      //       : updatedYScale(d['Close']) - updatedYScale(d['Open']);
      //   });

      // // update bollinger Bands based on zoom/pan
      // const updateUpperBandPlot = d3
      //   .line()
      //   .x(d => updatedXScale(d['Date']))
      //   .y(d => updatedYScale(d['upperBand']));
      // const updateLowerBandPlot = d3
      //   .line()
      //   .x(d => updatedXScale(d['Date']))
      //   .y(d => updatedYScale(d['lowerBand']))
      //   .curve(d3.curveBasis);
      // const area = d3
      //   .area()
      //   .x(d => updatedXScale(d['Date']))
      //   .y0(d => updatedYScale(d['upperBand']))
      //   .y1(d => updatedYScale(d['lowerBand']));
      // d3.select('.upper-band').attr('d', updateUpperBandPlot);
      // d3.select('.lower-band').attr('d', updateLowerBandPlot);
      // d3.select('.middle-band').attr('d', updateMovingAverageLinePlot);
      // d3.select('.band-area').attr('d', area);
      
      
      //UPDATE RSI
      
      // if (!d3.selectAll('.rsi-line').empty()){
      //   //const yRsiAxis = d3.axisRight(this.yRsiScale); -> we dont add this to keep it unchanged
      //   //this.yRsiAxis.call(yRsiAxis.scale(updatedYRsiScale));
        
      //   var updatedYRsiScale = d3.event.transform.rescaleY(this.yRsiScale); // use var instead of const
      //   const lineRsi = d3.line()
      //                       .x(d => updatedXScale(d['Date']))
      //                       .y(d => this.yRsiScale(d['value'])) // do not use .y(d => updatedYRsiScale(d['value'])) following to yRsiAxis unchanged 
      //   d3.select('.rsi-line').attr('d', lineRsi)
      //   d3.select('.instrument-vergence-line').attr('x1', updatedXScale(this.vergenceData['firstInstrumentData']['Date']))
      //                                       .attr('y1', updatedYScale(this.vergenceData['firstInstrumentData']['Close']))
      //                                       .attr('x2', updatedXScale(this.vergenceData['secondInstrumentData']['Date']))
      //                                       .attr('y2', updatedYScale(this.vergenceData['secondInstrumentData']['Close']));

      //   d3.select('.indicator-vergence-line').attr('x1', updatedXScale(this.vergenceData['firstInstrumentData']['Date']))
      //                                       //.attr('y1', this.yRsiScale(this.vergenceData['firstValue']))
      //                                       .attr('x2', updatedXScale(this.vergenceData['secondInstrumentData']['Date']))
      //                                       //.attr('y2', this.yRsiScale(this.vergenceData['secondValue']));
      // }

      // /* COMPARISON CHART */
      //   if(!d3.selectAll('.compare-line').empty()) {
      //       //const yCompareAxis = d3.axisRight(this.yCompareScale);
      //       //this.yCompareAxis.call(yCompareAxis.scale(updatedYCompareScale));
      //       var updatedYCompareScale = d3.event.transform.rescaleY(this.yCompareScale);
      //       const lineCompare = d3.line()
      //                               .x(d=> updatedXScale(d['Date']))
      //                               .y(d => this.yCompareScale(d['value']));
      //       d3.select('.compare-line').attr('d', lineCompare)
      //   }
      
      /*
      d3.select('.upper-line').attr('x1', updatedXScale(this.currentData[0]['Date']))
                                .attr('y1',updatedYRsiScale(70))
                                .attr('x2', updatedXScale(this.currentData[this.currentData.length-1]))
                                .attr('y2',updatedYRsiScale(70))
        */
      
     // update Price Legend                           
    //  const lastPoint = this.currentData[this.currentData.length-1]['Close'];
    // // console.log(d3.selectAll('.price-legend'))
    //  d3.selectAll('.box-legend')
    //     .attr('transform',`translate(${this.width},${updatedYScale(lastPoint)-5})`);
    //  d3.selectAll('.price-legend')
    //     .attr('transform',`translate(${this.width+25},${updatedYScale(lastPoint)+5})`)
      
     
    //   // update crosshair position on zooming/panning
    //   const overlay = d3.select('.overlay');
    //   const focus = d3.select('.focus');
    //   const bisectDate = d3.bisector(d => d.Date).left;
        
    //   // remove old crosshair
    //   overlay.exit().remove();

    //   // enter, and update the attributes
    //   overlay
    //     .enter()
    //     .append('g')
    //     .attr('class', 'focus')
    //     .style('display', 'none');

    //   overlay
    //     .attr('class', 'overlay')
    //     .attr('width', this.width)
    //     .attr('height', this.height_crosshair)
    //     .on('mouseover', () => focus.style('display', null))
    //     //.on('mouseout', () => focus.style('display', 'none'))
    //     .on('mousemove', (d, i, nodes) => {
    //       const correspondingDate = updatedXScale.invert(d3.mouse(nodes[i])[0]);
          
    //       //gets insertion point
    //       const i1 = bisectDate(this.currentData, correspondingDate, 1);
    //       const d0 = this.currentData[i1 - 1];
    //       const d1 = this.currentData[i1];
    //       const currentPoint =
    //         correspondingDate - d0['Date'] > d1['Date'] - correspondingDate
    //           ? d1
    //           : d0;
        /*
          focus.attr(
            'transform',
            `translate(${updatedXScale(currentPoint['Date'])}, ${updatedYScale(
              currentPoint['Close']
            )})`
          );

          focus
            .select('line.x')
            .attr('x1', - updatedXScale(currentPoint['Date']))
            .attr('x2', this.width - updatedXScale(currentPoint['Date']))
            .attr('y1', 0)
            .attr('y2', 0);

          focus
            .select('line.y')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', - updatedYScale(currentPoint['Close']))
            .attr('y2', this.height - updatedYScale(currentPoint['Close']));
          
            focus.select('.focusTextx')
            .attr('transform',`translate(0,${this.height + 30 - updatedYScale(currentPoint['Close'])})`)
            .text(currentPoint['Date'].toLocaleDateString());

          focus.select('.focusTexty')
            .attr('transform',`translate(${this.width + 10 - updatedXScale(currentPoint['Date'])},0)`)
            .text(currentPoint['Close'].toFixed(0));
          focus.select('.focusBoxX')
            .attr('transform',`translate(-30,${this.height + 20 - updatedYScale(currentPoint['Close'])})`);
          focus.select('.focusBoxY')
            .attr('transform',`translate(${this.width + 5 - updatedXScale(currentPoint['Date'])},-10)`)
        */
            // var x = d3.mouse(nodes[i])[0]
            // var y = d3.mouse(nodes[i])[1]
            // focus.attr('transform', `translate(${x}, ${y})`);
            // focus.select('line.x')
            //         .attr('x1', -x)
            //         .attr('x2', this.width - x)
            //         .attr('y1', 0)
            //         .attr('y2', 0);
            
            // focus.select('line.y')
            //         .attr('x1', 0)
            //         .attr('x2', 0)
            //         .attr('y1', - y)
            //         .attr('y2', this.height_crosshair - y);

            // focus.select('.focusTextx')
            //         .attr('transform',`translate(0,${this.height_crosshair + 30 - y})`)
            //         .text(updatedXScale.invert(x).toLocaleDateString());
            // focus.select('.focusBoxX')
            //         .attr('transform',`translate(-30,${this.height_crosshair + 20 - y})`);

            // focus.select('.focusTexty')
            //         .attr('transform',`translate(${this.width + 10 - x},0)`)
            //         .text(() => {
            //                 if (y <= this.height) {
            //                     return updatedYScale.invert(y).toFixed(0);
            //                 } else if(!d3.select('.rsi-line').empty() && !d3.select('.compare-line').empty()){
            //                             if (y >= updatedYRsiScale(updatedYRsiScale.domain()[1]) && y < updatedYRsiScale(updatedYRsiScale.domain()[0])) {
                                            
            //                                 return this.yRsiScale.invert(y).toFixed(0);
            //                             }
                                        
            //                             else if(y>= updatedYCompareScale(updatedYCompareScale.domain()[1]) && y <= updatedYCompareScale(updatedYCompareScale.domain()[0])){
                                            
            //                                 return this.yCompareScale.invert(y).toFixed(0);
            //                             }
                                        
            //                 } else if (!d3.select('.rsi-line').empty()) {
            //                      if ( y >= updatedYRsiScale(updatedYRsiScale.domain()[1]) && y < updatedYRsiScale(updatedYRsiScale.domain()[0])) {
            //                             return this.yRsiScale.invert(y).toFixed(0);
            //                         }
            //                 } else if (!d3.select('.compare-line').empty()){
            //                     if(y>= updatedYCompareScale(updatedYCompareScale.domain()[1]) && y <= updatedYCompareScale(updatedYCompareScale.domain()[0])){
            //                             return this.yCompareScale.invert(y).toFixed(0);
            //                         }
            //                 }
            //         });
            // focus.select('.focusBoxY')
            //         .attr('transform',`translate(${this.width + 5 - x},-10)`)
            //     this.updateLegends(currentPoint);
            //     this.updateSecondaryLegends(currentPoint['Date']);
        // });
    // }
}
export default zoomed