import * as d3 from 'd3';
import {min, max} from 'd3';

const toggleLine = (tickerParamsIndex, chartId,data, chartParameters,xScale,yScale) => {
    d3.select(`#${chartId} > g`)
            // .select('g')
            .selectAll(`.toggleCandleStick-${chartId}-${tickerParamsIndex}`)
            .remove();
    // if (chartParameters.chartType === 'line'){
    data = data.filter(item => item['Close'] !== null)
    // console.log(data)
    // console.log(yScale.range())
    // console.log(xScale.domain()) 
    const {tickerParams} = chartParameters
    const line = d3
            .line()
            .x(d => xScale(d['Date']))
            .y(d => yScale(d['Close']));
    const lineSelect = d3.select(`#${chartId} > g `)
                            .selectAll(`.toggleLine-${chartId}-${tickerParamsIndex}`)
                            
                            .data([data])
                            
    
    lineSelect.join(
        enter => enter
                            .append('path')
                            .style('fill', 'none')
                            .attr('class', `toggleLine-${chartId}-${tickerParamsIndex}`)
                            .attr('transform', `translate(${xScale(xScale.domain()[1])/2}, 0)`)
                            .attr('cursor','pointer')
                            .attr('pointer-events',"visible")
                            .attr('clip-path', `url(#clip-${chartId})`)
                            .attr('stroke', tickerParams[tickerParamsIndex].color)
                            .attr('stroke-width', '1.5')
                            .attr('d', line),
        
            
        update =>
        update
            .transition()
            .duration(750)
            .attr('stroke', chartParameters.tickerParams[tickerParamsIndex].color)
            .attr('d', line)
    );
    // lineSelect.attr('transform',`translate(${-yAxisWidth+50}, ${svgParams.height - svgParams.margin.bottom - svgParams.margin.top})`)
    // } else {
    //     d3.select(`.price-chart-${chartId}-${tickerParamsId}`).remove()
    // }
}

const toggleBar = (barIndexes, 
    chartId, barDatas, 
    chartParams, barIndicators, 
    barColors, xScale, barYScale) => {
    
    for ( let index of barIndexes) {
        d3.select(`#${chartId} > g`)
            // .select('g')
            .selectAll(`.toggleLine-${chartId}-${index}`)
            .remove();
    }
    /*
    [
        [
            {
                'Date':'06-2022',
                'Close': 1234455,
            },
            {
                'Date':'09-2022',
                'Close': 7894455,
            }
        ],
        [
            {
                'Date':'06-2022',
                'Close': 500000,
            },
            {
                'Date':'09-2022',
                'Close': 300000,
            }
        ]
    ]
    =>
    [
        {
            'Date':'06-2022',
            'GDP, Total, Constant Prices': 1234455,
            'GDP, Services, Constant Prices': 500000,
        },
        {
            'Date':'09-2022',
            'GDP, Total, Constant Prices': 7894455,
            'GDP, Services, Constant Prices': 300000,
        }
    ]
    */
    const svg = d3.select(`#${chartId} > g`)
    const indicatorNames =   barIndicators;
    // List of groups = species here = value of the first column called group -> I show them on the X axis
    // const dateGroup = barDatas.map(d => (d['Date']))
    const xScaleBar = xScale.paddingInner(0.1)
    

    const color = d3.scaleOrdinal()
        .domain(indicatorNames)
        .range(barColors)
    const xScaleIndicatorName = d3.scaleBand()
        .padding(0.05)
        .domain(indicatorNames)
        .rangeRound([0, xScaleBar.bandwidth()])
    //stack the data? --> stack per subgroup
    // const stackedData = d3.stack()
    //     .keys(indicatorNames)
    //     (stackedBarDatas)
    const yMin = barYScale.domain()[0]
    // console.log(barYScale.domain())
    // Show the bars
    svg.append("g")
        .attr('class', `toggleBar-${chartId}`)
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(barDatas)
        .join("g")
        .attr('transform', function (d) { return `translate(${xScaleBar(d.Date)}, 0)`})
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(function(d){return indicatorNames.map(name => {
            if (name in d) {
                
                return {
                    key: name, value:d[name]
                }
            } else {
                console.log({key:name, value:yMin})
                return {
                    key: name, value: Math.max(yMin, 0)
                } 
            }
            
        })})
        .join("rect")
            .attr('class',`bar-${chartId}`)
            .attr("x", d => xScaleIndicatorName(d.key))
            .attr("y", d => (d.value <= 0 ? barYScale(0) : barYScale(d.value)))
            .attr("height", d => (yMin <=0) ? Math.abs(barYScale(d.value) - barYScale(0)) 
                                            : chartParams.svgParams.height - barYScale(d.value))
            .attr("width",xScaleIndicatorName.bandwidth())
            // .attr('transform', `translate(${xScale(xScale.domain()[1])/2 - xScale.bandwidth()/4}, 0)`)
            .attr("fill", d => color(d.key))
        .lower()



}

/* 
https://jsfiddle.net/bqny2cme/8/ -> example for stack bar
*/
const toggleStackedBar = (barIndexes, 
    chartId, barDatas, 
    chartParams, barIndicators, 
    barColors, xScale, barYScale, sideOfYAxisGroup) => {

    const svg = d3.select(`#${chartId} > g`)
    // for ( let index of barIndexes) {
    //     d3.select(`#${chartId} > g`)
    //         // .select('g')
    //         .selectAll(`.toggleLine-${chartId}-${index}`)
    //         .remove();
    //     // svg.selectAll(`.yAxis.y${index}`).remove()//.selectAll('rect, text, g.tick, line').remove()
    // }
    
    /*
    [
        [
            {
                'Date':'06-2022',
                'Close': 1234455,
            },
            {
                'Date':'09-2022',
                'Close': 7894455,
            }
        ],
        [
            {
                'Date':'06-2022',
                'Close': 500000,
            },
            {
                'Date':'09-2022',
                'Close': 300000,
            }
        ]
    ]
    =>
    [
        {
            'Date':'06-2022',
            'GDP, Total, Constant Prices': 1234455,
            'GDP, Services, Constant Prices': 500000,
        },
        {
            'Date':'09-2022',
            'GDP, Total, Constant Prices': 7894455,
            'GDP, Services, Constant Prices': 300000,
        }
    ]
    */
    
    const indicatorNames =   barIndicators;
    // console.log(data)
    // List of groups = species here = value of the first column called group -> I show them on the X axis
    // const dateGroup = barDatas.map(d => (d['Date']))
    //create a new YAxis for stackedChart
    const stackedYMax = max(barDatas, d => Object.values(d).filter(v => typeof v === 'number').reduce((total,obj) => total + obj))
    // const stackedYMin = min(barDatas, d => Object.values(d).filter(v => typeof v === 'number').reduce((total,obj) => total + obj))
    // for stackedBarChart, yMin MUST be 0, because stacked data started at 0 [[[0, 12], [0, 6], [0, 11], [0, 19]] 
    const stackedYScale= barYScale.domain([0, stackedYMax * 1.1])//update the scale
    const color = d3.scaleOrdinal()
        .domain(indicatorNames)
        .range(barColors)

    //stack the data? --> stack per subgroup
    const stackedData = d3.stack()
        .keys(indicatorNames)
        (barDatas)
    
    for ( let i = 0; i < barIndexes.length ; i++) {
        
        let lastPrice =  stackedData[i][stackedData[i].length-1][1];
        // d3.select(`#${chartId} > g`)
        //     // .select('g')
        //     .selectAll(`.toggleLine-${chartId}-${index}`)
        //     .remove();
        let rectWidth = parseFloat(svg.selectAll(`.yAxis.y${barIndexes[i]} > rect`).attr('width'))
        svg.selectAll(`.yAxis.y${barIndexes[i]}`).selectAll('rect, text, g.tick, line').remove()
        svg.selectAll(`.yAxis.y${barIndexes[i]}`).call(sideOfYAxisGroup[barIndexes[i]] === 'R' ? d3.axisRight(stackedYScale).ticks(5)
                                                                                :d3.axisLeft(stackedYScale).ticks(5));
        svg.selectAll(`.yAxis.y${barIndexes[i]}`).append('rect')
            .attr('class',`yAxisBox`)
            .attr('width',rectWidth)
            .attr('height',12)
            .style('fill',`${chartParams.tickerParams[barIndexes[i]]['color']}`)
            .attr('transform',sideOfYAxisGroup[barIndexes[i]] === 'R' ? `translate(0,${stackedYScale(lastPrice)-10})` :`translate(0,${stackedYScale(lastPrice)})rotate(180)`)
        //add a text and a box to show current price
        svg.selectAll(`.yAxis.y${barIndexes[i]}`).append('text')
            .attr('class',`yAxisLabel`)
            .attr('transform',`translate(${0},${stackedYScale(lastPrice)})`)
            .attr('fill',`black`)
            .style('font-size','10px')
            .text(lastPrice.toFixed(2))
        svg.selectAll(`.yAxis.y${barIndexes[i]}`).selectAll("text")
            .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
            .style("color", "white");
        // svg.selectAll(`.yAxis.y${index}`).remove()//.selectAll('rect, text, g.tick, line').remove()
    }

    
    
                                        

    
    // console.log(stackedData)
    // Show the bars
    svg.append("g")
        .attr('class', `toggleStackedBar-${chartId}`)
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .join("g")
        .attr("fill", d => color(d.key))
        .attr('transform', `translate(${xScale(xScale.domain()[1])/4},0)`) // Divided by 4 to center the stacked bar at Date point
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data(d => d)
        .join("rect")
            .attr("x", d => xScale(d.data.Date))
            .attr("y", d => stackedYScale(d[1]))
            .attr("height", d => stackedYScale(d[0]) - stackedYScale(d[1]))
            .attr("width",xScale.bandwidth()/2)
        
            // .attr('transform', `translate(${xScale(xScale.domain()[1])/2 - xScale.bandwidth()/4}, 0)`)



}

export default {
    toggleLine,
    toggleStackedBar,
    toggleBar,
}