import * as d3 from 'd3'

const toggleLine = (tickerParamsIndex, chartId,data, chartParameters,xScale,yScale) => {
    d3.select(`#${chartId} > g`)
            // .select('g')
            .selectAll(`.toggleCandleStick-${chartId}-${tickerParamsIndex}`)
            .remove();
    // if (chartParameters.chartType === 'line'){
    data = data.filter(item => item['Close'] !== null)
    // console.log(yScale.range())
    // console.log(xScale.domain()) 
    const {tickerParams} = chartParameters
    const line = d3
            .line()
            .x(d => xScale(d['Date']))
            .y(d => yScale(d['Close']));
    const lineSelect = d3.select(`#${chartId} > g `)
                            .selectAll(`.toggleLine-${chartId}-${tickerParamsIndex}`)
                            .data([data])
                            
    
    lineSelect.join(
        enter => enter
                            .append('path')
                            .style('fill', 'none')
                            .attr('class', `toggleLine-${chartId}-${tickerParamsIndex}`)
                            .attr('cursor','pointer')
                            .attr('pointer-events',"visible")
                            .attr('clip-path', `url(#clip-${chartId})`)
                            .attr('stroke', tickerParams[tickerParamsIndex].color)
                            .attr('stroke-width', '1.5')
                            .attr('d', line),
        
            
        update =>
        update
            .transition()
            .duration(750)
            .attr('stroke', chartParameters.tickerParams[tickerParamsIndex].color)
            .attr('d', line)
    );
    // lineSelect.attr('transform',`translate(${-yAxisWidth+50}, ${svgParams.height - svgParams.margin.bottom - svgParams.margin.top})`)
    // } else {
    //     d3.select(`.price-chart-${chartId}-${tickerParamsId}`).remove()
    // }
}

const toggleCandleStick = (tickerParamsIndex,chartId,data,chartParameters,xScale,yScale) => {
    // if (chartParameters.chartType === 'candlesticks'){
        d3.select(`.toggleLine-${chartId}-${tickerParamsIndex}`).remove()
        // console.log(yScale.range())
        
        //group candlesticks chart with clip-path attribute
        d3.select(`#${chartId} >  g`).append('g')
            .attr('id',`candlesticks-series-${chartId}`)
            .attr('clip-path',`url(#clip-${chartId})`)
            
        // console.log(xScale.range(), yScale.range()) 
        const bodyWidth = 5
        const candlesticksLine = d3.line()
                                    .x(d=> d['x'])
                                    .y(d=> d['y'])
        const candlesticksSelection = d3.select(`#candlesticks-series-${chartId}`)
                                        .selectAll(`.toggleCandleStick-${chartId}-${tickerParamsIndex}`)
                                        .data(data)
        
        candlesticksSelection.join(enter => {
            const candlesticksEnter = enter.append('g')
                                            .attr('class',`toggleCandleStick-${chartId}-${tickerParamsIndex}`)
                                            .append('g')
                                            .attr('class','bars')
                                            .classed('up-day', d=> d['Close'] > d['Open'])
                                            .classed('down-day', d=> d['Close'] <= d['Open'])
            
            candlesticksEnter.append('path')
                            .classed('high-low',true)
                            .attr('d', d=> {
                                return candlesticksLine([
                                    {x: xScale(d['Date']), y:yScale(d['High'])},
                                    {x: xScale(d['Date']), y:yScale(d['Low'])}
                                ])
                            })
        
            candlesticksEnter.append('rect')
                            .attr('x', d=>xScale(d.Date) - bodyWidth/2)
                            .attr('y', d => {
                                return d['Close'] > d['Open'] ? yScale(d['Close']): yScale(d['Open'])
                            })
                            .attr('width',bodyWidth)
                            .attr('height',d=> {
                                return d['Close'] > d['Open'] ? yScale(d['Open']) - yScale(d['Close']) : yScale(d['Close']) - yScale(d['Open'])
                            })

            

        },
        update => {
            const candlesticksUpdate = update.classed('up-day', d=> d['Close'] > d['Open'])
                                            .classed('down-day', d=> d['Close'] <= d['Open'])
            candlesticksUpdate.select('path').attr('d', d=> {
                                
                                return candlesticksLine([
                                    {x: xScale(d['Date']), y:yScale(d['High'])},
                                    {x: xScale(d['Date']), y:yScale(d['Low'])}
                                ])
                            })

            candlesticksUpdate.select('rect')
                            .attr('x', d=>xScale(d.Date) - bodyWidth/2)
                            .attr('y', d => {
                                return d['Close'] > d['Open'] ? yScale(d['Close']): yScale(d['Open'])
                            })
                            .attr('width',bodyWidth)
                            .attr('height',d=> {
                                return d['Close'] > d['Open'] ? yScale(d['Open']) - yScale(d['Close']) : yScale(d['Close']) - yScale(d['Open'])
                            })
        }
        
        )
    // } else {
    //     d3.select(`#${chartId}`)
    //         .select('g')
    //         .selectAll(`.candlestick-${chartId}`)
    //         .remove();
    // }
}
export default {
    toggleLine,
    toggleCandleStick,
}