// const initState = {0:{0:[]}}

export const marketResearchReducer = (state={}, action) => {
    switch (action.type) {
        case 'CREATE_MARKET_RESEARCH': {
            let [workspaceId, mainLayoutId] = action.payload
            return {
                ...state,
                [workspaceId]: {
                    [mainLayoutId]: {
                        'sources':['VNDIRECT','MBS','SSI','HSC',
                                'VDS','MASVN','VCBS','Finngroup',
                                'Blackrock','GS','Citigroup',
                                'MorganStanley','WellFargo',
                                'BIDV'
                            ],
                        'types':['daily_commentary','weekly_commentary','macro_report',
                                'strategy_report','industry_report','company_report',
                                'bond_report','fx_report','ir_report','event_report',
                                'thematic_report'      
                            ]
                    }
                }
            }
        };
        case 'REMOVE_MARKET_RESEARCH': {
            let [workspaceId, mainLayoutId] = action.payload;
            if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]: value, ...newState}} = state;
                return {...state,
                    [workspaceId]: newState
                };
            } else {
                const {[workspaceId]: value, ...newState} = state;
                return newState
            };
        };
        case 'UPDATE_ITEM_IN_RESEARCH_LIST' :{
            let [workspaceId, mainLayoutId, itemType, itemValues] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]: {
                        ...state[workspaceId][mainLayoutId],
                        [itemType]:itemValues
                    }
                }
            };
        };

        default:
            return state;
    };
}