import React from 'react'
import { useReactTable,flexRender, getCoreRowModel,} from '@tanstack/react-table';
import {TableContainer,
        TableBody,
        TableHead,
        TableRow,
        TableCell} from '@mui/material';
import  StyledEngineProvider  from '@mui/material/StyledEngineProvider';
import {styled} from '@mui/system';

const StyledTableCellRow = styled(TableCell)(({theme}) =>({
    color:"white",
    height: 'auto !important',
    width: 'auto !important',
    marginRight:'10px',
    }));

const StyledTableRow = styled(TableRow)(({theme}) =>({
    height:'5px'
    }));

export default function Table({columns, data, layout, cols}) {
    const instance = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
    })
    // https://github.com/react-grid-layout/react-grid-layout/issues/628 adjust maxHeight according to RGL 
    // const {w,h} = layout
    // console.log('call table')
    let maxHeight, maxWidth
    if (layout) {
        maxHeight = layout.component === 'priceboard' ? layout.layoutProps.h * 30 - 40 
                                                            : layout.layoutProps.h * 30 - 90
        maxWidth = window.innerWidth * layout.layoutProps.w / cols
    } else {
        maxHeight = 500
        maxWidth = 500
    }
    
    // need to use withStyles and update the specific element class style, 
    // so it will reflect to all the elements.
    //https://stackoverflow.com/questions/64488489/react-material-table-set-row-height
    
    //TO add a infinite scroll to table -> https://codesandbox.io/s/react-table-infinite-scroll-h7v5q?file=/src/App.js
    //https://stackoverflow.com/questions/67161846/infinite-loading-with-react-table-react-virtualized-react-window
    const StyledTableCellHeader = styled(TableCell)(({theme}) =>({
            // root: {
            //     height: 10,
            //     padding:0
            // }
                background: 'linear-gradient(45deg, black 30%, black 90%)',
                color:"pink",
                fontWeight:"bold",
                justifyContent:"center",
                alignItems:"center",
            }));

    //https://stackoverflow.com/questions/75424545/react-mui-data-grid-free-styled-scrollbars-example-code-sandbox-provided
    const StyledTableContainer = styled(TableContainer)(({theme}) =>({
        
        backgroundColor: 'linear-gradient(45deg, black 30%, black 90%)',
        border: 5,
        borderRadius: 0,
        boxShadow: '0 3px 5px 0px rgba(255, 105, 135, .3)',
        // color: 'white',
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        padding: '0 0px',
        // overflow:'auto',
        // "& ::-webkit-scrollbar": {
        //     width: "8px",
        //     height: "8px",
        //     backgroundColor:"black"
        // },
        '& .MuiTableContainer-root::-webkit-scrollbar': {
            width: '0.4em',
          },
          '& .MuiTableContainer-virtualScroller::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '& .MuiTableContainer-virtualScroller::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
          },
          '& .MuiTableContainer-virtualScroller::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
        // "& ::-webkit-scrollbar-track": {
        //     boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        //     borderRadius: "10px",
        //     backgroundColor: 'rgba(0,0,0,0.05)'
        // },
        // '& ::-webkit-scrollbar-thumb': {
        //     borderRadius: '10px',
        //     backgroundColor: 'linear-gradient(left, #999, #888)',
        //     boxShadow: 'inset 0 0 1px rgba(0,0,0,0.8)',
        //     border: 'solid 2px #1a1b1c'
        // },
        
            }));

    // const classes = useStyles()
    return (
        <StyledEngineProvider injectFirst>
            <StyledTableContainer>
                <TableBody
                    // className={classes.root}
                    // stickyHeader
                    // classes={{root: classes.height}}
                    // {...instance.getTableProps()}
                >
                    <TableHead>
                        {instance.getHeaderGroups().map(headerGroup => (
                            <TableRow>
                                {headerGroup.headers.map(header => (
                                    <StyledTableCellHeader 
                                        // classes={{root: classes.header}}
                                        // https://stackoverflow.com/a/66804698 -> changing column width in react-table
                                        // {...column.getHeaderProps({
                                        //     style:{maxWidth:column.maxWidth,
                                        //             width:column.width
                                        //         } //add this to style column
                                        // })}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </StyledTableCellHeader>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody>
                        {instance.getRowModel().rows.map((row, i) => {
                            // prepareRow(row);
                            return (
                                <StyledTableRow  
                                    // className={classes.row} 
                                    // {...row.getRowProps()}
                                >
                                    {row.getVisibleCells().map(cell => {
                                        // console.log(cell)
                                        return <StyledTableCellRow 
                                                // className={classes.cell}
                                                // {...cell.getCellProps({
                                                //     style:{
                                                //         maxWidth:cell.column.maxWidth,
                                                //         width:cell.column.minWidth,
                                                //         marginLeft:cell.column.marginLeft
                                                //     }
                                                // })}
                                                >
                                                   {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </StyledTableCellRow>;
                                    })}
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </TableBody>
            </StyledTableContainer>
        </StyledEngineProvider>
    )
}