


const watcherReducer = (state={}, action) => {
	switch(action.type){
    case 'CREATE_WATCHER':{
      let [workspaceId,mainLayoutId] = action.payload
      const initSubLayouts = [
        {
            id:0,
            layoutProps:{
                x:0,
                y:0,
                w:4,
                h:1,
                i:`${mainLayoutId+1}.1`,
                static:true
            },
            watcherComponentName:'header'
        },
        {
            id:1,
            layoutProps:{
                x:0,
                y:1,
                w:2,
                h:3,
                i:`${mainLayoutId+1}.2`,
                static:true
            },
            watcherComponentName:'watchList'
        },
        {
            id:2,
            layoutProps:{
                x:2,
                y:1,
                w:2,
                h:3,
                i:`${mainLayoutId+1}.3`,
                static:true
            },
            watcherComponentName: 'chart'
        },
        {
            id:3,
            layoutProps:{
                x:0,
                y:5,
                w:2,
                h:4,
                maxW:2,
                i:`${mainLayoutId+1}.4`
            },
            watcherComponentName:'signalType',
            signalName:'seq5DayRise',
            tickers:[]
        },
        // {
        //     id:4,
        //     layoutProps:{
        //         x:0,
        //         y:9,
        //         w:2,
        //         h:4,
        //         maxW:2,
        //         i:`${id+1}.5`
        //     },
        //     component:'signalType',
        //     signalName:'seq5DayFall',
        //     tickers:[]
            
        // },

    ]
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:initSubLayouts
        }
      }
    } 
		// case 'ADD_NEW_WATCHER_LAYOUT':
		// 	let [workspaceId,mainLayoutId,layout] = action.payload
		// 	return {
		// 		...state,
    //     [workspaceId]:{
    //       ...state[workspaceId],
		// 		  [mainLayoutId]:layout
    //     }
		// 	}
    case 'REMOVE_WATCHER_LAYOUT': {
      //https://stackoverflow.com/questions/34401098/remove-a-property-in-an-object-immutably
      let [workspaceId,mainLayoutId] = action.payload
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
    } 
    case 'UPDATE_SUBLAYOUT_SIZE': {
      let [workspaceId,mainLayoutId,mainLayoutProps] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].map((item,index) => {
              if (index === 0){
                return {
                  ...item,
                  layoutProps:{
                    ...item.layoutProps,
                    w:mainLayoutProps['w']
                  }
                }
              } else if (index === 2){
                return {
                  ...item,
                  layoutProps:{
                    ...item.layoutProps,
                    w:(mainLayoutProps['w'] - 2) ,
                    h:( mainLayoutProps['h'] - 1)  
                  }
                } 
              }
              return item
            }),
          ]
        }
      }
    }

    case 'UPDATE_WATCHER_LAYOUTS_CHANGE': {
      let [workspaceId,mainLayoutId,layouts] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId] : [
            ...state[workspaceId][mainLayoutId].map((item,index) => {
              return {
                ...item,
                layoutProps:layouts[index]
              }
            })
          ]
        }  
      }
    }

    case 'REFRESH_WATCHER_GRID':{
      let[workspaceId,mainLayoutId,signalGrids] = action.payload
      // console.log(action.payload)
      return {...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].slice(0,3),
            ...signalGrids
          ]
        }
      }
    }
		case 'ADD_SIGNALED_TICKER':{
			let [workspaceId,mainLayoutId,signals] = action.payload
				return {...state,
          [workspaceId]:{
            ...state[workspaceId],
            [mainLayoutId]:[
              ...state[workspaceId][mainLayoutId].map((item,index) => {
                
                if(index >= 3) {
                  // console.log(signals[item.signalName])
                  return {
                    ...item,
                    tickers:signals[item.signalName]
                  }
                }
                return item
              })
            ]
          }
				}
    }
		default:
			return state
	}
}

const tickerWatchList = (state={}, action) => {
  
  switch (action.type ) {
    case 'CREATE_TICKER_WATCHLIST':{
      let [workspaceId,mainLayoutId] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[]
        }
      }
    } 
    
    // case 'INITIATE_TICKER_WATCHLIST':{
    //   let [workspaceId,mainLayoutId,initTicker] = action.payload
    //   return {
    //     ...state,
    //     [workspaceId]:{
    //       ...state[workspaceId],
    //       [mainLayoutId]:initTicker
    //     }
    //   }
    // }
    case 'REMOVE_TICKER_WATCHLIST': {
      let [workspaceId,mainLayoutId] = action.payload
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
    }
    case 'ADD_TICKER_INTO_WATCHLIST':{
      let [workspaceId,mainLayoutId,addedTicker] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId],
            addedTicker
          ]
        }
      }
    } 
    case 'REMOVE_TICKER_FROM_WATCHLIST': {
      let [workspaceId,mainLayoutId, indexOfTicker] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].filter((_,index) => index !== indexOfTicker)
          ]
        }
      }
    }
    default:
      return state
  }
  

}

const signalWatchList = (state={}, action) => {
  
  switch (action.type ) {
    case 'CREATE_SIGNAL_WATCHLIST':{
      let [workspaceId,mainLayoutId] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:['seq5DayRise']
        }
      }
    }
    // case 'INITIATE_SIGNAL_WATCHLIST':{
    //   let [workspaceId,mainLayoutId,initSignal] = action.payload
    //   return {
    //     ...state,
    //     [workspaceId]:{
    //       ...state[workspaceId],
    //       [mainLayoutId]:initSignal
    //     }
    //   }
    // }
    case 'UPDATE_SIGNAL_LIST':{
      let [workspaceId,mainLayoutId,updatedSignals] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:updatedSignals
        }  
      }
    }
    case 'REMOVE_SIGNAL_WATCHLIST': {
      let [workspaceId,mainLayoutId] = action.payload
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
    } 
    case 'ADD_SIGNAL_INTO_WATCHLIST' :{
      let [workspaceId,mainLayoutId,addedSignal] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId],
            addedSignal
          ]
        }
      }
    }
    case 'REMOVE_SIGNAL_FROM_WATCHLIST':{
      let [workspaceId,mainLayoutId,indexOfSignal] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:[
            ...state[workspaceId][mainLayoutId].filter((_,index) => index !== indexOfSignal)
          ]
        }
      }
    }
    default:
      return state;
  }
}
export {
  watcherReducer,
  tickerWatchList,
  signalWatchList
}
