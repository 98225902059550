// https://dev.to/link2twenty/react-redux-and-localstorage-2lih
import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from '../reducers/rootReducer'
import thunk from 'redux-thunk'
import throttle from 'lodash/throttle'
const saveToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('dashboardInfo',serializedState)
    } catch (e) {
        console.warn(e)
    }
}

// load string from localStorage and convert into an Object
// invalid output must be undefined
const loadFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('dashboardInfo')
        if(serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        console.warn(e)
        return undefined
    }
}

//create our store from our rootReducers and use loadFromLocalStorage
// to overwrite any values that already have saved
// const reducers = combineReducers({auth:authMainReducer, dashboardInfo:rootReducer})
const composeEnhanced = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, loadFromLocalStorage(),composeEnhanced(applyMiddleware(thunk)))

// listen for store changes and use saveToLocalStorage to
// save them to localStorage and choose which state should be save
// https://www.geeksforgeeks.org/how-to-persist-redux-state-in-local-storage-without-any-external-library/
store.subscribe(throttle(() => {
    // console.log(store.getState())
    const appStore = store.getState()
    saveToLocalStorage({
        workspace:appStore.workspace,
        mainChartParams:appStore.mainChartParams,
        dashboard:appStore.dashboard,
        mainChart:appStore.mainChart,
        watcher:appStore.watcher,
        signalInWatchList:appStore.signalInWatchList,
        tickerInWatchList:appStore.tickerInWatchList,
        // tickerData:{
        //     ...appStore.tickerData,
        //     ticker:appStore.tickerData.ticker
        // },
        simpleChart: appStore.simpleChart,
        tickersForNews:appStore.tickersForNews,
        tickersForPriceBoard:appStore.tickersForPriceBoard,
        overview:appStore.overview,
        marketmap: appStore.marketmap,
        marketResearch: appStore.marketResearch,
        macroeconomic: appStore.macroeconomic,
        
    })
}))

export default store;