
import React, { useState,useRef, useCallback } from "react";
import {useSelector, useDispatch} from "react-redux"
import _ from "lodash";
import isDeepEqual from 'fast-deep-equal'
import { WidthProvider, Responsive } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import './Dashboard.css';

import Overview from "./Overview/Overview";
import PriceBoard from './PriceBoard/PriceBoard'
import Charting from './Chart/Charting'
import News from "./News/News";
import Watcher from "./Watcher/Watcher";
import MarketMap from "./MarketMap/MarketMap";
import MarketResearch from "./Research/MarketResearch";
import Macroeconomic from "./Macroeconomic/Macroeconomic";

import Header from "../UniversalComponents/Header";
import ToolBox from "./ToolBox";

import { ButtonGroup, Button } from '@mui/material';

import { addNewLayout, addComponentIntoLayout,updateLayoutChange, updateLayoutChangeOnDragResizeEvent} from '../../js/actions/dashboardActions'
import { createMainChart } from "../../js/actions/mainChartActions";

import { createPriceBoard } from "../../js/actions/priceBoardActions";
import { createNewsBoard } from "../../js/actions/newsAction";
import { createOverview } from "../../js/actions/overviewActions";
import { createSignalWatchList, createTickerWatchList, createWatcher } from "../../js/actions/watcherActions";
import { createSimpleChart } from "../../js/actions/simpleChartActions";
import {createMarketMap} from "../../js/actions/marketMapActions";
import {createMarketResearch} from "../../js/actions/marketResearchActions";
import {createMacroeconomic} from "../../js/actions/macroeconomicActions";
import Quotes from "./Quotes/Quotes";
// import useDidMountEffect from "../UniversalComponents/useDidMountEffect.js";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// https://codesandbox.io/s/lucid-panini-6djzi?file=/src/App.js -> react-grid-layout in hooks
// https://medium.com/@sonalsonu51/resize-grid-item-in-rgl-react-grid-layout-using-a-button-572e45e4d3bc -> user want to resize the layout to fullsize or vice versa

// place a component as a variable
// https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const components = {
  overview: Overview,
  priceboard: PriceBoard,
  chart: Charting,
  watcher: Watcher,
  news: News,
  marketmap: MarketMap,
  marketresearch: MarketResearch,
  macroeconomic: Macroeconomic,
  quotes:Quotes
}
const createActions = {
  chart:createMainChart,
  priceboard: createPriceBoard,
  overview:createOverview,
  watcher:[createWatcher, createTickerWatchList, createSignalWatchList, createSimpleChart],
  news:createNewsBoard,
  marketmap: createMarketMap,
  marketresearch: createMarketResearch,
  macroeconomic: createMacroeconomic,
  // quotes:createQuotes,

}


const DashBoard = (props) => {
  // console.log('dashboard is rendered')
  const workspaceId = props.workspaceId
  const reduxStore = useSelector(state => state.dashboard)
  const layouts = reduxStore[workspaceId].items
  const hiddenLayouts = layouts.filter(layout => layout['isHidden'] === true)
  const visibleLayouts = layouts.filter(layout => typeof layout['isFullscreen'] === 'object').length > 0 
                                    ?  layouts.filter(layout => typeof layout['isFullscreen'] === 'object')
                                    : layouts.filter(layout => layout['isHidden'] !== true)
  
  const counter = reduxStore[workspaceId].newCounter
  const dispatch = useDispatch()
  const [breakpoint, updateBreakpoint] = useState({
                                          currentBreakpoint:'lg',
                                          currentCols:12
                                        })
  const inputRef = useRef()
  
  
  const layoutsRef = useRef(visibleLayouts)
  if (!isDeepEqual(layoutsRef.current, visibleLayouts)) {
    // console.log('false')
    layoutsRef.current = visibleLayouts
  }
  // console.log(allLayoutProps)
  const generateDOM = useCallback(() => {
    // Generate items with properties from the layout, rather than pass the layout directly
    // console.log(workspaceId) 
    // const layouts = reduxStore.dashboard.items;
    // console.log(breakpoint) 
    return _.map(visibleLayouts, function(l) {
      
      let {component,title,id,layoutProps, buttons} = l
      // let prevLayoutProps = layoutsRef.current.filter(layoutRef => layoutRef.id === id )
      // let isHidden = l.isHidden
      // if (component === 'priceboard') {console.log(l.layoutProps)}
      let isFullscreen = l.isFullscreen
      if (component) {
        
        const SpecificBoard = components[component]
        return (
          <div key={layoutProps.i} data-grid={layoutProps}>
            {component!=="watcher" &&
              <Header
                workspaceId={workspaceId} 
                title={title}
                inputRef={inputRef}
                componentId={id}
                isFullscreen={isFullscreen}
                componentName={component}
                
              />
              
            }
            {/* render component */}
            <SpecificBoard key={layoutProps.i} workspaceId={workspaceId} layout={l} width={window.innerWidth} mainBreakpoint={breakpoint} />
          </div>
        )
        
      
      } else {
        return (
          // <SizeMe>{({size}) => 
          <div key={layoutProps.i} data-grid={l.layoutProps}>
            <>
              <div className="rounded" key="?" onTouchStart={e => e.stopPropagation()} 
                    style={{position:"fixed", top:"30%"}}>
                <ButtonGroup disableElevation variant="contained" color="primary" 
                              style={{display:"inline-block", margin:"0",padding:"0", width:500, height:100,position: "relative"}}
                                          >
                  {/* add functional Button */}
                  {l.buttons.name.map((b,i) => (
                    
                    <Button 
                      key={i}
                      onClick={()=>clickComponentHandle(id,buttons.value[i])}
                      variant="contained" color="primary" 
                      style={{borderRadius:'8px',
                              padding:"32px 16px",
                              // width:"80px",
                              height:"50px",
                              boxShadow: "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)"}} 
                    >
                      {b}
                    </Button>
                    // <input  key={i} type='button' onClick={()=>clickComponentHandle(l.i,data1[l.i].buttons.value[i])} value={b} />
                  ))}
                </ButtonGroup>
              </div>
              <Header
                workspaceId={workspaceId} 
                title={title}
                inputRef={inputRef}
                componentId={id}
                isFullscreen={isFullscreen}
                componentName={component}
              
              />
              
              
            </>
          </div>
          // }
          // </SizeMe>
        );
      }
    });
  },[layoutsRef.current, breakpoint,workspaceId]);
  
  
  const onLayoutChange = (layouts) => {
    // console.log('call back here')
    dispatch(updateLayoutChange([workspaceId,layouts]))
  }
  
  

  const onLayoutChangeOnDragResizeEvent = (layout) => {
    // console.log("i was draggab;e");
    dispatch(updateLayoutChangeOnDragResizeEvent([workspaceId,layout]))
  }
  const addLayout = () => {
    
    const newLayoutId = counter + 1
    
    const newLayout = 
      
      {
      
        id:newLayoutId,
        layoutProps:{
          x:5,
          y:4,
          w:4,
          h:16,
          i:newLayoutId.toString()
        },
        
        buttons: {
          name:["Overview","Price Board","Chart","Watcher",
                "News","Market Map", 'Market Research','Macroeconomic','Quotes'],
          value:["overview","priceboard",'chart','watcher',
                'news','marketmap', 'marketresearch','macroeconomic','quotes']
        
        },
        component:null,
        // tickers:[],
        title:`Untitled-${newLayoutId}`,
          
        
        isHidden:false,
        isFullscreen:false,
      }
    dispatch(addNewLayout([workspaceId,newLayout,counter]))
  };
  const clickComponentHandle = (idx,boardType) => {
    // console.log(workspaceId)
    if (boardType !== 'watcher') {
      dispatch(createActions[boardType]([workspaceId,idx]))
    } else {
      for (let watcherAction of createActions[boardType]) {
        dispatch(watcherAction([workspaceId,idx]))
      }
    }
    dispatch(addComponentIntoLayout([workspaceId,idx,boardType]))
  }
  const breakpointChangeHandle = (newBreakpoint, newCols) => {
    // console.log(newBreakpoint,newCols)
    updateBreakpoint({
      currentBreakpoint:newBreakpoint,
      currentCols: newCols,
    })
  } 
  // useEffect(() => {
  //       updateDimensions();
  //       window.addEventListener('resize', updateDimensions)
  //       return () => window.removeEventListener("resize",updateDimensions)
  //   },[props.layout])
  //   const updateDimensions = () => {
  //     console.log(window.innerWidth)
  //   }
  let updatedLayoutProps = visibleLayouts.map(item =>  ({...item.layoutProps}))
  // console.log(updatedLayoutProps)
  return (
    <>
      <ToolBox
        workspaceId={workspaceId} 
        hiddenLayouts = {hiddenLayouts}
        addLayout={addLayout}
      />
      {/* <button onClick={addLayout} >+ Item</button> */}
      {/* {props.isAuthenticated ? <button onClick={() => props.logout()}>Logout</button> : null} */}
      <ResponsiveReactGridLayout
        {...props}
        
        className='mainLayout' 
        style={{background:'black'}}
        onBreakpointChange={breakpointChangeHandle}
        layouts={{[breakpoint.currentBreakpoint]:updatedLayoutProps}}
        preventCollision={true}
        allowOverlap={true}
        onDragStop={(layout,_,newLayoutItem) => onLayoutChangeOnDragResizeEvent(newLayoutItem)}
        onResizeStop={(layout,_,newLayoutItem) => onLayoutChangeOnDragResizeEvent(newLayoutItem)}
        onLayoutChange = {(layouts) => onLayoutChange(layouts)} 
      >

        {generateDOM()}
      </ResponsiveReactGridLayout>
    </>
  );
};
DashBoard.defaultProps = {
  isDraggable: true,
  isResizable: true,
  items: 5,
  rowHeight: 30,
  //width: 50,
  // onLayoutChange: function (layouts) {return layouts},
  cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
  margin:[0,0]
  
};
export default DashBoard;
