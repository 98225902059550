import React, {useState, useEffect,useRef,memo} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isDeepEqual from 'fast-deep-equal'
import InitializeChart from '../../../Chart/DrawChart1'
import { adjustSVGParams} from '../../../../../js/actions/simpleChartActions'
import MarketDataService from '../../../../../services/MarketService'
import * as d3 from 'd3'

const SimpleCharting = (props) => {
    // console.log('simple chart is rendered')
    const {workspaceId,layout, mainLayoutId, currentCols} = props 
    const d3Container = useRef(null)
    // let reduxStore = useSelector(state => state)
    let mainLayoutProps = useSelector(state => state.dashboard[workspaceId].items.filter(item => item.id === mainLayoutId)[0]['layoutProps'],isDeepEqual)
    let chartParams = useSelector(state => state.simpleChart[workspaceId][mainLayoutId])
    // let tickerParams, ticker
    // if (chartParams !== undefined) {
    let tickerParams = chartParams['tickerParams']
    let ticker = tickerParams[0]['ticker']
        // svgParams = chartParams['svgParams']
        // indicators = chartParams['indicators']
        // analysis = chartParams['analysis']
    // } else {
    //     tickerParams = []
    //     ticker = ''
        // svgParams = {}
        // indicators =[]
        // analysis = {}
    // } 
    // console.log(timeSeriesData)
    // if Dashboard is used, chartParams will come from dashboard, 
    // otherwise, it will be sourced from mainChartParams
    // const chartParams = reduxStore.mainChartParams
    
    const dispatch = useDispatch()
    
    //add the Event Listener to auto resize the svg's width and height
    const initTimeSeriesData = {
        historicalPrice:[],
        
    }
    const [timeSeriesData,setTimeSeriesData] = useState(initTimeSeriesData)
    const [loading, setLoading] = useState(true)
    
    const updateDimensions = () => {

        const margin = {top: 30, right: 50, bottom: 10, left:50 }
        // const i = chartParams.indicators.length >0 ? (0.75 * chartParams.indicators.length) : 1
        const newDimension = {
            height: ((mainLayoutProps.h - 1) * 30 ) - 20 - (margin.top + margin.bottom),//* 0.8429 * 1.,
            width : (window.innerWidth * (mainLayoutProps.w - 2) / currentCols) - (margin.left + margin.right), //* 0.8429,
            adjustedHeight: (((mainLayoutProps.h-1) * 30 ) - 20 - (margin.top + margin.bottom) ) ,
            }
        // because to use object a dependency may cause infinite loop in useEffect
        // , we must stringify the chartParams.
        // https://dmitripavlutin.com/react-useeffect-infinite-loop/
        dispatch(adjustSVGParams([workspaceId,mainLayoutId,newDimension]))  
    }
    useEffect(() => {
        updateDimensions();
        // window.addEventListener('resize', updateDimensions)
        //return () => window.removeEventListener("resize",updateDimensions)
        // return () => setValue(null)
    },[mainLayoutProps])
    
    useEffect(() => {
        if (ticker !=='') {
            let signalFormData = {
                ticker:ticker,
                signal:tickerParams[0].signalName
            } 
            // console.log(signalFormData)
            
            MarketDataService.getSignalSeries(signalFormData).then(
                res => {
                        // console.log(res.data.map(item => )
                        // console.log(res.data)
                        // dispatch(updateTimeSeriesData([
                        //         mainLayoutId,
                        //         res.data.map(item => {return {...item,'Date':new Date(item['Date'] * 1000)}})
                        // ]))
                        let revisedData = res.data.map(item => {return {...item,'Date':new Date(item['Date'] * 1000)}})
                        setTimeSeriesData(prevState => ({...prevState, historicalPrice: [revisedData]}))
                        setLoading(false)
                })
                .catch(error => {alert(error)})
        // return () => dispatch(updateTimeSeriesData([mainLayoutId,null]))
    }},[ticker])
    // useEffect(() => {
    //     return () => dispatch(clearTimeSeriesData([mainLayoutId]))
    // },[])

    useEffect(() => {
        // console.log(timeSeriesData, loading)
        if(timeSeriesData.historicalPrice.length > 0) {
            
            InitializeChart(d3Container.current,{timeSeriesData:timeSeriesData,
                                                chartParams,
                                                chartId:`simpleChart-${mainLayoutId}`,
                                                // tickerParamsIndex:0,
                                                indexOfLongestArray:0,
                                                // isYAxisLeft:true,
                                                // numberOfRightAxis:1,
                                                // numberOfLeftAxis:0
                                            },)
            
            
            //render the name of signal
            d3.select(`#simpleChart-${mainLayoutId} > g >.signalName`).remove()

            d3.select(`#simpleChart-${mainLayoutId} > g`).append('text')
            .attr('class',`signalName`)
            .attr('transform',()=> `translate(${0},${10})`)
            .attr("dy", ".15em")
            .attr("text-anchor", "start")
            .style("fill", `${tickerParams[0]['color']}`)
            .style("font-size", "12px") 
            .text(`${tickerParams[0].signalName}`);
        }
        
    },[timeSeriesData,layout])
    
    return (
        <div className="flex-container">
            
            <div className="flex-item">
                <span></span>
                <div ref={d3Container}>
                    {loading &&  <span style={{color:'white'}}>Loading...</span>}
                    <svg id={`simpleChart-${mainLayoutId}`} />
                </div>
            </div>
            
            
        </div>

    )
}
export default SimpleCharting