export const refreshStyle = {
	position: "absolute",
	right: "95px",
	top: 0,
	cursor: "pointer",
	color:"white",
	};
export const analysisStyle = {
	position: "absolute",
	right: "150px",
	top: 0,
	cursor: "pointer",
	color:"white",
	// backgroundColor:'grey',
	marginBottom:"20px"
	};
export const removeStyle = {
	position: "absolute",
	right: "2px",
	top: 0,
	cursor: "pointer",
	color:"white",
	};
export const fullscreenStyle = {
	position: "absolute",
	right: "35px",
	top: 0,
	cursor: "pointer",
	color:"white",
	};
export const minimizeStyle = {
	position: "absolute",
	right: "70px",
	top: 0,
	cursor: "pointer",
	color:"white",
	};
export const editableStyle = {
	position: "absolute",
	left: "200px",
	top: 0,
	cursor: "pointer",
	color:"orange",
	background:"none"
	};
export const expandMoreStyle = {
	// position: "absolute",
	// right: "-15px",
	// top: -10,
	cursor: "pointer",
	color:"white",
}
export const expandLessStyle = {
	// position: "absolute",
	// right: "150px",
	// top: 40,
	paddingBottom:0,
	cursor: "pointer",
	color:"white",
}