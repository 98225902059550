import {Grid} from '@mui/material'
import FinancialChart from './FinancialChart'

const Financial = (props) => {
	const {workspaceId,mainLayoutId} = props
	return (
		<Grid item container direction="row" spacing={2} style={{width:'100vh',position:'relative'}}>
			<Grid item style={{minWidth:600}}>
				<FinancialChart
					workspaceId={workspaceId} 
					mainLayoutId={mainLayoutId}
				/>
			</Grid>
		</Grid>
	)
}

export default Financial