const initialState = {
	
	
            tickerParams:[{
                ticker:'',
                axis:'R1',
                panel:'',
                color:'#FFA500',
                chartType:'toggleCandleStick',
                chartStyle:'',
                width:'',
                marker:'',
                yScale:null,
                signalName:''
            }],
            svgParams:{
                margin:{top: 30, right: 50, bottom: 30, left:50 },
                width:null,
                height:null,
                adjustedHeight: null,
            },
            analysis:{

                signal: {
                    indicatorHeight:0,
                    position:'main'
                } 
            },
            indicators:['signal'],
}



export const simpleChartReducer = (state={}, action) => {
  
	switch(action.type){
    case 'CREATE_SIMPLE_CHART':{
      let [workspaceId,mainLayoutId] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:initialState
        }
      }
    }
    case 'INITIATE_SIMPLE_CHART': {
      let mainLayoutId = action.payload[0]
      return {...state,[mainLayoutId]:initialState}
    }

    case 'REMOVE_SIMPLE_CHART': {
      let [workspaceId,mainLayoutId] = action.payload
			if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
      } else {
          const {[workspaceId]: value, ...newState} = state
          return newState
      }
    }
		case 'UPDATE_TICKER_AND_SIGNAL_NAME': {
      let[workspaceId,mainLayoutId,ticker,signalName] = action.payload
			return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:{
            ...state[workspaceId][mainLayoutId],
            tickerParams:[
              {
                ...state[workspaceId][mainLayoutId]['tickerParams'][0],
                ticker:ticker,
                signalName:signalName
              }
            ]
            
          }
        }
      }
    }
    
    case 'ADJUST_SVG_PARAMS':{
      let [workspaceId,mainLayoutId,params] = action.payload
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:{
            ...state[workspaceId][mainLayoutId],
            
            svgParams:{
              ...state[workspaceId][mainLayoutId].svgParams,
              ...params
            }
            
          }
        }
      }
    }
    case 'CHANGE_TICKER':{
      let [workspaceId,mainLayoutId,ticker] = action.payload
    
      return {
        ...state,
        [workspaceId]:{
          ...state[workspaceId],
          [mainLayoutId]:{
            ...state[workspaceId][mainLayoutId],
            tickerParams:[
              {...state[workspaceId][mainLayoutId]['tickerParams'][0],ticker:ticker}
            ]
            
          }
        }
      }
    }
	  default: return state
	}
}