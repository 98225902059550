

export const marketMapReducer = (state={0:{3:"marketmap"}}, action) => {
    switch(action.type) {
        case 'CREATE_MARKET_MAP' : {
            let [workspaceId, mainLayoutId] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]: 'marketmap'
                }
            }
        }
        case 'REMOVE_MARKET_MAP' : {
            let [workspaceId, mainLayoutId] = action.payload
            if (!isNaN(mainLayoutId)) {
                const {[workspaceId]: {[mainLayoutId]: value, ...newState}} = state
                return {
                    ...state,
                    [workspaceId]: newState
                }
            } else {
                const {[workspaceId]: value, ...newState} = state
                return newState
            }
        }
        default : return state
    }
}