const CREATE_PRICE_BOARD = 'CREATE_PRICE_BOARD'
const ADD_TICKER_INTO_PRICE_BOARD = 'ADD_TICKER_INTO_PRICE_BOARD'
const REMOVE_TICKER_OUT_OF_PRICE_BOARD = 'REMOVE_TICKER_OUT_OF_PRICE_BOARD'
const INITIATE_PRICE_BOARD= 'INITIATE_PRICE_BOARD'
const REMOVE_PRICE_BOARD = 'REMOVE_PRICE_BOARD'

export const createPriceBoard = payload => {
    return {
        type: CREATE_PRICE_BOARD,
        payload
    }
}

export const addTickerIntoPriceBoard = payload => {
    return {
        type: ADD_TICKER_INTO_PRICE_BOARD,
        payload
    }
}
export const removeTickerOutOfPriceBoard = payload => {
    return {
        type: REMOVE_TICKER_OUT_OF_PRICE_BOARD,
        payload
    }
}

export const initiatePriceBoard = payload => {
	return {
		type:INITIATE_PRICE_BOARD,
		payload
	}
}

export const removePriceBoard = payload => {
	return {
		type:REMOVE_PRICE_BOARD,
		payload
	}
}