import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import {Button} from "@mui/material"
//import { ButtonGroup, Button } from '@mui/material'
// import { selectAll } from 'd3-selection'
import TickerTable from './TickerTable'
import MovingAverageTable from './MovingAverageTable';
import SimulationTable from './SimulationTable';
import MarketDataService from "../../../services/MarketService";
// import {enableNews, enableVolume, enableRSI, addIndicator, removeIndicator, adjustSVGParams} from '../../js/actions/chartParamsActions'
// import {getRSI} from '../../js/actions/tickerActions'
// import {calculateRSI,checkConvergence,checkDivergence} from './analysis/renderRSI'
import {useDispatch } from 'react-redux';
import {useState,useEffect} from 'react'


const AnalysisModal = (props) => {
  // const reduxStore = useSelector(state => state)
  // const chartParams = reduxStore.mainChartParams
  const {workspaceId, mainChartActions,layout,chartParams,timeSeriesData} = props
  const {analysis,indicators,svgParams,tickerParams} = chartParams
  const mainLayoutId = layout.id
  const dispatch = useDispatch()
  const [symbolList, setSymbolList] = useState([])
  const [movingAverageParams, setMovingAverageParams] = useState(analysis?.['movingaverage']?.['multipleState'] 
                                                                  || [{type:'SMA',period:20,color:'#FFA500'}] )
  // const [peSimulationParams, setPeSimulationParams] = useState()
  const handleInputChange = (e) => {
    // console.log(e.target) 
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked:target.value
    // const inputValue = layout ? [layout.id, value] : value
    const name = target.name
    if (name === 'enableVolume') {
      dispatch(mainChartActions.enableVolume([workspaceId,mainLayoutId,value]))
      
    } else if (name === 'enableNews') {
      dispatch(mainChartActions.enableNews([workspaceId,mainLayoutId,value]))
    }
  }
  // https://stackoverflow.com/questions/60349756/react-js-two-submit-buttons-in-one-form
  const [submitButton,setSubmitButton] = useState('')
  const [formData, setFormData] = useState(analysis)
  
  const handleChange = (e) => {
    // console.log(e.target)
    const indicatorName = e.target.id.split('-')[0]
    if (e.target.name === 'doSimulation') {
      // console.log(analysis)
      const ticker = analysis[indicatorName]['ticker']
      let index = tickerParams.findIndex(tickerParam => tickerParam['ticker'] === ticker)
      const currentPrice = timeSeriesData.historicalPrice[index][timeSeriesData.historicalPrice[index].length-1]['Close']
      
      timeSeriesData[indicatorName].then(result => {
        
        const currentPE = result.timeSeriesPE[result.timeSeriesPE.length - 1]['pe']
        const currentEPS =  currentPrice * 1000 / currentPE
        const simulationParams = [
              {type:'Price',currentValue:currentPrice,expectedValue:parseInt(currentPrice)},
              {type:'EPS',currentValue:currentEPS, expectedValue:parseInt(currentEPS)}
        ]  
        setFormData({
          ...formData,
          [indicatorName]:{
            ...formData[indicatorName],
            [e.target.name] : e.target.type === 'checkbox' ? e.target.checked :  e.target.value,
            simulationParams:simulationParams
          }
        })
      })
    } else {
      setFormData({
        ...formData,
        [indicatorName]:{
          ...formData[indicatorName],
          [e.target.name] : e.target.type === 'checkbox' ? e.target.checked :  e.target.value
        }
      })
    }
  }
  const handleSubmitIndicator = e => {
    e.preventDefault()
    // https://stackoverflow.com/questions/23427384/get-form-data-in-reactjs 
    const [indicatorName, buttonType]= submitButton.split('-')
    let indicatorParams
    if (indicatorName !== 'movingaverage') {
      indicatorParams = {...formData,
                              [indicatorName]:{
                                ...formData[indicatorName],
                                ticker:e.target[`${indicatorName}-ticker`].value
                              }
                        }
    } else {
      indicatorParams = {...formData,
                              [indicatorName]:{
                                ...formData[indicatorName],
                                multipleState:movingAverageParams,
                                ticker:e.target[`${indicatorName}-ticker`].value
                              }
                            }
    }
    // console.log(formData)
    // console.log(indicatorParams)
    // const indicatorValue = layout ? [layout.id, indicatorName, indicatorParams[indicatorName]]: indicatorParams[indicatorName]
    // if (indicatorName === 'rsi') {
      if (buttonType ==='update'){
        
        //at the moment, we only allow 1 indicator per chart,
        //so we must check if another is existing. If exist, an alert is raised.
        // let indicators = ['rsi','pe']
        // let isAvailable = indicators.some(i => indicators.indexOf(i) >= 0)
        // console.log(isAvailable)
        if (indicators.includes(indicatorName) !== true) {
          // let numberOfIndicators = 1//chartParams.indicators.length + 1
          // console.log(numberOfIndicators)
          // const adjustedHeightValue =  {adjustedHeight:svgParams.height* (1 - 0.25 * numberOfIndicators)}
          
          // dispatch(mainChartActions.adjustSVGParamsMainChart([workspaceId,mainLayoutId,adjustedHeightValue]))
          
          dispatch(mainChartActions.addIndicator([workspaceId,mainLayoutId,indicatorName, indicatorParams[indicatorName]]))
        } else if (indicators.includes(indicatorName) === true) {
          // alert('Cannot add. You must remove the current indicator first')
           
          dispatch(mainChartActions.updateIndicatorParams([workspaceId,mainLayoutId,indicatorName,indicatorParams[indicatorName]]))
        }
        
      } else if (buttonType ==='remove' && indicators.includes(indicatorName) === true) {
        
        dispatch(mainChartActions.removeIndicator([workspaceId,mainLayoutId,indicatorName]))
        const indicatorHeight = analysis[indicatorName]['indicatorHeight']
        // console.log(indicatorName) 
        // only update the chart height if this indicator is in sub chart
        
        // if (indicatorHeight !== 0) {
        // updatetimeseriesdata(indicatorName)
        const adjustedHeightValue = {adjustedHeight:svgParams.adjustedHeight + indicatorHeight}
        dispatch(mainChartActions.adjustSVGParamsMainChart([workspaceId,mainLayoutId,adjustedHeightValue]))
        // }
        // let index = tickerParams.findIndex(tickerParam => tickerParam['ticker'] === e.target[`${indicatorName}-ticker`].value)
        // console.log(selectAll(`.${indicatorName}-${chartId}-${index}`),`.${indicatorName}-${chartId}-${index}`)//.remove()
        // d3.selectAll(`.pe-${chartId}-${tickerParamsIndex}`).remove()
      }

      // else {
      //   alert(`Cannot add more ${indicatorName}`)
      // }
      
    
    
  }

  

  const addNewTickerParams =() => {
    dispatch(mainChartActions.addNewTickerParams([workspaceId,layout.id,
      {
        ticker:'',
        axis:'L1',
        panel:null,
        color:'#FFA500',
        chartType:'toggleLine',
        chartStyle:null,
        width:null,
        marker:null
      }
    ]))
  }
  const addNewMovingAverageParams = () => {
    //shoule check if mutiplestate length is > 0
    setMovingAverageParams(prevParams => [...prevParams,
          {
            type:'SMA',
            period:20,
            color:'#FFA500',
          }
    ])
  }
  

  const handleSubmitInstrument = e => {
    console.log(e)
  }

  useEffect(() => {
        symbolListInfo()
    },[])
  const symbolListInfo = () => {
      try {
          MarketDataService.getSymbolList()
                          .then(response => {
                              // console.log(response.data) 
                              setSymbolList(response.data)
                          })
      } catch(error) {
          console.log(error)
      }
  }

  
  return (
    
    <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" size='lg' backdrop='static'>
      <Modal.Header closeButton style={{backgroundColor:'black'}}>
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'white'}}>
          Technical Analysis
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{backgroundColor:'black'}}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="add-instrument-tab" >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="add-instrument-tab">Instrument Selection</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bollingerband">Bollinger Band</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="macd">MACD</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="movingaverage">Moving Average</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pe">Price-To-Earnings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="rsi">Relative Strength Index</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="volume">Volume</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content >
                <Tab.Pane eventKey="add-instrument-tab">
                  <form id='add-instrument-tab' className="item" onSubmit={handleSubmitInstrument}>
                      <div className='instrument-selection'>
                        <IconButton
                          onClick={addNewTickerParams}
                          style = {{color:'white'}}
                        >
                          <AddCircleIcon />
                        </IconButton>
                        <TickerTable
                            workspaceId={workspaceId} 
                            chartParams={chartParams} 
                            layoutId = {layout.id} 
                            mainChartActions={mainChartActions}
                            suggestions={symbolList}
                                      
                        />
                        
                      </div>
                      
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="bollingerband">
                  <form id='bollingerband-tab' className='item' onSubmit={handleSubmitIndicator}>
                      <div id='parameters' className='item-content-2' style={{color:'orange'}}>
                          <h3>Parameters</h3>
                          <label htmlFor='bollingerband-ticker'>Ticker: </label>
                          <select id='bollingerband-ticker' name='ticker' value={formData['macd']?.['ticker'] || analysis['macd']?.['ticker']} onChange={handleChange}>
                              {chartParams.tickerParams.map((item,index)=> (
                                
                                  <option key={index} value={item.ticker}>{item.ticker}</option>
                             
                              ))}
                          </select><br />

                          <label htmlFor='bollingerband-mid-favcolor'>Bollinger Line: </label>
                          <input id='bollingerband-mid-favcolor' type='color' name='bollingerMidColor' defaultValue={chartParams.analysis?.bollingerband?.bollingerMidColor || "#008B8B"} onChange={handleChange}/><br />
                          
                          <label htmlFor='bollingerband-upper-favcolor'>Bollinger Upper Line: </label>
                          <input id='bollingerband-upper-favcolor' type='color' name='bollingerUpperColor' defaultValue={chartParams.analysis?.bollingerband?.bollingerUpperColor ||"#52B2BF"} onChange={handleChange}/><br />

                          <label htmlFor='bollingerband-lower-favcolor'>Bollinger Lower Line: </label>
                          <input id='bollingerband-lower-favcolor' type='color' name='bollingerLowerColor' defaultValue={chartParams.analysis?.bollingerband?.bollingerLowerColor ||"#52B2BF"} onChange={handleChange}/><br />
                          
                          <label htmlFor='bollingerband-period'>Period: </label>
                          <input id='bollingerband-period' name='period' type='number' defaultValue={chartParams.analysis?.bollingerband?.period || 20} onChange={handleChange}/> <br />
                          
                          <label htmlFor='bollingerband-upper-level'>Upper Level: </label>
                          <input id='bollingerband-upper-level' name='upperLevel' type='number' defaultValue={chartParams.analysis?.bollingerband?.upperLevel || 2} onChange={handleChange}/> <br />
                          
                          <label htmlFor='bollingerband-lower-level'>Lower Level: </label>
                          <input id='bollingerband-lower-level' name='lowerLevel' type='number' defaultValue={chartParams.analysis?.bollingerband?.lowerLevel || 2} onChange={handleChange}/> <br />   
                      </div>
                      
                          <button name='bollingerband-update' type="submit" onClick={()=>setSubmitButton('bollingerband-update')}>Update</button>
                          <button name='bollingerband-remove' type="submit" onClick={()=>setSubmitButton('bollingerband-remove')}>Remove</button>
                  
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="macd">
                  <form id='macd-tab' className='item' onSubmit={handleSubmitIndicator}>
                      <div id='parameters' className='item-content-2' style={{color:'orange'}}>
                          <h3>Parameters</h3>
                          <label htmlFor='macd-ticker'>Ticker: </label>
                          <select id='macd-ticker' name='ticker' value={formData['macd']?.['ticker'] || analysis['macd']?.['ticker']} onChange={handleChange}>
                              {chartParams.tickerParams.map((item,index)=> (
                                
                                  <option key={index} value={item.ticker}>{item.ticker}</option>
                             
                              ))}
                          </select><br />

                          <label htmlFor='macd-favcolor'>MACD Line: </label>
                          <input id='macd-favcolor' type='color' name='macdColor' defaultValue={chartParams.analysis?.macd?.macdColor || "#008B8B"} onChange={handleChange}/><br />
                          
                          <label htmlFor='macd-signal-favcolor'>MACD Signal Line: </label>
                          <input id='macd-signal-favcolor' type='color' name='signalColor' defaultValue={chartParams.analysis?.macd?.signalColor ||"#FFA500"} onChange={handleChange}/><br />

                          <label htmlFor='macd-histogram-favcolor'>MACD Histogram: </label>
                          <input id='macd-mean-favcolor' type='color' name='meanColor' defaultValue={chartParams.analysis?.macd?.histogramColor ||"red"} onChange={handleChange}/><br />

                          <label htmlFor='macd-fast-period'>Fast Period Level: </label>
                          <input id='macd-fast-period' name='fastperiod' type='number' defaultValue={chartParams.analysis?.macd?.fastPeriod || 12} onChange={handleChange}/> <br />
                          
                          <label htmlFor='macd-slow-period'>Slow Period Level: </label>
                          <input id='macd-slow-period' name='slowperiod' type='number' defaultValue={chartParams.analysis?.macd?.slowPeriod || 26} onChange={handleChange}/> <br />
                          
                          <label htmlFor='macd-signal-period'>Signal Period Level: </label>
                          <input id='macd-signal-period' name='signalperiod' type='number' defaultValue={chartParams.analysis?.macd?.signalPeriod || 9} onChange={handleChange}/> <br />
                          
                          
                      </div>
                      
                          <button name='macd-update' type="submit" onClick={()=>setSubmitButton('macd-update')}>Update</button>
                          <button name='macd-remove' type="submit" onClick={()=>setSubmitButton('macd-remove')}>Remove</button>
                  
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="movingaverage">
                  <form id='movingaverage' className="item" onSubmit={handleSubmitIndicator}>
                      <div id='parameters' className='item-content-2' style={{color:'orange'}}>
                        <h3>Parameters</h3>
                        <label htmlFor='movingaverage-ticker'>Ticker: </label>
                          <select id='movingaverage-ticker' name='ticker' value={formData['movingaverage']?.['ticker'] || analysis['movingaverage']?.['ticker']} onChange={handleChange}>
                              {chartParams.tickerParams.map((item,index)=> (
                                
                                  <option key={index} value={item.ticker}>{item.ticker}</option>
                             
                              ))}
                          </select><br />
                        <IconButton
                          onClick={addNewMovingAverageParams}
                          style = {{color:'white'}}
                        >
                          <AddCircleIcon />
                        </IconButton>
                        <MovingAverageTable
                            workspaceId={workspaceId} 
                            chartParams={chartParams} 
                            layoutId = {layout.id} 
                            setMovingAverageParams={setMovingAverageParams}
                            movingAverageParams={movingAverageParams}
                                      
                        />
                        
                      </div>
                      <button name='movingaverage-update' type="submit" onClick={()=>setSubmitButton('movingaverage-update')}>Update</button>
                      <button name='movingaverage-remove' type="submit" onClick={()=>setSubmitButton('movingaverage-remove')}>Remove</button>
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="pe">
                  <form id='pe-tab' className='item' onSubmit={handleSubmitIndicator}>
                      <div id='parameters' className='item-content-2' style={{color:'orange'}}>
                          <h3>Parameters</h3>
                          <label htmlFor='pe-ticker'>Ticker: </label>
                          <select id='pe-ticker' name='ticker' value={formData['pe']?.['ticker'] || analysis['pe']?.['ticker']} onChange={handleChange}>
                              {chartParams.tickerParams.map((item,index)=> (
                                
                                  <option key={index} value={item.ticker}>{item.ticker}</option>
                             
                              ))}
                          </select><br />

                          <label htmlFor='pe-favcolor'>PE Line: </label>
                          <input id='pe-favcolor' type='color' name='peColor' defaultValue={chartParams.analysis?.pe?.peColor || "#008B8B"} onChange={handleChange}/><br />
                          
                          <label htmlFor='pe-mean-favcolor'>Mean Line: </label>
                          <input id='pe-mean-favcolor' type='color' name='meanColor' defaultValue={chartParams.analysis?.pe?.meanColor ||"#FFA500"} onChange={handleChange}/><br />
                          
                          <label htmlFor='pe-std-1stLevel'>Standard Deviation-Level 1: </label>
                          <input id='pe-std-1stLevel' name='std1stLevel' type='number' defaultValue={chartParams.analysis?.pe?.std1stLevel || 1} onChange={handleChange}/>
                          <input id='pe-std-1stLevel-favcolor' type='color' name='std1stColor' defaultValue={chartParams.analysis?.pe?.std1stColor ||"#FFA500"} onChange={handleChange}/><br />
                          
                          <label htmlFor='pe-std-2ndLevel'>Standard Deviation-Level 2: </label>
                          <input id='pe-std-2ndLevel' type='number' name='std2ndLevel' defaultValue={chartParams.analysis?.pe?.std2ndLevel || 2} />
                          <input id='pe-std-2ndLevel-favcolor' type='color' name='std2ndColor' defaultValue={chartParams.analysis?.pe?.std2ndColor ||"#FFA500"} onChange={handleChange}/><br />
                          {analysis?.pe &&
                            <>
                              <input id='pe-doSimulation' type='checkbox' name='doSimulation' defaultChecked={chartParams.analysis?.pe?.doSimulation || false} onChange={handleChange} />
                              <label htmlFor="pe-doSimulation"> Do simulation?</label>
                            </>
                          }
                          {(formData?.pe?.doSimulation) && 
                            <SimulationTable
                              workspaceId={workspaceId} 
                              chartParams={chartParams} 
                              layoutId = {layout.id} 
                              setFormData={setFormData}
                              formData={formData}
                            />
                          }

                          <br />
                          <span style={{color:'white'}}>It is currently available to Vietnamese stocks</span>
                      </div>
                      
                          <button name='pe-update' type="submit" onClick={()=>setSubmitButton('pe-update')}>Update</button>
                          <button name='pe-remove' type="submit" onClick={()=>setSubmitButton('pe-remove')}>Remove</button>
                  
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="rsi">
                  <form id='rsi-tab' className='item' onSubmit={handleSubmitIndicator}>
                      <div id='parameters' className='item-content-2' style={{color:'orange'}}>
                          <h3>Parameters</h3>
                          <label htmlFor='rsi-ticker'>Ticker: </label>
                          <select id='rsi-ticker' name='ticker' value={formData['rsi']?.['ticker'] || analysis['rsi']?.['ticker']} onChange={handleChange}>
                              {chartParams.tickerParams.map((item,index)=> (
                                
                                  <option key={index} value={item.ticker}>{item.ticker}</option>
                             
                              ))}
                          </select><br />
                          <label htmlFor='rsi-method'>Smoothing Method: </label>
                          <select id='rsi-method' name='rsi-method'>
                              <option value='simple'>Wilder Smoothing</option>
                              <option value='exponential'>Exponential</option>
                          </select><br />
                          
                          <label htmlFor='rsi-period'>Period: </label>
                          <input id='rsi-period' name='period' type='number' defaultValue={chartParams.analysis?.rsi?.period || 14} onChange={handleChange} /><br />

                          <label htmlFor='rsi-overbought'>Overbought: </label>
                          <input id='rsi-overbought' name='upperRange' type='number' defaultValue={chartParams.analysis?.rsi?.upperRange || 70}  onChange={handleChange}/><br />
                          <label htmlFor='rsi-oversold'>Oversold: </label>
                          <input id='rsi-oversold' type='number' name='lowerRange' defaultValue={chartParams.analysis?.rsi?.lowerRange || 30} onChange={handleChange}/><br />

                          <label htmlFor='rsi-favcolor'>Format color: </label>
                          <input id='rsi-favcolor' type='color' name='color' defaultValue={chartParams.analysis?.rsi?.color || "#FFA500"} onChange={handleChange}/><br />

                          <label htmlFor='rsi-diverconvergence'>Divergence/Convergence</label>
                          <input type='checkbox' id='rsi-diverconvergence' name='isDivergenceConvergence' defaultChecked={chartParams.analysis?.rsi?.isDivergenceConvergence || false} onChange={handleChange}/>
                      </div>
                      
                          <button name='rsi-update' type="submit" onClick={()=>setSubmitButton('rsi-update')}>Update</button>
                          <button name='rsi-remove' type="submit" onClick={()=>setSubmitButton('rsi-remove')}>Remove</button>
                  
                  </form>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="news">
                  <form id='rsi-tab' className='item' onSubmit={handleSubmitIndicator}>
                    
                    <div id='news-tab' className='item' style={{color:'orange'}}>
                        <label htmlFor='rsi-ticker'>Ticker: </label>
                        <select id='news-ticker' name='ticker'>
                            {chartParams.tickerParams.map((item,index)=> (
                              
                                <option key={index} value={item.ticker}>{item.ticker}</option>
                          
                            ))}
                        </select><br />
                        <input type='checkbox' name='enableNews' checked={chartParams.analysis.news.enableNews} onChange={handleChange} />
                        <label htmlFor="news">News</label>
                    </div>
                    <button name='news-update' type="submit" onClick={()=>setSubmitButton('news-update')}>Update</button>
                    <button name='news-remove' type="submit" onClick={()=>setSubmitButton('news-remove')}>Remove</button>
                  </form>
                </Tab.Pane>*/}
                <Tab.Pane eventKey="volume">
                  <form id='rsi-tab' className='item' onSubmit={handleSubmitIndicator}>

                    <div id='volume-tab' className="item" style={{color:'orange'}}>
                        <label htmlFor='volume-ticker'>Ticker: </label>
                        <select id='volume-ticker' name='ticker' value={formData['volume']?.['ticker'] || analysis['volume']?.['ticker']} onChange={handleChange}>
                            {chartParams.tickerParams.map((item,index)=> (
                              
                                <option key={index} value={item.ticker}>{item.ticker}</option>
                          
                            ))}
                        </select><br />
                        
                        <label htmlFor='volume-rising-favcolor'>Rising: </label>
                        <input id='volume-rising-favcolor' type='color' name='risingColor' defaultValue={chartParams.analysis?.volume?.risingColor ||'#c0392b'} onChange={handleChange}/><br />

                        <label htmlFor='volume-histogram-favcolor'>Falling: </label>
                        <input id='volume-falling-favcolor' type='color' name='fallingColor' defaultValue={chartParams.analysis?.volume?.fallingColor ||'#03a678'} onChange={handleChange}/><br />
                    </div>
                    <button name='volume-update' type="submit" onClick={()=>setSubmitButton('volume-update')}>Update</button>
                    <button name='volume-remove' type="submit" onClick={()=>setSubmitButton('volume-remove')}>Remove</button>
                  </form>
                </Tab.Pane> 
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer style={{backgroundColor:'black'}}>
        <Button style={{color:'white'}} onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>

  );
}

export default AnalysisModal