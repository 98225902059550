import React, { useState} from "react";
import {useSelector, useDispatch} from "react-redux"
import {
    AppBar,
    Tabs,
    Tab,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";

import { styled } from "@mui/system";
import PropTypes from 'prop-types'
import Add from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import  StyledEngineProvider  from '@mui/material/StyledEngineProvider';
import DashBoard from "./DashBoard";
import { createNewWorkspace, deleteWorkspace, modifyWorkspaceLabel } from "../../js/actions/dashboardActions";
import { setActiveWorkspace, updateWorkspaceCount} from "../../js/actions/workspaceAction";
import { removeMainChart } from "../../js/actions/mainChartActions";
import { removeOverview } from "../../js/actions/overviewActions";
import { removeSignalWatchList, removeTickerWatchList, removeWatcherLayout } from "../../js/actions/watcherActions";
import { removePriceBoard } from "../../js/actions/priceBoardActions";
import { removeNewsLayout } from "../../js/actions/newsAction";
import AccountMenu from "./AccountMenu";
// import NewFunctionImageList from "../Login/AppVersion/UpdateItems";
const reduxActions = {
    chart:removeMainChart,
    overview: removeOverview,
    watcher:[removeWatcherLayout, removeTickerWatchList, removeSignalWatchList],
    priceboard: removePriceBoard,
    news: removeNewsLayout
}


const TabPanel = (props) => {
    const {children,  value, index, ...other} = props
    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    )
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs) (({theme}) => ({
    height:45,
    background:'#181818', //darkgrey
    "& .MuiTabScrollButton-root":{
        color:'orange'
    }
}));
const StyledTab = styled(Tab) (({theme}) => ({
    minHeight:45,
            // padding:0,
            height:45
}));
const StyledIconButton = styled(IconButton) (({theme, isactive}) => ({
            "&:hover": {
                opacity:1,
                backgroundColor:'grey'
        },
        '& svg': {
            fontSize: 18
        },
        marginLeft: 10,
        fontSize:20,
        padding:0,
        marginTop:0,
        opacity: isactive==="true" ? 1 : 0,
        color: isactive==="true" ? "#00FFFF" : "lightgrey",
}));

const Workspace = (props) => {

    const dispatch = useDispatch()
    const reduxStore = useSelector(state => state)
    const workspaceKeys = Object.keys(reduxStore.dashboard)
    const {activeWorkspace,workspaceCount,isHideWorkspace} = reduxStore.workspace

    const workspaces = workspaceKeys.map(key => { return {id:parseInt(key),label:reduxStore.dashboard[key].workspaceLabel}})
    // console.log(reduxStore)
    const [userInputs, setUserInputs] = useState(workspaces)
    // const [navBarVisible, setNavBarVisible] = useState(true)
    
    const [toggleInput, setToggleInput] =useState(true)    
    // const lastLoginDate = new Date(localStorage.getItem('lastLoginDate'))
    // const releasedAppDate = new Date(localStorage.getItem('releasedAppDate'))
    
    const addTab = () => {
        let newCount = workspaceCount + 1
        dispatch(updateWorkspaceCount(newCount))
        setUserInputs(prevState => [...prevState,{id:newCount,label:`WorkSpace-${newCount}`}])
        dispatch(setActiveWorkspace(newCount))
        dispatch(createNewWorkspace([newCount,`WorkSpace-${newCount}`]))
    }
    
    const handleCloseWorkspace = (event,id) => {
        
        event.stopPropagation() // IMPORTANT prevent the close Click is bound with handleTabChange event 
        if(workspaces.length === 1){
            return; // If you want all tabs to be deleted, then don't check for this case.
        }
        let currIndex = workspaces.findIndex(workspace => workspace.id === parseInt(id))
        let nextIndex = currIndex === 0 ? currIndex + 1 : currIndex - 1
        // get the id of workspace standing behind the deleted workspace
        let prevWorkspaceId = workspaces[nextIndex]['id']
        setUserInputs(prevState => [...prevState.filter(item => item.id !== id)])
        dispatch(setActiveWorkspace(prevWorkspaceId))
        //clean all redux states of available components in closed workspace
        const componentNames = reduxStore.dashboard[id]['items'].map(item => item['component']).filter(item => item !== null)
        // console.log(componentNames)
        for ( let componentName of componentNames ) {
            if (componentName === 'watcher') {
                for (let watcherAction of reduxActions[componentName]) {
                    dispatch(watcherAction([id,NaN]))
                }
            } else {
                dispatch(reduxActions[componentName]([id,NaN]))
            }
        } 
        dispatch(deleteWorkspace(id))
    }

    const handleTabChange = (event, value) => {
        dispatch(setActiveWorkspace(value))
    }
    const handleTabLabelChange = (id, e) => {
        setUserInputs(userInputs.map(userInput => userInput.id === id ? {...userInput,label:e.target.value}
                                                    : userInput))
        // setUserInput(e.target.value)
	}
	const handleKeyDown = (id, e) => {
		if(e.key === 'Enter' || e.key === 'Escape'){
            
            setToggleInput(true)
            dispatch(modifyWorkspaceLabel([id,e.target.value]))
            e.preventDefault()
            e.stopPropagation()
            // console.log(tabList.filter(tab => tab.label === userInput))
			// console.log(e,userInput)
		}
	}
    

    
    return (
        <StyledEngineProvider injectFirst>
                {/* {lastLoginDate <= releasedAppDate && <NewFunctionImageList />}  */}
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                >
                    {!isHideWorkspace &&
                    <Grid item container direction="row" spacing={0} xs={4} sm={6} md={10} lg={12}>
                        <Grid
                            item
                            xs={11} sm={11} md={11} lg={11}
                            // justifyContent="flex-start"
                        >
                            <AppBar position='static'>
                                {props.isAuthenticated ?
                                <div>
                                <StyledTabs
                                    value={activeWorkspace}
                                    onChange={handleTabChange}
                                    TabIndicatorProps={{   
                                        style: {
                                            background:'#FA5A37',
                                            top:0 // this will adjust the position of Tabs' indicator to the top
                                        },
                                        }} 
                                    indicatorColor="primary"
                                    textColor="secondary"
                                    variant="scrollable"
                                    scrollButtons='auto'
                                    // classes={{root:classes.tabsRoot}}
                                >
                                    {
                                        workspaces.map((workspace,index)=>{
                                            // console.log(workspace,userInputs)
                                            return (
                                            <StyledTab
                                                key={workspace.id.toString()}
                                                value={workspace.id}
                                                //add an input into label to allow user to edit the label
                                                label={
                                                    <React.Fragment>
                                                        {/* https://flaviocopes.com/react-edit-doubleclick/ */}
                                                        {toggleInput ? (
                                                            <span 
                                                                onDoubleClick={() => {
                                                                    setToggleInput(false)
                                                                }}
                                                                style={{color:'white'}}
                                                            >{workspace.label}
                                                            </span>
                                                        ) : (
                                                            <input 
                                                                style={{ fontSize: "smaller",border:'none',maxWidth:75, background:'none',color:'white',padding:0}}
                                                                value={userInputs[index].label}
                                                                onChange={(e)=>handleTabLabelChange(workspace.id, e)}
                                                                onKeyDown={(e)=>handleKeyDown(workspace.id,e)}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                }
                                                // classes={{
                                                //     // wrapper: classes.iconLabelWrapper,
                                                //     root:classes.tabRoot
                                                // }}
                                                icon={
                                                    // When clicking the close icon, you're not only executing handleClose 
                                                    // but also the handleChange function. This happens because of the principle 
                                                    // called event bubbling. https://stackoverflow.com/questions/63265780/react-material-ui-tabs-close
                                                    // <React.Fragment>
                                                    // we want to hide the close button when the workspace is inactive or not hovered 
                                                        <div>
                                                            
                                                             
                                                                <StyledIconButton
                                                                    onClick={event => handleCloseWorkspace(event,workspace.id)}
                                                                    isactive={activeWorkspace === workspace.id?"true":"false"}
                                                                >
                                                                    <CloseIcon />
                                                                </StyledIconButton>
                                                                
                                                            {/* <IconButton 
                                                                onClick={event => handleCloseWorkspace(event,workspace.id)}
                                                                // we want to hide the close button when the workspace is inactive or not hovered 
                                                                className={activeWorkspace === workspace.id ? classes.activeIconButton : classes.defaultIconButton} 
                                                            >
                                                                <CloseIcon />
                                                            </IconButton> */}
                                                        </div>
                                                    // </React.Fragment>
                                                }
                                                iconPosition="end"
                                                className="Workspace-tab"
                                                {...a11yProps(workspace.id)}
                                            />
                                            
                                        )})
                                    }
                                </StyledTabs>
                                
                                </div> 
                                : null
                                
                                }
                            </AppBar>
                            
                        </Grid>
                        <Grid
                            item
                            xs={1} sm={1} md={1} lg={1}
                            justifyContent="flex-end"
                        >
                                <div>
                                    <AccountMenu 
                                        logout={props.logout}
                                        changePassword='/update_password/'
                                    />
                                    {/* <Tooltip title="Logout">
                                        <IconButton 
                                            aria-label="logout" 
                                            className="nav-link" 
                                            style={{position:"absolute", right:"10px",borderColor:'white',color:'white', padding:0,margin:0}}
                                            fontSize="large"
                                            onClick={() => props.logout()}
                                        >
                                            <ExitToAppIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Change Password">
                                        <IconButton 
                                            aria-label="updatePassword" 
                                            className="nav-link" 
                                            style={{position:"absolute", right:"60px",color:'white',padding:0}}
                                            fontSize="large"
                                            href="/update_password/"
                                        >
                                            <VpnKeyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip> */}

                                    <Tooltip title="Add new workspace">
                                        <IconButton
                                            variant="outlined"
                                            onClick={addTab}
                                            style={{position:"absolute",color:'white'}}
                                        >
                                            <Add/>
                                        </IconButton>
                                    </Tooltip> 
                                </div>
                        </Grid>
                    </Grid>
                    }
                    <TabPanel value={activeWorkspace} index={activeWorkspace} style={{width:'100%'}}>
                            {reduxStore.dashboard[activeWorkspace] && <DashBoard workspaceId={activeWorkspace} />}
                    </TabPanel> 
                </Grid>
                
        </StyledEngineProvider>
    );
    
}

export default Workspace;