import {useMemo, useState, useEffect} from 'react'
import Table from '../../UniversalComponents/Table';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const MovingAverageTable =(props) => {
   // console.log('priceBoard is rendered')
   // console.log(props.layout.layoutProps)
   const {setMovingAverageParams, movingAverageParams} = props
   const handleMovingAverageParamsChange = (rowIndex,field,value) => {
      setMovingAverageParams(
         movingAverageParams.map((param,index) => index === rowIndex
                                                   ? {...param,[field]:value}
                                                   : param
                                 )  
      )
  }
   const EditableCell = ({
      //   value:initialValue,
      //   row:{index,original},
      //   column: {id},
      //   data: tableData,
      getValue,
        row,
        column,
        table
        
   }) => {
      const initialValue = getValue();
      const [userInput, setUserInput] = useState(initialValue)
      

      const onChange = e => {
         const currentUserInput = e.target.value.trim()
         // console.log(suggestions) 
            
         setUserInput(currentUserInput)
      }

      // it’s called when the user clicks outside of a focused text input.
      const handleBlur = (e) => {
         // if(e.key==='Enter' || e.key==='Escape') {
            
            setUserInput("")
            handleMovingAverageParamsChange(row.index, 'period', userInput)
         // }
      }
      // If the initialValue is changed external, sync it up with our state
      useEffect(() => {
         setUserInput(initialValue)
      }, [initialValue])

      return (
         <div>
               <input value={userInput} onChange={onChange} onBlur={handleBlur} />
               
         </div>
      )
      
   }
   const handleDeleteRow = (rowIndex) => {
      setMovingAverageParams(
         movingAverageParams.filter((param, index) => index !== rowIndex)
      )
   }
   const removeStyle = {
    //   position: "absolute",
    //   right: "2px",
    //   top: 0,
      cursor: "pointer",
      color:"white",
   };
   const columns = useMemo(
      () => [
   {  
      id:"type",
      header:'Type',
      accessorKey:'type',
      cell:({cell}) => {
         return (
         <div>
            <select
               value={cell.getValue()} 
               onChange = {(e)=>{handleMovingAverageParamsChange(cell.row.index,'type',e.target.value)}}
               name="chartType"
            >
            
               <option value="SMA">Simple</option>
               <option value="EMA">Exponential</option>
            
            </
            select>
         </div>
         )
      }
   },
   {
      id:"period",
      header:'Period',
      accessorKey:'period',
      cell: EditableCell
   },
   
   {  id:"color",
      header:'Color',
      accessorKey:'color',
      cell:({cell}) => {
         
         return (
         <div>
            <input  
               type='color' 
               value={cell.getValue()}
               onChange = {(e) => handleMovingAverageParamsChange(cell.row.index,'color',e.target.value)} 
            />
            
         </div>
         )
      }
   },
   {
      id:"actions",
      header:'Action',
      accessorKey:'actions',
      cell: ({cell}) => {
            
            // const ticker = props.row.values.ticker
            // const chartType = props.row.values.chartType
            return (
               <div> 
                  <div>
                        <IconButton 
                           aria-label="delete" 
                           style={removeStyle} 
                           onClick={() => handleDeleteRow(cell.row.index)}
                        >
                           <DeleteIcon fontSize="small" />
                        </IconButton>

                        
                  </div>
                              
               </div>
            );
      }, 
   },
   
   ],
   [movingAverageParams]
   )


    
    
   return (
      <div className="container" >
         <div className="row">
               <Table columns={columns} data={movingAverageParams} layout={null}/>
         </div>
      </div>
   )
}


export default MovingAverageTable