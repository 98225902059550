const CREATE_WATCHER = 'CREATE_WATCHER'
const CREATE_TICKER_WATCHLIST = 'CREATE_TICKER_WATCHLIST'
const CREATE_SIGNAL_WATCHLIST = 'CREATE_SIGNAL_WATCHLIST'
// const ADD_NEW_WATCHER_LAYOUT = 'ADD_NEW_WATCHER_LAYOUT'
const REMOVE_WATCHER_LAYOUT = 'REMOVE_WATCHER_LAYOUT'
const UPDATE_SUBLAYOUT_SIZE = 'UPDATE_SUBLAYOUT_SIZE' //action when user change the size of main Layout, the header and simple chart size will automatically change following
const UPDATE_WATCHER_LAYOUTS_CHANGE = 'UPDATE_WATCHER_LAYOUTS_CHANGE' // action when user change the size of signalItem
const ADD_SIGNALED_TICKER = 'ADD_SIGNALED_TICKER'
const REFRESH_WATCHER_GRID = 'REFRESH_WATCHER_GRID'
// const INITIATE_TICKER_WATCHLIST = 'INITIATE_TICKER_WATCHLIST'
const REMOVE_TICKER_WATCHLIST = 'REMOVE_TICKER_WATCHLIST'
const ADD_TICKER_INTO_WATCHLIST = 'ADD_TICKER_INTO_WATCHLIST'
const REMOVE_TICKER_FROM_WATCHLIST = 'REMOVE_TICKER_FROM_WATCHLIST'
// const INITIATE_SIGNAL_WATCHLIST = 'INITIATE_SIGNAL_WATCHLIST'
const REMOVE_SIGNAL_WATCHLIST = 'REMOVE_SIGNAL_WATCHLIST' 
const ADD_SIGNAL_INTO_WATCHLIST = 'ADD_SIGNAL_INTO_WATCHLIST' 
const REMOVE_SIGNAL_FROM_WATCHLIST = 'REMOVE_SIGNAL_FROM_WATCHLIST'
const UPDATE_SIGNAL_LIST = 'UPDATE_SIGNAL_LIST'

export const createWatcher = payload => {
	return {
		type:CREATE_WATCHER,
		payload
	}
}
export const createTickerWatchList = payload => {
	return {
		type:CREATE_TICKER_WATCHLIST,
		payload
	}
}
export const createSignalWatchList = payload => {
	return {
		type:CREATE_SIGNAL_WATCHLIST,
		payload
	}
}
// export const addNewWatcherLayout = payload => {
// 	return {
// 		type:ADD_NEW_WATCHER_LAYOUT,
// 		payload
// 	}
// }
export const removeWatcherLayout = payload => {
	return {
		type:REMOVE_WATCHER_LAYOUT,
		payload
	}
}
export const updateSubLayoutSize = payload => {
	return {
		type:UPDATE_SUBLAYOUT_SIZE,
		payload
	}
}
export const updateWatcherLayoutsChange = payload => {
	return {
		type:UPDATE_WATCHER_LAYOUTS_CHANGE,
		payload
	}
}
export const addSignaledTicker = payload => {
	return {
		type:ADD_SIGNALED_TICKER,
		payload
	}
}

export const refreshWatcherGrid = payload => {
	return {
		type:REFRESH_WATCHER_GRID,
		payload
	}
}
// export const initiateTickerWatchList = payload => {
// 	return {
// 		type:INITIATE_TICKER_WATCHLIST,
// 		payload
// 	}
// }
export const removeTickerWatchList = payload => {
	return {
		type:REMOVE_TICKER_WATCHLIST,
		payload
	}
}
export const addTickerIntoWatchList = payload => {
	return {
		type:ADD_TICKER_INTO_WATCHLIST,
		payload
	}
}

export const removeTickerFromWatchList = payload => {
	return {
		type:REMOVE_TICKER_FROM_WATCHLIST,
		payload
	}
}

// export const initiateSignalWatchList = payload => {
// 	return {
// 		type:INITIATE_SIGNAL_WATCHLIST,
// 		payload
// 	}
// }

export const removeSignalWatchList = payload => {
	return {
		type:REMOVE_SIGNAL_WATCHLIST,
		payload
	}
}

export const addSignalIntoWatchList = payload => {
	return {
		type:ADD_SIGNAL_INTO_WATCHLIST,
		payload
	}
}

export const removeSignalFromWatchList = payload => {
	return {
		type:REMOVE_SIGNAL_FROM_WATCHLIST,
		payload
	}
}

export const updateSignalList = payload => {
	return {
		type:UPDATE_SIGNAL_LIST,
		payload
	}
}

