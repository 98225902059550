import {useState} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import {useDispatch} from 'react-redux';

import EconomicModal from './EconomicModal/EconomicModal';

import {toggleLayoutType, deleteEconomicSubLayout, updateEconomicSubLayoutPeriodRange} from '../../../js/actions/macroeconomicActions';



const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
}));

const PeriodButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  // flexDirection: 'row',
  // flexWrap: 'wrap',
  // justifyContent: 'flex-start',
  // alignItems: 'center',
  padding: 0,
  margin:0,
  position:'absolute',
  top:2,
  height:15,
  // gap: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  border: 'unset !important',
  '& .MuiToggleButtonGroup-grouped': {
    // border: '1px solid !important',
    // borderRadius: theme.spacing(2),
    borderColor: 'unset',
    color: theme.palette.text.primary,
  },
  '& .MuiToggleButton-standard': {
      color: '#30a39c'
  }
}));

const PeriodButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#9370DB'
  }
});

const EconomicHeader = (props) => {
    const {workspaceId, mainLayoutId, subLayoutIdx, layoutType, 
         title, inputRef, periodRange, isModalOpen } = props
    const dispatch = useDispatch();
    
    const [modalShow, setModalShow] = useState(isModalOpen);
    


    const handleChange = (event) => {
        let updatedLayoutType = event.target.checked ? 'table' : 'chart';
        
        dispatch(toggleLayoutType([workspaceId, mainLayoutId, subLayoutIdx, updatedLayoutType]));
    }

    const handleDelete = (subLayoutIdx) => {
      dispatch(deleteEconomicSubLayout([workspaceId, mainLayoutId, subLayoutIdx]));
    }

    const handlePeriodSelector = (event,value) => {
      dispatch(updateEconomicSubLayoutPeriodRange([workspaceId, mainLayoutId, subLayoutIdx, value]));
    }
    
    
    return (
        
        <div>
            <div
                style={{
                    display: 'flex',
                    height:'20px',
                    backgroundColor:'#43464B'
                }}
            >
                {/* SWITCH BUTTON BETWEEN CHART AND TABLE */}
                <div onMouseDown={e=> e.stopPropagation()}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography style={layoutType == "chart" ? {color:'white'}: null}>Chart</Typography>
                        <AntSwitch 
                            onChange={handleChange}
                            checked={layoutType =='chart' ? false: true} 
                            inputProps={{ 'aria-label': 'ant design' }} 
                        />
                        <Typography style={layoutType == "table" ? {color:'white'}: null}>Table</Typography>
                    </Stack>
                </div>

                {/* PERIOD RANGE SELECTOR  */}
                
                <div onMouseDown={e=> e.stopPropagation()}>
                    <Tooltip title= 'Select Period'>
                      <PeriodButtonGroup
                        value={periodRange}
                        // style={{height:10, top:2, borderColor:'white'}}
                        exclusive
                        size='small'
                        onChange={handlePeriodSelector}
                        aria-label="text alignment"
                      >
                        <PeriodButton value="95" style={{color:"white"}}>
                          3M
                        </PeriodButton>
                        <PeriodButton value="185" style={{color:"white"}}>
                          6M
                        </PeriodButton>
                        <PeriodButton value="366" style={{color:"white"}}>
                          1Y
                        </PeriodButton>
                        <PeriodButton value="730" style={{color:"white"}}>
                          2Y
                        </PeriodButton>
                        <PeriodButton value="1830" style={{color:"white"}}>
                          5Y
                        </PeriodButton>
                      </PeriodButtonGroup>
                    </Tooltip>
                </div>
                
                {/* SETTINGS */}
                <div onMouseDown={e=> e.stopPropagation()}>
                    <Tooltip title= {layoutType == 'chart' ? 'Chart Setting' : 'Table Setting'}>
                        <IconButton
                            onClick={() => setModalShow(true)}
                            style={{position:"absolute", right:"40px",top:2,borderColor:'white',color:'white', padding:0,margin:0}}
                            size='small'
                            sx={{ml : 2}}
                            // aria-controls = {open ? 'account-menu' : undefined}
                            aria-haspopup = "true"
                            // aria-expanded = {open ? 'true' : undefined}
                        >
                            <Settings fontSize='small' />
                        </IconButton>
                    </Tooltip> 
                </div>
                <div onMouseDown={e=> e.stopPropagation()}>
                    <Tooltip title='Delete'>
                        <IconButton
                            onClick={() => handleDelete(subLayoutIdx)}
                            style={{position:"absolute", right:"10px",top:2,borderColor:'white',color:'white', padding:0,margin:0}}
                            size='small'
                            sx={{ml : 2}}
                            // aria-controls = {open ? 'account-menu' : undefined}
                            aria-haspopup = "true"
                            // aria-expanded = {open ? 'true' : undefined}
                        >
                            <DeleteForeverRoundedIcon fontSize='small' />
                        </IconButton>
                    </Tooltip> 
                </div>
            </div>
            {modalShow && 
                <div onMouseDown = {e => e.stopPropagation()}>
                    <EconomicModal 
                        workspaceId={workspaceId}
                        mainLayoutId={mainLayoutId}
                        subLayoutIdx={subLayoutIdx}
                        // mainChartActions={mainChartActions}
                        // mainLayout = {props.layout}
                        // subLayout={chartParams}  
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            }
        </div>

    )
}
export default EconomicHeader;