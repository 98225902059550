import {treemap, hierarchy, select, 
        scaleThreshold, selectAll
    } from 'd3'


const drawMarketMap = ({current,chartId, data, width, height}) => {
    // const svg = select(current)
    selectAll(`#${chartId} > g`).remove()
    const margin = {left: 50, right: 50, bottom: 30, top: 30}
    const svg = select(current)
                        .attr('width', width + margin.left + margin.right)
                        .attr('height', height+ margin.bottom + margin.top)
                        .append('g')
                        .style('border', '1px solid white')
                        .attr('transform', `translate(0, ${-margin.top+15})`)
    // console.log(data)
    //give the data to this cluster layout
    var tooltip = select('body').append('div')
                                .attr('class',`tooltip-${chartId}`)
                                .style('position','absolute')
                                .style('z-index','10')
                                .style('visibility','hidden')
                                .style('padding','10px')
                                .style('background','rgba(0,0,0,0.6)')
                                .style('border-radius','4px')
                                .style('color','#fff')

    var root = hierarchy(data).sum(d => Math.abs(parseFloat(d['Percent'])))
                                .sort((a,b)=>b.value-a.value) // and sort them in descending order 
    // console.log(root.leaves())
    //initialize treemap
    treemap()
        .size([width+ margin.left + margin.right, height+ margin.bottom + margin.top])
        .paddingTop(margin.top-15)
        .paddingRight(7)
        .paddingInner(2)
        (root);
    // const sectorDomain = data['children'].map(d => d['sector'])
    // console.log(sectorDomain)
    
    const opacity = scaleThreshold()
                    .domain([1, 2.5, 4, 5.5, 6]) //rules : <1 : .2 ; 1 to 2.5 : .4 ; ... ; >6 : 1
                    .range([.2, .4, .6, .8, 1])

    //select nodes
    var nodes = svg.selectAll('rect')
                    .data(root.leaves())

    //animate new additions
    // nodes
    //     .transition()
    //     .attr('x', d => d.x0)
    //     .attr('y', d => d.y0)
    //     .attr('width', d=> d.x1 - d.x0)
    //     .attr('height', d => d.y1 - d.y0)
    //     .style('opacity', d => opacity(d.data.value))
    //     .style('fill', d => color(d.parent.data.name))
                    
    // draw rectangles
    nodes.enter()
        .append('rect')
        .attr('class',d =>`child-${chartId}-${d.parent.data['sector']}`)
        .attr('x', d => d.x0)
        .attr('y', d => d.y0)
        .attr('width', d => d.x1 - d.x0)
        .attr('height', d => d.y1 - d.y0)
        .style('stroke', 'black')
        .style('fill', d => parseFloat(d.data['Percent']) > 0 ? 'green' : 'red')
        .style('opacity', d => opacity(Math.abs(parseFloat(d.data['Percent']))))
        .on('mouseover', function(event, selectedData) {
            
            // select(this).style('stroke', 'yellow')
            // console.log(selectedData)
            select(this.parentNode)
                .selectAll('rect')
                .filter(d=> d.parent.data['sector'] ===selectedData.parent.data['sector'])
                .style('stroke','yellow')
            var positionX =  selectedData.parent.x0
            var positionY = selectedData.parent.y0
            var width = selectedData.parent.x1
            var height = selectedData.parent.y1
            // console.log(width)
            // select(`#${chartId} > g`).enter()
            //                         .append('rect')
            //                         .attr('class', `highlightRect-${chartId}`)
            //                         .attr('x', positionX)
            //                         .attr('y',positionY)
            //                         .attr('width', width)
            //                         .attr('height', height)
            //                         .style('stroke','yellow')
            // console.log(select(`.highlightRect-${chartId}`))
            var childrenData = selectedData.parent.data.children
            var tableHtml = ""
            for(let i=0; i< childrenData.length; i++ ) {
                if (parseFloat(childrenData[i]['Percent']) > 4 || parseFloat(childrenData[i]['Percent']) < -4) {
                    tableHtml += `<tr bgcolor="orange">
                                    <td>${childrenData[i]['symbol']}</td>
                                    <td>${childrenData[i]['Price']}</td>
                                    <td>${childrenData[i]['Percent']}</td>
                                </tr>`
                } else {
                    tableHtml += `<tr>
                                    <td>${childrenData[i]['symbol']}</td>
                                    <td>${childrenData[i]['Price']}</td>
                                    <td>${childrenData[i]['Percent']}</td>
                                </tr>`
                }
            }
            tooltip
                .html(
                    `
                    <h5>${selectedData.parent.data['sector']}</h5>
                    <table cellspacing="7" cellpadding="3" border="1">
                        <tr>
                            <th>Symbol</th>
                            <th>Price</th>
                            <th>Percent</th>
                        </tr>
                        ${tableHtml}
                    </table>`
                )
                .style('visibility','visible')
            // console.log(select(this.parentNode).selectAll(`.child-${chartId}-${d.parent.data['sector']}`))
        })
        .on('mousemove', function(event){
            tooltip
                .style('top',event.y - 10 + 'px')
                .style('left',event.x + 10 + 'px')
        })
        .on('mouseout',function() {
            tooltip.html(``).style('visibility','hidden')
            select(this.parentNode)
                .selectAll('rect')
                .style('stroke','black')
        })

    //select node titles
    // var nodeText = svg.selectAll('text')
    //                     .data(root.leaves())
    
    // add the text
    nodes.enter()
            .append('text')
            .attr('x', d=> (d.x0 + (d.x1-d.x0)/2)) // center the text
            .attr('y', d => d.y0 + (d.y1 - d.y0)/2) //center the text
            .attr("text-anchor", "middle")
            .text( d => d.data.label)
            
            .attr('font-size',d => `${(d.x1 - d.x0)/7}px`)
            .attr('fill','white')//d => parseInt(d.data['Percent']) > 0 ? 'green' : 'red')
    nodes.exit().remove()
    // select node titles
    // var nodeVals = svg.selectAll('vals')
    //                     .data(root.leaves())
    // add the values
    // nodes.enter()
    //         .append('text')
    //         .attr('x', d=> d.x0 + 5)
    //         .attr('y', d => d.y0 + 35)
    //         .text(d => d.data['Price'])
    //         .attr('font-size', d => `${(d.x1 - d.x0)/10}px`)
    //         .attr('fill', 'white')
    // add the parent node titles
    var nodesTitle = svg.selectAll('titles')
        .data(root.descendants().filter(d => d.depth === 1))

        .enter()
        .append('text')
        .attr('x', d => d.x0)
        .attr('y', d => d.y0 + 10)
        .attr('width',d=> d.x1 - d.x0)
        .text( d => d.data['sector'].toUpperCase())
        
        .attr('font-size', '12px')
        .attr('fill', 'white')
        .call(dotme)
        // .on('mouseover', function(event,selectedData){
        //     console.log(selectedData)
        // })
    nodesTitle.exit().remove() 
    // add the chart heading
    // svg.append('text')
    //     .attr('x', 0)
    //     .attr('y', 14) // +20 to adjust position (lower)
    //     .text('Three group leaders and 14 employees')
    //     .attr('font-size', '19px')
    //     .attr('fill', 'grey')
    
    function dotme(text) {
        text.each(function() {
            
            var text = select(this);
            
            var words = text.text().split(/\s+/);
            
            var ellipsis = text.text('').append('tspan').attr('class', 'elip').text('...');
            var width = parseFloat(text.attr('width')) - ellipsis.node().getComputedTextLength();
            var numWords = words.length;
            // console.log(text.attr('width'))
            var tspan = text.insert('tspan', ':first-child').text(words.join(' '));
            
            // Try the whole line
            // While it's too long, and we have words left, keep removing words
            // console.log(tspan.node(),tspan.node().getComputedTextLength(), width) 
            while (tspan.node().getComputedTextLength() > width && words.length) {
                
                words.pop();
                tspan.text(words.join(' '));
            }
            
            if (words.length === numWords) {
                ellipsis.remove();
            }
        });
    }
}
export default drawMarketMap