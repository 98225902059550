import http from "../http-common";
import axios from 'axios'
import * as settings from '../settings'
import getCookie from './csrftoken';

const getHistoricalPrice = ticker => {
    return http.get(`/history/${ticker.toLowerCase()}`)
};

const getTickerOverview = ticker => {
    return http.get(`/tickerOverview/${ticker.toLowerCase()}`)
}

const getHistoricalPrice1 = (tickerList) => {
    const data = []
    tickerList.forEach(ticker => data.push(http.get(`/history/${ticker.toLowerCase()}`)))
    //console.log(data)
    return axios.all(data)
};
const getLatestPriceInfo = (tickerList) => {
    const data = []
    tickerList.forEach(ticker => data.push(http.get(`/quote/${ticker.toLowerCase()}`)))
    
    return axios.all(data) 
}
const getNews = ticker => {
    return axios.get(`https://s.cafef.vn/Ajax/Events_RelatedNews_New.aspx?symbol=${ticker}&PageIndex=1&PageSize=1000&Type=2`)
}

const getHistoricalPriceAndNews = ticker => {
    const requestPrice = http.get(`/history/${ticker.toLowerCase()}`)
    const requestNews = axios.get(`https://s.cafef.vn/Ajax/Events_RelatedNews_New.aspx?symbol=${ticker}&PageIndex=1&PageSize=1000&Type=2`)
    return axios.all([requestPrice, requestNews])
}

const getSymbolList = () => {
    return http.get('/symbols')
}

const getSignalList = () => {
    return http.get('/signals')
}

const getNewsSources = () => {
    return http.get('/newsSources')
}

const getSignal = (signalFormData) => {
    const token = localStorage.getItem('token')
    const csrftoken = getCookie('csrftoken');

    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken':csrftoken
        } 
    let url = `${settings.API_SERVER}/api/signalCheck`
    let method ='post'
    let config = {headers,method,url,data:signalFormData}
    return axios(config)
}

const getSignalSeries = (signalFormData) => {
    const token = localStorage.getItem('token')
    const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
        } 
    let url = `${settings.API_SERVER}/api/signalSeries`
    let method ='post'
    let config = {headers,method,url,data:signalFormData}
    return axios(config)
}

const getIndicatorSeries = (formData) => {
    const token = localStorage.getItem('token')
	const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
        } 
    let url = `${settings.API_SERVER}/api/indicatorSeries`
    let method ='post'
    let config = {headers,method,url,data:formData}

    return axios(config)
}
const getFinancialStatementSeries = (formData) => {
    const token = localStorage.getItem('token')
	const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
        } 
    let url = `${settings.API_SERVER}/api/financialStatementSeries`
    let method ='post'
    let config = {headers,method,url,data:formData}
    return axios(config)
}
const getCompanyPerformanceInCurrentYear = ticker => {
    return http.get(`/companyPerformanceInCurrentYear/${ticker.toLowerCase()}`)
};
const getNews2 = (formData) => {
    const token = localStorage.getItem('token')
	const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
        } 
    let url = `${settings.API_SERVER}/api/news`
    let method ='post'
    let config = {headers,method,url,data:formData}
    return axios(config)
}

const getMarketMapData = () => {
    return http.get('/marketMapData')
}

const getMarketResearch = (formData) => {

    const token = localStorage.getItem('token')
    const csrftoken = getCookie('csrftoken')
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
    }
    let url = `${settings.API_SERVER}/api/documents/research`
    let method = 'post'
    let config = {headers, method,url, data:formData}
    return axios(config)
}
const getMacroeconomicIndicatorGenerals = (formData) => {
    const token = localStorage.getItem('token');
    console.log(token)
    const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
    }
    let url = `${settings.API_SERVER}/api/macroeconomicIndicatorGenerals`;
    let method = 'post';
    let config = {headers, method,url, data:formData};
    return axios(config)
}
// get Macroeconomic data
const getMacroeconomicData = (formData) => {
    const token = localStorage.getItem('token');
    console.log(token)
    const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-type":"application/json",
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
    }
    let url = `${settings.API_SERVER}/api/macroeconomic`;
    let method = 'post';
    let config = {headers, method,url, data:formData};
    return axios(config)
}

const postResearchReport = (data) => {
    var formData = new FormData()
    formData.append('title', data.title)
    formData.append('doc_file', data.research_file)
    formData.append('research_source', data.research_source)
    formData.append('research_abstract',data.research_abstract)
    formData.append('tag_codes', data.tag_codes)
    const token = localStorage.getItem('token');
    const csrftoken = getCookie('csrftoken');
    let headers = {
        "Content-Type":"multipart/form-data", // important
        'Authorization':`Token ${token}`,
        'X-CSRFToken': csrftoken
    }
    let url = `${settings.API_SERVER}/upload/signed-url`
    let method='post'
    let config = {headers, method, url, data: formData}
    return axios(config)
}

export default {
    getHistoricalPrice,
    getHistoricalPrice1,
    getLatestPriceInfo,
    getNews,
    getHistoricalPriceAndNews,
    getSymbolList,
    getSignalList,
    getNewsSources,
    getSignal,
    getSignalSeries,
    getIndicatorSeries,
    getFinancialStatementSeries,
    getCompanyPerformanceInCurrentYear,
    getNews2,
    getTickerOverview,
    getMarketMapData,
    getMarketResearch,
    getMacroeconomicIndicatorGenerals,
    getMacroeconomicData,
    postResearchReport,
    
};