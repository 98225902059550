import React, {useState, useRef, useEffect} from 'react'
// import { useDeepCompareEffect } from 'react-use'
import {useSelector} from 'react-redux'
import * as DrawFinancialChart from './DrawFinancialChart'
import MarketDataService from '../../../../services/MarketService'
import { Button } from '@mui/material'
import DrawText from './DrawText'
// import {selectAll} from 'd3'
const FinancialChart = (props) => {
    // console.log('financial chart renderred')
	const {workspaceId, mainLayoutId} = props
	const d3ContainerBalanceSheet = useRef(null)
	const d3ContainerIncomeStatement = useRef(null)
	const reduxStore = useSelector(state => state)
    const chartParams = reduxStore.overview[workspaceId][mainLayoutId]
    const ticker = chartParams.tickerParams[0]['ticker']
    const [reportTermTypeIS, setReportTermTypeIS] = useState(1)
    const [reportTermTypeBS, setReportTermTypeBS] = useState(1)

    const [chartTypeIS, setChartTypeIS] = useState('barChart')
    const [chartTypeBS, setChartTypeBS] = useState('barChart')

    const [timeseriesIS, setTimeseriesIS] = useState([])
    const [timeseriesBS, setTimeseriesBS] = useState([])
    const [performanceInCurrentYearData, setPerformanceInCurrentYear] = useState(null)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (ticker) {
            // console.log(reportTermTypeIS)
            let formData = {
                ticker:ticker,
                reportType:'KQKD',
                reportTermType:reportTermTypeIS,
                // activeRatios:['Gross profit margin']
            }
    
            MarketDataService.getFinancialStatementSeries(formData).then(
                                response => {
                                    console.log(response.data)
                                    setTimeseriesIS([...response.data])
                                    setLoading(false)
                                }
                            ).catch((error) =>
                                DrawText(`IncomeStatementChart-${mainLayoutId}`, 'Income Statement')
                            )
            if (reportTermTypeIS === 1) {
                MarketDataService.getCompanyPerformanceInCurrentYear(ticker)
                                .then((response) => {
                                    setPerformanceInCurrentYear(response.data)

                })
            } else {
                setPerformanceInCurrentYear(null)
            }
        }
    },[reportTermTypeIS,ticker])
    useEffect(() => {
        if (ticker) {
            let formData = {
                ticker:ticker,
                reportType:'CDKT',
                reportTermType:reportTermTypeBS,
            }
    
            MarketDataService.getFinancialStatementSeries(formData).then(
                                response => {
                                    setTimeseriesBS([...response.data])
                                    setLoading(false)
                                }
                            ).catch((error) =>
                                
                                DrawText(`BalanceSheetChart-${mainLayoutId}`, 'Balance Sheet')
                            )
        }
    },[reportTermTypeBS,ticker])
    useEffect(() => {
        // console.log(timeseriesIS)
        if (timeseriesIS.length > 0) {
            DrawFinancialChart[chartTypeIS](timeseriesIS,`IncomeStatementChart-${mainLayoutId}`,'IncomeStatement',performanceInCurrentYearData)
        }
    },[timeseriesIS,chartTypeIS,performanceInCurrentYearData])
    useEffect(() => {
        if (timeseriesBS.length > 0) {
            DrawFinancialChart[chartTypeBS](timeseriesBS,`BalanceSheetChart-${mainLayoutId}`,'BalanceSheet')
        }
    },[timeseriesBS,chartTypeBS])

	return (
		<div className="flex-container">
			<div className="flex-item">
                <div>
                    <Button 
                        variant='contained' 
                        disabled={reportTermTypeIS === 2 ? true : false}
                        size='small'
                        onClick={() => setReportTermTypeIS(2)}
                    >
                        Quarter
                    </Button>
                    <Button 
                        variant='contained' 
                        disabled={reportTermTypeIS === 1 ? true : false}
                        size='small'
                        onClick={() => setReportTermTypeIS(1)}
                    >
                        Annual
                    </Button>
                    <select 
                        value={chartTypeIS} 
                        onChange={(e)=>setChartTypeIS(e.target.value)}
                        style={{position:'relative',left:300}}
                    >
                        <option value='barChart'>Value</option>
                        <option value='lineChart'>YoY %Growth</option>
                    </select>
                </div>
				<div ref={d3ContainerIncomeStatement}>
					{loading &&  <span style={{color:'white'}}>Loading...</span>}
					<svg id={`IncomeStatementChart-${mainLayoutId}`} />
				</div>
                <div>
                    <Button 
                        variant='contained' 
                        disabled={reportTermTypeBS === 2 ? true : false}
                        size='small'
                        onClick={() => setReportTermTypeBS(2)}
                    >
                        Quarter
                    </Button>
                    <Button 
                        variant='contained' 
                        disabled={reportTermTypeBS === 1 ? true : false}
                        size='small'
                        onClick={() => setReportTermTypeBS(1)}
                    >
                        Annual
                    </Button>
                    <select 
                        value={chartTypeBS} 
                        onChange={(e)=>setChartTypeBS(e.target.value)}
                        style={{position:'relative',left:300}}
                    >
                        <option value='barChart'>Value</option>
                        <option value='lineChart'>YoY %Growth</option>
                    </select>
                </div>
                <div ref={d3ContainerBalanceSheet}>
					{loading &&  <span style={{color:'white'}}>Loading...</span>}
					<svg id={`BalanceSheetChart-${mainLayoutId}`} />
				</div>
			</div>
		</div>

    	)
}
export default FinancialChart