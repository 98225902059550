import {Responsive as ResponsiveReactGridLayout} from 'react-grid-layout';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {useSelector,useDispatch} from 'react-redux';
import _ from "lodash";

import {useState} from "react";
// import MarketDataService from "../../../services/MarketService";
import {addNewEconomicSubLayout ,updateEconomicSubLayoutProps, addNewEconomicTemplate} from "../../../js/actions/macroeconomicActions";

import MacroeconomicTable from "./Table/MacroeconomicTable";
import EconomicChart from "./Chart/EconomicChart";
import EconomicHeader from "./EconomicHeader";

import IconButton from '@mui/material/IconButton';
// import RefreshIcon from '@mui/icons-material/Refresh';
import AddchartIcon from '@mui/icons-material/Addchart';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import {styled} from '@mui/system';

import { Tooltip } from '@mui/material';
import { refreshStyle } from '../../UniversalComponents/cssStyle/iconStyle';

const StyledDiv = styled('div') (({theme}) => ({
    overflow:'auto',
    '&::-webkit-scrollbar': {
        width: "8px",
        height: "8px",
    },
    //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
    '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.3)",
        'border-radius': "10px",
        'background': 'rgba(0,0,0,0.05)'
    },
    '&::-webkit-scrollbar-thumb': {
        'border-radius': '10px',
        'background': 'linear-gradient(left, #999, #888)',
        '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.8)',
    },
    // '&::-webkit-scrollbar-thumb':{
    //     'background': 'linear-gradient(left, #888, #777)'
    // },
  }));

const Macroeconomic = (props) => {
    const {id, layoutProps} = props.layout;
    const dispatch = useDispatch();

    const workspaceId = props.workspaceId;
    const {currentBreakpoint, currentCols} = props.mainBreakpoint;
    const mainLayoutProp = layoutProps;
    //mainLayout Height
    const height = (layoutProps.h -1) * 30;
    const mainLayoutWidth = window.innerWidth * layoutProps.w / currentCols - 100
    // console.log(height, mainLayoutWidth)
    const storedMacroeconomicState = useSelector(state => state['macroeconomic'][workspaceId][id]['items']);
    const newCounter = useSelector(state => state['macroeconomic'][workspaceId][id]['newCounter']);
    
    // get values in array of objects -> [['a','b']['c']['d']] -> flatten -> ['a','b','c','d']
    // const indicators = storedMacroeconomicState.map(state => state['indicators']).flat();
    // const childLayoutIds = storedMacroeconomicState.map(state => state['layoutProps']['i']);

    let listOfsubLayoutProps = storedMacroeconomicState.map(state=> ({...state.layoutProps}))
    let subLayoutHeights = storedMacroeconomicState.map(state=> (state.layoutProps['h']-1) * props.rowHeight)

    const [openTemplateModal, setOpenTemplateModal] = useState(storedMacroeconomicState.length === 0);
    const [template, setTemplate] = useState('Vietnam')
    
    
    //CHART
    const generateDOM = () => {
        // const layout = generateLayout();
        return _.map(storedMacroeconomicState, function (state,idx) {
            
            let {layoutProps, layoutType, indicators, countries, sources, periodRange ,chartParams} = state;
            
            // let subLayoutId = state.id;
            // const mins = [layoutProps.minW, layoutProps.minH],
            //         maxes = [layoutProps.maxW, layoutProps.maxH];
            
            if (indicators.length > 0) {
                return (
                    <div key={layoutProps.i} data-grid={layoutProps}>
                        <div>
                            <EconomicHeader 
                                workspaceId={workspaceId}
                                mainLayoutId={id}
                                subLayoutIdx={idx} 
                                subLayoutProps={layoutProps}
                                layoutType={layoutType}
                                periodRange={periodRange}
                                isModalOpen={false}
                            />
                        </div>
                        {layoutType === 'table' ? 
                        
                            <MacroeconomicTable 
                                        key={layoutProps.i}
                                        workspaceId={workspaceId} 
                                        mainLayoutId={id} 
                                        subLayoutProps={layoutProps} 
                                        subLayoutCols={props.cols} 
                                        width={window.innerWidth}
                                        indicators={indicators}
                                        countries={countries}
                                        sources={sources}
                                        periodRange={periodRange}
                                        // dataset={timeSeries[layoutProps.i]}
                            />
                        :
                        
                            <EconomicChart 
                                        key={layoutProps.i}
                                        workspaceId={workspaceId} 
                                                mainLayoutId={id}
                                                mainLayoutProp = {mainLayoutProp}
                                                subLayoutIdx = {idx} 
                                                subLayoutProps={layoutProps} 
                                                subLayoutCols={currentCols}
                                                subLayoutHeight = {subLayoutHeights[idx]}
                                                mainLayoutWidth={mainLayoutWidth}
                                                // dataset={timeSeries[layoutProps.i]}
                                                chartParams={chartParams}
                                                indicators={indicators}
                                                countries={countries}
                                                sources={sources}
                                                periodRange={periodRange}
                            />
                        }
                    </div>
                )
                
            } else {
                return (
                    <div key={layoutProps.i} data-grid={layoutProps}>
                            <div>
                                <EconomicHeader 
                                    workspaceId={workspaceId}
                                    mainLayoutId={id}
                                    subLayoutIdx={idx} 
                                    subLayoutProps={layoutProps}
                                    layoutType={layoutType}
                                    periodRange={periodRange}
                                    isModalOpen={true}
                                />
                            </div>
                            
                    </div>
                )
            }
            
        });
    }

    const onLayoutChange = (layout) => {
        // let testLayout = layout[0]
        // console.log((testLayout.h-1)*props.rowHeight)
        let updatedSubLayoutProps = layout.map(item => {return {
                                                    x: item.x,
                                                    y: item.y,
                                                    w: item.w,
                                                    h: item.h
                                                }})
        // console.log(updatedSubLayoutProps)
        dispatch(updateEconomicSubLayoutProps([workspaceId,id,updatedSubLayoutProps]))
        props.onLayoutChange(layout)
    }

    const addNewSubLayout = () => {
        let newLayout = {
            id: newCounter,
            layoutProps: {
                x: (storedMacroeconomicState.length *2) % (currentCols || 12),
                y: Infinity, // puts it at the bottom
                w:6,
                h:12,
                i:`${id+1}.${newCounter}`,
                minW:2,
                // maxW:6,
                minH:2,
                // maxH:6
            },
            macroeconomicComponentName:'Untitled',
            countries:[],
            indicators:[],
            sources:[],
            periodRange:'366',
            fromDate:'',
            toDate:'',
            layoutType:'chart',
            chartParams:{
                tickerParams:[],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    adjustedHeight: 420,
                 },
            }
        }
        dispatch(addNewEconomicSubLayout([workspaceId, id, newLayout, newCounter]));
    }

    const handleEconomicTemplateChange =(e) => {
        setTemplate(e.target.value)
    }
    const handleEconomicTemplateSubmit =(e) => {
        setOpenTemplateModal(false)
        dispatch(addNewEconomicTemplate([workspaceId,id, template]))
        e.preventDefault()
    }
    const useStyles = styled({
        root:{
            
            overflow:'auto',
            '&::-webkit-scrollbar': {
                width: "8px",
                height: "8px",
            },
            //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.3)",
                'border-radius': "10px",
                'background': 'rgba(0,0,0,0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
                'border-radius': '10px',
                'background': 'linear-gradient(left, #999, #888)',
                '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.8)',
            },
            // '&::-webkit-scrollbar-thumb':{
            //     'background': 'linear-gradient(left, #888, #777)'
            // },
            
        },
        

    })

    // const classes = useStyles()

    return (
        <StyledDiv>
            <div className="buttom-item">
                <Tooltip title="Add new Economic Chart">
                    <IconButton 
                        aria-label="minimize"
                        // color="primary"
                        style={refreshStyle}
                        onClick={addNewSubLayout}
                    >
                        <AddchartIcon style={{fontSize:25}}/>
                    </IconButton>
                </Tooltip>      
                            
            </div>
            <div onMouseDown={e => e.stopPropagation()}>
                <ResponsiveReactGridLayout
                    style={{ height:height, width:props.width}} 
                    {...props}
                    width={props.width}
                    layout={{[currentBreakpoint]:listOfsubLayoutProps}}
                    onLayoutChange={onLayoutChange}
                    
                >
                    {storedMacroeconomicState.length !== 0 && generateDOM()}
                </ResponsiveReactGridLayout>
            </div>
            <Modal
                open={openTemplateModal}
                // onClose={handleClose}
                
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: '#000063',
                    border: '2px solid #000',
                    boxShadow: 24,
                    pt: 2,
                    px: 4,
                    pb: 3,
                }}>
                    <form onSubmit={handleEconomicTemplateSubmit}>
                        <label style={{color:'white'}}>
                        Pick your template:
                        <select value={template} onChange={handleEconomicTemplateChange}>
                            <option value="Vietnam">Vietnam</option>
                            {/* <option value="lime">Lime</option>
                            <option value="coconut">Coconut</option> */}
                            <option value="Customs">Customs</option>
                        </select>
                        </label>
                        <input type="submit" value="Submit" />
                    </form>
                
                </Box>
            </Modal>
        </StyledDiv>
    );

    
}


// Macroeconomic.defaultProps = {
//     isDraggable: true,
//     isResizable: true,
//     //   preventCollision:true,
//     //   autoSize:true,
//     //   isBounded:true,
//     // items: 5,
//     rowHeight: 30,
//     //   verticalCompact:true,
//     width: 1920, //KEY IMPORTANT
//     // onLayoutChange: function () {},
//     breakpoints:{lg: 1500, md: 996, sm: 768, xs: 480, xxs: 0},
//     cols: {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
//     margin:[0,0]
// }

Macroeconomic.defaultProps = {
    isDraggable: true,
    isResizable: true,
    // preventCollision:true,
    //   autoSize:true,
    //   isBounded:true,
    // items: 5,
    rowHeight: 30,
    //   verticalCompact:true,
    // width: 1920, //KEY IMPORTANT
    onLayoutChange: function () {},
    // breakpoints:{lg: 1500, md: 996, sm: 768, xs: 480, xxs: 0},
    // cols: 12,
    margin:[0,0]
}
export default Macroeconomic;