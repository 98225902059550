import * as actionTypes from '../actions/authActionTypes'

export const initialState = {
	error: null,
	loading: false,
	token: null
}

const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties
	}
}

const authStartReducer = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true
	})
}

const authSuccessReducer = (state, action) => {
	return updateObject(state, {
		error:null,
		loading:false,
		token: action.token
	})
}

const authFailReducer = (state, action) => {
	return updateObject(state, {
		error:action.error,
		loading: false
	})
}

const authLogoutReducer = (state, action) => {
	return updateObject(state, {
		token: null
	})
}

const authMainReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START : return authStartReducer(state, action)
		case actionTypes.AUTH_SUCCESS: return authSuccessReducer(state,action)
		case actionTypes.AUTH_FAIL: return authFailReducer(state,action)
		case actionTypes.AUTH_LOGOUT: return authLogoutReducer(state, action)
		default:
			return state
	}
}

export default authMainReducer