const init = {
	activeWorkspace:0,
	workspaceCount:0,
	isHideWorkspace:false,
}
export const workspaceReducer = (state=init ,action) => {
	switch(action.type){
		case 'SET_ACTIVE_WORKSPACE':{
			let activeWorkspace = action.payload
			// console.log(activeWorkSpace)
			return {
				...state,
				activeWorkspace: activeWorkspace
			}
		}
		case 'UPDATE_WORKSPACE_COUNT':{
			let newCount = action.payload
			return {...state, workspaceCount:newCount}
		}
		case 'HIDE_WORKSPACE':{
			let isHide = action.payload
			return {...state, isHideWorkspace:isHide}
		}
		default: return state
	}
}
