// import * as d3 from 'd3'
import {select, selectAll,
        min, max,
        scaleTime,scaleLinear, 
        axisRight, axisLeft, axisBottom,
        timeDay,
        zoom} from 'd3'
// import React, {useRef, useEffect, useState} from 'react'
// import renderVolumeCharts from './analysis/renderVolumeCharts'
// import renderNews from './analysis/renderNews'
import RenderIndicators from './analysis/renderIndicators'
import ToggleChartType from './toggleChartType'
// import Draw from './draw'
import crossHair from './generateCrossHair'
import zoomed from './zoomed'
// import { removeSVGItems } from './removeSVGItems'
const InitializeChart = (container,props) => {
    // console.log('Draw Chart is rendered')
    const {timeSeriesData, chartParams,chartId, indexOfLongestArray} = props
    const {tickerParams,svgParams,analysis,indicators} = chartParams
    // console.log(props)
    selectAll(`#${chartId} > g`).remove()
    const svg = select(`#${chartId}`)
                    .attr('width',svgParams.width + svgParams.margin.left + svgParams.margin.right)
                    .attr('height',svgParams.height + svgParams.margin.top + svgParams.margin.bottom)
                    // .attr("preserveAspectRatio","xMinYMin meet")
                    // .attr('viewBox',`0 0 ${svgParams.width +svgParams.margin.left + svgParams.margin.right} ${svgParams.height + svgParams.margin.top + svgParams.margin.right}`)
                    //.classed("svg-content", true)
                    .append('g')
                    // .attr('transform', `translate(${32}, ${0})`)
                    .style('border','1px solid white')
                    .attr('transform', `translate(${svgParams.margin.left}, ${svgParams.margin.top})`)
    // d3.selectAll(`#${chartId} > g > .yAxis`).remove()
    
    let yScaleGroup = []
    let secondXScaleRange = svgParams.width
    let firstXScaleRange = 0
    let yAxisRightWidth = 0
    let yAxisLeftWidth = 0
    let sideOfYAxisGroup=[]
    for (let i=0; i<tickerParams.length; i++) {
        let tickerParamsIndex = i 
        const yAxisName = tickerParams[tickerParamsIndex]['axis']
        const [sideOfYAxis,position] = [...yAxisName]
        sideOfYAxisGroup.push(sideOfYAxis) 
        
        const yMin = min(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
        const yMax = max(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
        // const yMinVolume = d3.min(timeSeriesData.historicalPrice, d => d['Volume'])
        // const yMaxVolume = d3.max(timeSeriesData.historicalPrice, d=>d['Volume'])
        
        var yScale = scaleLinear().domain([yMin * 0.7, yMax * 1.1])
                                    .range([svgParams.adjustedHeight,0]);
        yScaleGroup.push(yScale)
        const yAxis = svg.append('g')
                                .attr('class',`yAxis y${tickerParamsIndex}`)
                                // .style('color',`${tickerParams[tickerParamsIndex]['color']}`)
                                // .style('font-color','white')
        

        // console.log(svg)
        const lastPrice = timeSeriesData.historicalPrice[tickerParamsIndex][timeSeriesData.historicalPrice[tickerParamsIndex].length - 1]['Close']
        if (sideOfYAxis==='R'){
            yAxis.call(axisRight(yScale).tickSize(3)) 
            yAxis.selectAll("line")
                .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

            yAxis.selectAll("path")
                .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
            yAxis.append('rect')
                    .attr('class',`yAxisBox`)
                    // .attr('width',30)
                    .attr('height',12)
                    .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                    .attr('transform',`translate(${0},${yScale(lastPrice)-10})`)
            //add a text and a box to show current price
            yAxis.append('text')
                    .attr('class',`yAxisLabel`)
                    .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                    .attr('fill',`black`)
                    .style('font-size','10px')
                    .text(lastPrice.toFixed(2))

            let yAxisGroups = selectAll(`#${chartId} > g > .yAxis`).filter(`.${sideOfYAxis}`)//.filter(`.y${tickerParamsIndex}`).node().getBBox())
            
            let currentYAxisRightWidth = yAxis.node().getBBox().width
            // let currentYAxisWidth = position === '1'? 0 : d3.selectAll(`g > .yAxis`).filter(`.y${i}`).node().getBBox().width
            yAxisRightWidth += position === '1'? 0 : yAxis.node().getBBox().width
            yAxis.selectAll('rect').attr('width',currentYAxisRightWidth)
            yAxis.attr('transform',`translate(${(yAxisGroups.nodes().length === 1 ? svgParams.width:svgParams.width - yAxisRightWidth )},${0})`)
            
            //format white color to other ticks
            yAxis.selectAll("text")
                .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
                .style("color", "white");
            // console.log(yAxisGroups.nodes().length === 1)
            secondXScaleRange = yAxisGroups.nodes().length === 1 ? svgParams.width :svgParams.width - yAxisRightWidth
            // console.log(secondXScaleRange) 
        } else {           
            yAxis.call(axisLeft(yScale))
            //format yAxis components
            yAxis.selectAll("line")
                .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

            yAxis.selectAll("path")
                .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

            yAxis.append('rect')
                .attr('class',`yAxisBox`)
                // .attr('width',60)
                .attr('height',12)
                .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                .attr('transform',`translate(${0},${yScale(lastPrice)})rotate(180)`)
         
            yAxis.append('text')
                    .attr('class',`yAxisLabel`)
                    .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                    .attr('fill',`black`)
                    .style('font-size','10px')
                    .text(lastPrice.toFixed(2))
            // yAxisWidth = position === 1 ? 0 : yAxis.node().getBBox().width
            
            let yAxisGroups = selectAll(`#${chartId} > g > .yAxis`).filter(`.${sideOfYAxis}`)
            yAxisLeftWidth += position === '1'? 0 : yAxis.node().getBBox().width
            // console.log(yAxisGroups.nodes()) //.filter(`.y${tickerParamsIndex}`).node().getBBox())
            // for (let n = 1; n < yAxisGroups.nodes().length; n++){
            //     yAxisLeftWidth += yAxisGroups.nodes()[n].getBBox().width
            // }
            let currentYAxisLeftWidth = yAxis.node().getBBox().width
            // console.log(currentYAxisLeftWidth)
            yAxis.selectAll('rect').attr('width',currentYAxisLeftWidth)
            yAxis.attr('transform',`translate(${yAxisLeftWidth},${0})`)
            yAxis.selectAll("text")
                .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
                .style("color", "white");
            firstXScaleRange = yAxisGroups.nodes().length === 1 ? 0 :yAxisLeftWidth
        }
        
        // https://codesandbox.io/s/creating-visualizations-with-d3-and-react-forked-z37rb?file=/src/zoom-container.js
        
        // svg.append('g')
        //     .attr('id',`news-series-${chartId}`)
        //     .attr('clip-path','url(#clip)')
        
        // renderNews(chartId,timeSeriesData.news,chartParams,xScale,yScale)
        
        
        
        
    }
    //add legend
    for (let i=0; i<tickerParams.length; i++) {
        let ticker  = tickerParams[i]['ticker']
        let timeSeriesLength = timeSeriesData.historicalPrice[i].length
        const todayPrice =  timeSeriesData['historicalPrice'][i][timeSeriesLength - 1]
        svg.append('text')
            .attr('class',`ticker-legend-${i}`)
            .attr('transform',`translate(${yAxisLeftWidth},${(i * 15) - 15 })`)
            .attr("dy", ".15em")
            .attr("text-anchor", "start")
            .style("fill", `${tickerParams[i]['color']}`)
            .style("font-size", "12px") 
            .text(`${ticker}, Last: ${todayPrice['Close'].toFixed(2)}, High: ${todayPrice['High'].toFixed(2)}, Low: ${todayPrice['Low'].toFixed(2)}, Open: ${todayPrice['Open'].toFixed(2)}`);
    }

    const xMin = min(timeSeriesData.historicalPrice[indexOfLongestArray], d=> d['Date'])
    const xMax = max(timeSeriesData.historicalPrice[indexOfLongestArray], d=> d['Date'])
    const xAxis = svg.append('g').attr('class','xAxis')
    const xAxisPadding = timeDay.count(xMin,xMax) * 0.05
    // console.log(secondXScaleRange)
    var xScale = scaleTime().domain([xMin,timeDay.offset(xMax,xAxisPadding)])
                                    .range([firstXScaleRange,secondXScaleRange])
    
    
    xAxis.call(axisBottom(xScale)).attr('transform',`translate(${0}, ${svgParams.height})`)
            .selectAll('text').style('color',`white`) 
    // add a clip-path attribute for zoom area
    svg.append('clipPath')
            .attr('id', `clip-${chartId}`)
            .append('rect')
            .attr('width', svgParams.width - yAxisLeftWidth - yAxisRightWidth)
            .attr('height', svgParams.adjustedHeight)
            .attr('transform',`translate(${yAxisLeftWidth},${0})`)
    // svg.append('g')
    //         .attr('id', `candlesticks-series-${chartId}`)
    //         .attr('clip-path','url(#clip)');
    //start rendering chartType, indicators
    for (let i=0; i<chartParams.tickerParams.length; i++) {
        let tickerParamsIndex = i 
        // console.log(i)
        const chartType = tickerParams[tickerParamsIndex]['chartType']
        const renderFunction = ToggleChartType[chartType]
        renderFunction(tickerParamsIndex,chartId,timeSeriesData.historicalPrice[tickerParamsIndex],chartParams,xScale,yScaleGroup[i])
        // renderVolumeCharts(chartId,timeSeriesData.historicalPrice[tickerParamsIndex],chartParams,xScale,yScale)
    }
    // console.log(indicatorsInSubChart)
    // add indicators
    if (indicators.length >= 1){
        
        const indicatorsInSubChart = Object.keys(analysis).filter(item => analysis[item].position === 'sub')
        const indicatorsInMainChart = Object.keys(analysis).filter(item => analysis[item].position === 'main')
        // console.log(indicatorsInMainChart,indicatorsInSubChart)
        if (indicatorsInSubChart.length > 0) {
            // console.log(indicatorsInSubChart)
            for (const indicator of indicatorsInSubChart){
                
                // console.log(index)
                const f = RenderIndicators[indicator]
                if(chartId.split('-')[0] === 'simpleChart'){    
                    // console.log(timeSeriesData)
                    f(0,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[0],indicatorsInSubChart)
                } 
                // else if(index !== -1){
                //     f(index,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[index],yAxisLeftWidth,yAxisRightWidth)
                // } 
                else {
                    let ticker = analysis[indicator]['ticker']
                    let index = tickerParams.findIndex(tickerParam => tickerParam['ticker'] === ticker)
                    index !== -1 // this happened when we change the instrument
                            ? f(index,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[index],yAxisLeftWidth,yAxisRightWidth,indicatorsInSubChart)
                            : f(0,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[0],yAxisLeftWidth,yAxisRightWidth,indicatorsInSubChart)
                }
            }
        }
        if (indicatorsInMainChart.length > 0) {
            // console.log(indicatorsInMainChart)
            for (const indicator of indicatorsInMainChart){
                const f = RenderIndicators[indicator]
                if(chartId.split('-')[0] === 'simpleChart'){    
                    // console.log(timeSeriesData)
                    f(0,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[0],indicatorsInMainChart)
                } 
                else {
                    let ticker = analysis[indicator]['ticker']
                    let index = tickerParams.findIndex(tickerParam => tickerParam['ticker'] === ticker)
                    // console.log(index)
                    index !== -1 // this happened when we change the instrument
                            ? f(index,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[index],yAxisLeftWidth,yAxisRightWidth,indicatorsInMainChart)
                            : f(0,chartId,timeSeriesData,chartParams,xScale,yScaleGroup[0],yAxisLeftWidth,yAxisRightWidth,indicatorsInMainChart)
                }
            }
        }
    }
    // Draw.line(chartId)
    // add crossHair 
    crossHair(chartId,timeSeriesData,null,chartParams,xScale,yAxisLeftWidth,yAxisRightWidth)
    var d3zoom = zoom()
                        .extent([[0,0], [svgParams.width, svgParams.height]])
                        .translateExtent([[0,0],[svgParams.width,svgParams.height]])
                        .scaleExtent([1,5])
                        .on("zoom", event => zoomed(event,
                                                    chartId,
                                                    chartParams,
                                                    tickerParams.length,
                                                    xScale,
                                                    yScaleGroup,
                                                    sideOfYAxisGroup,
                                                    yAxisLeftWidth,yAxisRightWidth,
                                                    xMin,xMax,xAxisPadding,
                                                    svgParams.width,
                                                    timeSeriesData,
                                                    ))

    svg.call(d3zoom)
}



    
export default InitializeChart
