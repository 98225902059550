import MarketDataService from "../../../../services/MarketService";

const rsi = (instrumentData, rsiParams) => {
        
        const data = instrumentData
        console.log(data)
        var arr = [];
        let {period, upperRange,lowerRange,isDivergenceConvergence} = rsiParams
        period = parseInt(period)
        upperRange = parseInt(upperRange)
        lowerRange = parseInt(lowerRange)
        for (let i = 1; i< data.length; i++) {
            var change = data[i]['Close']-data[i-1]['Close'];
                arr.push({
                    Date: data[i]['Date'],
                    change: change,
                    
                })
        }
        console.log(rsiParams) 
        var avgGain0 = (arr.slice(0,period).filter(({change}) => change > 0).reduce((total,num) => {
            return total + num['change'];
        },0))/period;
        var avgLoss0 = (arr.slice(0, period).filter(({change}) => change < 0).reduce((total,num) => {
            return total + Math.abs(num['change']);
        },0))/period;
        var avgGain = [avgGain0];
        var avgLoss = [avgLoss0];
        var rs0 = avgGain0/avgLoss0;
        var rsi = [{Date: arr[period]['Date'],
                    value : 100 - (100/(1+rs0))}];
        for (let i = period+1; i< arr.length; i++){
            change = arr[i]['change'];
            avgGain.push((change > 0 ) ? (avgGain[i-period-1] * (period - 1) + change)/period :  (avgGain [i-period-1] * (period - 1) + 0)/period)
            avgLoss.push((change < 0 ) ? (avgLoss[i-period-1] * (period - 1) + Math.abs(change))/period :  (avgLoss [i-period-1] * (period - 1) + 0)/period)
            
            var rs = avgGain[i-period]/avgLoss[i-period]
            rsi.push({
                Date: arr[i]['Date'],
                value: 100 - (100/(1+rs)) 
            })
        }
        if (isDivergenceConvergence){
            return {timeSeriesRSI:rsi, 
                    checkConvergence:checkConvergence(rsi,instrumentData,lowerRange),
                    checkDivergence:checkDivergence(rsi,instrumentData,upperRange)
                    }
        } else {
            return {
                timeSeriesRSI:rsi, 
                checkConvergence:null,
                checkDivergence:null
            }
        }
        
    }
const checkConvergence = (rsiData,instrumentData,condition) => {
    const rsiDataLength = rsiData.length;
    var firstIndexLowest = rsiDataLength - 1;
    var secondIndexLowest = firstIndexLowest;
    const checkPeriod = 60; //days
    const length_diff = instrumentData.length - rsiDataLength;
    // console.log(rsiData.length, instrumentData.length, instrumentData.length - rsiDataLength)
    // find the lowest value
    if (rsiDataLength > checkPeriod) {
        var firstLowestValue = rsiData[rsiDataLength - 1]['value'];
        let i = rsiDataLength; 
        while (i > (rsiDataLength - checkPeriod)) {
            if (rsiData[i-1]['value'] < firstLowestValue ) {
                firstLowestValue = rsiData[i - 1]['value'];
                firstIndexLowest = i - 1; 
            }
            i --;
        }
        
        // find the 2nd lowest value
        if (firstIndexLowest !== rsiDataLength - 1) {
            
            var secondLowestValue = 100;
            let i = firstIndexLowest + 1;
            while (i< (rsiDataLength - 2)){
                
                if (rsiData[i]['value'] < rsiData[i-1]['value'] 
                    && rsiData[i]['value'] <= rsiData[i+1]['value'] 
                    && rsiData[i]['value'] < rsiData[i-2]['value']
                    && rsiData[i]['value'] <= rsiData[i+2]['value']) {
                        if (rsiData[i]['value'] < secondLowestValue) {

                            secondLowestValue = rsiData[i]['value'];
                            secondIndexLowest = i;
                        }
                    }
                i++;
            }
            
            // console.log(instrumentData[firstIndexLowest + length_diff]['Close'] > instrumentData[secondIndexLowest+length_diff]['Close'])
            if (firstLowestValue < condition 
                && instrumentData[firstIndexLowest + length_diff]['Close'] > instrumentData[secondIndexLowest+length_diff]['Close']
                && secondLowestValue !== 100){
                    console.log('find the 2nd value')
                    return {type: 'Convergence',
                            firstValue: firstLowestValue,
                            secondValue: secondLowestValue,
                            firstInstrumentData: instrumentData[firstIndexLowest+length_diff],
                            secondInstrumentData: instrumentData[secondIndexLowest+length_diff],
                            firstPoint: firstIndexLowest,
                            secondPoint: secondIndexLowest
                            };
                }
                
        } 

        
        
    }
}

const checkDivergence = (rsiData,instrumentData,condition) => {
    const rsiDataLength = rsiData.length;
    var firstIndexHighest = rsiDataLength - 1;
    var secondIndexHighest = firstIndexHighest;
    const checkPeriod = 60; //days
    const length_diff = instrumentData.length - rsiDataLength;
    console.log(rsiData.length, instrumentData.length, instrumentData.length - rsiDataLength)
    // find the lowest value
    if (rsiDataLength > checkPeriod) {
        var firstHighestValue = rsiData[rsiDataLength - 1]['value'];
        let i = rsiDataLength; 
        while (i > (rsiDataLength - checkPeriod)) {
            if (rsiData[i-1]['value'] > firstHighestValue ) {
                firstHighestValue = rsiData[i - 1]['value'];
                firstIndexHighest = i - 1; 
            }
            i --;
        }
        
        // find the 2nd lowest value
        if (firstIndexHighest !== rsiDataLength - 1) {
            
            var secondHighestValue = 0;
            let i = firstIndexHighest + 1;
            while (i< (rsiDataLength - 2)){
                
                if (rsiData[i]['value'] > rsiData[i-1]['value'] 
                    && rsiData[i]['value'] >= rsiData[i+1]['value'] 
                    && rsiData[i]['value'] > rsiData[i-2]['value']
                    && rsiData[i]['value'] >= rsiData[i+2]['value']) {
                        if (rsiData[i]['value'] > secondHighestValue) {

                            secondHighestValue = rsiData[i]['value'];
                            secondIndexHighest = i;
                        }
                    }
                i++;
            }
            
            console.log(instrumentData[firstIndexHighest + length_diff]['Close'],instrumentData[secondIndexHighest+length_diff]['Close'])
            if (firstHighestValue > condition 
                && instrumentData[firstIndexHighest + length_diff]['Close'] < instrumentData[secondIndexHighest+length_diff]['Close']
                && secondHighestValue !== 0){
                    console.log('find the 2nd value')
                    return {type: 'Divergence',
                            firstValue: firstHighestValue,
                            secondValue: secondHighestValue,
                            firstInstrumentData: instrumentData[firstIndexHighest+length_diff],
                            secondInstrumentData: instrumentData[secondIndexHighest+length_diff],
                            firstPoint: firstIndexHighest,
                            secondPoint: secondIndexHighest
                            };
                }
                
        } 

        
        
    }
}

const macd = () => {
    
}

const pe = (instrumentData, PeParams) => {
    console.log(PeParams)
    let {ticker,std1stLevel,std2ndLevel} = PeParams
    let formData = {
                ticker:ticker,
                indicatorCode:'PERatio',
                dataSeries: instrumentData,
                params: [parseInt(std1stLevel), parseInt(std2ndLevel),'dilutedEPS']
            }
    
    let fetchedTimeSeries =  MarketDataService.getIndicatorSeries(formData).then(
                                response => {
                                    return response.data
                                }
                            ).catch(error => console.log(`this ticker is not available for the PE Ratio`))
    return fetchedTimeSeries
}

const news = (ticker) => {
    let tickerList = []
    let formData = {
                tickers:tickerList.append(ticker),
                
            } 
        // console.log(signalFormData) 
            MarketDataService.getNews2(formData).then(
                response => {
                        return response.data     
                })
                .catch(error => {alert(error)})
}

const indicators = (instrumentData,indicatorCode, params) => {
    let formData = {
                priceSeries:instrumentData,
                indicatorCode:indicatorCode,
                params:params
            }
    
    let fetchedTimeSeries =  MarketDataService.getIndicatorSeries(formData).then(
                                response => {
                                    return response.data
                                }
                            ).catch(error => console.log(`this ticker is not available for the PE Ratio`))
    return fetchedTimeSeries
}

export default {
    indicators,
    news
}