const initialState = {
            tickerParams:[{
                ticker:null,
                axis:'R1',
                panel:'',
                color:'#FFA500',
                chartType:'toggleLine',
                chartStyle:'',
                width:'',
                marker:'',
            }],
            svgParams:{
                margin:{top: 30, right: 50, bottom: 30, left:50 },
                width:null,
                height:null,
                adjustedHeight: null,
            },
            // analysis:{

            //     events: {
            //         enableNews:false,
            //         enableMarketConsensus:false,
            //     },
                
            //     rsi: {
            //         period:14,
            //         upperRange:70,
            //         lowerRange:30,
            //         color:"#FFA500",
            //         isDivergenceConvergence:false,
            //     },
            //     volume: {
            //         enableVolume:false,
            //     }
            // },
            indicators:[],
        }



export const overviewReducer = (state={}, action) => {
	switch(action.type) {

        case 'CREATE_OVERVIEW':{
            let [workspaceId,mainLayoutId] = action.payload
            return {
                ...state,
                [workspaceId]:{
                ...state[workspaceId],
                [mainLayoutId]:initialState
                }
            }
        }

		// case 'INITIATE_OVERVIEW':{
		// 	let [mainLayoutId,ticker] = action.payload
        //     let newState
        //     //the first condition is to initiate both layout and overview simultaneously 
        //     if (ticker) {
        //         newState = {...initialState,
        //                     tickerParams:[
        //                         {
        //                             ...initialState['tickerParams'][0],
        //                             ticker:ticker.toUpperCase()
        //                         }
        //                     ]
                                                
        //         }
        //     } else {
        //         newState = {...initialState}
        //     }
		// 	return {...state,[mainLayoutId]:newState}

		// }
        case 'REMOVE_OVERVIEW':{
			let [workspaceId,mainLayoutId] = action.payload
            if (!isNaN(mainLayoutId)) {
                const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
                return {...state,
                    [workspaceId]:newState
                }
            } else {
                const {[workspaceId]: value, ...newState} = state
                return newState
            }
		}
        case 'ADD_TICKER_INTO_OVERVIEW_FROM_HEADER':{
			let [workspaceId,mainLayoutId, ticker] = action.payload
            if (!state[workspaceId]?.[mainLayoutId]){
                let newState = {...initialState,
                            tickerParams:[
                                {
                                    ...initialState['tickerParams'][0],
                                    ticker:ticker.toUpperCase()
                                }
                            ]
                                                
                }
                return {
                    ...state,
                        [workspaceId]:{
                        ...state[workspaceId],
                        [mainLayoutId]:newState
                    }
                }
            } else { 
                return {
                    ...state,
                    [workspaceId]: {
                        ...state[workspaceId],
                        [mainLayoutId]:{
                            ...state[workspaceId][mainLayoutId],
                            tickerParams:[
                                {
                                    ...state[workspaceId][mainLayoutId]['tickerParams'][0],
                                    ticker:ticker.toUpperCase()
                                }
                            ]
                        }
                    }
                }
            }
		}
        case 'ADJUST_SVG_PARAMS_OVERVIEW':{
			let [workspaceId,mainLayoutId, updatedSvgParams] = action.payload
            
			return {
                ...state,
                [workspaceId]: {
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        svgParams:{
                            ...state[workspaceId][mainLayoutId]['svgParams'],
                            ...updatedSvgParams
                        }

                    }
                }    
            }
		}
		default: return state

	}
	

}