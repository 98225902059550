// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import IconButton from '@mui/core/IconButton';
import {Grid, Chip, Box } from "@mui/material"
// import Signal from '../Signal';
import { updateTickerAndSignalName} from '../../../../js/actions/simpleChartActions';
import {useDispatch} from 'react-redux'
import { scrollbarStyle } from "../../../UniversalComponents/cssStyle/scrollbarStyle";

const RenderTickers = (workspaceId,tickers,signalName,mainLayoutId) => {
    // const tickers = chartParams.ticker
    // console.log(Array(tickers))
    const dispatch = useDispatch()
    const handleClick = (ticker) => {
        
            dispatch(updateTickerAndSignalName([
                    workspaceId,
                    mainLayoutId,    
                    ticker,
                    signalName,
                   
                ]))

    }
    if (tickers !== undefined) {
        return (tickers
            // .fill(null)
            .map((t,i) => (
            <Grid item key={i}>
                <Chip label={t} onClick={(e) => handleClick(e.target.textContent)} onMouseDown={e => e.stopPropagation()} />
            </Grid>
            )))
    }
}
const SignalType = (props) => {
    const {w,h} = props.layout.layoutProps
    const calculatePxWH = (w,h,colWidth,rowHeight,margin) => {
        let widthPx = Math.ceil(window.innerWidth * (w + margin[0]) + margin[0])/colWidth
        let heightPx = Math.ceil((h - 1) * (rowHeight + margin[1]) + margin[1])
        return [widthPx,heightPx]
    }
    const [widthPx, heightPx] = calculatePxWH(w,h,12,30,[0,0])
    return (
        <div>
            <button style={{backgroundColor:'#101010',width:widthPx - 2,border:'none', boxShadow:'0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'}}>
                <span style={{color:'white'}}>{props.layout.signalName}</span>
                <span style={{color:'white',position:'absolute',right:'5px'}}>{props.layout.tickers !== undefined ? props.layout.tickers.length : 0}</span>
            </button>
            <Box  
                    // {...scrollbarStyle}
                    maxWidth={widthPx} 
                    height={heightPx}
            >
                
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Grid container spacing={1}>
                        {RenderTickers(props.workspaceId,props.layout.tickers, props.layout.signalName,props.mainLayoutId)}
                        {/* <Grid item style={{ marginLeft: "auto" }}>
                            <Button variant="contained" color="primary" onClick={() => {}}>
                            Add
                            </Button>
                        </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
export default SignalType

