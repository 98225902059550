import * as d3 from 'd3'

const DrawText = (chartId,chartName) => {
	
	d3.selectAll(`#${chartId} > g`).remove()
	const margin = {top: 30, right: 50, bottom: 30, left:50 }
	const height = 400 - (margin.top + margin.bottom)
	const width = 600 - (margin.left + margin.right)
	const svg = d3.select(`#${chartId}`)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.style('border','1px solid black')
			.attr('transform', `translate(${margin.left}, ${margin.top})`)
	svg.append('text')
                        .attr('class',`text-${chartId}`)
                        .attr('transform',`translate(${30},${ 100})`)
                        // .attr("dy", ".15em")
                        // .attr("text-anchor", "start")
                        .style("fill", `white`)
                        .style("font-size", "15px") 
                        .text(`The ${chartName} is not available for this ticker.`);
	svg.append('text')
                        .attr('class',`text-${chartId}`)
                        .attr('transform',`translate(${30},${ 120})`)
                        // .attr("dy", ".15em")
                        // .attr("text-anchor", "start")
                        .style("fill", `white`)
                        .style("font-size", "15px") 
                        .text(`Please remove or change ticker.`);
}

export default DrawText