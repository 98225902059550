const parseHistoricalData = (data) => {
    var quotes = data;
    // console.log(data)
    const keys = Object.keys(quotes)
    if (keys.includes('t')){
        var arr1 = [];
        for (var i=0; i< quotes.t.length; i++) {
            arr1.push({
                Date: new Date(1000 * quotes.t[i]),
                Open: quotes.o[i],
                High: quotes.h[i],
                Low: quotes.l[i],
                Close: quotes.c[i],
                Volume: quotes.v[i]

            });
        }
    } else if(keys.includes('timestamp')) {
        var arr1 = [];
        for (var i=0; i< quotes.timestamp.length; i++) {
            arr1.push({
                Date: new Date(1000 * quotes.timestamp[i]),
                Open: quotes.indicators.quote[0].open[i],
                High: quotes.indicators.quote[0].high[i],
                Low: quotes.indicators.quote[0].low[i],
                Close: quotes.indicators.quote[0].close[i],
                Volume: quotes.indicators.quote[0].volume[i]

            });
        }
    }
    return arr1.filter(item => item.Close !== null)
}
const parseNews = (data) => {
    var text = data.match(/>(.*?)<\/a>/g);
    if(text !== null) {
            var arr2 = data.match(/<span class="timeTitle">(.*?)<\/span>/g).map((val,i) => {
                var dateParts = val.replace(/<span class="timeTitle">|<\/span>/g,'').split(" ")[0].split("/");
                var dateObject =  new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
                return {Date: dateObject,
                        text : text[i].replace(/>|<\/a>/g,'')};
            });
        } else {arr2 = null}
    return arr2
}


export {
    parseHistoricalData,
    parseNews,
}
