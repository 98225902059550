// import * as d3 from 'd3'
import {select, selectAll,
    min, max,
    scaleBand,scaleLinear, 
    axisRight, axisLeft, axisBottom,
    } from 'd3'
// import React, {useRef, useEffect, useState} from 'react'
// import renderVolumeCharts from './analysis/renderVolumeCharts'
// import renderNews from './analysis/renderNews'
import ToggleChartType from './ChartType';

const DrawEconomicChart = (container,props) => {
    // console.log('Draw Chart is rendered')
    const {timeSeriesData, chartParams,chartId, indexOfLongestArray, barIndicators, allIndicators} = props
    const {tickerParams,svgParams} = chartParams
    // console.log(props)
    selectAll(`#${chartId} > g`).remove()
    const svg = select(`#${chartId}`)
                    .attr('width',svgParams.width + svgParams.margin.left + svgParams.margin.right)
                    .attr('height',svgParams.height + svgParams.margin.top + svgParams.margin.bottom)
                    .append('g')
                    // .attr('transform', `translate(${32}, ${0})`)
                    .style('border','1px solid white')
                    .attr('transform', `translate(${svgParams.margin.left}, ${svgParams.margin.top})`)
    // d3.selectAll(`#${chartId} > g > .yAxis`).remove()
    
    let yScaleGroup = []
    let secondXScaleRange = svgParams.width
    let firstXScaleRange = 0
    let yAxisRightWidth = 0
    let yAxisLeftWidth = 0
    let sideOfYAxisGroup=[]
    let previousYAxisNames =[] //['R1','L1','R2','R1','L2'] -> R1 duplicated at [0, 3]
    let duplicatedIndexes = []
    let isStackedBar = false
    //here is a function that return an array of duplicate element's indexes
    function duplicateIndexes(arr, el){

        let duplicate = [];
        for (let i = 0; i < arr.length; i++){
            if (arr[i] === el){
                duplicate.push(i)
            } 
        }
        return duplicate
    }
    for (let i=0; i<tickerParams.length; i++) {
        let tickerParamsIndex = i
        if( tickerParams[i]['chartType'] === 'toggleStackedBar') { isStackedBar = true };
        // console.log(isStackedBar)
        const yAxisName = tickerParams[tickerParamsIndex]['axis'];
        const indexOfExistingYAxisName = previousYAxisNames.indexOf(yAxisName);
        
        const [sideOfYAxis,position] = [...yAxisName]

        sideOfYAxisGroup.push(sideOfYAxis) 
        if (indexOfExistingYAxisName === -1) {
            /*
            The indexOf() method returns the first index at which a given element can be found in the array, 
            or -1 if it is not present.
            - Get the yMin and Ymax => update yScale
            */
            const yMin = min(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
            const yMax = max(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
            const lowerBoundFactor = yMin <=0 ? 1.1 : 0.9
            const upperBoundFactor = yMax <=0 ? 0.9 : 1.1
            var yScale = scaleLinear().domain([yMin * lowerBoundFactor, yMax * upperBoundFactor])
                                        .range([svgParams.height,0]);
        } else {
            /*
            if yAxisName exists previously, we compare the old Yscale vs new Yscale
            and get the wider range

            - Update the duplicatedIndexes based on list of previousYAxisNames
            - Get the most recent duplicatedIndex
            - Compare the current range [yMin, yMax] vs old range [oldYMin, oldYMax] to update a range which covers all data points
            - re-update the yScale for all duplicated yAxiss in yScaleGroup => ensure the all data paths are scaled at only yScale
            */
            
            duplicatedIndexes = duplicateIndexes(previousYAxisNames,yAxisName)
            
            // get the most recent duplicated index
            let recentDuplicatedIndex = duplicatedIndexes[duplicatedIndexes.length -1]
            
            const yMin = min(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
            const yMax = max(timeSeriesData.historicalPrice[tickerParamsIndex], d=> d['Close'])
            
            const oldYMin = yScaleGroup[recentDuplicatedIndex].domain()[0]//min(timeSeriesData.historicalPrice[recentDuplicatedIndex], d=> d['Close'])
            const oldYMax = yScaleGroup[recentDuplicatedIndex].domain()[1]//max(timeSeriesData.historicalPrice[recentDuplicatedIndex], d=> d['Close'])
            const oldLowerBoundFactor = oldYMin <=0 ? 1.1 : 0.9  
            const oldUpperBoundFactor = oldYMax <=0 ? 0.9 : 1.1

            const newYMin = Math.min(yMin, oldYMin/oldLowerBoundFactor);
            const newYMax = Math.max(yMax, oldYMax/oldUpperBoundFactor);
            const newLowerBoundFactor = newYMin <=0 ? 1.1 : 0.9 
            const newUpperBoundFactor = newYMax <=0 ? 0.9 : 1.1

            yScale = scaleLinear().domain([newYMin * newLowerBoundFactor, newYMax * newUpperBoundFactor])
                                        .range([svgParams.height,0]);
        
            //replace the yScale of the old duplicatedIndexes to new one.
            for (let duplicatedIndex of duplicatedIndexes) {
                yScaleGroup[duplicatedIndex] = yScale
            }

        }
        
        yScaleGroup.push(yScale)
        const yAxis = svg.append('g')
                                .attr('class',`yAxis y${tickerParamsIndex}`)
                                
        

        // console.log(svg)
        const lastPrice = timeSeriesData.historicalPrice[tickerParamsIndex][timeSeriesData.historicalPrice[tickerParamsIndex].length - 1]['Close']
        if (sideOfYAxis==='R'){
            // SIDE OF YAXIS IS RIGHT
            if (indexOfExistingYAxisName === -1) {
                /*
                 - IF THE CURRENT RIGHT AXIS IS NOT EXISTED PREVIOUSLY
                 - append line, path, rect.yAxisBox, text.yAxisLabel
                */
                yAxis.call(axisRight(yScale).ticks(5))
                yAxis.selectAll("line")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

                yAxis.selectAll("path")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
    
                yAxis.append('rect')
                        .attr('class',`yAxisBox`)
                        // .attr('width',30)
                        .attr('height',12)
                        .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)-10})`)
                //add a text and a box to show current price
                yAxis.append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(lastPrice.toFixed(2))
            } else {
                /*
                - IF THE CURRENT RIGHT AXIS IS EXISTED PREVIOUSLY
                - Loop for all duplicated YAxiss 
                    + get the wide of the duplicated YAxis
                    + remove all correspondent elements(rect.yAxisBox, text.yAxisLabel, g.tick, line) of the yAxis
                    + get the correspondent price
                    + re-append the rect.yAxisBox (width, height, transform), the text.yAxisLabel(transform)
                */
                for (let duplicatedIndex of duplicatedIndexes) {
                    let oldCurrentYAxisRightWidth = parseFloat(svg.selectAll(`.yAxis.y${duplicatedIndex} > rect`).attr('width'))
                    // console.log(duplicatedIndex, svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect, text, g.tick, line'))
                    // console.log(svg.selectAll(`.yAxis.y${duplicatedIndex} > g, Path, rect, text`))
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect, text, g.tick, line').remove()
                    const oldLastPrice = timeSeriesData.historicalPrice[duplicatedIndex][timeSeriesData.historicalPrice[duplicatedIndex].length - 1]['Close']
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).append('rect')
                        .attr('class',`yAxisBox`)
                        // .attr('width',30)
                        .attr('height',12)
                        .attr('width', oldCurrentYAxisRightWidth)
                        .style('fill',`${tickerParams[duplicatedIndex]['color']}`)
                        .attr('transform',`translate(${0},${yScale(oldLastPrice)-10})`)
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(oldLastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(oldLastPrice.toFixed(2))
                    
                    // console.log(currentYAxisLeftWidth)
                    // svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect').attr('width',oldCurrentYAxisRightWidth)
                }
                yAxis.call(axisRight(yScale).ticks(5))
                yAxis.selectAll("line")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
                 
                yAxis.selectAll("path")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

                yAxis.append('rect')
                        .attr('class',`yAxisBox`)
                        // .attr('width',30)
                        .attr('height',12)
                        .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)-10})`)
                //add a text and a box to show current price
                yAxis.append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(lastPrice.toFixed(2))
                
            }

            

            let yAxisGroups = selectAll(`#${chartId} > g > .yAxis`).filter(`.${sideOfYAxis}`)//.filter(`.y${tickerParamsIndex}`).node().getBBox())
            
            let currentYAxisRightWidth = yAxis.node().getBBox().width
        
            yAxisRightWidth += position === '1'? 0 : yAxis.node().getBBox().width

            yAxis.select('rect').attr('width',currentYAxisRightWidth)
            
            yAxis.attr('transform',`translate(${(yAxisGroups.nodes().length === 1 ? svgParams.width:svgParams.width - yAxisRightWidth )},${0})`)
            
            //format white color to other ticks
            
            yAxis.selectAll("text")
                .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
                .style("color", "white");
            
            secondXScaleRange = yAxisGroups.nodes().length === 1 ? svgParams.width :svgParams.width - yAxisRightWidth
            
        } else {  
            // SIDEOFYAXIS IS LEFT 
            if (indexOfExistingYAxisName === -1) {
                // IF THE CURRENT LEFT AXIS IS NOT EXISTED PREVIOUSLY
                yAxis.call(axisLeft(yScale).ticks(5))
                //format yAxis components
                yAxis.selectAll("line")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
                    
                yAxis.selectAll("path")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
    
                yAxis.append('rect')
                    .attr('class',`yAxisBox`)
                    // .attr('width',60)
                    .attr('height',12)
                    .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                    .attr('transform',`translate(${0},${yScale(lastPrice)})rotate(180)`)
            
                yAxis.append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(lastPrice.toFixed(2))
            } else {
                // IF THE CURRENT LEFT AXIS IS EXISTED PREVIOUSLY
                for (let duplicatedIndex of duplicatedIndexes) {
                    let oldCurrentYAxisleftWidth = parseFloat(svg.selectAll(`.yAxis.y${duplicatedIndex} > rect`).attr('width'))
                    // console.log(duplicatedIndex, svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect, text, g.tick, line'))
                    // console.log(svg.selectAll(`.yAxis.y${duplicatedIndex} > g, Path, rect, text`))
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect, text, g.tick, line').remove()
                    const oldLastPrice = timeSeriesData.historicalPrice[duplicatedIndex][timeSeriesData.historicalPrice[duplicatedIndex].length - 1]['Close']
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).append('rect')
                        .attr('class',`yAxisBox`)
                        // .attr('width',30)
                        .attr('height',12)
                        .attr('width', oldCurrentYAxisleftWidth)
                        .style('fill',`${tickerParams[duplicatedIndex]['color']}`)
                        .attr('transform',`translate(0,${yScale(oldLastPrice)})rotate(180)`)
                    svg.selectAll(`.yAxis.y${duplicatedIndex}`).append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(oldLastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(oldLastPrice.toFixed(2))
                    
                    // console.log(currentYAxisLeftWidth)
                    // svg.selectAll(`.yAxis.y${duplicatedIndex}`).selectAll('rect').attr('width',oldCurrentYAxisRightWidth)
                }
                yAxis.call(axisLeft(yScale).ticks(5))
                yAxis.selectAll("line")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);
                 
                yAxis.selectAll("path")
                    .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

                yAxis.append('rect')
                        .attr('class',`yAxisBox`)
                        // .attr('width',30)
                        .attr('height',12)
                        .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)})rotate(180)`)
                //add a text and a box to show current price
                yAxis.append('text')
                        .attr('class',`yAxisLabel`)
                        .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                        .attr('fill',`black`)
                        .style('font-size','10px')
                        .text(lastPrice.toFixed(2))
            }

            yAxis.selectAll("path")
                .style("stroke", `${tickerParams[tickerParamsIndex]['color']}`);

            yAxis.append('rect')
                .attr('class',`yAxisBox`)
                // .attr('width',60)
                .attr('height',12)
                .style('fill',`${tickerParams[tickerParamsIndex]['color']}`)
                .attr('transform',`translate(${0},${yScale(lastPrice)})rotate(180)`)
        
            yAxis.append('text')
                    .attr('class',`yAxisLabel`)
                    .attr('transform',`translate(${0},${yScale(lastPrice)})`)
                    .attr('fill',`black`)
                    .style('font-size','10px')
                    .text(lastPrice.toFixed(2))
            // yAxisWidth = position === 1 ? 0 : yAxis.node().getBBox().width
            
            let yAxisGroups = selectAll(`#${chartId} > g > .yAxis`).filter(`.${sideOfYAxis}`)
            yAxisLeftWidth += position === '1'? 0 : yAxis.node().getBBox().width
    
            let currentYAxisLeftWidth = yAxis.node().getBBox().width
            // console.log(currentYAxisLeftWidth)
            yAxis.selectAll('rect').attr('width',currentYAxisLeftWidth)
            yAxis.attr('transform',`translate(${yAxisLeftWidth},${0})`)
            yAxis.selectAll("text")
                .filter(function() {return !this.classList.contains('yAxisLabel')}) //if we want to use "this", dont use arrow function
                .style("color", "white");
            firstXScaleRange = yAxisGroups.nodes().length === 1 ? 0 :yAxisLeftWidth
        }
        previousYAxisNames.push(yAxisName)
        
        
    }
    select(`#${chartId} > g`)
                    // .select('g')
                    .selectAll(`.yAxisBox`).raise()
    select(`#${chartId} > g`)
                    // .select('g')
                    .selectAll(`.yAxisLabel`).raise()
    // const xMin = min(timeSeriesData.historicalPrice[indexOfLongestArray], d=> d['Date'])
    // const xMax = max(timeSeriesData.historicalPrice[indexOfLongestArray], d=> d['Date'])
    const xAxis = svg.append('g').attr('class','xAxis')
    // const xAxisPadding = timeDay.count(xMin,xMax) * 0.05
    // console.log(firstXScaleRange)
    var xScale = scaleBand()
                    // .rangeRound([0,svgParams.width])
                    .rangeRound([firstXScaleRange,secondXScaleRange])
                    // .paddingInner(0.1)
                    .domain(timeSeriesData.historicalPrice[indexOfLongestArray].map(d => d.Date))
    

    xAxis.call(axisBottom(xScale)).attr('transform',`translate(${0}, ${svgParams.height})`)
            .selectAll('text').style('color',`white`) 
    // add a clip-path attribute for zoom area
    svg.append('clipPath')
            .attr('id', `clip-${chartId}`)
            .append('rect')
            .attr('width', svgParams.width - yAxisLeftWidth - yAxisRightWidth)
            .attr('height', svgParams.height)
            .attr('transform',`translate(${yAxisLeftWidth},${0})`)
    // console.log(barIndicators)
    // for (let i=0; i<tickerParams.length; i++) {
    //     let indicator  = indicators[i]
    //     let timeSeriesLength = timeSeriesData.historicalPrice[i].length
    //     const todayPrice =  timeSeriesData['historicalPrice'][i][timeSeriesLength - 1]
    //     svg.append('text')
    //         .attr('class',`indicator-legend-${i}`)
    //         .attr('transform',`translate(${yAxisLeftWidth},${(i * 15) - 15 })`)
    //         .attr("dy", ".15em")
    //         .attr("text-anchor", "start")
    //         .style("fill", `${tickerParams[i]['color']}`)
    //         .style("font-size", "12px") 
    //         .text(`${indicator}`);
    // }
    
    let barIndexes = []
    let barColors = []
    let barYScaleGroup = []
    
    for (let i=0; i<chartParams.tickerParams.length; i++) {
        // let tickerParamsIndex = i 
        // console.log(i)
        const chartType = tickerParams[i]['chartType']
        const color = tickerParams[i]['color']
        
        if (chartType == 'toggleStackedBar' || chartType == 'toggleBar'){
            // console.log('toggleBar add index')
            barIndexes.push(i)
            barColors.push(color)
            barYScaleGroup.push(yScaleGroup[i])

            // indicator  = barIndicators[i]
            // draw a rect after legend text
            //https://stackoverflow.com/questions/18488385/legend-in-multi-line-chart-d3
            svg.append('rect')
            .attr('class',`indicator-legend-rect-${i}`)
            .attr('x', yAxisLeftWidth +5)
            .attr('y', (i * 15) - 22)
            .attr('width', 10)
            .attr('height', 10)
            .style('fill', `${tickerParams[i]['color']}`);
            

        } else {
            //not toggleStackBar
            // console.log(lineIndicators)
            // indicator = lineIndicators[i]
            // draw a line after legend text
            svg.append("line")
                .attr('class',`indicator-legend-line-${i}`)
                .style("stroke", `${tickerParams[i]['color']}`)
                .style("opacity", 1)
                .attr("x1", yAxisLeftWidth)
                .attr("y1", (i * 15) - 15)
                .attr("x2", 20)
                .attr("y2", (i * 15) - 15);

            const renderFunction = ToggleChartType[chartType]
            renderFunction(i,chartId,timeSeriesData.historicalPrice[i],chartParams,xScale,yScaleGroup[i])
        }

        
        svg.append('text')
            .attr('class',`indicator-legend-text-${i}`)
            .attr('transform',`translate(${yAxisLeftWidth + 25},${(i * 15) - 15 })`)
            .attr("dy", ".15em")
            .attr("text-anchor", "start")
            .style("fill", `${tickerParams[i]['color']}`)
            .style("font-size", "12px") 
            .text(`${allIndicators[i]}`);

        
        
        
    }
    // ensure bar style is selected and there is barDatas
    const barDatas = timeSeriesData.barHistoricalPrice
    // console.log(barIndexes.length, barDatas.length)
    if (barIndexes.length === barIndicators.length && barDatas.length > 0 ) {
        if (isStackedBar) {
            const toggleStackedBar = ToggleChartType['toggleStackedBar']
            toggleStackedBar(barIndexes, chartId, 
                    barDatas ,
                    chartParams, barIndicators, 
                    barColors, xScale,
                    barYScaleGroup[barYScaleGroup.length-1],
                    sideOfYAxisGroup)
            select(`#${chartId} > g`)
                    // .select('g')
                    .selectAll(`.toggleStackedBar-${chartId}`).lower()
        } else {
            // console.log('toggleBar')
            const toggleBar = ToggleChartType['toggleBar']
            toggleBar(barIndexes, chartId, 
                    barDatas ,
                    chartParams, barIndicators, 
                    barColors, xScale,
                    barYScaleGroup[barYScaleGroup.length-1])
            select(`#${chartId} > g`)
                    // .select('g')
                    .selectAll(`.toggleBar-${chartId}`).lower()
        }
        
    }
    // lower all yAxis elements to back of svg
    svg.selectAll(`.yAxis`).lower()


    

}



export default DrawEconomicChart;
