const CREATE_NEW_WORKSPACE = 'CREATE_NEW_WORKSPACE'
const DELETE_WORKSPACE = 'DELETE_WORKSPACE'
const MODIFY_WORKSPACE_LABEL = 'MODIFY_WORKSPACE_LABEL'
const ADD_NEW_LAYOUT = 'ADD_NEW_LAYOUT'
const REMOVE_LAYOUT='REMOVE_LAYOUT'
const SAVE_LAYOUT_PROPS_CHANGE = 'SAVE_LAYOUT_PROPS_CHANGE'
const ADD_COMPONENT_INTO_LAYOUT = 'ADD_COMPONENT_INTO_LAYOUT'
const UPDATE_LAYOUT_CHANGE = "UPDATE_LAYOUT_CHANGE"
const UPDATE_LAYOUT_CHANGE_ON_DRAG_RESIZE_EVENT = 'UPDATE_LAYOUT_CHANGE_ON_DRAG_RESIZE_EVENT'
const CHANGE_LAYOUT_HEIGHT = "CHANGE_LAYOUT_HEIGHT"
const UPDATE_LAYOUT_CHANGE_FULLSCREEN = "UPDATE_LAYOUT_CHANGE_FULLSCREEN"
const UPDATE_LAYOUT_CHANGE_HIDDEN = "UPDATE_LAYOUT_CHANGE_HIDDEN"
const UPDATE_COMPONENT_TITLE = 'UPDATE_COMPONENT_TITLE'


export const createNewWorkspace = payload => {
    return {
        type: CREATE_NEW_WORKSPACE,
        payload
    }
}

export const deleteWorkspace = payload => {
    return {
        type: DELETE_WORKSPACE,
        payload
    }
}

export const modifyWorkspaceLabel = payload => {
    return {
        type: MODIFY_WORKSPACE_LABEL,
        payload
    }
}

export const addNewLayout = payload => {
    return {
        type: ADD_NEW_LAYOUT,
        payload
    }
}

export const removeLayout = payload => {
    return {
        type: REMOVE_LAYOUT,
        payload
    }
}
export const saveLayoutPropsChange = payload => {
    return {
        type: SAVE_LAYOUT_PROPS_CHANGE,
        payload
    }
}
export const addComponentIntoLayout = payload => {
    return {
        type: ADD_COMPONENT_INTO_LAYOUT,
        payload
    }
}
export const updateLayoutChange = payload => {
    return {
        type: UPDATE_LAYOUT_CHANGE,
        payload
    }
}
export const updateLayoutChangeOnDragResizeEvent = payload => {
    return {
        type: UPDATE_LAYOUT_CHANGE_ON_DRAG_RESIZE_EVENT,
        payload
    }
}
export const changeLayoutHeight = payload => {
    return {
        type: CHANGE_LAYOUT_HEIGHT,
        payload
    }
}

export const updateLayoutChangeFullScreen = payload => {
    return {
        type: UPDATE_LAYOUT_CHANGE_FULLSCREEN,
        payload
    }
}
export const updateLayoutChangeHidden = payload => {
    return {
        type: UPDATE_LAYOUT_CHANGE_HIDDEN,
        payload
    }
}


export const updateComponentTitle = payload => {
    return {
        type: UPDATE_COMPONENT_TITLE,
        payload
    }
}