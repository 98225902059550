import React, {Fragment,useState} from "react"
import {useDispatch,useSelector} from 'react-redux'
import {addNewLayout} from '../../js/actions/dashboardActions'
import { addTickerIntoPriceBoard } from "../../js/actions/priceBoardActions"
import { addTickerIntoOverviewFromHeader } from "../../js/actions/overviewActions"
import { addSignalIntoWatchList, addTickerIntoWatchList } from "../../js/actions/watcherActions"
import { addTickerIntoNewsList } from "../../js/actions/newsAction"
import { addTickerIntoMainChartFromHeader } from "../../js/actions/mainChartActions"
const reduxAction = {
  tickerInWatchListAction: addTickerIntoWatchList,
  signalInWatchListAction: addSignalIntoWatchList,
  overviewAction: addTickerIntoOverviewFromHeader,
  // initiateOverviewAction:initiateOverview,
  newsActions: addTickerIntoNewsList,
  priceBoardActions:addTickerIntoPriceBoard,
  mainChartActions: addTickerIntoMainChartFromHeader
}

const Autocomplete = (props) => {
    const dispatch = useDispatch()
    const [activeSuggestion,setActiveSuggestion] = useState(0)
    const [filteredSuggestions, setFilteredSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [userInput, setUserInput] = useState('')
    const {workspaceId,layoutId, suggestions, reduxActionName, filterKeys, placeholderName,width,isNestedList,positionOfSuggestion} = props
    const [shortName, fullName] = filterKeys
    const counter = useSelector(state=> state.dashboard[workspaceId].newCounter)
    // console.log(props) 
    const handleChange = e => {
        
        // const {suggestions} = props
        
        const currentUserInput = e.target.value
        let currentFilteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion[fullName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
        );
        // if useInput does not match long_name, then it will continue to try match the symbols 
        if (!currentFilteredSuggestions.length){
          currentFilteredSuggestions=suggestions.filter(
            suggestion =>
                suggestion[shortName].toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
          )
        }
        // console.log(currentFilteredSuggestions) 
        setFilteredSuggestions(currentFilteredSuggestions)
        setShowSuggestions(true)
        setUserInput(currentUserInput)
        
    }

    const handleClick = (ticker) => {
        
        setFilteredSuggestions([])
        setShowSuggestions(false)
        setUserInput('')
        if (isNestedList === false) {
          dispatch(reduxAction[reduxActionName]([workspaceId,layoutId,ticker]))
        } else {
          addLayout('overview','overviewAction',ticker)
        }
    }

    const handleKeyDown = e => {
      // console.log(e.key)
        if (e.key === 'Enter') {
          // console.log('enter')
          // const checkDuplicated = tableDat.map(d=> d['ticker']).indexOf(userInput)

          // if (checkDuplicated !== -1) {
          //     setUserInput("")
          //     alert('this ticker is existed')
          //     return
          // }

          if (!e.target.value.replace(/\s/g, "").length) return;

          //check whether the ticker in the symbolLists database
          const isExisted = suggestions.some(item=> item.symbol.toUpperCase() === userInput.toUpperCase())
          
          if (!isExisted) {
              setUserInput("")
              alert("This ticker is not in database")
              return
          }
          
          if (isNestedList === false) {
            setActiveSuggestion(0)
            setShowSuggestions(false)
            setUserInput('')
            dispatch(reduxAction[reduxActionName]([workspaceId,layoutId,e.target.value.toUpperCase()]))
          } else {
            addLayout('overview','overviewAction',e.target.value.toUpperCase())
          }
        } else if (e.key === "ArrowUp") {
            if (activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1)
        } else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    }
    const addLayout = (componentName,actionName,ticker) => {
    
      const newLayoutId = counter + 1
      
      const newLayout = 
        
        {
        
          id:newLayoutId,
          layoutProps:{
            x:5,
            y:4,
            w:4,
            h:16,
            i:newLayoutId.toString()
          },
          
          buttons: {
            name:["Overview","Price Board","Chart","Watcher","News"],
            value:["overview","priceboard",'chart','watcher','news']
          },
          component:componentName,
          title:`Untitled-${newLayoutId}`,
            
          isHidden:false,
          isFullscreen:false,
        }
      setActiveSuggestion(0)
      setShowSuggestions(false)
      setUserInput('')
      dispatch(addNewLayout([workspaceId,newLayout,counter]))
      dispatch(reduxAction[actionName]([workspaceId,counter+1,ticker]))
    };
    //a function to bold the cell value
    const boldQuery = (str, query, col, rowStyle,isNestedList,firstRow,shortName) => {
        const n = str.toUpperCase();
        const q = query.toUpperCase();
        const x = n.indexOf(q);
        if (!q || x === -1) {
            return col === 1 ? <div><span style={{paddingRight:"35px"}}>{str}</span></div>:str // bail early
        }
        const l = q.length;
        return (<td style={rowStyle}>
                    <span onClick={()=>handleClick(shortName)}>{str.substr(0, x)}<b><u>{str.substr(x, l)}</u></b>{str.substr(x + l)}</span>
                    {(isNestedList === true && firstRow === 0)?
                      <ul style={{backgroundColor:'grey'}}>
                          <li onClick={()=> addLayout('overview','overviewAction',str)}>{str}: Overview</li>
                          <li onClick={()=> addLayout('chart','mainChartActions',str)}>{str}: Chart</li>
                          <li onClick={()=> addLayout('news','newsActions',[str])}>{str}: News</li>
                      </ul>
                    :null}
                </td>)
    }
    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          // https://stackoverflow.com/questions/7421775/css-i-want-a-div-to-be-on-top-of-everything 
          <table className="suggestions" style={{position:'absolute',zIndex:10, top:positionOfSuggestion, backgroundColor:'black'}}>
            <tbody>
              {filteredSuggestions.map((suggestion, index) => {
                let className;
                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }
                return (
                  <tr style={{color:'white',cursor:'pointer'}}className={className} key={suggestion[fullName]}>
                    {boldQuery(suggestion[shortName],userInput,1,{paddingRight:"35px"},isNestedList,index,suggestion[shortName])}{boldQuery(suggestion[fullName],userInput,2,{verticalAlign:'top'},false,0,suggestion[shortName])}
                    
                  </tr>
                 //should not create space -> https://stackoverflow.com/questions/39914455/react-validatedomnesting-text-cannot-appear-as-a-child-of-tr 
                );
              })}
            </tbody>
          </table>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em style={{color:'white'}}>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }
    const BarStyling = {width:width,left:10,background:"black", border:"none", padding:"0.3rem", color:'white',textOverflow:'ellipsis'}
    return (
       <Fragment>
           <input
            style={BarStyling}
            type='text'
            placeholder={placeholderName}
            onChange={handleChange}
            onKeyDown = {handleKeyDown}
            value = {userInput}
           />
           {suggestionsListComponent}
       </Fragment> 

    )
}

export default Autocomplete