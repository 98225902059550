import React, {useState, useEffect} from 'react';
const WebSocket = require('ws');

const Quotes = (props) => {
    const {id} = props.layout
    const workspaceId = props.workspaceId;
    const cols = props.mainBreakpoint.currentCols || 12;

    const [quote, setQuote] = useState({});
    useEffect(() => {
        const socket = new WebSocket(`ws://127.0.0.1:8000/quotes/${["BTC-USD"]}`)
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setQuote(data);
        };
        socket.onclose = () => {
            console.error("Websocket close");
        };
    },[]);

    return (
        <div>
            <p>Price:{quote.price}</p>
        </div>
    )

}

export default Quotes