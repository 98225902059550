import * as d3 from 'd3'
import React, {useRef, useEffect, useState} from 'react'
// import { useSelector } from 'react-redux';
import drawTreeMap from './drawTreeMap';
import MarketDataService from "../../../services/MarketService";
// const dataset = {
//     "children":[
//         {
//             "name":"boss1",
//             "children":[
//                 {"name":"mister_a","group":"A","value":28,"colname":"level3"},
//                 {"name":"mister_z","group":"A","value":90,"colname":"level3"},
//                 {"name":"mister_b","group":"A","value":19,"colname":"level3"},
//                 {"name":"mister_c","group":"C","value":18,"colname":"level3"},
//                 {"name":"mister_d","group":"C","value":19,"colname":"level3"}
//             ],
//             "colname":"level2"
//         },
//         {
//             "name":"boss2",
//             "children":[
//                 {"name":"mister_e","group":"C","value":14,"colname":"level3"},
//                 {"name":"mister_f","group":"A","value":11,"colname":"level3"},
//                 {"name":"mister_g","group":"B","value":15,"colname":"level3"},
//                 {"name":"mister_h","group":"B","value":16,"colname":"level3"}
//             ],
//             "colname":"level2"
//         },
//         {
//             "name":"boss3",
//             "children":[
//                 {"name":"mister_i","group":"B","value":40,"colname":"level3"},
//                 {"name":"mister_j","group":"A","value":13,"colname":"level3"},
//                 {"name":"mister_k","group":"A","value":13,"colname":"level3"},
//                 {"name":"mister_l","group":"D","value":25,"colname":"level3"},
//                 {"name":"mister_m","group":"D","value":16,"colname":"level3"},
//                 {"name":"mister_n","group":"D","value":28,"colname":"level3"}
//             ],
//             "colname":"level2"}
//         ],
//         "name":"CEO"
//     };

const MarketMap = (props) => {
    const ref = useRef(null);
    const {id, layoutProps} = props.layout
    const cols = props.mainBreakpoint.currentCols || 12
    const height = (layoutProps.h -1) * 30 - 60
    const width = window.innerWidth * layoutProps.w / cols - 100
    // console.log(width)
    const [dataset, setDataset] = useState()

    useEffect(() => {
        var d = new Date();
        d.toLocaleString('en-US', { timeZone: 'Asia/Ho_Chi_Minh' })
        let interval
        if (d.getHours() < 15 || d.getHours() > 9) {
            // console.log('interval')
            interval = setInterval(() => {
                            MarketDataService.getMarketMapData()
                                            .then(response => {
                                                setDataset(response.data)
                                                
                                            })
                        },900000);
        }
        MarketDataService.getMarketMapData()
                                        .then(response => {
                                            setDataset(response.data)
                                            
                                        })
        return () => clearInterval(interval)
        
    },[])

    useEffect(() => {
        if(dataset) {
            drawTreeMap({
                        current: ref.current,
                        chartId: `marketmap-${id.toString()}`, 
                        data: dataset,
                        width: width,
                        height: height
                    });
        }
    },[dataset, width,height]) 

    return (
        <div >
            <svg id={`marketmap-${id.toString()}`} ref={ref}/>
        </div>
    )
}

export default MarketMap