import {useMemo, useState, useEffect} from 'react'
import Table from '../../UniversalComponents/Table';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const SimulationTable =(props) => {
   // console.log('priceBoard is rendered')
   // console.log(props.layout.layoutProps)
   const {setFormData, formData} = props
   console.log(formData)
   const handleMovingAverageParamsChange = (rowIndex,field,value) => {
      console.log(rowIndex,field, value)
      const data = {
         ...formData,
         pe:{
            ...formData['pe'],
            simulationParams:[
               ...formData['pe']['simulationParams'].map((param,index) => index === rowIndex
                                                   ? {...param,[field]:value}
                                                   : param
                                 )  
            ]
         }
      }
      console.log(data)
      setFormData(data)
   }
   const EditableCell = ({
        value:initialValue,
        row:{index,original},
        column: {id},
        data: tableData,
        
   }) => {
      const [userInput, setUserInput] = useState(initialValue)
       

      const onChange = e => {
         const currentUserInput = e.target.value.trim()
         // console.log(suggestions) 
            
         setUserInput(currentUserInput)
      }

      // it’s called when the user clicks outside of a focused text input.
      const handleBlur = (e) => {
         // if(e.key==='Enter' || e.key==='Escape') {
            
            setUserInput("")
            handleMovingAverageParamsChange(index, 'expectedValue', parseInt(userInput))
         // }
      }
      const handleKeyDown = (e) => {
         if(e.key==='Enter' || e.key==='Escape') {
            
            setUserInput("")
            handleMovingAverageParamsChange(index, 'expectedValue', parseInt(userInput))
         }
      }
      // If the initialValue is changed external, sync it up with our state
      useEffect(() => {
         setUserInput(initialValue)
      }, [initialValue])

      return (
         <div>
               <input value={userInput} onChange={onChange} onBlur={handleBlur} onKeyDown={handleKeyDown} />
               
         </div>
      )
      
   }
   
   const columns = useMemo(
      () => [
   {
      Header:'Name',
      accessor:'type',
      Cell:(props) => {
         return (
            <div>
               <span>{props.value}</span>
            </div>
         )
      }
   },
   {
      Header:'Current',
      accessor:'currentValue',
      Cell: (props) => {
         
         return (
            <div>
               <span>{props.value}</span>
            </div>
         )
      }
   },
   
   {
      Header:'1Y forward value',
      accessor:'expectedValue',
      Cell:EditableCell
   },
   
   
   ],
   [formData['pe']['simulationParams']]
   )

    
    
   return (
      <div className="container" >
         <div className="row">
               <Table columns={columns} data={formData['pe']['simulationParams']} layout={null}/>
         </div>
      </div>
   )
}


export default SimulationTable