import {useState, useRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import _ from "lodash";


import MarketDataService from "../../../../services/MarketService";

import DrawEconomicChart from "./DrawEconomicChart";
// import EconomicModal from "./EconomicModal"

import {updateSvgParamsEconomicChart} from "../../../../js/actions/macroeconomicActions";


const EconomicChart = (props) => {
    const d3Container = useRef(null);
    const {workspaceId, mainLayoutId,
        subLayoutIdx, subLayoutProps,subLayoutCols, chartParams, 
         indicators, countries, sources,
        periodRange} = props;
    const {tickerParams, svgParams} = chartParams;
    const subChartId = `subLayout-${mainLayoutId}-${subLayoutProps.i}`.split('.').join("");
    // console.log(subChartId)
    // const uniqueIndicatorNames = [...new Set(dataset.map(item => item['economic_indicator_name']))];
    
    const [rawData, setRawData] = useState([]);
    const [timeSeriesData,setTimeSeriesData] = useState({
                                                historicalPrice:[],
                                                barHistoricalPrice:[]
                                            })
    const [barIndicators, setBarIndicators] = useState([]);
    const [allIndicators, setAllIndicators] = useState([]);
    
    const dispatch = useDispatch();

    

    useEffect(() => {
        let formData = {
            
            indicators: indicators,
            countries: countries,
            sources:sources,
            periodRange: periodRange
        }
        
        MarketDataService.getMacroeconomicData(formData)
                        .then(response => {
                            
                            let data = response['data']['data']

                            const allIndicatorNames = [...new Set(data.flat().map(item => item['economic_indicator_name']))];
                            
                            setAllIndicators(allIndicatorNames)
                            
                            let modifiedData = []
                            for (let idx in indicators) {
                                let subData = []
                                for (let d of data[idx]) {
                                    subData.push({
                                        Date: d['reference_period'],
                                        Close: d['unit'] === 'Index' ? d['value']-100 : d['value']
                                    })
                                }
                                
                                modifiedData.push(subData)
                            }
                            // console.log(modifiedData)
                        
                            setTimeSeriesData(prevState => ({...prevState, 
                                historicalPrice: modifiedData, 
                                // barHistoricalPrice: barHistoricalPrice
                            }))

                            
                            setRawData(data)
                            

                        })
                        .catch(e => console.log(e))
    },[indicators, periodRange])

    useEffect(() => {
        // bardata
        //get the indexes of indicators are rendered under bar Chart (mutiple or stacked)
        
        let barIndicatorIndexes=[];
        let barHistoricalPrice = [];
        tickerParams.some(function(tickerParam, i) {
            if (tickerParam.chartType === "toggleStackedBar" || tickerParam.chartType === "toggleBar") {
                barIndicatorIndexes.push(i);
                
            } 
        });
        
        // if(lineIndicatorIndexes.length>0 && rawData.length === tickerParams.length) {
            // let filteredLineData = _.map(lineIndicatorIndexes, _.propertyOf(rawData)).flat()
            
        // }
        // console.log(barIndicatorIndexes)
        
        if (barIndicatorIndexes.length>0 && rawData.length === tickerParams.length) {
            let filteredBarData = _.map(barIndicatorIndexes, _.propertyOf(rawData)).flat()
            
            const uniqueBarIndicatorNames = [...new Set(filteredBarData.map(item => item['economic_indicator_name']))];
            

            const uniqueDates = [...new Set(filteredBarData.map(item => item['reference_period']))];
            
            for (let date of uniqueDates) {
                let subObject = {
                    'Date':date,
                }
                for (let d of filteredBarData) {
                    if (d['reference_period']===date){
                        let value = d['unit'] = 'Index' ? d['value']-100 : d['value']
                        subObject[d['economic_indicator_name']]= value
                    }
                    
                }
                barHistoricalPrice.push(subObject)
            }
            // console.log(barHistoricalPrice)
            setTimeSeriesData(prevState => ({...prevState, 
                // historicalPrice: modifiedData, 
                barHistoricalPrice: barHistoricalPrice}))
            setBarIndicators(uniqueBarIndicatorNames)    
            
            
        } else {
            setTimeSeriesData(prevState => ({...prevState, 
                // historicalPrice: modifiedData, 
                barHistoricalPrice: []}))
            setBarIndicators([])
        }

    },[tickerParams, rawData])

    useEffect(() => {
        
        if (timeSeriesData.historicalPrice.length === tickerParams.length && tickerParams.length > 0 && allIndicators.length > 0){
            
            const indexOfLongestArray = timeSeriesData.historicalPrice
                                                    .reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0)
            DrawEconomicChart(d3Container.current,
                            {
                                timeSeriesData, 
                                chartParams:chartParams, 
                                chartId:subChartId,
                                indexOfLongestArray:indexOfLongestArray,
                                barIndicators: barIndicators,
                                allIndicators: allIndicators
                            },
                        
                        )
        }
    },[timeSeriesData,tickerParams,svgParams])

    const updateDimensions = () => {
        /*
        // the height and weight of chart will depend the subLayoutProps' h and w respectively
        */
    //    console.log(subLayoutProps.h, mainLayoutProp.h)
       const margin = {top: 30, right: 50, bottom: 10, left:50 }//{top: 10, right: 15, bottom: 2, left: 15}
       const newDimension = {
            height: ((subLayoutProps.h - 1 ) * 30) - 15 - (margin.top + margin.bottom),
            width: (window.innerWidth * (subLayoutProps.w)/ subLayoutCols) - (margin.left + margin.right),

       }
    //    console.log(newDimension)
    //    console.log(subLayoutHeight)
       dispatch(updateSvgParamsEconomicChart([workspaceId, mainLayoutId, subLayoutIdx, newDimension]));
    }

    useEffect(() => {
        updateDimensions();
    },[subLayoutProps])

    return (
        <div className="flex-container">
            <div className="flex-item">
                <div ref={d3Container} >
                    <svg id={subChartId} />
                </div>
            </div>
        </div>
    );
    
}

export default EconomicChart;