const initialState = {
	tickerParams:[{
		ticker:'',
		axis:'R1',
		panel:'',
		color:`#${Math.random().toString(16).substr(-6)}`,
		chartType:'toggleCandleStick',
		chartStyle:'',
		width:'',
		signalName:''
	}],
	svgParams:{
		margin:{top: 30, right: 50, bottom: 30, left:50 },
		width:null,
		height:null,
		adjustedHeight: null,
	},
	analysis:{
		
	},
	indicators:[],
}
const indicatorState = {
      bollingerband:{
         ticker:'',
         bollingerMidColor:"#008B8B",
         bollingerUpperColor:"#52B2BF",
         bollingerLowerColor:"#52B2BF",
         period:20,
         upperLevel:2,
         lowerLevel:2,
         position:'main',
         indicatorHeight:0
      },
      macd:{
         ticker:'',
         macdColor:"#008B8B",
         signalColor:"#FFA500",
         histogramColor:'red',
         fastPeriod:26,
         slowPeriod:12,
         signalPeriod:9,
         position:'sub',
         indicatorHeight:100 
      },
      movingaverage:{
         ticker:'',
         position:'main',
         indicatorHeight:0,
         multipleState:[]

      },
      news: {
            enableNews:false,
            ticker:'',
            position:'main',
            indicatorHeight:100
         },
		
		rsi: {
         ticker:'',
			period:14,
			upperRange:70,
			lowerRange:30,
			color:"#FFA500",
			isDivergenceConvergence:false,
         position:'sub',
         indicatorHeight:100
		},
		volume: {
			ticker:'',
         position:'main',
         risingColor:'#c0392b',
         fallingColor:'#03a678',
         indicatorHeight:0,
		},
		pe: {
            ticker:'',
            std1stLevel: 1,
            std2ndLevel: 2,
            std1stColor:"#FFA500",
            std2ndColor:"#FFA500",
            meanColor:"#FFA500",
            peColor:"#008B8B",
            position:'sub',
            doSimulation:false,
            simulationParams:[],
            
            indicatorHeight:100
         }, 
}
const firstState = {
   0:{
      4:{
         tickerParams:[{
            ticker:'^GSPC',
            axis:'R1',
            panel:'',
            color:`#${Math.random().toString(16).substr(-6)}`,
            chartType:'toggleCandleStick',
            chartStyle:'',
            width:'',
            signalName:''
         }],
         svgParams:{
            margin:{top: 30, right: 50, bottom: 30, left:50 },
            width:860,
            height:420,
            adjustedHeight: 420,
         },
         analysis:{},
         indicators:[],
      }
   }
}
export const mainChartReducer = (state=firstState,action) => {
	switch(action.type) {
      case 'CREATE_MAIN_CHART': {
         let [workspaceId,mainLayoutId] = action.payload
         // console.log(action.payload)
         return {
            ...state,
            [workspaceId]:{
               ...state[workspaceId],
               [mainLayoutId]:initialState
            }
         }
      }
		case 'ADD_TICKER_INTO_MAIN_CHART_FROM_HEADER': {
			let [workspaceId,mainLayoutId,ticker] = action.payload
         // mainLayoutId = mainLayoutId.toString()
			// let newState
			// console.log({...state.tickerParams})
			//if a new chart is initiated, a initiated is added
			// or if all tickers are deleted, but the component is still existed
         // console.log(state)
			if (!state[workspaceId]?.[mainLayoutId]) {
				let newState = {...initialState,
						tickerParams:[
							{
							...initialState.tickerParams[0],
							ticker:ticker.toUpperCase()
							}
						]
					}                
            // console.log(newState)
				return {
					...state,
               [workspaceId]:{
                  ...state[workspaceId],
					   [mainLayoutId]:newState
               }
				}
			} 
			// else if(state[mainLayoutId]['tickerParams'].length === 0) {
			// 	newState
			// }
			
			else {
				return {...state,
               [workspaceId]:{
                  ...state[workspaceId],
                  [mainLayoutId]:{
                     ...state[workspaceId][mainLayoutId],
                     tickerParams:[
                        {
                        ...state[workspaceId][mainLayoutId].tickerParams[0],
                        ticker:ticker.toUpperCase()
                        }
                     ]		
                  }
				   }
            }
			}
			
		}
		case 'REMOVE_MAIN_CHART': {
			let [workspaceId,mainLayoutId] = action.payload
         // mainLayoutId = mainLayoutId.toString()
         // console.log(mainLayoutId)
         // mainLayoutId? console.log(mainLayoutId,'undefined') :  console.log(mainLayoutId,'ok number') 
         if (!isNaN(mainLayoutId)) {
            // console.log(mainLayoutId)
            const {[workspaceId]:{[mainLayoutId]:value,...newState}} = state
            // console.log(newState)
            // return newState
            return {...state,
               [workspaceId]:newState
            }
         } else {
            const {[workspaceId]: value, ...newState} = state
            // console.log(newState)
            return newState
         }
		}
		case "ADJUST_SVG_PARAMS_MAIN_CHART": {
			let [workspaceId,mainLayoutId,svgParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
			// console.log({...state[workspaceId]})
			return {
				...state,
            [workspaceId]:{
               ...state[workspaceId],
               [mainLayoutId]:{
                  ...state[workspaceId][mainLayoutId],
                  svgParams:{
                     ...state[workspaceId][mainLayoutId].svgParams,
                     ...svgParams
                  }
                  
               }
            }
			}
		}
		
		// case "ENABLE_NEWS" :{
		// 	let [workspaceId,mainLayoutId,enabledNews] = action.payload
      //    // mainLayoutId = mainLayoutId.toString()
		// 	return {
		// 		...state,
      //       [workspaceId]:{
      //          ...state[workspaceId],
      //          [mainLayoutId]: {
      //             ...state[workspaceId][mainLayoutId],
      //             analysis: {
      //                ...state[workspaceId][mainLayoutId].analysis,
      //                events: {
      //                   ...state[workspaceId][mainLayoutId].analysis.event,
      //                   enableNews: enabledNews
      //                }
      //             }
               
      //          }
      //       }
		// 	}
			
			
		// }
		
		// case "ENABLE_VOLUME" :{
		// 	let [workspaceId,mainLayoutId, enabledVolume] = action.payload
		//    // mainLayoutId = mainLayoutId.toString()
		// 	return {
		// 		...state,
      //       [workspaceId]:{
      //             ...state[workspaceId],
      //          [mainLayoutId]: {
      //             ...state[workspaceId][mainLayoutId],
      //             analysis: {
      //                ...state[workspaceId][mainLayoutId].analysis,
      //                volume: {
      //                   ...state[workspaceId][mainLayoutId].analysis.volume,
      //                   enableVolume: enabledVolume
      //                }
      //             }
                  
      //          }
      //       }
		// 	}
		// }
			
		case "UPDATE_INDICATOR_PARAMS" :{
			let [workspaceId,mainLayoutId, indicatorName,indicatorParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
			return {
				...state,
            [workspaceId]:{
                  ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  analysis: {
                     ...state[workspaceId][mainLayoutId].analysis,
                     [indicatorName]: {
                        ...state[workspaceId][mainLayoutId].analysis[indicatorName],
                        ...indicatorParams
                     }
                  }
                  
               }
            }
			}
		}
			
		case "ADD_INDICATOR" : {
			let [workspaceId,mainLayoutId, indicatorName, indicatorParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
         // console.log(indicatorState[indicatorName])	
			return {
				...state,
            [workspaceId]:{
                  ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  analysis:{
                     ...state[workspaceId][mainLayoutId]['analysis'],
                     [indicatorName]:{...indicatorState[indicatorName], ...indicatorParams}
                  },
                  indicators: [
                     ...state[workspaceId][mainLayoutId].indicators,
                     indicatorName
                  ]
               }
            }
			}
		}
		
		case "REMOVE_INDICATOR" : {
			let [workspaceId,mainLayoutId, indicatorName] = action.payload
         const {[indicatorName]:value, ...newState} = {...state[workspaceId][mainLayoutId]['analysis']}
			return {
				...state,
            [workspaceId]:{
                  ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  analysis:newState,
                  indicators:[
                     ...state[workspaceId][mainLayoutId].indicators.filter(indicator=>indicator.toLowerCase()!== indicatorName.toLowerCase())
                  ]
               }
            }
			}
		}

		case "ADD_NEW_TICKER_PARAMS": {
			let [workspaceId,mainLayoutId, tickerParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
			return {
				...state,
            [workspaceId]:{
                  ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  tickerParams: [
                     ...state[workspaceId][mainLayoutId].tickerParams,
                     tickerParams
                  ]
                  
               }
            }
			}
		}
			
		case "UPDATE_TICKER_PARAMS": {
			let [workspaceId,mainLayoutId, tickerParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
				
				return {
				...state,
            [workspaceId]:{
               ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  tickerParams:tickerParams
                  
                  
               }
            }
         }
      }
			
		case "CHANGE_TICKER_PARAMS": {
		
			let [workspaceId,mainLayoutId, rowIndex, columnId, value] = action.payload
         //rowIndex=0
         //columnId = "ticker"
      //    [
      //       {
      //           "ticker": "^GSPC",
      //           "axis": "R1",
      //           "panel": "",
      //           "color": "#0a0df2",
      //           "chartType": "toggleCandleStick",
      //           "chartStyle": "",
      //           "width": "",
      //           "signalName": ""
      //       }
      //   ]
			return {
				...state,
            [workspaceId]:{
               ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  
                  tickerParams:[
                     ...state[workspaceId][mainLayoutId].tickerParams.map((row,index) => {
                     if(index === rowIndex) {
                        return {
                        ...state[workspaceId][mainLayoutId].tickerParams[rowIndex],
                        [columnId]:value,
                        }
                     }
                     return row
                     })
                  ]
                  
               }
            }
			}
		}
			
		case "REMOVE_TICKER_PARAMS": {
			let [workspaceId,mainLayoutId, tickerParams] = action.payload
         // mainLayoutId = mainLayoutId.toString()
         return {
				...state,
               [workspaceId]:{
                     ...state[workspaceId],
               [mainLayoutId]: {
                  ...state[workspaceId][mainLayoutId],
                  
                  tickerParams:[
                     ...state[workspaceId][mainLayoutId].tickerParams.filter(row => row.ticker.toLowerCase() !== tickerParams.toLowerCase())
                  ]
                  
               }
            
				}
         }
		}
				
		default: return state
	}
}