import React, {useEffect, useState} from "react"

import { editableStyle } from "./cssStyle/iconStyle"
//Component accepts text, placeholder values and also pass what type of Input - input, textarea
//so that we can use it for styling accordingly
const Editable = ({
	childRef,
	text,
	type,
	placeholder,
	children,
	...props
}) => {
	//Manage the state whether to show the label or the input box. By default, label will be shown
	const [isEditing, setEditing] = useState(false)

	/*
	using use effect, when isEditing state is changing, check whether it is set to true,
	if true, then focus on the reference amount 
	*/
	useEffect(() => {
		if (childRef && childRef.current && isEditing === true) {
			childRef.current.focus()
		}
	},[isEditing, childRef])

	//Event handler while passing any key while editing
	const handleKeyDown = (event, type) => {
		const {key} = event
		const keys = ['Escape','Tab']
		const enterKey = 'Enter'
		const allKeys = [...keys, enterKey]
		/* 
		- For text area, check only Escape and Tab key and set  the state to false
		- for everything else, all three keys will set the state to false
		*/
		if (
			(type === 'textarea' && keys.indexOf(key) > -1) ||
			(type !== 'textarea' && allKeys.indexOf(key) > -1)
		) {
			setEditing(false)
		}

	}
	/*
	- It will display a label is `isEditing` is false
	- It will display the children (input or textarea) if `isEditing` is true
	- when input `onBlur`, we will set the default non edit mode
	*/

	return (
		<section {...props} style={editableStyle}>
			{isEditing ? (
				<div
					onBlur={() => setEditing(false)}
					onKeyDown={e => handleKeyDown(e, type)}
				>
					{children}
				</div>
			): (
				<div
					onClick={() => setEditing(true)}
				>
					<span>
						{text || placeholder || "Editable content"}
					</span>
				</div>
			)}
		</section>
	)
}

export default Editable
