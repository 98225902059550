import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav';

import {Button} from "@mui/material";

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import IndicatorConfigTable from './IndicatorConfigTable';
import Container from './DropdownContainer';

import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import MarketDataService from "../../../../services/MarketService";


import {addNewIndicatorInMacroeconomic,removeIndicatorInMacroeconomic} from "../../../../js/actions/macroeconomicActions";


const EconomicModal = (props) => {
    const {workspaceId, mainLayoutId, subLayoutIdx} = props
    const subLayoutState = useSelector(state => state['macroeconomic'][workspaceId][mainLayoutId]['items'][subLayoutIdx]);
    const currentIndicators = subLayoutState['indicators'];
    
    // const currentCountries = subLayoutState['country'];
    const subLayoutProps = subLayoutState['layoutProps'];
    const chartParams = subLayoutState['chartParams'];
    
    const dispatch = useDispatch()
    const [indicatorGenerals, setIndicatorGenerals] = useState({})
    
    const width = window.innerWidth * subLayoutProps.w / 12 - 100
    // const selectedIndicatorOptions = allEconomicIndicatorOptions.filter((el) => {
    //     return currentIndicators.some(s => s === el.value);
    // })
    
    // get unique values with [... new Set(items)] then create a new array of objects [{},{}]
    // const selectedCountryOptions = allCountryOptions.filter((el) => {
    //     return [... new Set(currentCountries)].some(s => s === el.value);
    // })
    // create a data for IndicatorConfigTable
    // const dataIndicatorConfigTable = chartParams['tickerParams'].map((param,idx) => 
    //     ({...param, ...selectedIndicatorOptions[idx]})
    // )
    const dataIndicatorConfigTable = chartParams['tickerParams'].map((param,idx) => 
        ({...param, ...{label:currentIndicators[idx]}})
    )
    // const dateRangeConfigTable = subLayoutState.map((state,idx) => ({
    //     label: state,
    //     fromDate: state['fromDate'],
    //     toDate: state['toDate'],

    // }))
    // console.log(dataIndicatorConfigTable)
    // const selectIndicator = (remainingItems) => {
    //     console.log(remainingItems)
    //     const remainingItemValues = remainingItems.map(item => item['value']);
    //     // console.log(remainingItemValues, selectedIndicatorOptions)
    //     if (remainingItems.length > selectedIndicatorOptions.length) {
    //         dispatch(addNewIndicatorInMacroeconomic([workspaceId,mainLayoutId,subLayoutIdx, remainingItemValues]))
    //     } else {
    //         //remainingItems.length < selectedIndicatorOptions.length
    //         let removedIndicator = currentIndicators.filter(item => remainingItemValues.indexOf(item) == -1);
    //         let indexOfRemovedItem = currentIndicators.indexOf(removedIndicator[0])
    //         console.log(indexOfRemovedItem)
    //     }
        
    // }

    const onChange = (currentNode, outstandingIndicators) => {
        // console.log(outstandingIndicators)
        if (currentNode.checked) {
            // console.log(currentNode.checked)
            dispatch(addNewIndicatorInMacroeconomic([workspaceId, mainLayoutId, subLayoutIdx, 
                                                currentNode.economic_indicator_code, currentNode.country, currentNode.source]))
        } else {
            
            // const removedIndex = outstandingIndicators.indexOf(currentNode.economic_indicator_code)
            // console.log(removedIndex)
            dispatch(removeIndicatorInMacroeconomic([workspaceId,
                                                    mainLayoutId,
                                                    subLayoutIdx, 
                                                    currentNode.economic_indicator_code]));
        } 
        // console.log(currentNode);
      };

    useEffect(() => {
        let formData = {
            indicators: currentIndicators,
        }
        MarketDataService.getMacroeconomicIndicatorGenerals(formData)
                        .then(response => {
                            
                            // for (let i in response['data']){
                            //     for (let z in response['data'][i]['children']){
                            //         for (let k in response['data'][i]['children'][z]['children']){
                            //             for (let g in response['data'][i]['children'][z]['children'][k]['children']){
                            //                 if (currentIndicators.includes(response['data'][i]['children'][z]['children'][k]['children'][g]['value'])){
                            //                     response['data'][i]['children'][z]['children'][k]['children'][g]['checked']=true
                            //                 }
                            //             }
                            //         }
                            //     }
                            // }
                            console.log(response['data'])
                            setIndicatorGenerals(response['data'])
                            
                        })
    },[])


    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" size='lg' backdrop='static'>
            <Modal.Header closeButton style={{backgroundColor:'black'}}>
                <Modal.Title id="contained-modal-title-vcenter" style={{color:'white'}}>
                Economic Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid" style={{backgroundColor:'black'}}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="add-economic-indicator" >
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className='flex-column'>
                            <Nav.Item>
                                <Nav.Link eventKey="add-economic-indicator">
                                    <FormatColorFillIcon fontSize='small' />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="adjust-date-range">
                                    Date Range
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="add-economic-indicator">
                                <form id='add-economic-indicator' className="item"
                                        //  onSubmit={handleSubmitInstrument}
                                >
                                    <div className='economic-selection'>
                                        <div>
                                            <div style={{display:"inline-block", margin:"0",padding:"0",  width:width/2, height:40,position: "relative", zIndex:1 }}>    
                                                <Container
                                                    data={indicatorGenerals} 
                                                    onChange={(currentNode) => onChange(currentNode, currentIndicators)}
                                                    keepOpenOnSelect={true}
                                                    texts={{placeholder: "Choose indicator..."}}
                                                    className="react-multi-select-component"
                                                />
                                            </div> 
                                        </div>
                                        
                                        <IndicatorConfigTable
                                            workspaceId={workspaceId}
                                            mainLayoutId={mainLayoutId}
                                            subLayoutIdx = {subLayoutIdx} 
                                            data={dataIndicatorConfigTable} 
                                            // layoutId = {layout.id} 
                                            // mainChartActions={mainChartActions}
                                            suggestions={currentIndicators}
                                                    
                                        />
                                        
                                    </div>
                                </form>
                            </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content>
                            <Tab.Pane eventKey="adjust-date-range">
                                <form id='adjust-date-range' className="item"
                                        //  onSubmit={handleSubmitInstrument}
                                >
                                    <div className='date-range-selection'>
                                        
                                        <IndicatorConfigTable
                                            workspaceId={workspaceId}
                                            mainLayoutId={mainLayoutId}
                                            subLayoutIdx = {subLayoutIdx} 
                                            data={dataIndicatorConfigTable} 
                                            // layoutId = {layout.id} 
                                            // mainChartActions={mainChartActions}
                                            suggestions={currentIndicators}
                                                    
                                        />
                                        
                                    </div>
                                </form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>  
                </Row>
            </Tab.Container>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor:'black'}}>
                <Button style={{color:'white'}} onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default EconomicModal;