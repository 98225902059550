import {useEffect, useState,} from 'react';
// import isDeepEqual from 'fast-deep-equal'
import PropTypes from 'prop-types';
import {Grid,
        Card, CardContent, Typography,
        AppBar,Tabs,Tab, ListItemText} from '@mui/material'

import {styled} from '@mui/system';
import  StyledEngineProvider  from '@mui/material/StyledEngineProvider';
import MarketDataService from '../../../services/MarketService'
import {useSelector } from 'react-redux';
// import Autocomplete from '../../UniversalComponents/Autocomplete';
import Summary from './Summary/Summary';
import Financial from './Financial/Financial';


const StyledCard = styled(Card) (({theme}) => ({
    backgroundColor:'#282c34',
            color:'white',
            border:'none',
            height:90
}));

const StyledAppBar = styled(AppBar) (({theme}) => ({
    bbackgroundColor:'grey',
    width:300,
    height:30 
}));

const StyledListItemText = styled(ListItemText) (({theme}) => ({
    '& span, & svg': {
        fontSize: '0.8rem'
        },
}));


const TabPanel = (props) => {
    const {children,  value, index, ...other} = props
    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    )
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Overview = (props) => {
    // console.log('overview is re-rendered')
    const layoutProps = props.layout.layoutProps
    
    const workspaceId = props.workspaceId
    const mainLayoutId = props.layout.id
    const cols = props.mainBreakpoint.currentCols || 12
    // console.log(layoutProps)
    // const reduxStore = useSelector(state => state)
    // const overviewState = reduxStore.overview
    let ticker = useSelector(state => state.overview[workspaceId][mainLayoutId]['tickerParams'][0]['ticker'])
    // if (overviewState[mainLayoutId]) {
    //     ticker = overviewState[mainLayoutId]['tickerParams'][0]['ticker']
    // } else {
    //     ticker = null
    //     dispatch(initiateOverview([mainLayoutId,null]))
    // }
    const [priceParams, setPriceParams] = useState()
    const [value, setValue] = useState(0)
    const width = window.innerWidth * layoutProps.w / cols
    const height = (layoutProps.h - 1) * 30
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [isTickerAvailable, setTickerAvailable] = useState(ticker? true:false); 

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    // const handleChangeIndex = (index) => {
    //     setValue(index)
    // }

    const StyledGrid = styled(Grid) (({theme}) => ({
        height:(layoutProps.h - 1) * 30,
            maxWidth: window.innerWidth * layoutProps.w / 10,
            overflow:'auto',
            '&::-webkit-scrollbar': {
                width: "8px",
                height: "8px",
            },
            //https://codepen.io/aurer/pen/hnDgC -> scrollbar template
            '&::-webkit-scrollbar-track': {
                '-webkit-box-shadow': "inset 0 0 6px rgba(0,0,0,0.3)",
                'border-radius': "10px",
                'background': 'rgba(0,0,0,0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
                'border-radius': '10px',
                'background': 'linear-gradient(left, #999, #888)',
                '-webkit-box-shadow': 'inset 0 0 1px rgba(0,0,0,0.8)',
            },
      }));
    
    
    useEffect(() =>{
        if (ticker) {
            setLoading(true);
            setTickerAvailable(true);
            getTickerInfo(ticker);
        }
    },[ticker])
    
    const getTickerInfo = (ticker) => {
        try {
            MarketDataService.getTickerOverview(ticker)
                            .then(response => {
                                    setPriceParams(response.data)
                                    setLoading(false)
                            }).catch(e => {
                                setLoading(false)
                                setError(true)
                            })
        } catch (error) {
            alert(error)
        }
    }

    
    // const classes = useStyles()
	return (
        <>
            
            <StyledEngineProvider injectFirst>
             
             { ticker && priceParams && !isLoading && !isError &&
             <StyledGrid container 
                 spacing={2} 
                 // xs={4} sm={6} md={10} lg={12} 
                 justify="flex-start"
                 
                 // style={{maxHeight:(layoutProps.h - 1) * 30,
                 //         maxWidth: window.innerWidth * layoutProps.w / 10,
                 //         overflowY:'auto'}}
             >
                 <Grid item style={{minWidth:1000}} xs={4} sm={6} md={10} lg={12}>
                     <StyledCard >
                         <CardContent>
                             <Typography 
                                 variant='h5'
                                 display='inline' 
                                 // className={classes.listItemText}
                             >
                                 {priceParams['ticker'].toUpperCase()}
                             </Typography>
                             <Typography 
                                 variant='h6'
                                 display='inline'
                                 style={{marginLeft:40}} 
                                 // className={classes.typography} 
                             >
                                 {priceParams['long_name']}
                             </Typography>
                             <Typography 
                                 variant='h6'
                                 display='inline'
                                 style={{marginLeft:40}} 
                                 // className={classes.typography} 
                             >
                                 {priceParams['ExchangeName']}
                             </Typography>
                             <br/>
                             <Typography 
                                 variant='h6'
                                 display='inline'
                                 style={{color : priceParams['net_change'] < 0 ? 'red' : 'green' }} 
                             >
                                 {priceParams['last']}
                             </Typography>
                             <Typography 
                                 variant='h6'
                                 display='inline' 
                                 style={{marginLeft:40, color : priceParams['net_change'] < 0 ? 'red' : 'green'}} // className={classes.typography} 
                             >
                                 {priceParams['net_change']}
                             </Typography>
                             <Typography 
                                 variant='h5'
                                 display='inline' 
                                 style={{marginLeft:40, color : priceParams['net_change'] < 0 ? 'red' : 'green'}} // className={classes.typography} 
                             >
                                 {priceParams['pct_change']}
                             </Typography>
                         </CardContent> 
                     </StyledCard>
                 </Grid>
                 <Grid item xs={4} sm={6} md={10} lg={12}>
                     <StyledAppBar position='static' >
                         <Tabs
                             value={value}
                             onChange={handleChange}
                             indicatorColor='primary'
                             // textColor='primary'
                             variant='fullWidth'
                             aria-label="full width tabs example"
                             style={{minHeight:'30px', alignItems:'center'}}
                         >
                             <Tab label="Summary" {...a11yProps(0)} style={{color:'white'}} />
                             <Tab label="Financial" {...a11yProps(1)} style={{color:'white'}}/>
                         </Tabs>
                     </StyledAppBar>
                 </Grid>
                 {/* <Grid item xs={12} sm={8}>
                     <Paper style={{ height:"90px",width:580, background:'lightgrey'}} />
                 </Grid> */}
                 <TabPanel value={value} index={0}>
                     <Summary
                         workspaceId={workspaceId} 
                         mainLayoutId={mainLayoutId}
                         priceParams={priceParams}
                         // classes={classes}
                         width={width}
                         height={height}
                     /> 
                 </TabPanel>
                 <TabPanel value={value} index={1}>
                     <Financial
                         workspaceId={workspaceId}
                         mainLayoutId={mainLayoutId}
                     />
                 </TabPanel>
             </StyledGrid>}
             {!isTickerAvailable &&<div><span style={{color:'white'}}>Please input a ticker</span></div> }
             {isLoading && <span style={{color:'white'}}>Loading...</span>}
            {isError && <span style={{color:'white'}}>We have data error. Try to refresh later</span>}
         </StyledEngineProvider>
        </>
        
	)
}

export default Overview