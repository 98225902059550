function generateLightColorHex() {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
}

const template = {
    'Vietnam': [
        {
            macroeconomicComponentName:'Vietnam GDP',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:['Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam'],
            indicators:['VN.GDP.GTVI','VN.GDP.GAFAFVI','VN.GDP.GIACVI','VN.GDP.GSVI','VN.GDP.GTCP','VN.GDP.GAFAFCP','VN.GDP.GIACCP','VN.GDP.GSCP'],
            sources:['GSO','GSO','GSO','GSO','GSO','GSO','GSO','GSO'],
            chartParams:{
                tickerParams:[
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
        {
            macroeconomicComponentName:'Vietnam CPI',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:['Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam'],
            indicators:['VN.CPI.CHYC','VN.CPI.CFACSYC','VN.CPI.CHACMYC','VN.CPI.CTYC','VN.CPI.CMAHSYC','VN.CPI.CEYC','VN.CPI.CHAGR'],
            sources:['GSO','GSO','GSO','GSO','GSO','GSO','GSO'],
            chartParams:{
                tickerParams:[
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
        {
            macroeconomicComponentName:'Vietnam CPI',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:['Vietnam','Vietnam','Vietnam','Vietnam'],
            indicators:['VN.RET.RSTCC','VN.RET.RSTSCC','VN.RET.RSTMC','VN.RET.RSTSMC'],
            sources:['GSO','GSO','GSO','GSO'],
            chartParams:{
                tickerParams:[
                    
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
        {
            macroeconomicComponentName:'Vietnam Industry Sector',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:['Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam'],
            indicators:['VN.IIP.IWIYC','VN.IIP.IMAQYC','VN.IIP.IMYC','VN.IIP.IWIMC','VN.IIP.IMAQMC','VN.IIP.IMMC'],
            sources:['GSO','GSO','GSO','GSO','GSO','GSO'],
            chartParams:{
                tickerParams:[
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleBar',
                        
                    },
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
        {
            macroeconomicComponentName:'Vietnam Trade',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:['Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam','Vietnam'],
            indicators:['VN.TRADE.ETOTYOY','VN.TRADE.ECEPSACTMTD','VN.TRADE.ETMPAPTMTD','VN.TRADE.ETAGMTD','VN.TRADE.EMETAIMTD','VN.TRADE.EWAWPMTD','VN.TRADE.ECOMTD'],
            sources:['Customs','Customs','Customs','Customs','Customs','Customs','Customs'],
            chartParams:{
                tickerParams:[
                    {
                        axis:'R1',
                        color:generateLightColorHex(),
                        chartType:'toggleLine',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    {
                        axis:'L1',
                        color:generateLightColorHex(),
                        chartType:'toggleStackedBar',
                        
                    },
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
    ],
    'Customs':[
        {
            macroeconomicComponentName:'Untitled',
            fromDate:'',
            toDate:'',
            today:true,
            periodRange:'366',
            layoutType:'chart',
            countries:[],
            indicators:[],
            sources:[],
            chartParams:{
                tickerParams:[
                    
                ],
                svgParams:{
                    margin:{top: 30, right: 50, bottom: 30, left:50 },
                    width:860,
                    height:420,
                    
                 },
            }

        },
    ]
}

export const macroeconomicReducer = (state={}, action) => {
    switch (action.type) {
        case 'CREATE_MACROECONOMIC': {
            let [workspaceId, mainLayoutId] = action.payload
            // const initSubLayouts = [
            //     {
            //         id: 0,
            //         layoutProps: {
            //             x:0,
            //             y:0,
            //             w:6,
            //             h:12,
            //             i:`${mainLayoutId+1}.0`,
            //             minW:2,
            //             // maxW:6,
            //             minH:2,
            //             // maxH:6
            //         },
            //         macroeconomicComponentName:'Vietnam GDP',
            //         countries:['Vietnam','Vietnam'],
            //         indicators:['VN.CPI.CHMOM','VN.GDP.GSCP'],
            //         sources:['GSO','GSO'],
            //         fromDate:'',
            //         toDate:'',
            //         periodRange:'366',
            //         layoutType:'chart',
            //         chartParams:{
            //             tickerParams:[
            //                 {
            //                     axis:'R1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleLine',
            //                     isBarChart:false,
            //                     isStackedBarChart:false,
            //                 },
            //                 {
            //                     axis:'R1',
            //                     color:`#${Math.random().toString(16).substr(-6)}`,
            //                     chartType:'toggleLine',
            //                     isBarChart:false,
            //                     isStackedBarChart:false,
            //                 }
            //             ],
            //             svgParams:{
            //                 margin:{top: 30, right: 50, bottom: 30, left:50 },
            //                 width:860,
            //                 height:420,
                            
            //              },
            //         }
            //     },
            //     {
            //         id: 1,
            //         layoutProps: {
            //             x:6,
            //             y:0,
            //             w:6,
            //             h:12,
            //             i:`${mainLayoutId+1}.1`,
            //             minW:2,
            //             // maxW:6,
            //             minH:2,
            //             // maxH:6
            //         },
            //         macroeconomicComponentName:'Vietnam CPI',
            //         // macroeconomic_category:['Prices','Prices'],
            //         // macroeconomic_sub_category:['Consumer Prices/Inflation','Consumer Prices/Inflation'],
            //         countries:['Vietnam','Vietnam'],
            //         indicators:['VN.CPI.CCIAGR','VN.CPI.CHYOY'],
            //         sources:['GSO','GSO'],
            //         fromDate:'',
            //         toDate:'',
            //         periodRange:'366',
            //         layoutType:'chart',
            //         chartParams:{
            //             tickerParams:[
            //                 {
            //                     axis:'R1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleLine',
            //                     isBarChart:false,
            //                     isStackedBarChart:false,
            //                 },
            //                 {
            //                     axis:'L1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleLine',
            //                     isBarChart:false,
            //                     isStackedBarChart:false,
            //                 }
            //             ],
            //             svgParams:{
            //                 margin:{top: 30, right: 50, bottom: 30, left:50 },
            //                 width:860,
            //                 height:420,
            //                 adjustedHeight: 420,
            //              },
            //         }
            //     },
            //     {
            //         id: 2,
            //         layoutProps: {
            //             x:0,
            //             y:7,
            //             w:6,
            //             h:12,
            //             i:`${mainLayoutId+1}.2`,
            //             minW:2,
            //             // maxW:6,
            //             minH:2,
            //             // maxH:6
            //         },
            //         macroeconomicComponentName:'Vietnam Retail',
            //         // macroeconomic_category:['Consumer Sector','Consumer Sector','Consumer Sector'],
            //         // macroeconomic_sub_category:['Retail sales','Retail sales', 'Retail sales'],
            //         countries:['Vietnam','Vietnam','Vietnam'],
            //         indicators:['VN.RET.RSTMC','VN.RET.RSRSMC','VN.RET.RSTSMC'],
            //         sources:['GSO','GSO','GSO'],
            //         fromDate:'',
            //         toDate:'',
            //         periodRange:'366',
            //         layoutType:'chart',
            //         chartParams:{
            //             tickerParams:[
            //                 {
            //                     axis:'R1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleBar',
            //                     isBarChart:true,
            //                     isStackedBarChart:false,
                                
            //                 },
            //                 {
            //                     axis:'R1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleBar',
            //                     isBarChart:true,
            //                     isStackedBarChart:false,
                                
            //                 },
            //                 {
            //                     axis:'R1',
            //                     color:generateLightColorHex(),
            //                     chartType:'toggleBar',
            //                     isBarChart:true,
            //                     isStackedBarChart:false,
                                
            //                 }
            //             ],
            //             svgParams:{
            //                 margin:{top: 30, right: 50, bottom: 30, left:50 },
            //                 width:860,
            //                 height:420,
            //                 adjustedHeight: 420,
            //              },
            //         }
            //     },


            // ]
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        items:[],
                        newCounter:0
                    }
                    
                }
            };
        };
        case 'REMOVE_MACROECONOMIC': {
            let [workspaceId, mainLayoutId] = action.payload
            if (!isNaN(mainLayoutId)) {
                const {[workspaceId]: {[mainLayoutId]: value, ...newState}} = state
                return {...state,
                    [workspaceId]: newState
                };
            } else {
                const {[workspaceId]: value, ...newState} = state
                return newState;
            }
        };
        case 'ADD_NEW_ECONOMIC_TEMPLATE': {
            let [workspaceId, mainLayoutId, country] = action.payload;
            console.log(template[country])
            const layouts = template[country].map((tem, idx) => ({
                id: idx,
                layoutProps: {
                    x:idx*6,
                    y:Infinity,
                    w:6,
                    h:12,
                    i:`${mainLayoutId+1}.${idx}`,
                    minW:4,
                    minH:4,
                },
                ...tem
            }))
            console.log(layouts)
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        items: layouts,
                        newCounter: template[country].length
                    }
                }
            }
        };

        case 'ADD_NEW_ECONOMIC_SUBLAYOUT': {
            let [workspaceId, mainLayoutId, newLayout, newCounter] = action.payload
            // console.log(newLayout)
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        items: [
                            ...state[workspaceId][mainLayoutId]['items'],
                            newLayout
                        ],
                        newCounter: newCounter+1
                    }
                }
            }
        };
        case 'DELETE_ECONOMIC_SUBLAYOUT': {
            let [workspaceId, mainLayoutId, subLayoutIdx] = action.payload
            // console.log(newLayout)
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        items:[
                            ...state[workspaceId][mainLayoutId]['items'].filter((_,idx) => idx !== subLayoutIdx)
                        ]
                    }
                }
            }
        };
        case 'UPDATE_ECONOMIC_SUBLAYOUT_PROPS':{
            let [workspaceId,mainLayoutId,updatedSubLayoutProps] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        items:[
                            ...state[workspaceId][mainLayoutId]['items'].map((item, index) => {
                                // if (item.layoutProps.i == ) 
                                return {
                                    ...item,
                                    layoutProps:{
                                        ...item.layoutProps,
                                        ...updatedSubLayoutProps[index]
                                    }
                                }
                            })
                        ]
                    }
                }
            }
        };
        case 'UPDATE_ECONOMIC_SUBLAYOUT_PERIOD_RANGE' :{
            let [workspaceId, mainLayoutId,subLayoutIdx, periodRange] = action.payload
            
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]: {
                        ...state[workspaceId][mainLayoutId],
                        items:[
                            ...state[workspaceId][mainLayoutId]['items'].map((item,index)=> {
                                if (index === subLayoutIdx) {
                                    // console.log(...item['indicators'])
                                    return {
                                        ...item,
                                        periodRange:periodRange
                                    }
                                }
                                return item;
                            })
                            
                        ]
                    }
                }
            };
        };
        case 'ADD_NEW_INDICATOR_IN_MACROECONOMIC' :{
            let [workspaceId, mainLayoutId,subLayoutIdx, selectedIndicator, country, source] = action.payload
            
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]: {
                        ...state[workspaceId][mainLayoutId],
                        items:[
                            ...state[workspaceId][mainLayoutId]['items'].map((item,index)=> {
                                if (index === subLayoutIdx) {
                                    // console.log(...item['indicators'])
                                    return {
                                        ...item,
                                        indicators:[
                                            ...item['indicators'],
                                            selectedIndicator
                                        ],
                                        countries:[
                                            ...item['countries'],
                                            country
                                        ],
                                        sources:[
                                            ...item['sources'],
                                            source
                                        ],
                                        chartParams:{
                                            ...item['chartParams'],
                                            tickerParams:[
                                                ...item['chartParams']['tickerParams'],
                                                {
                                                    axis:'R1',
                                                    color:generateLightColorHex(),
                                                    chartType:'toggleLine',
                                                    isBarChart:false,
                                                    isStackedBarChart:false,
                                                },
                                                
                                            ]
    
                                        }
                                    }
                                }
                                return item;
                            })
                            
                        ]
                    }
                }
            };
        };
        case 'REMOVE_INDICATOR_IN_MACROECONOMIC' :{
            let [workspaceId, mainLayoutId,subLayoutIdx, removedIndicator] = action.payload
            
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]: {
                        ...state[workspaceId][mainLayoutId],
                        items: [
                            ...state[workspaceId][mainLayoutId]['items'].map((item,index)=> {
                                if (index === subLayoutIdx) {
                                    const removedIndex = item['indicators'].indexOf(removedIndicator)
                                    
                                    return {
                                        ...item,
                                        indicators:[
                                            ...item['indicators'].filter((_,idx) => idx !== removedIndex)
                                        ],
                                        countries:[
                                            ...item['countries'].filter((_,idx) => idx !== removedIndex)
                                        ],
                                        sources:[
                                            ...item['sources'].filter((_,idx) => idx !== removedIndex)
                                        ],
                                        chartParams:{
                                            ...item['chartParams'],
                                            tickerParams:[
                                                ...item['chartParams']['tickerParams'].filter((param, idx) => idx !== removedIndex)
                                                
                                            ]
    
                                        }
                                    }
                                }
                                return item;
                            })
                            
                        ]
                    }
                }
            };
        };
        case 'UPDATE_SVG_PARAMS_ECONOMIC_CHART': {
            
            let [workspaceId, mainLayoutId, subLayoutIdx, svgParams] = action.payload
            return {
                ...state,
                [workspaceId]:{
                    ...state[workspaceId],
                    [mainLayoutId]:{
                        ...state[workspaceId][mainLayoutId],
                        items: [
                            ...state[workspaceId][mainLayoutId]['items'].map((item, index) => {
                                if (index === subLayoutIdx){
                                    // console.log(index, subLayoutIdx)
                                    return {
                                        ...item,
                                        chartParams:{
                                            ...item.chartParams,
                                            svgParams:{
                                                ...item.chartParams.svgParams,
                                                ...svgParams
                                            }
                                        }
                                    }
                                    
                                }
                                return item
                            })
                        ]
                    }
                }
            }  
        };
        case 'UPDATE_TICKER_PARAMS_ECONOMIC_CHART': {
            
            let [workspaceId, mainLayoutId, subLayoutIdx, tickerIndex, updatedtickerParams, isMassUpdate] = action.payload
            if (isMassUpdate){
                /*
                if the bar chart exists, then when user changes axis, 
                all axiss of ticker params which containing barChar will be changed accordingly
                */
                return {
                    ...state,
                    [workspaceId]:{
                        ...state[workspaceId],
                        [mainLayoutId]: {
                            ...state[workspaceId][mainLayoutId],
                            items: [
                                ...state[workspaceId][mainLayoutId]['items'].map((item, index) => {
                                    if (index === subLayoutIdx){
                                        // console.log(index, subLayoutIdx)
                                        return {
                                            ...item,
                                            chartParams:{
                                                ...item.chartParams,
                                                tickerParams: [
                                                    ...item.chartParams.tickerParams.map((tickerItem, tickerItemIndex) => {
                                                        if (tickerItem['chartType'] === 'toggleBar' ||tickerItem['chartType'] === 'toggleStackedBar' || tickerIndex === tickerItemIndex) {
                                                            return {
                                                                ...tickerItem,
                                                                ...updatedtickerParams
                                                            }
                                                        } 
                                                        // else if (fieldName == 'chartType' && tickerItem['chartType']=='toggleLine') {

                                                        // }
                                                        return tickerItem
                                                        }
                                                    )
                                                ]
                                            }
                                        }
                                        
                                    }
                                    return item
                                })
                            ]
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    [workspaceId]:{
                        ...state[workspaceId],
                        [mainLayoutId]: {
                            ...state[workspaceId][mainLayoutId],
                            items: [
                                ...state[workspaceId][mainLayoutId]['items'].map((item, index) => {
                                    if (index === subLayoutIdx){
                                        // console.log(index, subLayoutIdx)
                                        return {
                                            ...item,
                                            chartParams:{
                                                ...item.chartParams,
                                                tickerParams: [
                                                    ...item.chartParams.tickerParams.map((tickerItem, tickerItemIndex) => {
                                                        if (tickerItemIndex === tickerIndex) {
                                                            return {
                                                                ...tickerItem,
                                                                ...updatedtickerParams
                                                            }
                                                        }
                                                        return tickerItem
                                                        }
                                                    )
                                                ]
                                            }
                                        }
                                        
                                    }
                                    return item
                                })
                            ]
                        }
                    }
                }  
            }
            
        };

        case 'TOGGLE_LAYOUT_TYPE': {
            let [workspaceId, mainLayoutId, subLayoutIdx, updatedLayoutType] = action.payload;
            
            return {
                ...state,
                [workspaceId]: {
                    ...state[workspaceId],
                    [mainLayoutId]: {
                        ...state[workspaceId][mainLayoutId],
                        items: [
                            ...state[workspaceId][mainLayoutId]['items'].map((item,index) => {
                                if(index === subLayoutIdx) {
                                    return {
                                        ...item,
                                        layoutType: updatedLayoutType
                                    }
                                }
                                return item
                            })
                        ]
                    }
                }

            }
        }
        default:
            return state;
    }
}