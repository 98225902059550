import Table from '../../../UniversalComponents/Table';
import MarketDataService from "../../../../services/MarketService";
import {useEffect, useState} from 'react';

const MacroeconomicTable = (props) => {
    // const {id, layoutProps} = props.layout;
    // const mainLayoutId = props.mainLayoutId;
    const subLayoutProps = props.subLayoutProps;
    // console.log(props)
    // const workspaceId = props.workspaceId;
    const indicators = props.indicators;
    const countries = props.countries;
    const sources = props.sources;
    const periodRange = props.periodRange;
    const cols = props.cols;
    // const height = (subLayoutProps.h -1) * 30 - 60
    // const width = window.innerWidth * subLayoutProps.w / cols - 100

    const [dataset, setDataset]= useState([]);
    const [columns, createColumns] = useState([]);
    // console.log(dataset)
    // const indicators = props.indicators;
    const [loading, setLoading] = useState(true);
    
    // const uniqueReferencePeriod = [...new Set(dataset.map(item => item['reference_period']))]
    /*
    [
        {
            ....
            economic_indicator_name: "GDP, Total, Constant Prices"
            reference_period: "6-2022"
            value: 1346821.3015521285
            ....
        },
        {
            ....
            economic_indicator_name: "GDP, Total, Constant Prices"
            reference_period: "9-2022"
            value: 1376320.95164018
            ....
        },
        {
            ....
            economic_indicator_name: "GDP, Services, Constant Prices"
            reference_period: "6-2022"
            value: 558902.7014410442
            ....
        },
        {
            ....
            economic_indicator_name: "GDP, Services, Constant Prices"
            reference_period: "9-2022"
            value: 587646.7209197523
            ....
        },
        ...

    ]

    turn it in to =>

    [
        {
            6-2022: 1346821.3015521285,
            9-2022: 1376320.95164018,
            Indicator: "GDP, Total, Constant Prices"
        {
            6-2022: 558902.7014410442
            9-2022: 587646.7209197523
            Indicator: "GDP, Services, Constant Prices"
        }
    ]


    */

    useEffect(() => {
        let formData = {
            indicators: indicators,
            countries: countries,
            sources:sources,
            periodRange:periodRange
        }
        MarketDataService.getMacroeconomicData(formData)
                        .then(response => {
                            let data = response['data']['data'].flat()
                            // let filterData={}
                            // let numberOfIndicatorInEachSubLayout = storedMacroeconomicState.map(s => s['indicators'].length)
                            // // console.log(numberOfIndicatorInEachSubLayout)
                            // // console.log(data.slice(2,4))
                            // //transform [Array(2),Array(2),Array(5),Array(5)] => {'2.1':[Array(2), Array(2)], '2.2':[Array(5), Array(5)]}
                            // let firstIndex = 0
                            // for (let i = 0; i< childLayoutIds.length; i++){
                            //      let secondIndex = firstIndex + numberOfIndicatorInEachSubLayout[i];
                            //     filterData[childLayoutIds[i]] = data.slice(firstIndex,secondIndex).flat()
                            //     firstIndex = secondIndex;
                            // }
                            
                            // console.log(data.flat())
                            const uniqueIndicatorNames = [...new Set(data.map(item => item['economic_indicator_name']))];
                            const newDataset = []
                            for (let indicatorName of uniqueIndicatorNames) {
                                let subObject = {
                                    'Indicator':indicatorName,
                                }
                                for (let d of data) {
                                    if (d['economic_indicator_name']===indicatorName){
                                        subObject[d['reference_period']]= d['unit'] === 'Index' ? d['value']-100 : d['value']
                                    }
                                    
                                }
                                newDataset.push(subObject)
                            }
                            // console.log(newDataset)

                            const newColumns = Object.keys(newDataset[0]).map(key => {
                                return {
                        
                                        Header:key,
                                        accessor:key,
                                        Cell:(props) => {
                                            
                                            let cellValue = props.column.Header !=='Indicator' ? Number(props.value).toFixed(2) : props.value;
                                            return (
                                                <div>
                                                    <span style={{color: props.column.Header !=='Indicator'?'white':'green'}}>{cellValue}</span>
                                                </div>
                                            )
                                        },
                                        // padding:0,
                                        // width:100
                                    
                                }
                            })
                            setDataset(newDataset)
                            createColumns(newColumns)
                            setLoading(false)

                        })
    },[indicators,periodRange]) //why "indicators" variable cause useEffect continuously called

    
    

    // const columns = useMemo(
    //     () => [
    //         // {
    //         //     Header:'Indicators',
    //         //     accessor:'economic_indicator_name',
    //         //     Cell:(props) => {
    //         //         console.log(props)
    //         //         return (
    //         //             <div>
    //         //                 <span style={{color:'green'}}>{props.value}</span>
    //         //             </div>
    //         //         )
    //         //     },
    //         //     padding:0,
    //         //     width:100
    //         // },
    //         ...childColumns,
            
    //     ],[]
    // )
    return (
        <div className = "container">
            
            <div className="row">
                {loading && <span style={{color:'white'}}>Loading...</span>}
                {dataset.length !== 0 && !loading && <Table columns={columns} data={dataset} layout={{'component':'priceboard','layoutProps':subLayoutProps}} cols={cols}/>}
                {dataset.length === 0 && !loading && <span style={{color:'white'}}>There is no data</span>}
            </div>
        </div>
    )
}
export default MacroeconomicTable