import React,{ useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Typography from '@mui/material/Typography';

import {styled} from '@mui/system';
import Container from '@mui/material/Container';


import * as settings from '../../settings'
// import {useHistory, useLocation} from 'react-router-dom'
import axios from 'axios';

const StyledPaper = styled('div') (({theme}) => ({
  marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const StyledAvatar = styled(Avatar) (({theme}) => ({
  margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary,
}));

const StyledForm = styled('form') (({theme}) => ({
  width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    background:"dark",
}));
const StyledButton = styled(Button) (({theme}) => ({
  margin: theme.spacing(3, 0, 2),
}));
const StyledTypography = styled(Typography) (({theme}) => ({
  color: theme.palette.success
}));



function PasswordUpdate(props) {
  
  const [new_password1, setNewPassword1] = useState(null)
  const [new_password2, setNewPassword2] = useState(null)
  const [success, setSuccess] = useState(false)
  const handleFormFieldChange = (event) => {
    setSuccess(false)
    switch(event.target.id) {
      case 'new_password1': setNewPassword1(event.target.value); break;
      case 'new_password2': setNewPassword2(event.target.value); break;
      default: return null
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (new_password1 !== new_password2) {
      alert("Passwords don't match")
    } else {
      let headers = {'Authorization': `Token ${props.token}`}
      let method='post'
      let url = settings.API_SERVER + '/api/auth/update_password/'
      let passwordFormData = new FormData();
      passwordFormData.append("new_password1", new_password1)
      passwordFormData.append("new_password2", new_password2)
      let config = {headers, method, url, data:passwordFormData}

      //Axios update_password API Call
      axios(config).then(res => {
        setSuccess(true);
      }).catch(error=> {
        alert(error)
      })
    }
  }

  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <StyledPaper>
        {success ? <StyledTypography variant="button" gutterBottom>Password update successful!</StyledTypography>:null}
        <StyledAvatar>
          <VpnKeyIcon />
        </StyledAvatar>
        <Typography component="h1" variant="h5">
          Update Password
        </Typography>
        <StyledForm  noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="new_password1"
            label="Enter New Password"
            name="new_password1"
            type="password"
            error={new_password1 !== new_password2}
            helperText={new_password1 !== new_password2 ? "Passwords don't match": null}
            onChange={handleFormFieldChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="new_password2"
            label="Enter New Password"
            name="new_password2"
            type="password"
            error={new_password1 !== new_password2}
            helperText={new_password1 !== new_password2 ? "Passwords don't match": null}
            onChange={handleFormFieldChange}
          />
          
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            
          >
            Sign In
          </StyledButton>
          
        </StyledForm>
      </StyledPaper>
      
    </Container>
  );
}

export default PasswordUpdate