import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

import Upload from '../Upload/Upload';

export default function AccountMenu (props) {
    const [uploadModalShow, setUploadModalShow] = React.useState(false);
    const [anchorEL, setAnchorEL] = React.useState(null);
    const open = Boolean(anchorEL);
    const handleClick = (event) => {
        // console.log(event.currentTarget)
        setAnchorEL(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEL(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ display:'flex', alignItems:'center', textAlign:'center'}}>
                <Tooltip title='Account Setting'>
                    <IconButton
                        onClick={handleClick}
                        style={{position:"absolute", right:"10px",top:10,borderColor:'white',color:'white', padding:0,margin:0}}
                        size='small'
                        sx={{ml : 2}}
                        aria-controls = {open ? 'account-menu' : undefined}
                        aria-haspopup = "true"
                        aria-expanded = {open ? 'true' : undefined}
                    >
                        <Settings fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEL}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    
                    component={Link}
                    to={props.changePassword}
                >
                    <ListItemIcon>
                        <VpnKeyIcon fontSize="small" />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem
                    onClick={() => setUploadModalShow(true)}
                >
                    <ListItemIcon>
                        <FileUploadIcon fontSize="small" />
                    </ListItemIcon>
                    Upload
                </MenuItem>
                <MenuItem
                    onClick={() => props.logout()}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {uploadModalShow &&
                    <div onMouseDown={e => e.stopPropagation()}> 
                    {/* IMPORTANT capture the grid item's click and cancel the drag event  https://stackoverflow.com/questions/38543470/capturing-grid-item-click-event-in-react-grid-layout */}
                        <Upload
                            show={uploadModalShow}
                            onHide={() => setUploadModalShow(false)}
                        />
                    </div>
            }
        </React.Fragment>
    );

}