import * as d3 from 'd3'
import {select, selectAll,
        min,max,
        scaleLinear,
        pointer} from 'd3'
const crossHair = (chartId,timeSeriesData,
                    zoomEvent,chartParams,
                    xScale,yAxisLeftWidth,yAxisRightWidth) => {
    // define x and y crosshair properties
    selectAll(`#${chartId} > g > .focus`).remove()              
    selectAll(`#${chartId} > g > .overlay`).remove()
    const width = chartParams.svgParams.width
    const height = chartParams.svgParams.height
    
    const adjustedHeight = chartParams.svgParams.adjustedHeight
    const height_crosshair = height
    // console.log(timeSeriesData.historicalPrice) 
    const margin = chartParams.svgParams.margin
    const {tickerParams, indicators, analysis} = chartParams

    const focus = select(`#${chartId} > g`).append('g')
                    .attr('class', `focus`)//-${chartId}-${tickerParamsIndex}`)
                    .style('display', 'none');

    // focus.append('circle').attr('r', 4.5).style('fill','white');
    focus.append('line').classed('x', true).style('stroke', '#67809f').style('stroke-width', '1.5px').style('stroke-dasharray', '3 3');
    focus.append('line').classed('y', true).style('stroke', '#67809f').style('stroke-width', '1.5px').style('stroke-dasharray', '3 3');
    
    for (let i=0;i<timeSeriesData.historicalPrice.length; i++){
        // d3.select(`#${chartId}`).selectAll(`.focusTexty-${i}`,`.focusBoxY-${i}`).remove()    
        let currentYAxisLeftWidth = selectAll(`g > .yAxis`).filter(`.y${i}`).node().getBBox().width
        focus.append('rect')
            .attr('class',`focusBoxY-${i}`)
            .attr('width',currentYAxisLeftWidth)
            .attr('height',12)
            .style('fill',`${tickerParams[i]['color']}`)
            
            
        focus.append('text')
            .attr('class', `focusTexty-${i}`)
            .attr('text-anchor','start')
            .attr('font-size',10)
            .style('fill','black');
    
    }

    focus.append('rect')
            .attr('class',`focusBoxX`)
            .attr('width',60)
            .attr('height',12)
            .style('fill','white')
    focus.append('text')
        .attr('class', 'focusTextx')
        .attr('text-anchor', 'middle')
        .style('fill','black')
        .attr('font-size',10);
    // focus.raise()
    const overlay = select(`#${chartId} > g`).append('rect')
        .attr('class', `overlay`)//-${chartId}-${tickerParamsIndex}`)
        // .attr('width', width)
        // .attr('height', height_crosshair)
        .attr('fill', 'none')
        .style('pointer-events', 'all');

    // d3.selectAll(`.focus`)//-${chartId}-${tickerParamsIndex} line`)
        // focus
        // .style('stroke', '#67809f')
        // .style('stroke-width', '1.5px')
        // .style('stroke-dasharray', '3 3');

    

    // enter and update the attributes
    overlay.enter()
        .append('g')
        .attr('class', `focus`)//-${chartId}-${tickerParamsIndex}`)
        .style('display','none');

    overlay//.attr('class', 'overlay')
        .attr('width', width)
        .attr('height', height_crosshair)
        .attr('transform',`translate(${0},0)`)
        .on('mouseover', () => focus.style('display', null))
        .on('mouseout', () => focus.style('display', 'none'))
        .on('mousemove', (event) => generateCrosshair(event,chartId,tickerParams,indicators,analysis, timeSeriesData,zoomEvent,xScale,width,height,height_crosshair,adjustedHeight,margin,yAxisLeftWidth,yAxisRightWidth));      
}

const generateCrosshair = (current,chartId,tickerParams,indicators,analysis,timeSeriesData,zoomEvent,xScale,width,height,height_crosshair,adjustedHeight) => {
    //returns corresponding value from the domain
    const focus = select(`#${chartId} > g > .focus`)//-${chartId}-${tickerParamsIndex}`);
    // const bisectDate = d3.bisector(d => d['Date']).left;
    // const correspondingDate = xScale.invert(d3.pointer(current)[0]);

    //gets insertion point
    //   const i = bisectDate(timeSeriesData, correspondingDate, 1);
    //   const d0 = timeSeriesData[i-1];
    //   const d1 = timeSeriesData[i];
    //   const currentPoint = correspondingDate - d0['Date'] > d1['Date'] - correspondingDate ? d1 : d0;

    var x = pointer(current)[0]
    var y = pointer(current)[1]
    // console.log(current.target)
    
    

    focus.attr('transform', `translate(${x}, ${y})`);
    

    focus.select('.focusTextx')
            .attr('transform',`translate(${0},${height_crosshair + 10 - y})`)
            .text(xScale.invert(x).toLocaleDateString());
    focus.select('.focusBoxX')
            .attr('transform',`translate(${-30},${height_crosshair+ 0 - y})`);

    

    let yAxisRightWidth = 0
    let yAxisLeftWidth = 0 
    for (let i=0;i<timeSeriesData.historicalPrice.length; i++){
        let yMin = d3.min(timeSeriesData.historicalPrice[i], d=> d['Close'])
        let yMax = d3.max(timeSeriesData.historicalPrice[i], d=> d['Close'])
        // incorporate different scale when zooming
        let updatedYScale = zoomEvent?.transform.rescaleY(scaleLinear().domain([yMin * 0.7, yMax * 1.1])
                                .range([adjustedHeight,0])) 
                                || scaleLinear().domain([yMin * 0.7, yMax * 1.1])
                                    .range([adjustedHeight,0])
        let [sideOfYAxis,position] = [...tickerParams[i]['axis']]
        if (sideOfYAxis === 'R') {
            let currentYAxisWidth = position === '1'? 0 : selectAll(`g > .yAxis`).filter(`.y${i}`).node().getBBox().width
            yAxisRightWidth += currentYAxisWidth
        } else {
            // console.log(sideOfYAxis)
            let currentYAxisWidth = position === '1'? 0 : selectAll(`g > .yAxis`).filter(`.y${i}`).node().getBBox().width
            yAxisLeftWidth += currentYAxisWidth
        }
        // console.log(yAxisLeftWidth)
        // console.log(indicators)
        //get the all tick values in yAxis of indicator to re-create a yIndicatorScale
        let yValues=[]
        let index
        let indicatorName
        let indicatorHeight
        let yIndicatorScaleGroup = []
        let accumulatedIndicatorHeightOfThisIndicator = 0
        for(let z =0; z< indicators.length; z++) {
            accumulatedIndicatorHeightOfThisIndicator += analysis[indicators[z]]['indicatorHeight']
            if (!select(`.${indicators[z]}-${chartId}-${i}.yRightAxis`).empty()){
                select(`.${indicators[z]}-${chartId}-${i}.yRightAxis`).selectAll(".tick")
                            .each(d=>yValues.push(d))
                index = i
                indicatorName = indicators[z].toUpperCase()
                indicatorHeight = analysis[indicators[z]]['indicatorHeight']
                const adjustedFactor = indicatorName === 'RSI' ? [1, 1]:[0.9, 1.1]
                let min = yValues[0] * adjustedFactor[0]
                let max = yValues[yValues.length - 1] * adjustedFactor[1]
                yIndicatorScaleGroup.push(scaleLinear()
                                            .domain([min,max])
                                            .range([height- (accumulatedIndicatorHeightOfThisIndicator -indicatorHeight),height-accumulatedIndicatorHeightOfThisIndicator]))//adjustedHeight])
                // break
            }
            // console.log(select(`.${indicators[z]}-${chartId}-${i}.yRightAxis`)) 
        }
        // console.log(y,yIndicatorScaleGroup.map(s=> s.range()))
        
        focus.select(`.focusBoxY-${i}`)
                .attr('transform',() => sideOfYAxis === 'R' ? `translate(${width - x - yAxisRightWidth},-5)`
                                                            :`translate(${-x + yAxisLeftWidth},5)rotate(-180)`)
                // adjust the visiblity of the box
                //yIndicatorScaleGroup.filter(scale => (y>scale.range()[1] && y<scale.range()[0]))[0] -> this condition is to only show the box in the selected indicator
                .style('visibility',() => (y < adjustedHeight || (index === i && yIndicatorScaleGroup.filter(scale => (y>scale.range()[1] && y<scale.range()[0])).length > 0))  ? 'visible':'hidden')
        focus.select(`.focusTexty-${i}`)
                .attr('transform',() => sideOfYAxis==='R'? `translate(${width - x - yAxisRightWidth},5)`
                                                        : `translate(${-x + yAxisLeftWidth},2)`)
                .text(() => {
                                if (y<adjustedHeight) {
                                    let numberOfDigitsBeforeDecimal = updatedYScale.invert(y).toString().split('.')[0].length
                                    return numberOfDigitsBeforeDecimal < 3 ? updatedYScale.invert(y).toFixed(2) : updatedYScale.invert(y).toFixed(0);
                                } else {
                                    if (index === i) {
                                        return yIndicatorScaleGroup.filter(scale => (y>scale.range()[1] && y<scale.range()[0]))[0]?.invert(y).toFixed(2)
                                    }
                                }
                            })
                .style("text-anchor",() => sideOfYAxis === 'R' ? "start":"end")
        
        
    }
    
    focus.select('line.x')
            .attr('x1', -x + yAxisLeftWidth)
            .attr('x2', width - x - yAxisRightWidth)
            .attr('y1', 0)
            .attr('y2', 0);

    focus.select('line.y')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', -y)
        .attr('y2', height_crosshair - y);//this.height - y); 
    //updates the legend to display the date,open, close, high, low and volume and selected mouseover area
    //   updateLegends(currentPoint); 
    // secondary legends to display moving average and bollinger bands values
    //   updateSecondaryLegends(currentPoint['Date']);
}

const updateLegends = (currentPoint) => {
      selectAll('.primary-legend').remove();
      const legendKeys = Object.keys(currentPoint); // return ["Date","Open","High","Low","Close","Volume"]
      const lineLegendSelect = select('#chart')
                                 .select('g')
                                 .selectAll('.primary-legend')
                                 .data(legendKeys);
      lineLegendSelect.join(
          enter => enter.append('g')
                        .attr('class', 'primary-legend')
                        .attr('transform', (d, i) => `translate(${i * 95}, 0)`)
                        .append('text')
                        .text(d => {
                            if (d === 'Date') {
                                return `${d}: ${currentPoint[d].toLocaleDateString()}`;
                            } else if (d === 'High' || d ==='Low' || d ==='Open' || d === 'Close') {
                                return `${d}: ${currentPoint[d].toFixed(2)}`;
                            } else {
                                return `${d}: ${currentPoint[d]}`;
                            }
                        })
                        .style('font-size', '0.8em')
                        .style('fill','orange')
                        .attr('transform', 'translate(15,0)')// align texts with boxes
      );
  }

const updateSecondaryLegends = (currentDate) => {
    const secondaryLegend = {};

    if (this.movingAverageData) {
        const currentPoint = this.movingAverageData.filter(
            datapoint => datapoint['Date'] === currentDate
        )[0];
        secondaryLegend['movingAverage'] = currentPoint;
    }
    if (this.bollingerBandsData) {
        const currentBollingerBandsPoint = this.bollingerBandsData.filter(
            datapoint => datapoint['Date'] === currentDate
        )[0];
        secondaryLegend['bollingerBands'] = currentBollingerBandsPoint;
    }

    const secondaryLegendKeys = Object.keys(secondaryLegend);

    selectAll('.secondary-legend').remove();
    if (secondaryLegendKeys.length > 0) {
        const secondaryLegendSelect = select('#chart')
                                        .select('g')
                                        .selectAll('.secondary-legend')
                                        .data(secondaryLegendKeys);
        secondaryLegendSelect.join(
            enter => enter.append('g')
                        .attr('class', 'secondary-legend')
                        .attr('transform', (d,i) => `translate(${i * 170}, 0)`)
                        .append('text')
                        .text(d => {
                            if (d === 'movingAverage') {
                                return `MovingAverage (50): ${secondaryLegend[d]['average'].toFixed(2)}`;
                            } else if (d === 'bollingerBands') {
                                return `Bollinger Bands (20, 2.0, MA): ${secondaryLegend[d]['lowerBand'].toFixed(2)} - ${secondaryLegend[d]['average'].toFixed(2)}`;
                            }
                        })
                        .style('font-size', '0.8em')
                        .style('fill', 'orange')
                        .attr('transform', 'translate(100,25)'),
            exit => exit.remove()              
        );
    }
    
  }

export default crossHair