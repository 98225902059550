import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../../UniversalComponents/Table'
import MarketDataService from "../../../../../services/MarketService"
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeTickerFromWatchList } from '../../../../../js/actions/watcherActions';
// import { signalWatchList } from '../../../../../js/reducers/watcherReducer';

const TickerTable = (props) => {

   const reduxStore = useSelector(state => state)
   console.log(props) 
   const layoutId = props.layoutId
   const workspaceId = props.workspaceId
   const tickerWatchList = reduxStore.tickerInWatchList[workspaceId][layoutId]
   // const signalWatchList = reduxStore.signalInWatchList
   
   
   // const actions = props.actions
   const dispatch = useDispatch()

   const [symbolList, setSymbolList] = useState([
      {symbol:'',long_name:''}
   ])
   // console.log(tickerParams)
   useEffect(() => {
      if(tickerWatchList.length > 0) {
         symbolListInfo()
      }
   },[tickerWatchList])
   const symbolListInfo = () => {
      
         MarketDataService.getSymbolList()
                           .then(response => {
                              let data = tickerWatchList.map(ticker => response.data.filter(d => d.symbol === ticker.toUpperCase())[0])
                              setSymbolList(data)
                           })
                           .catch(error => {
                              alert(error.name)
                           })
      
   }
   
   
   
   const handleDeleteRow = (indexOfTicker) => {
      
      dispatch(removeTickerFromWatchList([workspaceId,layoutId,indexOfTicker]))
   }
   const removeStyle = {
   //   position: "absolute",
   //   right: "2px",
   //   top: 0,
   cursor: "pointer",
   color:"white",
   };
   const columns = React.useMemo(
      () => [
         {
               Header:'Ticker',
               accessor:'symbol',
               Cell:(props) => {
                  // console.log(props)
                  return (
                     <span>{props.value.toUpperCase()}</span>
                  )
               }
         },
         {
               Header:'Full Name',
               accessor:'long_name',
               Cell: (props) => {
                  return (
                     <span>{props.value}</span>
                  )
               }
         },
     
         {
               Header:'Action',
               accessor:'actions',
               Cell: (props) => {
                  
                  // const ticker = props.row.values.symbol
                  // const  = props.row.values.chartType
                  return (
                     <div> 
                           <div>
                              <IconButton 
                                 aria-label="delete" 
                                 style={removeStyle} 
                                 onClick={() => handleDeleteRow(props.row.index)}
                              >
                                 <DeleteIcon fontSize="small" />
                              </IconButton>

                              
                           </div>
                                       
                     </div>
                  );
               }, 
         },
         
      ],
      []
   )
   return (
      <div>
         <Table columns={columns} data={symbolList} layout={null} />
         {/* <button onClick={handleClick}>Update</button> */}
         {/* <span>{tableValues[0]['ticker']}</span> */}
      </div>
   )
}

export default TickerTable;