import React,{useEffect} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
// import UseLocalStorage from './storage/UseLocalStorage'
import './App.css';
// import Chart from './components/Chart/Chart'
// import DashBoard from './components/DashBoard/DashBoard'
import Workspace from './components/DashBoard/Workspace';
// import Charting from './components/DashBoard/Chart/Charting'
import Login from './components/Login/Login'
import PasswordUpdate from './components/Login/PasswordUpdate';
import * as actions from './js/actions/authActionTypes'
// import { reduce } from 'd3-array';
// import store from './js/store/store'
import { connect } from 'react-redux'

import { createTheme, ThemeProvider } from '@mui/material/styles'
// import {ErrorBoundary} from 'react-error-boundary'
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import IconButton from '@mui/core/IconButton';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import Tooltip from '@mui/core/Tooltip';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { expandMoreStyle, expandLessStyle } from './components/UniversalComponents/cssStyle/iconStyle';
// const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
//   return (
//     <div>
//       <h1>An error occurred: {error.message}</h1>
//       <button onClick={resetErrorBoundary}>Try again</button>
//     </div>
//   );
// };
const theme = createTheme()
function App(props) {
  // const [navBarVisible, setNavBarVisible] = useState(true)
  // const toggleNavBar = () => {
  //   setNavBarVisible(!navBarVisible)
  // }
  useEffect(() => {
    props.setAuthenticatedIfRequired()
  })
  console.log()
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          
          
          <div>
            <Routes>
              <Route exact path="/login" element={<Login {...props}/>} />
              {/* <PrivateRoute exact path="/update_password/" isAuthenticated={props.isAuthenticated}><PasswordUpdate {...props}/></PrivateRoute> */}
              <Route exact path="/update_password/" element={<PrivateRoute isAuthenticated={props.isAuthenticated}><PasswordUpdate {...props}/></PrivateRoute>}/>
              <Route exact path="/dashboard" element ={<PrivateRoute isAuthenticated={props.isAuthenticated}><Workspace {...props} /></PrivateRoute>}>
              </Route>

              
              {/* <Route exact path='/charting' component = {() => <Charting />} /> */}
              <Route exact path="/" element={<PrivateRoute isAuthenticated={props.isAuthenticated}><Navigate to="/dashboard"/></PrivateRoute>}>
                
              </Route>
              {/* <PrivateRoute   isAuthenticated={props.isAuthenticated}><Navigate to="/dashboard"/></PrivateRoute> */}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}
function PrivateRoute({isAuthenticated, children, ...rest}) {
  // console.log(isAuthenticated);
  return (
    isAuthenticated ? (children) : <Navigate to={{pathname:"/login",}}></Navigate>
  )
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null && typeof state.auth.token !=='undefined',
    token: state.auth.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthenticatedIfRequired: () => dispatch(actions.authCheckState()),
    logout:() => dispatch(actions.authLogout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
