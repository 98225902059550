import React, { useState } from 'react';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from 'react-bootstrap/Modal'

import MarketService from '../../services/MarketService';


const Upload = (props) => {
    const [data, setData] = useState({
        title:'',
        research_abstract:"",
        research_resource:"",
        research_file:null,
        tag_codes:"",

    });
    const [errors, setError] = useState({
        title:'',
        research_abstract:"",
        research_resource:"",
        research_file:"",
        tag_codes:"",
    });
    const [success, setSuccess] = useState('')
    const handleChange = ({ currentTarget: input}) => {
        let newData = { ...data };
        newData[input.name] = input.value;
        setData(newData);
    };
    const handleFileChange = (e) => {
        let newData = {...data};
        console.log(e.target.files[0])
        newData["research_file"] = e.target.files[0];
        setData(newData);
    };

    const doSubmit = async (e) => {
        e.preventDefault();
        const response = await MarketService.postResearchReport(data);
        if (response.status === 400) {
            setError(response.data)
        } else {
            console.log(response)
            setSuccess(response.data.success)
        }
    }
    return (
        <Modal {...props} animation={false} aria-labelledby="contained-modal-title-vcenter" size='lg' backdrop='static'>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Upload Research Report
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Form>
                    <Row>
                        <Form.Group className="mb-3" controlId="titleInput">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={data.title}
                                isInvalid={errors.title}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                maxLength={100}
                            />
                            {errors.title && (
                                <Form.Text className="alert-danger" tooltip>
                                    {errors.title}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Research File</Form.Label>
                            <Form.Control
                                type="file"
                                name="research_file"
                                accept="pdf"
                                onChange={(e) => {
                                    handleFileChange(e);
                                }}
                            />
                            {errors.research_file && (
                                <Form.Text className="alert-danger" tooltip>
                                    {errors.research_file}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" controlId="researchAbstractInput">
                        <Form.Label>Research Abstract</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            name="research_abstract"
                            value={data.research_abstract}
                            isInvalid={errors.research_abstract}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                        {errors.research_abstract && (
                            <Form.Text className="alert-danger" tooltip>
                                {errors.research_abstract}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={(e) => doSubmit(e)}
                    >
                        Submit
                    </Button>
                </Form>
                <div>
                    <h5>{success}</h5>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={props.onHide}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Upload