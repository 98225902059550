const CREATE_MARKET_RESEARCH = 'CREATE_MARKET_RESEARCH';
const REMOVE_MARKET_RESEARCH = 'REMOVE_MARKET_RESEARCH';
const UPDATE_ITEM_IN_RESEARCH_LIST = 'UPDATE_ITEM_IN_RESEARCH_LIST';
export const createMarketResearch = payload => {
    return {
        type: CREATE_MARKET_RESEARCH,
        payload
    }
}

export const removeMarketResearch = payload => {
    return {
        type: REMOVE_MARKET_RESEARCH,
        payload
    }
}
export const updateItemInResearchList = payload => {
    return {
        type: UPDATE_ITEM_IN_RESEARCH_LIST,
        payload
    }
};
