import * as d3 from 'd3'

export const removeSVGItems = (chartId, tickerParamsIndex, chartType) => {
  d3.selectAll(`#${chartId} > g`).remove()
	// d3.select(`#${chartId} > g >.yAxis-${tickerParamsIndex}`).remove()
  //       d3.select(`#${chartId} > g >.xAxis`).remove()
  //       d3.select(`#${chartId} > g >.text-${tickerParamsIndex}`).remove()

  //       d3.select(`#${chartId}`).selectAll(`.${chartType}-${chartId}-${tickerParamsIndex}`).remove()

	// d3.select(`#${chartId}`).selectAll(`.mid-line-${chartId},
  //                                           .upper-line-${chartId},
  //                                           .lower-line-${chartId},
  //                                           .yRightRsiAxis-${chartId}-${tickerParamsIndex},
  //                                           .yLeftRsiAxis-${chartId}-${tickerParamsIndex},
  //                                           .rsi-line-${chartId}-${tickerParamsIndex},
  //                                           .RSI-annotation-${tickerParamsIndex},
  //                                           .inst-vergence-line-${chartId}-${tickerParamsIndex},
  //                                           .indicator-vergence-line-${chartId}-${tickerParamsIndex}`)
  //                                         .remove()

  //   	d3.select(`#${chartId}`).selectAll(`.pe-upper-std2ndLevel-${chartId},
  //                                           .pe-upper-std1stLevel-${chartId},
  //                                           .pe-lower-std2ndLevel-${chartId},
  //                                           .pe-lower-std1stLevel-${chartId},
  //                                           .pe-mean-${chartId},
  //                                           .pe-ceiling-${chartId},
  //                                           .yRightPEAxis-${chartId}-${tickerParamsIndex},
  //                                           .yLeftPEAxis-${chartId}-${tickerParamsIndex},
  //                                           .pe-line-${chartId}-${tickerParamsIndex},
  //                                           .annotation-${tickerParamsIndex}`)
  //                                         .remove()
}
